
@import "../../../sass/imports";

.nutrition-info {

    > header {
        text-align: center;
    }

    .nutrient-bars {
        width: 100%;
        margin: auto;
        overflow: hidden;
        padding-top: 10px;
        @include transition(all .333s ease-in-out);
        position: relative;
        text-align: center;

        > li {
            display: inline-block;
            width: calc(100% - 20px);
            max-width: 280px;
            margin: 20px 10px;

            @include max-screen(47em) {
                margin: 5px 10px;
            }
        }
    }

    .amt {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.64;
        margin-right: 5px;
        white-space: nowrap;
        display: flex;
        vertical-align: top;
        gap: 5px;

        em {
            text-transform: uppercase;
            max-width: 200px;
        }
    }

    .per {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.64;
        text-align: right;
        width: 90px;
        color: $josh-blue-gray;
        display: inline-block;
    }

    .bar {
        margin-top: 10px;
        position: relative;
        height: 10px;
        width: 100%;
        background-color: rgba(154, 158, 179, 0.1);
        @include border-radius(30px);
    }

    .fodmap {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.64;
        display: inline-block;

        &[data-fodmap="fodmap-friendly"] {
            color: #2CD296;
        }

        &[data-fodmap="fodmap-tolerable"] {
            color: #FF7735;
        }

        &[data-fodmap="not-fodmap-friendly"] {
            color: #FF6262;
        }    
    }

    .fill {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        // background-image: linear-gradient(to left, #b40078, #fa607d);
        background-color: $raspberry;
        overflow: hidden;
        @include border-radius(30px);
    }

    .macros-pie-chart {
        display: inline-block;
    }

    .display-list {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: flex-start;
        gap: 40px;
        margin-top: 30px;
    }

    .display-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 250px;
        text-align: left;

        >div {
            display: flex;
            justify-content: space-between;
        }
    }

    .edit-row {
        position: relative;
        white-space: nowrap;
        margin: .5em 0;

        .name {
            display: inline-block;
            width: calc(100% - 150px);
            font-size: 15px;
            font-weight: bold;
            color: $text-gray;
        }

        em {
            width: 60px;
            margin: 0 0 0 5px;
            text-align: left;
            display: inline-block;
            white-space: nowrap;
            color: $bluey-gray;
        }
    }

    .warning-msg {
        color: $text-navy;
        font-size: 16px;
        font-weight: bold;
        text-align: center;

        i {
            color: $yellow3;
            font-size: 18px;
        }
    }

    input[type="number"] {
        width: 75px;
        text-align: right;
        font-family: monospace;
        padding: 4px 8px;
    }
}

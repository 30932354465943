

@mixin mobile-base(){

    *, *:before, *:after {
        @include border-box;
        @include tap-highlight(transparent);
        margin: 0;
        padding: 0;
        outline: 0;
    }

    html {
        overflow-x: hidden;
        // @include touch-callout('pan-y');
        // min-height: 100%;
    }

    body {
        font-size: 16px;
        line-height: 1.4;
        position: relative;
        /*top: 0;*/
        /*right: 0;*/
        /*bottom: 0;*/
        /*left: 0;*/
        overflow-x: hidden;
        width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 100%;
        word-wrap: break-word;
        text-rendering: optimizeLegibility;
        -webkit-backface-visibility: hidden;
        -webkit-user-drag: none;
        -ms-content-zooming: none;
        @include text-size-adjust(none);
        @include font-smoothing(on);
        /*@include user-select(none);*/
    }

    /**
     * Set ms-viewport to prevent MS "page squish" and allow fluid scrolling
     * https://msdn.microsoft.com/en-us/library/ie/hh869615(v=vs.85).aspx
     */
    @-ms-viewport { width: device-width; }
}

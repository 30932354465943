
.grocery-order-popup {
    .dropdown-content {
        min-width: 275px;
    }

    .el-fonts {
        color: white;
    }


}

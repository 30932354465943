
@import "./../../../../sass/imports";

.site-footer {
    background: #465567;
    padding: 30px 32px;
    clear: both;

    color: white;
    font-size: 14px;

    .footer-container {
        position: relative;
        max-width: 875px;
        margin: auto;

        > footer {
            h6 {
                font-size: 12px;
                font-weight: normal;
                text-align: center;
                margin: 13px 0 0 0;
            }
        }
    }

    .logo-container {
        text-align: center;
        display: block;

        @include min-screen(600px) {
            display: inline-block;
        }

        img {
            max-width: 87px;
            margin: auto;

            @include min-screen(600px) {
                max-width: 160px;
                margin: 0 0 0 -5px;
            }
        }

        span {
            display: inline-block;
            vertical-align: bottom;
            font-weight: bold;
            font-size: 11px;

            @include min-screen(600px) {
                font-size: 18px;
            }
        }
    }

    .sections {
        text-align: center;
    }

    .section {
        vertical-align: top;
        text-align: left;
        margin: 20px;
        max-width: 250px;
        display: inline-block;

        @include min-screen(500px) {
            &:first-child {
                margin: 20px 20px 20px 0;
            }

            &:last-child {
                margin: 20px 0 20px 20px;
            }
        }
    }

    a, a:visited {
        color: white;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    li em {
        font-weight: bold;
        margin: 0 0 10px 0;
        display: inline-block;
    }

    .tagline {
        text-align: center;
        margin: 5px 0;

        @include min-screen(600px) {
            text-align: left;
        }
    }

    .social-media {
        max-width: none;
        display: block;
        text-align: center;
        margin: 0 0 0 0;

        @include min-screen(600px) {
            position: absolute;
            top: 0;
            right: 0;
        }

        li {
            display: inline-block;

            a {
                color: $text-gray;
                display: inline-block;
                width: 24px;
                height: 24px;
                background-color: #d8d8d8;
                text-align: center;

                @include border-radius(40px);
                margin: 4px;
                padding: 3px 0;

                @include min-screen(600px) {
                    font-size: 18px;
                    width: 40px;
                    height: 40px;
                    padding: 10px 0;
                }
            }
        }
    }

    .retrieve-invite {
        max-width: 200px;
    }

    .contact-sales2-btn, .retrieve-invite2-btn {
        @include outline-button(white);
        background-color: transparent;
        width: 180px;

        margin: 10px 0;
        white-space: nowrap;
        padding: 9px 16px;

        &:hover {
            color: $text-navy;
        }
    }
}

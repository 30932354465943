
@import "../../../../../sass/imports";

.dashboard-activity {
    display: block;
    clear: both;
    margin: 30px 15px 30px 0;
    width: calc(100% - 30px);

    &:first-child {
        margin-top: 0;
    }

    .thumb {
        display: inline-block;
        width: 50px;
        height: 50px;
        vertical-align: top;
        cursor: pointer;
        overflow: hidden;
        @include box-shadow(0, 5px, 10px, 0, rgba(0, 0, 0, 0.1));
        @include border-radius(50px);

        .icon, .feather {
            color: $text-gray;
            font-size: 30px;
            padding: 9px;
            display: inline-block;
            vertical-align: top;
        }

        img.icon {
            padding: 0;
        }
    }

    .container {
        display: inline-block;
        width: calc(100% - 50px);
        min-height: 50px;
        vertical-align: top;
        padding: 5px 10px 0 10px;

        > footer {
            text-align: left;
        }
    }

    > img {
        float: left;
        margin-right: 20px;
        cursor: pointer;
    }

    .icon-cart, .icon-email, .icon-settings2, .icon-search3, .icon-clock2 {
        color: $text-gray;
        font-size: 30px;
        padding: 9px;
        display: inline-block;
        vertical-align: top;
    }

    .from-now {
        // display: inline-block;
        // float: right;

        font-size: 10px;
        letter-spacing: 1px;
        color: $text-gray;
        padding: 3px;

        i {
            font-size: 20px;
            display: inline-block;
            padding-right: 5px;
            vertical-align: -30%;
            color: $bluey-gray;
        }

        em {
            font-size: 10px;
            font-weight: bold;
        }
    }

    p {
        font-size: 14px;
        color: $text-gray;

        em {
            font-weight: bold;
            display: inline-block;
        }
    }

    h6 {
        font-size: 16px;
        font-weight: normal;
        color: $text-gray;
        cursor: pointer;

        a, em {
            color: $raspberry;
            display: inline-block;
        }
    }

    .prescription-mismatch-container {
        clear: both;
        float: none;
        margin: 0;
    }

    .prescription-mismatch-btn {
        padding: 10px;
        height: auto;
        width: auto;
        background-color: $lighter-gray;
        border: none;
        white-space: normal;
    }

    &.update-prefs {
        .content {
            > span {
                &:after {
                    content: ', ';
                }

                &:last-child {
                    &:after {
                        content: ' ';
                    }
                }
            }
        }
    }
}

@media print {
    .hide-on-print {
        display: none !important;
    }

    .avoid-page-break-before {
        -webkit-column-break-before: avoid;
        page-break-before: avoid;
        break-before: avoid;
    }

    .avoid-page-break-after {
        -webkit-column-break-after: avoid;
        page-break-after: avoid;
        break-after: avoid;
    }

    .avoid-page-break-inside {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    // a[href]:after {
    //     content: " (" attr(href) ")";
    // }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    button,
    input[type="checkbox"] {
        display: none !important;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        // max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

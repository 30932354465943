
//------------  Media Query ------------//
//  Author: Rafal Bromirski
// https://github.com/paranoida/sass-mediaqueries
//-----------------------------------------//
// To target all iOS or all Android see cross-platform.scss
// ----
// Using a mobile first approach and having multiple SASS files for breakpoints
// can become hard to manage if you have a large app/website. Changing styles
// for 1 component means searching through multiple SASS files.
// Using mixins like this keep your styles in one location and makes it much easier to maintain.
// This practice is also better suited for device agnostic breakpoints. Set breakpoints when your
// design requires it. Do not set breakpoints based on apple products unless you're only shipping
// to apple devices. 320,480,768,1024 do not cover all mobile designs.  -JF

// Mixins:
//   @ min-screen(width)                      // shortcut for @media screen and (min-width ...)
//   @ max-screen(width)                      // shortcut for @media screen and (max-width ...)
//   @ screen(min-width, max-width)           // shortcut for @media screen and (min-width ...) and (max-width ...)
//   ---
//   @ min-screen-height(height)              // shortcut for @media screen and (min-height ...)
//   @ max-screen-height(height)              // shortcut for @media screen and (max-height ...)
//   @ screen-height(min-height, max-height)  // shortcut for @media screen and (min-height ...) and (max-height ...)
//   ---
//   @ hdpi(ratio)                            // devices with hidpi displays (default ratio: 1.3)
//   ---
//   @ iphone3                                // only iPhone (2, 3G, 3GS) landscape & portrait
//   @ iphone3-landscape                      // only iPhone (2, 3G, 3GS) only landscape
//   @ iphone3-portrait                       // only iPhone (2, 3G, 3GS) only portrait
//   ---
//   @ iphone4                                // only iPhone (4, 4S) landscape & portrait
//   @ iphone4-landscape                      // only iPhone (4, 4S) only landscape
//   @ iphone4-portrait                       // only iPhone (4, 4S) only portrait
//   ---
//   @ iphone5                                // only iPhone (5) landscape & portrait
//   @ iphone5-landscape                      // only iPhone (5) only landscape
//   @ iphone5-portrait                       // only iPhone (5) only portrait
//   ---
//   @ ipad                                   // all iPads (1, 2, 3, 4, Mini) landscape & portrait
//   @ ipad-landscape                         // all iPads (1, 2, 3, 4, Mini) only landscape
//   @ ipad-portrait                          // all iPads (1, 2, 3, 4, Mini) only portrait
//   ---
//   @ ipad-retina                            // only iPad (3, 4) landscape & portrait
//   @ ipad-retina-landscape                  // only iPad (3, 4) only landscape
//   @ ipad-retina-portrait                   // only iPad (3, 4) only portrait
//
// ---------------------------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------------
// --- screen ----------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin screen($res-min, $res-max)
{
  @media screen and ( min-width: $res-min ) and ( max-width: $res-max )
  {
    @content;
  }
}

@mixin max-screen($res)
{
  @media screen and ( max-width: $res )
  {
    @content;
  }
}

@mixin min-screen($res)
{
  @media screen and ( min-width: $res )
  {
    @content;
  }
}

@mixin screen-height($res-min, $res-max)
{
  @media screen and ( min-height: $res-min ) and ( max-height: $res-max )
  {
    @content;
  }
}

@mixin max-screen-height($res)
{
  @media screen and ( max-height: $res )
  {
    @content;
  }
}

@mixin min-screen-height($res)
{
  @media screen and ( min-height: $res )
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- hdpi ------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

// Based on bourbon hidpi-media-queries file (https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_hidpi-media-query.scss)
// HiDPI mixin. Default value set to 1.3 to target Google Nexus 7 (http://bjango.com/articles/min-device-pixel-ratio/)

@mixin hdpi($ratio: 1.3)
{
  @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min--moz-device-pixel-ratio: $ratio),
  only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-resolution: #{round($ratio*96)}dpi),
  only screen and (min-resolution: #{$ratio}dppx)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- iphone ----------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin iphone3
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 1)
  {
    @content;
  }
}

@mixin iphone3-portrait
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 1) and (orientation:portrait)
  {
    @content;
  }
}

@mixin iphone3-landscape
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 1) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- iphone-retina ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin iphone4
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3)
  {
    @content;
  }
}

@mixin iphone4-portrait
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) and (orientation:portrait)
  {
    @content;
  }
}

@mixin iphone4-landscape
{
  @media only screen and (min-device-width: 320px)  and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- iphone-5 --------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin iphone5
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    @content;
  }
}

@mixin iphone5-portrait
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)

  {
    @content;
  }
}

@mixin iphone5-landscape
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- ipads (all) -----------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin ipad
{
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
  {
    @content;
  }
}

@mixin ipad-portrait
{
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait)
  {
    @content;
  }
}

@mixin ipad-landscape
{
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- ipad-retina -----------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin ipad-retina
{
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 2)
  {
    @content;
  }
}

@mixin ipad-retina-portrait
{
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 2) and (orientation:portrait)
  {
    @content;
  }
}

@mixin ipad-retina-landscape
{
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 2) and (orientation:landscape)
  {
    @content;
  }
}

@mixin orientation-portrait
{
  @media (orientation:portrait)
  {
    @content;
  }
}

@mixin orientation-landscape
{
  @media (orientation:landscape)
  {
    @content;
  }
}


@import "../../../../sass/imports";

.pro-scroller {
    position: relative;

    > header {
        padding: 20px;
        padding-bottom: 10px;

        h2 {
            text-align: center;
            font-weight: 300 !important;
            font-size: 16px !important;
            color: $text-gray !important;

            em {
                font-weight: 900;
            }
        }
    }

    .scroller-main {
        max-width: 730px;
        margin: auto;

        .scroll-left, .scroll-right {
            border: 0 none;
            display: block;
            text-align:center;

            position: absolute;
            top: 50%;

            z-index: 3;
            cursor: pointer;
            background: none;
            i {
                color: $grayscale6;
                font-size: 1.5em;
                display: block;
                z-index: 1;
                @include transition(all 0.333s ease-in-out);
            }

            @include max-screen(1000px) {
                display: none;
            }
        }

        .scroll-left {
            left: 20px;

            i:before {
                @include transform(rotateZ(90deg));
                display: inline-block;
            }
        }

        .scroll-right {
            right: 20px;

            i:before {
                @include transform(rotateZ(-90deg));
                display: inline-block;
            }
        }

        &[data-scroll-position="0"] {
            .scroll-left i {
                opacity: 0.3;
            }
        }
    }

    .scroller-viewport {
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: none;
        text-align: center;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .inner-slider {
        display: table;
        padding: .5em .9em;

        @include max-screen(320px) {
            padding-left: .25em;
            paddng-right: .25em;
        }
    }

    .card-container {
        display: table-cell;
        vertical-align: top;
        position: relative;

        padding: .75em;
        width: 228px;
        min-width: 228px;
        max-width: 228px;

        > h5 {
            color: $text-gray;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 15px;
            text-transform: uppercase;
            text-align: center;
        }

        .loading-plans {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        > button, > a button {
            margin-top: 23px;
            background-color: $mint-green3;
            border: none;
            width: 100%;
            padding: 14px;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            color: white;
        }
    }



}


@import "../../../../../sass/imports";

.customizer-prescription-mismatch-list {
    text-align: left;

    .prescription-mismatch-part {
        margin-top: 1em;

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        color: $text-gray;
        font-size: 16px;
        font-weight: bold;
    }

    ul {
        li {
            display: inline-block;
            width: 260px;
            color: $text-gray;

            font-size: 14px;
            padding-left: 2em;
            margin-top: 20px;

            em {
                font-weight: bold;
            }
        }
    }
}

.customizer-prescription-mismatch-btn {
    display: inline-block;
    .icon-warning1 {
        color: $warning-yellow;
    }
}

@import "../../../../../sass/imports";

.meal-details {

    .expander-btn {
        display: flex
;       justify-content: space-between;
        align-items: center;

        &::after {
            margin-left: 0;
        }
    }

    .meal-type-expand {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;

        @include max-screen(47em) {
            gap: 10px;
        }

        .meal-type-label {
            width: 150px;
            text-wrap: auto;

            @include max-screen(47em) {
                width: 120px;
            }

            .meal-type-name, .meal-type-range {
                color: $raspberry;
            }

            .meal-type-name {
                font-size: 14px;
                text-transform: capitalize;
            }

            .meal-type-range {
                font-weight: normal;
                margin-top: 2px;
                text-transform: uppercase;
            }
        }

        .meal-type-details {
            display: flex;
            align-items: center;
            gap: 20px;
            font-weight: 300;
            color: $el-black;
            text-transform: none;

            span {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 180px;
                text-wrap: auto;
            }
        }

        @include max-screen(790px) {
            .meal-type-details {
                width: 150px;
                text-wrap: auto;
                flex-direction: column;
                gap: 0;

                span {
                    width: 150px;
                }
            }
        }
    }

    .meal-types-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .icon-spinner2 {
            font-size: 50px;
            color: $raspberry;
        }
    }

    .eating-pattern {
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px !important;

        .select-container {
            min-width: 110px;

            .value {
                font-size: 14px;
            }

            .option[data-selected=true] {

                label {
                    color: white;
                }
            }

            .option {
                padding: 7px 10px;

                label {
                    padding: 0;
                }
            }
        }
    }

    .meal-type-info {
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        .with-label {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            input {
                font-size: 14px;
            }

            .select-container {
                min-width: 110px;

                .value {
                    font-size: 14px;
                }
            }
        }

        @include max-screen(640px) {
            .with-label {
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
            }
        }

        .meal-type, .keywords, .max-time, .max-cost, .time, .type, .participants {
            width: fit-content;

            input::placeholder {
                font-style: normal;
            }
        }

        .keywords {
            input {
                width: 270px;
            }
        }

        .time {
            .select-container {
                width: calc(50% - 20px);
            }
        }

        .participants {
            display: flex;
            flex-direction: column;
            gap: 0;
            margin: 30px 5px 50px;

            .participant {
                display: flex;
                align-items: center;
                gap: 20px;
                width: 100%;
            }
        }
    }
}

@import "../../../../sass/imports";

.terms-of-service-modal {
    .privacy-policy {
        @include min-screen(47em) {
            padding-top: 20px;
        }
    }
}


@import "../../sass/imports";

.privacy-policy {
    padding-top: 120px;
    background-color: white;

    > header {
        h1 {
            font-size: 2.5em;
            text-align: center;
            color: $white;
        }
    }

    .eula-content {
        max-width: 940px;
        margin: auto;
    }

    table {
        max-width: 600px;

        td, th {
            border: 1px solid $text-gray;
            padding: 5px;
            font-size: 14px;
        }
    }


    .privacy-policy-contents {
        display: block;
        width: 100%;
        color: $text-gray;
        padding: 20px;

        h1 {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin: 1em;
            margin-bottom: 0;
        }

        h2 {
            text-transform: uppercase;
            font-size: 16px;
            margin-top: 1em;
            margin-bottom: 1em;
        }

        h3 {
            font-size: 14px;
            margin-top: 1em;
        }

        em {
            font-weight: bold;
        }


        li, p {
            margin-bottom: 1em;
            font-size: 14px;
        }

        address {
            margin: 1em;
            font-style: normal;
            font-size: 14px;
        }

        ol {
            list-style: decimal;
            li { margin-left: 2em; }
        }

        ul {
            list-style: disc;
            li { margin-left: 2em; }
        }


        .bold {
            font-weight: bold;
        }

        .italic {
            font-style: italic;
        }

        .underline {
            text-decoration: underline;
        }

        .uppercase {
            text-transform: uppercase;
        }
    }
}


@import "../../../../../docs/sass/imports";


.nutrient-range-filters-container {
    margin-top: .5em;
    width: 100%;

    > footer {
        text-align: center;

        button {
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    &[data-showall="true"] {
        .nutrient-range-filters {
            max-height: 1700px;

            &:after {
                opacity: 0;
                height: 0;
            }
        }

        > footer button {
            text-decoration: none;
            color: $inactive-text-gray;
        }
    }


    .nutrient-range-filters {
        position: relative;

        max-height: 75px;
        overflow: hidden;
        @include transition(all .333s ease-in-out);
        text-align: center;

        &:after {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 20px;
            background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            @include transition(all 0.333s ease-in-out);
        }

        .nutrient-filter {
            text-align: left;
            display: inline-block;
            width: 20%;
            max-width: 250px;
            min-width: 160px;
            padding: 0 .5em;

            @include max-screen(47em) {
                width: 40%;
                min-width: 145px;
            }

            > label {
                font-weight: 800;
                font-size: 10px;
                text-transform: uppercase;
                color: $text-gray;
                line-height: 1.2;
                display: inline-block;

                &[data-long="true"] {
                    font-size: 9px;
                }

                .icon-chevron-down {
                    margin-left: 5px;
                }

                em {
                    text-transform: none;
                    font-weight: normal;
                }
            }
        }

        .typeable-range {
            > label {
                display: inline-block;
                width: calc(50% - 4px);
                margin: auto 2px;
                font-size: 9px;
                text-transform: uppercase;

                input {
                    width: calc(100% - 2px);
                    font-size: 14px;
                    @include border-radius(0);
                    color: $raspberry;
                    font-weight: bold;
                    border: 1px solid $light-gray-border;

                    @include placeholder {
                        font-style: italic;
                    }
                }

                &:first-child {
                    margin-left: 0 !important;
                }

                &:last-child {
                    margin-right: 0 !important;
                }
            }

        }
    }

    .sub-action-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;
    }
}


.nutrient-availability-modal-body-container {
    text-align: center;

    .el-toggle-switch {
        margin: 20px auto;
    }
    > footer {
        margin-top: 40px;
    }

}




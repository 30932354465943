@import "./../../../../sass/imports";

// off canvas nav
.side-navigation {
    width: 280px;
    height: 100vh;
    max-width: 280px;
    overflow-x: auto;
    z-index: 20;
    background: rgba(0, 0, 0, 0.95);
    padding: 25px;
    @include transition(0.33s all ease-in-out);

    hr {
        border-bottom: 1px solid white;
        width: 100%;
        margin: 20px 0;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.side-menu-modal {
    .drawer-menu-modal-container {
        top: 0;
        right: 0;
        left: auto;
        border: none;
        height: 100vh;
        width: 280px;
        @include border-radius(0);

        .inner-container {
            width: 100%;
            height: auto;
            background: none;
            @include border-radius(0);
        }

        .child-container {
            position: relative;
            top: auto;
            right: auto;
        }

        .cancel-btn {
            display: none;
        }
    }
}

.side-nav-list {
    list-style-type: none;
    li {
        padding: 0 0 0 25px;
        font-size: 14px;
    }
    header {
        color: white;
        font-weight: bold;
        cursor: default;
        font-size: 16px;
    }
    a {
        color: white;
        display: block;
        padding: 0.25em 0;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: $mint-green3;
        }
    }
}

.side-nav-header {
    width: 100%;
    clear: both;

    a {
        display: block;
    }

    > a {
        display: inline-block;
    }

    .side-nav-name {
        padding: 10px 15px 0 0;
    }

    h6 {
        color: #eee;
        font-size: 1.25em;
        text-align: left;
        font-weight: 600;
        white-space: no-wrap;
        padding: 6px 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: bottom;
    }

    .profile-image {
        @include border-radius(100%);
        width: 60px;
        height: 60px;
        margin: 0 10px 0 -10px;

        float: left;
    }

    .off-canvas-close {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        color: white;
        font-size: 14px;
        padding: 0.5em;
        background-color: transparent;

        @supports (top: constant(safe-area-inset-top)) {
            top: constant(safe-area-inset-top);
        }
        @supports (top: env(safe-area-inset-top)) {
            top: env(safe-area-inset-top);
        }
    }

    .avatar {
        padding-right: 15px;

        i,
        em {
            width: 40px;
            height: 40px;
            @include border-radius(35px);
            display: inline-block;
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
        }

        em {
            font-size: 16px;
            white-space: nowrap;
            font-weight: bold;
            padding: 9px 4px;
            text-align: center;
            color: $raspberry;
            background-color: white;
        }

        i.icon-male3,
        i.icon-female3 {
            font-size: 24px;
            padding: 8px;
            background-color: white;
            color: $raspberry;
        }
    }

    .side-nav-profile {
        display: flex;
        align-items: center;
    }
}

.side-nav-list {
    list-style-type: none;
    clear: both;
    li {
        padding: 0 0 0 25px;
        font-size: 14px;
    }
    header {
        color: white;
        font-weight: 800;
        cursor: default;
        font-size: 16px;
        font-weight: bold;
        margin-top: 1em;
    }
    a {
        color: white;
        display: block;
        padding: .25em 0;
        text-decoration: none;
        cursor: pointer;
    }
}

.side-nav-footer {
    .side-nav-list {
        li {
            padding-left: 25px;
            font-size: 13px;
        }
    }

    .off-canvas-social {
        display: table;
        width: 100%;
        margin: 1.5em 0 1em 0;
        > div {
            display: table-cell;
            text-align: center;
        }
        a {
            color: white;
            font-size: 1.5em;
            display: block;
            text-decoration: none;
            &:hover {
                color: $mint-green3;
            }
        }
    }
}

@media print {
    .parent-layout,
    .main-layout,
    .scroll-container {
        position: static;
    }
    .off-canvas-nav {
        display: none;
    }
}

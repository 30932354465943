@import "../../../sass/imports";

.upgrade-membership-modal-container {

    &[data-long="true"] {
        overflow-y: auto;
    }

    .header-container {

       .header-close-btn{
            position: absolute;
            bottom: -5px;
            color: white;
            font-size: 15px;
            text-align: center;
            width: 100%;
            font-weight: bold;
            letter-spacing: 1.25px;
            text-transform: uppercase;

            p {
                display: inline-block;
                line-height: 22px;
            }

            p::before {
                    @include icon-font;
                    content: $icon-arrow-left;
                    color: white;
                    position: absolute;
                    left: 7%;
                    line-height: 22px;
                    font-size: 21px;

            }

       }

       @include max-screen(47em) {
            .close-modal-btn{
                width: 100%;
                height: 110px;
                .icon-arrow-left {
                    float: left;
                    margin: 0;
                }

            }
        }

    }

}


.upgrade-membership {
    padding: 20px;
    @include max-screen(47em) {
        margin: 0 auto;
    }

    @include min-screen(47em) {
        width: 590px;
    }

    color: $text-navy;

    h1 {
        text-align: center;
        width: 100%;

        &.permit-picker-title {
            width: 60%;
            @include max-screen(47em) {
                text-align: center;
                width: 100%;
                width: 90%;
                margin: auto;
            }
        }
        font-size: 24px;
        margin: 0 auto 1em;
    }


    &.loading {
        &.onboarding {
            margin: 0 auto;
            padding: 0px 20px;
        }
        &.offers, &.invoice-preview, &.subscribing {
            @include keyframes(skeleton-load) {
                0% {
                    left: -200%;
                }
                100% {
                    left: 200%;
                }
            }

            h1, li, div{
                color: transparent;
                position: relative;
                min-height: 1.5em;
                overflow: hidden;
                border-radius: 17px;

                &:before {
                    display: block;
                    width: 200%;
                    height: 100%;
                    content: ' ';
                    background: linear-gradient(90deg, transparent 0%, #EAEAEA 50%, transparent 100%);
                    @include animation-name(skeleton-load);
                    @include animation-duration(2s);
                    @include animation-timing-function(linear);
                    @include animation-iteration-count(infinite);

                    position: absolute;
                    top: 50%; @include transform(translateY(-50%));
                    left: -100%;
                }

                &:after {
                    width: 80%;
                }
            }

            &.invoice-preview, &.subscribing{
                h1{
                    font-size: 24px;
                    margin-top: 20px;
                    margin-bottom: 40px;
                    padding: 0 20px 20px;
                }
            }

            li {
                margin-bottom: 10px;
            }

            .permit-card-frame {
                margin-top: 15px;
                max-width: 550px;
                height: 170px;
            }

            .invoice-section-frame {
                margin-top: 15px;
                max-width: 550px;
                height: 85px;
            }

            .add-on-frame {
                margin: 25px 0 0;
                height: 190px;
                padding: 20px;
            }

            .logo-frame {
                display: block;
                width: 72px;
                height: 72px;
                margin: auto;
            }

            .footer-frame {
                display: block;
                margin: auto;
                width: 80%;
                padding: 20px;
                height: 40px;
            }
        }
    }

    h2 {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }

    > footer {
        text-align: center;
    }

    .icon-spinner2 {
        display: inline-block;
        color: $raspberry;
        font-size: 35px;
    }

    &.card-capture{
        .subscription-price{
            width: 50%;
            display: block;
            margin: 0 auto;
        }
    }

    .subscription-price{
        text-align: center;

        p {
            display: inline-block;
            &:first-child {
                font-weight: bold;
                font-size: 30px;
            }
        }
    }

    .permit-picker-header {
        max-width: 550px;
        position: relative;
        margin: 0 auto;

        .select-container {
            width: 170px;
            @include max-screen(47em) {
                display: block;
                position: relative;
                bottom: initial;
                margin: 25px auto;
                width: 100%;
            }
        }
    }


    .feature-blurb {
        display: block;
        padding-bottom: 15px;
        margin: 0 auto;

        @include min-screen(47em) {
            width: calc(100% -  185px);
        }
        ul {
            li {
                padding: 5px 0 5px 30px;
                position: relative;
                @include max-screen(47em) {
                    padding: 5px 0 5px 50px;
                }
                &:before {
                    @include icon-font;
                    content: $icon-check2;
                    color: $mint-green3;
                    font-size: 14px;
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    @include max-screen(47em) {
                        left: 25px;
                    }
                    @include transform(translateY(-50%));
                }
            }
        }
    }

    .permit-card {
        padding: 10px 50px 10px 15px;
        margin: 20px auto;
        max-width: 550px;
        position: relative;
        cursor: pointer;
        @include min-screen(47em) {
            height: 170px;
        }

        @include border-radius(10px);
        @include box-shadow(0px, 10px, 40px, 10px, rgba(0, 0, 0, 0.1));

        h3 {
            font-size: 20px;
            font-weight: bold;
            padding: 10px 0;
        }

        > p {
            padding: 0px 0 25px 0;
        }

        span {
            p {
                display: inline-block;
                font-size: 16px;
                &.subscription-price {
                    font-size: 30px;
                }
            }
        }

        .permit-bubble {
            position: absolute;
            width: 68px;
            height: 68px;
            border-radius: 68px;
            right: 40px;
            top: 50px;
            background: #EAEDEF;
            padding: 26px 21px;

            i {
                margin-right: -6px;
                display: inline-block;
                font-weight: bold;
            }
        }
    }



    .current-plan {
        font-weight: bold;
        color: $mint-green3;
    }

    .valid-permits {
        div:first-child {
            position: relative;
            border: 2px solid #2DCA93;

            &:before {
                content: 'best value';
                position: absolute;
                top: 12px;
                left: 140px;
                margin: 10px;
                color: white;
                background-color: #2DCA93;
                padding: 5px 10px;
                @include border-radius(37px);
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }

    .permit-picker {
        margin: 0 auto;
        max-width: 550px;
        @include min-screen(47em) {
            margin: 0 30px;
        }
    }

    .stripe-invoice {
        > footer {
            text-align: center;
            padding: 20px 0;

            button {
                @include flat-button($raspberry);
                height: 40px;
                width: 80%;
                margin: 0;
            }
            .assurance {
                color: #81909F;
                font-size: 14px;
                margin: 5px 0 0;
            }
        }
    }

    .mobile-side-testimonial-container{
        color: #425569;

        margin: 0;
        h2 {
            font-size: 24px;
        }

        > div {
            margin: 20px 0;
            > div {
              border-bottom: 1px solid #425569;
              margin-bottom: 25px;
            }
        }
        .avatar {
            width: 41px;
            height: 41px;
            margin: 0;
            background-color: #EAEDEF;
            @include border-radius(80px);
            text-align: center;
            display: inline-block;
            margin: 20px 20px 20px 0;
            vertical-align: bottom;
        }

        .dietitian-title {
            display: inline-block;
            width: 85%;
            margin: 0;
            margin: 20px 0;
            line-height: 22px;

            > p {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

        }
    }

    .billing-toggle{
        display: inline-block;
        width: 340px;
        font-size: 14px;  
        height: 40px;
        background: #FFFFFF;
        border-radius: 20px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 40px;
        position: relative;
        left: calc((100% - 340px) / 2);
        background-color: $el-grayish-blue1;
        text-transform: uppercase;
        color: $el-grayish-blue;

        @include max-screen(47em) {
            font-size: 12px;
            width: 300px;
            left: calc((100% - 340px) / 2);
        }

        .savings {
            background-color: $el-green2;
            color: $el-grayish-blue;
            font-size: 10px;
            border-radius: 4px;
            padding: 3px 8px;
            position: relative;
            left: 10px;
            bottom: 1px;
            font-weight: bold;
        }

        @include max-screen(47em) {
            display: block;
            position: relative;
            top: initial;
            right: initial;
            margin: 25px auto;
        }

        .selected-billing-side {
            position: absolute;
            height: 100%;
            width: 50%;
            font-family: Lato;
            font-style: normal;
            text-align: center;
            text-transform: capitalize;

            &[data-right-side="true"] {
                left: 50%;
            }

            &[data-active="true"] {
                color: #FFFFFF;
                background-color: $el-grayish-blue;
                height: calc(100% - 6px);
                line-height: 34px;
                top: 3px;
                border-radius: 20px;
            }
        }
    }

    &.contact-your-pro {
        h2 {
            margin: 0 0 2em 0;
        }

        .my-dietitian .dietitian {
            border-right: none;
        }
    }
}

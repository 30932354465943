
@import "../../../../../sass/imports";

.plan-editor-stats {
    text-align: center;

    &[data-showall="true"] {
        .display-list {
            max-height: 99em;

            &:after {
                display: none;
            }
        }

        .see-all-nutrition-btn {
            text-decoration: none;
            color: $inactive-text-gray;
        }
    }

    .display-list {
        max-width: 1000px;
        margin: auto;
        overflow: hidden;
        padding-top: 10px;
        @include transition(all .333s ease-in-out);
        position: relative;

        &:after {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 20px;
            @include background-image(linear-gradient(top, rgba(255,255,255,0) 0%, #fafafa 100%));
            @include transition(all 0.333s ease-in-out);
        }
    }

    .other-insights {
        margin-top: 30px;

        .insight {
            display: inline-block;
            border: .5px solid $light-gray-border;
            padding: 30px;
            width: 130px;
            margin: 10px;
        }

        .groceries {
            cursor: pointer;
        }

        em {
            font-size: 20px;
            font-weight: bold;
            color: $text-gray;

            > span {
                font-size: 9px;
                display: inline-block;
                margin-left: 5px;
                color: $inactive-text-gray;
                text-transform: uppercase;
            }
        }

        p  {
            font-size: 9px;
            color: $inactive-text-gray;
            text-transform: uppercase;
        }
    }
}

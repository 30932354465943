// Messy is the opposite of neat, e.g.: bourbon-neat, which we don't want to use anymore.

@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition:    $transition;
    -o-transition:      $transition;
    transition:         $transition;
}

@mixin background-image($bgi) {
    background-image: $bgi;
}

@mixin appearance($appearance) {
    -webkit-appearnace: $appearance;
}

@mixin filter($filter) {
    filter: $filter;
    -webkit-filter: $filter;
}

@mixin animation-name($name) {
    animation-name: $name;
}

@mixin animation-duration($content) {
    animation-duration: $content
}

@mixin animation-timing-function($content) {
    animation-timing-function: $content
}

@mixin animation-iteration-count($content) {
    animation-iteration-count: $content
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@import "../../../../sass/imports";

.category-card {
    .recipe-card-image {
        background-image: $preferences-gradient;
    }

    .icon-logo2 {
        position: absolute;
        top: 40%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        font-size: 60px;
        opacity: .2;
    }
}

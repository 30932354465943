
@import "../../../../sass/imports";

.global-search-no-results {
    text-align: center;

    h2 {
        margin: 20px auto;
        font-size: 18px;
        line-height: 1.1;
        color: #4a494d;
    }

    i {
        display: inline-block;
        font-size: 75px;
        color: $raspberry;
        margin: 10px 0 20px 0;
    }

    > footer {
        text-align: center;
    }

    .create-custom-meal-btn {
        background-color: $orange4;
        border: none;

        color: white;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 8px 32px;

        @include border-radius(32px);
    }
}

// http://colormonster.io/
//https://www.google.com/design/spec/style/color.html#color-color-palette
// color maps: http://codepen.io/zgreen/blog/sass-color-maps

// Default Color Variables

// styleguide colors
$orange: #fd5408 !default;
$orange2: #EA8123 !default;
$orange3: #e97f2a !default;
$orange4: #ff7735 !default;
$pink: #d99fc1 !default;
$hot-pink: #e169b4 !default;
// old raspberry = #a00f64
$raspberry: #A30063 !default;
$raspberry1: #a02065 !default;
$dark-magenta: #780b4b !default;
$favorites: #ed1b77 !default;

$purple1: #5f6b97 !default;
$purple2: #37467d !default;
$purple3: #5f1e50 !default;
$dark-violet: #5f1e50 !default;

// styleguide red scale: light to dark
$red1: #fc7572 !default;
$red2: #fb4743 !default;
$red3: #fa1914 !default;
$red4: #e32c07 !default;
$red5: #bb130f !default;

// styleguide blue scale: light to dark
$blue1: #bde1f1 !default;
$blue2: #9cd2ea !default;
$blue3: #5ab4dc !default;
$blue4: #5f6b97 !default;
$blue5: #37467d !default;

// styleguide yellow scale
$yellow1: #fff369 !default;
$yellow2: #ffeb05 !default;
$yellow3: #e6b400 !default;

// styleguide green scale: light to dark (different shades)
$light-green: #82d75a !default;
$lime-green: #086446 !default;
$mint-green1: #9fe9d1 !default;
$mint-green2: #6fdeba !default;
$mint-green3: #2cd296 !default;
$mint-green4: #3DB083 !default;
$kelly-green: #0b9669 !default;
$dark-green: #086446 !default;
$teal-green: #249c71 !default;

// styleguide grayscale color range 1-10 (white to black)
$light-gray: #f1f2f5 !default;
$lighter-gray: #f3f3f3 !default;
$grayscale1: #ffffff !default;
$grayscale2: #e6e7ea !default;
$grayscale3: #cdcfd5 !default;
$grayscale4: #b4b7c0 !default;
$grayscale5: #9b9fab !default;
$grayscale6: #828796 !default;
$grayscale7: #626571 !default;
$grayscale8: #434243 !default;
$grayscale9: #212226 !default;
$grayscale10: #000000 !default;

// Colors from Ionut
$text-gray: #425569;
$text-navy: #203152;
$inactive-text-gray: #9b9fb4;
$bluey-gray: #a8acbe;
$light-gray-border: #e2e2e2;
$super-light-gray: #fafafa;
$icon-inactive: #9b9fb4;
$another-gray: #e3e4e9;
$yet-another-gray: #334356;
$yet-another-gray2: #8a92a0;
$yet-another-gray3: #59677e;
$warning-yellow: #f5a623;
$background-gray1: #243342;
$logo-primary-color: #233150;
$logo-accent-color: $mint-green3;
$warning-yellow: #f6a623;
$grocery-button: #128d15;
$katherine-gray: #a8acbe;
$hip-gray: #3f556b;

// Colors I should have gotten from Ionut
$error-border: #ff7a7a;
$another-border: #d5d5d5;
$yet-another-border: #e1e1e1;

// Colors from Josh
$grocery-meals: #8d9dad;
$dark-slate-gray: #324253;
$josh-blue-gray: #7c8a99;
$light-blue-grey: #ced8de;
$josh-light-gray: #b6bfca;
$another-josh-gray: #50667b;

// Gradients from Josh
$default-card-gradient: linear-gradient(120deg, $bluey-gray, #f0f0f0);
$leftover-card-gradient: linear-gradient(120deg, #D0AF74, #D2BA86);
$product-card-gradient: linear-gradient(120deg, #F085A3, #FAC9B6);
$custom-card-gradient: linear-gradient(289deg, #74CAEE, #93DAF9);
$food-card-gradient: linear-gradient(115deg, #F2AE8D, #FCCA9E);

$participants-gradient: linear-gradient(to right, #DA67AD, #B7498C);
$avoidances-gradient: linear-gradient(to right, #EB959E, #E97A99);
$preferences-gradient: linear-gradient(to right, #2EABE4, #137EAB);
$cooking-gradient: linear-gradient(to right, #2CD296, #0EAA7F);
$infocard-gradient: linear-gradient(to bottom, #5b7287, #3d4854);
$past-meals-gradient: linear-gradient(to bottom, $grayscale2, $grayscale3);

// Colors from Katherine
$list-heading-gray: #4f5362;
$inactive-bullet-gray: #cacbd0;

// NAVIGATION VARIABLES
$nav-meals: $mint-green3;
$nav-groceries: $raspberry;
$nav-prep: $orange3;
$nav-goals: $light-green;
$nav-library: $dark-violet;

// MEAL VARIABLES
// $breakfast-color: $yellow3;
// $lunch-color: $orange;
// $dinner-color: $purple2;
// $other-color: $kelly-green;
// $meals-color: $grayscale8;
$breakfast-color: #95b9c7;
$lunch-color: #0074d9;
$dinner-color: #684690;
$snack-color: #001f3f;
$leftovers-color: $grayscale6;

// white gradient
$white-start: #ececed !default;
$white-end: #dfdfe0 !default;

//gray gradient
$gray-start: #fefefe !default;
$gray-end: #e7e7e7 !default;

// links
$link-color: #0fc88c !default;
$link-hover: #0b9669 !default;
$link-active: #0fc88c !default;
$tap-highlight: transparent !default;

// alerts
$info-color: #a00f64 !default;
$alert-color: #5ab4dc !default;
$error-color: #e32c07 !default;
$error-background: #F8E8E8 !default;
$warning-color: #e6b400 !default;
$warning-background: #F7E2CB !default;
$success-color: #64cd32 !default;
$success-background: #E2F8E3 !default;

// disabled
$disabled-color: #999 !default;
$disabled-text: #666 !default;
$disabled-background: #ECECED !default;
$disabled-border: #CCCCCC !default;

// named colors
$white: #ffffff !default;
$black: #000000 !default;
$yellow: #f1c40f !default;
$other-orange: #e67e22 !default;
$red: #991b1f !default;
$dark-red: #923333 !default;
$pink: #f02475 !default;
$purple: #9b59b6 !default;
$charcoal: #4D4E53 !default;
$asphalt: #34495e !default;
$blue: #3498db !default;
$blue-alt: #00539F !default;
$turquoise: #1abc9c !default;
$green: #228442 !default;
$clouds: #ecf0f1 !default;
$red : #C13832 !default;
$pomegranate : #C0392B !default;
$belize: #2980b9 !default;
$emerald : #2ecc71 !default;
$sunflower: #f1c40f !default;

//  brand colors
$android-green: #b3c833 !default;
$windows-purple: #9B4F96 !default;
$apple-button: #181818 !default;
$firefox-orange: #E66000 !default;
$twitter: #00ABF0 !default;
$facebook: #3A5898 !default;
$tumblr: #44546B !default;
$linkedin: #007AB8 !default;
$pinterest: #CD1D1F !default;
$googleplus: #DE492F !default;
$gmail: #E54C3B !default;
$rss: #FF7F00 !default;
$youtube: #CD332D !default;
$github: #171516 !default;
$stackoverflow: #FF7312 !default;
$instagram: #231F20 !default;
$flickr: #FF0083 !default;
$foursquare: #0A9ACF !default;
$dribbble: #ea4c89 !default;
$dropbox: #007FE1 !default;
$trello: #3493CA !default;
$skype: #009FE3 !default;


// dont think we'll use this
$primary-color: #00539F !default;

$primary-complement: complement($primary-color);
$primary-inverted: invert($primary-color);
$primary-grayscale: grayscale($primary-color);

$primary-light: lighten($primary-color, 10%);
$primary-lighter: lighten($primary-color, 20%);
$primary-lightest: lighten($primary-color, 30%);

$primary-dark: darken($primary-color, 10%);
$primary-darker: darken($primary-color, 20%);
$primary-darkest: darken($primary-color, 30%);

$primary-hue-ten: adjust-hue($primary-color, 10);
$primary-hue-fifty: adjust-hue($primary-color, 50);
$primary-hue-hundred: adjust-hue($primary-color, 100);

$primary-saturate: saturate($primary-color, 50%);
$primary-desaturate: desaturate($primary-color, 50%);



// EatLove Style Guide Colors

$el-orange: #FF7735;
$el-orange1: #FFDECE;
$el-orange2: #FFA579;
$el-orange3: #C94200;
$el-orange4: #882C00;
$el-orange-gradient: linear-gradient(270deg, #D74600 -25%, #FF7735 100%);

$el-green: #2CD296;
$el-green1: #C1FFE0;
$el-green2: #62EBA7;
$el-green3: #00AC57;
$el-green4: #00783C;
// no green gradient

$el-raspberry: #A10C63;
$el-raspberry1: #FEE9F2;
$el-raspberry2: #CB5A9F;
$el-raspberry3: #950F4C;
$el-raspberry4: #720034;
$el-raspberry5: #FFF7FA;

$el-raspberry-gradient1: linear-gradient(270deg, #B40078 -25%, #FA607D 100%);
$el-raspberry-gradient2: linear-gradient(131.61deg, #DA67AD 4.29%, #B7498C 89.01%);
$el-raspberry-gradient3-1: #E97A99;
$el-raspberry-gradient3-2: #EB959E;
$el-raspberry-gradient3: linear-gradient(311.36deg, $el-raspberry-gradient3-1 8.17%, $el-raspberry-gradient3-2 96.53%);

$el-grayish-blue: #475672;
$el-grayish-blue1: #E7ECEF;
$el-grayish-blue2: #C3C6D3;
$el-grayish-blue3: #64779B;
$el-grayish-blue4: #2E3B55;

$el-blue: #55ACEE;
$el-blue1: #D9EFFF;
$el-blue2: #B4DEFF;
$el-blue3: #0078B5;
$el-blue4: #3B5998;
$el-blue-gradient: linear-gradient(304.35deg, #137EAB 20.37%, #2EABE4 96.54%);

$el-gold: #DCB76B;
$el-gold1: #FFF1D6;
$el-gold2: #F8D692;
$el-gold3: #C6993C;
$el-gold4: #AD8124;
$el-gold-gradient: linear-gradient(288.97deg, #BC9B60 20.62%, #D2BA86 97.35%);

$el-black: #000;
$el-gray1: #777881;
$el-gray2: #B7B7B7;
$el-gray3: #E2E2E2;
$el-gray4: #ECEFF1;
$el-gray5: #F6F7F7;
$el-white: #fff;

$el-error: #FF1B1B;
$el-success: #2CD296;


@import "../../../../sass/imports";

.patient-conditions {

    ul {
        li {
            width: 100% !important;
        }
    }
}


@import "../../../../../sass/imports";

.meal-preferences {
    padding-top: 10px;

    .toggle-button {
        width: 129px;
        max-width: 129px;
    }

    .skill-level, .plan-size, .leftovers-enabled {
        width: calc(50% - 20px);
        margin: 10px;

        @include max-screen(430px) {
            width: calc(100% - 20px);
        }
    }

    .kitchen-equipment {
        margin-top: 30px !important;

        .tag {
            width: 96px;
            font-size: 14px;
            text-transform: lowercase;
        }

        .el-pro-checkbox {
            flex: 80px;
            flex-grow: 0;
            margin-bottom: 0;

            &::before {
                font-size: 18px;
                width: 20px;
                height: 20px;
            }
        }

        .equipment {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            align-items: baseline;
        }
    }

    .select-meal-preferences {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .basic-limit-tag {
        font-size: 14px;
        color: $text-gray;
        width: 100%;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        margin: 20px 10px 0;

        @include max-screen(675px) {
            margin: 20px 5px 0;
        }
    }

    > p, .extra-avoidances > p, .family-size > p {
        text-align: center;
        padding: 20px 0;
        color: $text-gray;
        font-size: 14px;
    }

    .meal-types-container {
        border: 1px solid transparent;
        margin: 0 -1px;
        text-align: center;

        @include min-screen(430px) {
            margin: 30px -1px 0 -1px;
        }

        p {
            color: $text-gray;
            font-size: 14px;
        }
    }

    .spinners {
        text-align: center;
    }

    .spinner {
        display: inline-block;
        width: 130px;
        height: 79px;

        position: relative;
        border: 1px solid $raspberry;
        color: $raspberry;
        text-align: center;
        padding: 10px;
        margin: 10px;

        .increment, .decrement {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 28px;
            font-weight: 800;
            border: none;
            background: none;
        }

        .decrement {
            left: 0;
        }

        .increment {
            right: 0;
        }

        p {
            font-size: 10px;
            font-weight: bold;
        }

        em, i {
            display: block;
        }
    }

    .max-cost-per-serving {
        display: flex;
        flex-wrap: wrap;
        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        min-width: 0;

        @include min-screen(524px) {
            min-width: 614px;
            padding-left: 89px;
            padding-right: 46px;
        }


        .keyword {
            margin: 10px 0;
            width: 50%;
            text-align: left;
            white-space: nowrap;

            @include max-screen(340px) {
                width: 100%;
            }

            label {
                padding: 0;
                width: 60px;

                @include max-screen(523px) {
                    margin-left: 10px;
                }
            }

            @include max-screen(523px) {
                .input-container {
                    margin-left: 10px;
                }
                input {
                    width: 135px !important;
                }
            }

            @include max-screen(340px) {
                input {
                    width: 250px !important;
                }
            }
        }
    }

    .keyword-preferences {
        text-align: center;
        margin-top: 30px;

        p {
            color: #5a6677;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .keyword {
        margin: 10px;
        text-align: left;

        label {
            display: inline-block;
            width: 150px;
            font-size: 12px;
            font-weight: bold;
            color: $text-gray;

            padding-left: 77px;
            vertical-align: middle;

            @include max-screen(523px) {
                padding-left: 0;
                display: block;
            }
        }

        .select-container, input[type="text"], input[type="number"] {
            max-width: 333px;
            width: calc(100% - 150px);
            vertical-align: middle;
            background-color: transparent !important;

            @include max-screen(523px) {
                width: 100%;
            }
        }

        .input-container {
            position: relative;
            display: inline;

            span {
                margin-right: 5px;
            }

            ::placeholder {
                font-size: 10px;
                font-weight: 700;
                font-style: normal;
                text-align: right;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
        }

        .example {
            position: absolute;
            bottom: 3px;
            right: 11px;
            font-size: 10px;
            font-weight: bold;
            color: $icon-inactive;
            z-index: 0;
        }
    }

    .extra-avoidances {
        border: 1px solid $yellow2;
        @include box-shadow(0, 3px, 5px, 0, rgba($yellow2, 0.05));

        margin-top: 1em;
        padding: 20px 0;

        p {
            padding: 0 20px;
        }

        em {
            text-transform: uppercase;
            font-size: 10px;
            background-color: $icon-inactive;
            color: white;
            padding: 0px 5px 1px 5px;
            @include border-radius(2px);
        }
    }

    .section-sunbasket {
        display: flex;
        flex-direction: column;
        margin: 30px 10px 0;
        color: #5A6677;
        color: $text-gray;

        @include max-screen(675px) {
            margin: 30px 5px 0;
        }

        .toggle-option-sunbasket {
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 12px;

            label {
                vertical-align: middle;
                padding-top: 1px;
            }

            > p {
                color: #5A6677;
                margin-right: 13px;
                white-space: nowrap;
                vertical-align: middle;
                line-height: 25px;     
            }

            .el-popup {
                .dropdown-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;              
                }
            }
        }
    }
}

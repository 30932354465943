
@import "../../../sass/imports";

.ingredient-warning {
    position: relative;

    abbr {
        color: $red5;
        border-bottom: none !important;
    }

    .ingredient-warning-popover {
        @include transition(opacity .333s ease-in-out);
        @include box-shadow(3px, 1px, 8px, 0px, rgba(0, 0, 0, 0.5));
        @include border-radius(5px);

        max-height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 100%;
        left: 50%;
        @include transform(translateX(-10px));
        background: white;
        padding: .5em;
        color: $grayscale8;
        font-size: 14px;

        em {
            font-weight: 800;
        }

        p:last-child {
            margin-top: 1em;
            font-size: 10px;
        }
    }

    &[data-visible="true"] {
        .ingredient-warning-popover {
            display: block;
            opacity: 1;
            max-height: 10em;
            min-width: 10em;
            z-index: 1;
        }
    }
}

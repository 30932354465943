
@import "../../../sass/imports";

.meal-title-container{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .meal-subtitle {
        font-weight: 300;
    }

    .meal-title-content {
        display: flex;
        flex-direction: row;

        @include max-screen(800px) {
            flex-direction: column;
            align-items: center;
        }
    }
}
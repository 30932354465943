@import "../../../../../sass/imports";

.mobile-web-barcode-modal {
    max-height: unset;
}

#quaggaTarget {
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    position: relative;
    background-color: $el-grayish-blue4;

    button {
        position: relative;
        top: 25px;
        left: 10px;
        z-index: 2;
    }

    > video {
        width: 100%;
        height: 100%;
    }
    
    > canvas.drawing, canvas.drawingBuffer {
        position: absolute;
        left: 0;
        top: 0;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        border-top: 3px solid red;
    }

    .icon-spinner2 {
        position: absolute;
        z-index: 2;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        @include transform(translate(-50%, -50%));

        font-size: 80px;
        display: block;
        color: $el-grayish-blue3;
    }
}


.barcode-scanner-error-modal {
    text-align: center;

    .example-barcode {
        margin: 50px auto 0 auto;
        max-width: 185px;
    }
}

@import "../../../../../sass/imports";


.food-editor-modal-content-footer {
    flex-direction: column;
    gap: 10px;

    .el-error-msg {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }
}

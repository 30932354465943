
@import "../../../sass/imports";

.global-search-results-params {
    max-width: 940px;
    position: relative;

    @include min-screen(47em) {
        margin: 20px 20px 0px 20px;
    }

    @include min-screen(1011px) {
        margin: 20px auto 0px auto;
    }

    > h2 {
        font-size: 11px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.09;
        letter-spacing: 0.9px;
        color: #425569;

        padding-top: 0.5em;
        padding-bottom: 2.5em;

        @include min-screen(47em) {
            font-size: 16px;
            font-weight: 900;
            line-height: 0.88;
            padding: 2px 2em 30px 0;
        }

        &[data-total="0"] {
            opacity: 0;
        }
    }

    .param-item {
        display: inline-block;
        cursor: pointer;

        border: solid 0.5px #e3e4e9;
        padding: 8px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        color: #425569;

        i {
            display: inline-block;
            font-size: 8px;
            margin-left: 3em;
        }
    }

    .create-custom-btns {
        button {
            white-space: nowrap;
            font-size: 12px;
            border: 1px solid $light-gray-border;
            padding: 8px 32px;
            @include border-radius(32px);
            margin: 10px 0;
            width: 100%;
            text-transform: uppercase;
            background: none;
        }
    }

    .create-custom-dropdown .dropdown-btn, .create-custom-btn {
        font-size: 12px;
        color: $raspberry;
        border: 1px solid $raspberry;
        background: none;
        padding: 4px 10px;
        @include border-radius(30px);
    }

    .create-custom-btn {
        position: absolute;
        top: -2px;
        right: 0;
    }

    .create-custom-dropdown {
        position: absolute;
        top: -2px;
        right: 0;

        .dropdown-dropdown {
            @include transform(translate(-80%,0px));

            @include min-screen(47em) {
                @include transform(translate(-70%,0px));
            }
        }

        .triangle, .triangle2 {
            left: 80%;

            @include min-screen(47em) {
                left: 70%;
            }
        }
    }
}


@import "../../../sass/imports";

.cookie-consent-banner {
    @include transition(.333s all linear);
    border-radius: 0 !important;
    border: 0;
    z-index: 10;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    /*top: calc(100% - 17.5em);*/
    top: auto;
    background-color: $grayscale9;
    color: white;
    max-height: 0;
    padding-top: 20px;
    @include box-shadow(0, -5px, 10px, 0, rgba(0, 0, 0, 0.25));

    &[data-drawer="true"] {
        max-height: 25em;
    }

    .inner-container {
        padding: 10px;
    }

    footer {
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: bold;
    }

    a {
        text-decoration: underline;
        color: $mint-green3;

        &:visited {
            color: darken($super-light-gray, 10%);
        }
    }

    footer {
        padding: 10px;
    }

    button {
        background-color: $raspberry;
        border: none;
        color: white;
        font-weight: bold;
        padding: 8px 20px;
        font-size: 14px;
        text-transform: uppercase;
    }
}


@import "../../../../sass/imports";

.dashboard-try-it-out {
    margin: 0 auto 40px auto;

    .card-container {
        max-width: 158px;
    }

    h1 {
        color: $text-gray;
        font-weight: bold;
        font-size: 24px;
        text-align: left;
        margin-top: 0;
        margin-bottom: 10px;
    }

    h2 {
        margin: 0 0 1em 0;
        white-space: nowrap;
        text-align: left;
        font-size: 14px;
        color: $text-gray;

        em {
            color: $mint-green3;
        }
    }

    .try-it-btn {
        @include flat-button($raspberry);
        font-size: 10px;
        font-weight: bold;
        width: 100%;
        display: block;
        margin: 20px 0;
        padding: 7px;
        max-width: 158px;
    }

    .subtext {
        max-width: 218px;
        font-size: 11px;
        line-height: 1.27;
        letter-spacing: 0.51px;
        text-align: left;
        color: #50667b;

        a {
            color: $raspberry;
            text-decoration: none;
            display: block;
        }
    }
}



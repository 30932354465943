
@import "../../../sass/imports";
@import '../MyAccount/PreferencesForm.scss';

.prefs-avoidances {
    color: $text-navy;

    @import '../Preferences/AvoidanceSelect.scss';

    h3 {
        font-size: 16px;
        font-weight: bold;
    }

    p {
        font-size: 14px;
        font-weight: normal;
    }

    &.preferences-form {

        .avoidances-page {
            .icon-spinner2 {
                display: inline-block;
                color: $el-raspberry;
                font-size: 40px;
            }
        }

        .select-bubbles {
            margin: 10px 0 0 0;

            > li {
                margin: 5px;
                width: calc(50% - 10px);
                max-width: none;
                padding: 0;

                button {
                    width: 100%;
                    border-radius: 50px;
                    height: auto;
                    padding: 8px 16px;
                }
            }
        }

        .choose-diets {
            max-width: 400px;
            margin: auto;
            margin-bottom: 3em;

            .select-bubbles {
                li p {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: $bluey-gray;
                }
            }
        }

        .choose-allergens {
            max-width: 500px;
            margin: 0 auto 3em auto;

            .select-bubbles {

                > li {
                    width: calc(50% - 10px);
                    min-width: 100px;
                }

                button {
                    &[data-active="true"] {
                        background-color: $katherine-gray !important;
                        border: solid 0.5px darken($katherine-gray, 10%) !important;
                        color: white !important;
                        position: relative;

                        &:after {
                            @include icon-font;
                            content: $icon-x;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            @include transform(translate(-50%, -50%));
                            display: inline-block;
                            font-size: 20px;
                            color: $super-light-gray;
                        }
                    }

                }
            }
        }
    }
}



@import "../../../../../../sass/imports";

.macro-warning {
    color: $yellow3;
    font-size: 12px;

    i {
        font-size: 16px;
        vertical-align: middle;
    }
}

@import "../../../../../sass/imports";

.fodmap-entry-modal-container {


    .fodmap-entry {
        position: relative;
        margin: 15px auto;
        max-width: 250px;
        display: block;

        > input {
            width: calc(100% - 40px);
            max-width: 150px;
        }

        > label {
            width: calc(100% - 40px);
            display: block;
        }

        .level-btn {
            position: absolute;
            top: 50%;
            right: 0;
            width: 40px;
            height: 40px;
            @include border-radius(40px);
            @include transform(translate(0, -50%));
            border: none;

            &[data-level="0"] {
                background-color: $mint-green3;
            }

            &[data-level="1"] {
                background-color: $yellow3;
            }

            &[data-level="2"] {
                background-color: $red3;
            }
        }
    }
}

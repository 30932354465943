

// @mixin box-shadow($shadow...) {
//   -webkit-box-shadow: $shadow;
//      -moz-box-shadow: $shadow;
//           box-shadow: $shadow;
// }

@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
    @if $inset {
        @include prefixer(box-shadow, inset $top $left $blur $size $color, webkit moz spec);
    } @else {
        @include prefixer(box-shadow, $top $left $blur $size $color, webkit moz spec);
    }
}
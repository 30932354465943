
@import "../../../../sass/imports";

.drawer-menu-modal-overlay {
    border: 0;
    position: fixed;
    z-index: 5;
    top: 0; bottom: 0;
    left: 0; right: 0;
    opacity: 0;
    background-color: rgba(135, 139, 158, 0.74);

    @include transition(opacity .5s ease-in-out);

    &.drawer-overlay-after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

.drawer-menu-modal {

    &.drawer-modal-after-open {
        .drawer-menu-modal-container {
            @include transform(scale(1));

            .child-container {
                opacity: 1;
            }
        }
    }

    &.ReactModal__Content--before-close {
        .drawer-menu-modal-container {
            @include transform(scale(0));

            .child-container {
                opacity: 0;
            }
        }
    }

}

.drawer-menu-modal-container {
    @include transition(transform .5s ease-in-out);
    will-change: transform;
    height: 895px;
    width: 895px;
    // opacity: .5;

    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.5);
    @include border-radius(1000em);
    overflow: visible;

    position: absolute;
    bottom: -197px;
    left: -555px;
    @include transform(scale(0));

    // &[data-drawer="true"] {
    //     @include transform(scale(1));
    //     opacity: 1;

    //     .child-container {
    //         opacity: 1;
    //     }
    // }

    .menu-title {
        position: absolute;
        text-transform: uppercase;
        color: white;
        font-size: 22px;
        font-weight: bold;
        max-width: 143px;
        text-align: left;
    }

    > header {
        position: absolute;
        top: 135px;
        right: 100px;
        padding: 0 60px 0 0;
        z-index: 10;

        h3 {
            color: white;
            text-transform: uppercase;
            font-size: 22px;
            max-width: 143px;
            font-weight: bold;
            margin: 43px 0 0 0;
            line-height: 1.09;
        }
    }

    .inner-container {
        position: absolute;
        bottom: 50%;
        left: 50%;
        height: 810px;
        width: 810px;
        @include transform(translate(-50%, 50%));
        will-change: height, width;
        @include border-radius(1000em);
        background-color: rgba(255, 255, 255, 0.95);
    }

    .child-container {
        position: absolute;
        top: 190px;
        right: -25px;
        opacity: 0;
        @include transition(opacity 0.5s ease-in-out);
    }

    .menu-item {
        display: block;
        margin: 0;
        padding: 13px 0;
        color: $text-gray;
        font-size: 11px;
        font-weight: 800;
        line-height: 1.4;
        letter-spacing: 1px;

        text-transform: uppercase;
        text-align: right;
        width: 100%;

        background: none;
        border: none;

        i {
            @include icon-font;
            content: $icon-dinner;
            background-color: white;
            padding: 8px;
            margin: 0 0 0 15px;
            font-size: 20px;
            color: $raspberry;
            vertical-align: -10%;
            display: inline-block;
            width: 36px;
            height: 36px;
            @include border-radius(60px);
            @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));
        }

        &:disabled {
            opacity: 0.3;
        }
    }

    .feed-menu-content {
        > *  {
            &:nth-child(1) { padding-right: 49px; }
            &:nth-child(2) { padding-right: 26px; }
            &:nth-child(3) { padding-right: 12px; }
            &:nth-child(4) { padding-right: 8px; }
            &:nth-child(5) { padding-right: 13px; }
            &:nth-child(6) { padding-right: 29px; }
            &:nth-child(7) { padding-right: 56px; }
        }
    }

    .cancel-btn {
        color: transparent !important;
        padding: 27px 8px !important;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;

        i {
            background-color: lighten($text-gray, 10%);
            color: white !important;
            font-size: 19px;
            padding: 14px;
            width: auto; height: auto;
            @include box-shadow(0, 12px, 12px, 0, rgba(0, 0, 0, 0.1));
        }
    }

}


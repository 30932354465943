
@import "../../../../../sass/imports";

.customizer-groceries-modal {
}

.customizer-groceries-container {

    .modal-scroll-container {
        padding: 20px;
    }

    h3 {
        text-transform: uppercase;
        font-size: 14px;
        color: $grayscale8;
        font-weight: 800;
    }

    tr {
        border-bottom: 1px solid $grayscale3;
    }

    th {
        font-size: 14px;
        font-weight: bold;
        color: $grayscale8;
    }

    td {
        font-size: 14px;
        color: $grayscale6;
        white-space: nowrap;
        padding: 5px;

        a {
            color: $grayscale8;
        }
    }

    th, td {
        padding-left: 3px;
        padding-right: 3px;

        &:nth-child(even) {
            background-color: #f6f6f6;
        }
    }

    .totals-row th {
        white-space: nowrap
    }

    .img-cell .dropdown-btn {
        border: none;
        background-color: none;
    }

    .value-cell {
        text-align: right;
    }

    .grocery-cell {
        ul li {
            font-size: 12px;
        }
    }

    .buyorg {
        color: $mint-green3;
        font-size: 18px;
    }

    em {
        text-align: left;
        display: inline-block;
        min-width: 1em;
        font-size: 10px;
    }

    .mapped-meals {
        h4 {
            font-size: 18px;
            font-weight: normal;
            margin: 0 0 10px 0;

            em {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}

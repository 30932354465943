@import "../../../../sass/imports";

.pro-free-trial-banner {
    padding: 5px;
    width: 100%;
    background-color: #FF7735;
    color: white;
    padding: 5px;
    position: relative;
    overflow: hidden;


    @include min-screen(47em) {
        padding: 20px 0 20px 20px;
    }

    .banner-container {
        position: relative;
        max-width: 800px;
        margin: auto;
    }

    h3 {
        color: white;
        text-align: center;
        font-size: 18px;
        font-weight: bold;

        @include min-screen(47em) {
            text-align: left;
        }
    }

    p {
        color: white;
        width: 100%;
        text-align: center;

        @include min-screen(47em) {
            width: calc(100% - 250px);
            text-align: left;
        }
    }

    footer {
        text-align: center;

        @include min-screen(47em) {
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            right: 0;
            padding: 20px;
        }

        button {
            @include flat-button(white);
            @include border-radius(0);
            color: white;
            font-size: 14px;
            margin: 10px;
            max-width: 250px;
            background-color: #324253;
            @include border-radius(40px);
            border: none;

            &:hover {
                color: darken($text-gray, 10%);
            }
        }
    }

    .leaf {
        position: absolute;
        color: #FFA579;

        &.first  {
            width: 136px;
            height: 150px;
            left: 1%;
            font-size: 225px;
            bottom: 55%;
            transform: matrix(-0.92, 0.39, 0.39, 0.92, 0, 0);
        }

        &.second  {
            width: 72.5px;
            height: 80px;
            font-size: 125px;
            left: 24%;
            bottom: 25%;
            transform: matrix(-0.87, 0.5, 0.5, 0.87, 0, 0);
        }

        &.third  {
            width: 72.5px;
            height: 80px;
            left: 74.6%;
            font-size: 125px;
            top: 25%;
            transform: matrix(0.58, -0.82, -0.82, -0.58, 0, 0);
        }

        &.fourth  {
            width: 136px;
            height: 150px;
            left: 90%;
            font-size: 225px;
            bottom: 43%;
            transform: matrix(-0.54, 0.84, 0.84, 0.54, 0, 0);
        }
    }

}

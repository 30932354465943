@import "../../../sass/imports";

.activity-level-selector {
    width: calc(100% - 10px);
    position: relative;

    .activity-level-group {
        .select-container {
            &[data-state="true"] {
                .select-dropdown {
                    max-height: fit-content;
                }
            }
        }
        .dropdown-content {
            color: $text-gray;

            ul {
                text-align: left;
                font-size: 14px;
                min-width: 275px;

                .option {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    @include transition(all 0.2s ease-in-out);

                    span {
                        font-weight: 700;
                    }

                    label {
                        font-size: 11px;
                        margin-top: -4px;
                        padding-bottom: 4px;
                    }

                    &:hover {
                        background-color: $grayscale8;
                        color: white;

                        label {
                            color: white;
                            cursor: pointer;
                        }
                    }

                    &[data-selected="true"] {
                        background-color: $dark-violet;
                        color: white;

                        label {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}


@import "../../../../sass/imports";
@import "../Patients/Editor/PatientForm.scss";
@import './Modals.scss';

.recommend-modal-container {
    color: $text-navy;
    background-color: white;
    width: 563px;
    max-width: 100%;

    > header {
        padding: 20px 0;
    }

    .recipients-container {
        h2 {
            margin: 1em 0 0 0;
        }

        .add-patient-form > ul {
            min-height: auto;
        }
    }
    .modal-scrollable {
        position: absolute;
        top: 0;
        bottom: 0;

        overflow-y: auto;
        width: calc(100%); // hides the scrollbar
    }


    .modal-body {
        padding: 40px;
        padding-bottom: 0;

        @include max-screen(500px) {
            padding: 40px 20px;
        }
    }

    .el-modal2-body-container {
        padding: 20px 40px;
    }

    .collection-card-container, .collection-recipients-container {
        width: 100%;
        max-width: 300px;
        display: block;
        margin: auto;
        padding-left: 0;
    }

    .collection-recipients-container {
        margin: 10px auto;
    }

    .plan-card-container {
        margin: auto;
        width: 150px;
        display: block;
        padding-left: 0;
        @include min-screen(47em) {
            width: 150px;
            display: inline-block;
            vertical-align: top;
        }
    }

    .plan-recipients-container {
        width: 100%;
        display: block;
        padding-left: 0;
        margin: 15px 0px 0px 0px;
        @include min-screen(47em) {
            margin: 0 10px 0 30px;
            width: calc(100% - 190px);
            display: inline-block;
            vertical-align: top;
        }
    }

    .recipients-container {
        padding-left: 40px;
        max-width: none;
        width: calc(60%);

        @include max-screen(500px) {
            padding-left: 20px;
        }

        .patient-select {
            margin-top: 10px;
        }

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: $text-gray;
        }


        li {
            width: 100%;
            clear: right;

            .icon-male2, .icon-female2, .icon-warning1 {
                vertical-align: top;
                font-size: 20px;
                padding: 12px 12px 12px 0;

                display: inline-block;
            }

            .icon-male2, .icon-female2 {
                color: $raspberry;
            }

            .icon-warning1 {
                color: $orange3;
            }

            .text-info {
                display: inline-block;
                width: calc(100% - 68px);
                margin-left: 0px;
                padding-top: 6px;

                p {
                    font-size: 13px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $text-gray;
                    max-height: 1.2em;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .conditions {
                    font-size: 10px;
                    color: $inactive-text-gray;
                    text-transform: none;
                }
            }

            button {
                float: right;
                border: none;
                background: none;
                padding: 12px;
                font-size: 16px;
                color: $icon-inactive;
            }
        }
    }

    .add-patient-form {
        padding: 0 !important;

        .with-label {
            width: calc(100% - 20px);
            input { width: 100%; }
        }

        > ul {
            margin-top: 10px;
            max-height: 200px;
            overflow-y: auto;
            min-height: 8em;
        }

        > footer {
            margin: 10px 0 0 0;
            text-align: center;
        }
    }

    .rw-combobox {
        input {
            border: none !important;
        }
    }

    .message {
        width: 100%;
        border: 1.5px solid $light-gray-border;
        height: 100px;
        margin-top: 40px;
        padding: 17px 20px;
        font-size: 14px;
        color: $text-gray;
        -webkit-appearance: none;

        &::placeholder {
            color: $inactive-text-gray;
        }
    }


    .global-search-checkbox {
        margin-top: 20px;
        span {
            text-transform: uppercase;
            color: $inactive-text-gray;
            font-size: 10px;
        }
    }

    .error-msg {
        opacity: 0;

        color: red;
        font-weight: bold;
        text-align: center;

        &[data-active="true"] {
            opacity: 1;
        }
    }
    .add-recipient-btn {
        @include outline-button($raspberry);
        font-size: 11px;
        color: #a30063;
        border-color: #a30063;
        text-transform: uppercase;
        background-color: #fff;
        padding: 9px;
        margin: 5px;
    }

    .recipients-added {
        justify-content: left;
        white-space: nowrap;
        max-height: 20%;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 15px 0;
    }


    .icon-male2, .icon-female2, .avatar{
        vertical-align: top;
        width: 55px;
        display: inline-block;
        height: 55px;
        border-radius: 55px;
        overflow: hidden;
        margin: 0px 20px 0px 0px;
    }

    .icon-male2, .icon-female2 {
        font-size: 25px;
        color: $text-gray;
        border: solid;
        border-color: $text-gray;
        padding: 9px 9px 9px 11px;
        border-width: 1px;
        line-height: 1.4;
    }

    .recipients-added > span {
        padding: 10px 0;
        width: 70px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        .x-symbol {
            font-size: 8px;
            font-weight: bold;
            margin: auto;
        }
        > div {
            text-align: center;
        }
    }

    .recipient-label {
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 3px 13px 0 0;
    }

    .create-patient-btn {
        margin: 10px auto 0px auto;
        display: block;
        @include min-screen(47em) {
            margin: 0;
            display: inline-block;
        }
        background:none;
        border:none;
        padding:0;
        cursor: pointer;
        font-size: 12px;
        color: #a30063;
        text-decoration: underline;
    }

    .add-recipient-header {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 3.5% 0 5%;
        height: 25%;
        white-space: nowrap;
        > * {
            display: inline-block;
        }

        > button {
            margin: 0;
        }
    }

    .add-recipient-header-title{
        margin: 0px 3.5%;
        @include min-screen(47em) {
            margin: 0 100px;
        }
        > p {
            font-weight: bold;
        }
    }

    .add-recipient-header-cancel-btn {
        @include outline-button($text-gray);
        padding: 8px 20px;
    }

    .add-recipient-header-done-btn {
        @include flat-button($raspberry);
    }

    .add-recipient-container {
        height: 89%;
        padding: 5%;
    }

    .add-recipient-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 250px);
        max-height: 500px;
        padding-bottom: 50px;

        &[data-has-recipients="true"] {
            height: calc(100vh - 350px);
            max-height: 350px;
        }
    }

    .recipient-row-container {
        display: flex;
        align-items: center;
        border-top: solid;
        border-color: lightgrey;
        border-width: 1px;
        width: 100%;
        min-height: 75px;
        .icon-check-circle, .icon-radio-unchecked {
            font-size: 22px;
            padding-right: 10px;
        }
        .icon-female2, .icon-male2 {
            padding: 9px 9px 9px 15px;
        }
    }

    .recipient-row {
        width: 75%;
    }

    .recipient-name {
        width: 90%;
        display: inline-block;
        margin-left: 5%;
    }

    .keyword-search{
        @include min-screen(47em) {
            display: inline-block;
        }
        > header {
            position: relative;

            &:before {
                position: absolute;
                z-index: 2;
                @include icon-font;
                content: $icon-search5;
                top: 50%;
                left: 28%;
                @include min-screen(47em) {
                    left: 39%;
                }
                @include transform(translate(0, -50%));
                font-size: 18px;
            }
        }
    }

    .recipient-keyword-search {
        display: block;
        width: 50%;
        margin: auto;
        border-radius: 25px;
        background-color: #FAF9F6;
        padding: 13px 32.1px 12px 38.9px;
        font-size: 18px;
        position: relative;
        border: none;
        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }
        @include min-screen(47em) {
            margin: 0px 10px 0px 150px;
        }
    }


    .dot {
        height: 20px;
        width: 20px;
        background-color: white;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        position: absolute;
        display: flex;
        right: 17%;
        top: 8%;
        filter: drop-shadow(3px 2px 6px rgba(0, 0, 0, 0.3));
    }


    .loading-spinner-container {
        text-align: center;
        margin-top: 30%;
    }

    .loading-spinner-header {
        margin-bottom: 15px;
    }

    .loading-spinner {
        font-size: 100px;
        display: inline-block;
        color: #a00f64;
    }

    .hide-elment {
        visibility: hidden;
    }
}

.recommendation-sending {
    text-align: center;
    min-height: 450px;

    h2 {
        text-align: center;
        color: $text-gray;
        color: 18px;
        margin: 20px;
        font-size: 22px;
    }

    > i {
        display: inline-block;;
        text-align: center;
        font-size: 30px;
        color: $raspberry;
    }
}

.recommendation-sent {
    text-align: center;
    min-height: 240px;

    h2 {
        text-align: center;
        color: $text-gray;
        color: 18px;
        margin: 20px;
        font-size: 22px;
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        color: $text-gray;
    }

    > i {
        display: inline-block;;
        text-align: center;
        font-size: 30px;
        color: $raspberry;
    }
}




@import "../../../../sass/imports";
@import "../../Recipes/SchedulingModal.scss";
@import "../../FeedModals.scss";
@import "LeftoverOffsetsModal.scss";

.meals-reschedule-modal {

}

.meals-reschedule-modal-container {
    color: $text-navy;

    .custom-calendar {
        width: calc(100% - 30px);
        margin: 0 0 0 30px;
    }

    &.edit-meal-modal-container {
        .modal-scroll-container {
            padding: 15px 15px 120px 15px;

            > header {
                text-align: center;
                padding: 20px;

                h1 {
                    font-size: 20px;
                    margin: 0 0 20px 0;
                }

                h3 {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }

    .preferences-form {
        .checkbox-btn {
            color: $text-navy;

            &:before {
                display: block;
            }
        }

        .select-container {
            width: calc(100% - 30px);
            margin: 5px 0 5px 30px;
        }
    }

    .move-shift {
        margin: 10px 0 0 0;
    }

    .radio-btn {
        font-size: 12px;
        text-align: left;
        position: relative;
        padding: 0 0 0 30px;
        border: none;
        background: none;
        width: calc(100% - 30px);

        em {
            font-weight: bold;
        }

        &:before {
            @include icon-font;
            content: $icon-radio-unchecked;

            position: absolute;
            top: 50%;
            left: 0;
            @include transform(translateY(-50%));
            font-size: 25px;
        }

        &[data-selected="true"] {
            &:before {
                content: $icon-radio-checked;
                color: $mint-green3;
            }
        }
    }
}


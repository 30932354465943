@import "../../../../sass/imports";

.plan-card-search-result {

    plan-card-search-result-text {
        top: 40%;
    }

    a {
        span {
            h2 {
                text-align: left;
                font-size: 15px;
                font-weight: normal;
                color: $raspberry;
                margin: 0 0px 1px 0;
            }

            svg {
                position: absolute;
                bottom: 50px;
                left: 115px;
            }
        }


        h3 {
            margin-top: 10px;
        }

        .plan-size-info {
            bottom: 66px;
            left: 129px;
            color: white;
            font-size: 11px;
            position: absolute;

            &[data-dinner="true"] {
                font-size: 8px;
            }
        }
    }





    h3 {
        overflow: visible;
    }

}

@import "../../../sass/imports";

.google-map-container {

    .map-container {
        height: 316px;
        width: 100%;

        @include min-screen(47em) {
            height: calc(100vh - 80px);
        }
    }

    .autocomplete {
        width: 100%;
        border-radius: 40px;

        border: none;
        @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.19));
        font-size: 13px;
        padding: 11px 0 11px 40px;
        color: $text-gray;
        @include transition(box-shadow 0.25s ease-in-out);
        -webkit-appearance: none;

        &:focus {
            @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
        }

        @include placeholder {
            text-transform: uppercase;
            font-size: 11px;
        }
    }

    .autocomplete-container {
        opacity: 0;
        @include transition(all 0.33s ease-in-out);

        position: absolute;
        top: 55px;
        z-index: -1;
        width: 100%;
        max-width: 335px;
        left: 50%;
        @include transform(translate(-50%, 0));

        @include min-screen(47em) {
            left: calc(60% + 50px);
            top: 95px;
            width: 29vw;
            @include transform(translate(0));
        }

        &:before {
            position: absolute;
            top: 50%;
            left: 10px;
            @include transform(translateY(-50%));
            @include feather-font;
            content: $feather-map-pin;
            color: $text-gray;
            font-size: 20px;
            padding: 5px 10px 5px 5px;
        }
    }

    &[data-show-autocomplete="true"] {
        .autocomplete-container {
            opacity: 1;
            z-index: 2;
        }
    }

    .search-here-btn {
        opacity: 0;
        @include transition(all 0.33s ease-in-out);

        position: absolute;
        top: 310px;
        right: 28px;
        z-index: -1;

        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 1.2px;
        color: white;
        background-color: $raspberry;
        @include border-radius(40px);
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(163, 0, 99, 0.2);
        padding: 9px 22px;
        border: none;

        @include min-screen(47em) {
            top: auto;
            bottom: 40px;
            right: calc(40% + 40px);
            font-size: 12px;
        }
    }

    &[data-show-search-here="true"] {
        .search-here-btn {
            opacity: 1;
            z-index: 2;
        }
    }
}

.info-window-contents {
    background-color: white;
    display: none;
    min-width: 150px;

    &[data-visible="true"] {
        display: block;
    }

    h3 {
        font-size: 16px;
        color: $text-navy;
    }

    p {
        font-size: 14px;
        color: $text-navy;
    }

    .alert {
        color: red;
        font-weight: bold;
    }

    .icon-spinner, .icon-spinner2 {
        display: block;
        font-size: 36px;
        width: 36px;
        margin: auto;
        color: $raspberry;
    }
}

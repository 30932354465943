
@import "../../../sass/imports";

.stripe-payment-form {
    max-width: 500px;
    margin: auto;

    #card-elements {
        border: solid 0.5px $bluey-gray;
        padding: 10px;
        border-radius: 5px;
        margin: 20px 5px 0 5px;
        @include box-shadow(0, 5px, 5px, 0, rgba(0, 0, 0, .05));
    }

    form {
        > footer {
            text-align: center;
            width: 100%;
            margin: 20px 0 0 0;
        }

        .coupon-code, .referral {
            width: calc(50% - 10px);
            input {
                border: solid 0.5px $bluey-gray;
                padding: 10px;
                border-radius: 5px;
                @include box-shadow(0, 5px, 5px, 0, rgba(0, 0, 0, .05));
            }
        }

        .error-msg {
            border: 1px solid #c13e0e;
            font-size: 18px;
            border-radius: 10px;
            padding: 10px 10px 10px 20px;
            color: #c8552b;
            max-width: 490px;
            text-align: left;
            margin: 10px auto 20px;
        }

    }

    .stripe-submit {
        @include flat-button($raspberry);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 300px;

        @include min-screen(47em) {
            width: 335px;
            height: 40px;
            padding: 10px;
            width: 300px;
        }
    }

    .powered-by {
        text-align: right;
        margin: 10px 5px 20px 0;
    }

    .assurance {
        color: #81909F;
        font-size: 14px;
        margin: 5px 0 0;
    }
}


@import "../../../../sass/imports";

.publisher-submenu {
    &.pro-dropdown-container {
        .dropdown-btn {
            border: none;
            background: none;
        }

        .dropdown-dropdown {
            top: auto;
            bottom: 0%;
            left: 0;
            transform: translate(-100%, 0);
        }

        .dropdown-content {
            min-width: 150px;
        }

        .close-btn {
            display: none;
        }

        .triangle, .triangle2 {
            display: none;
        }

        .action-btn {
            width: calc(100% - 10px);
            @include outline-button(#425569);
            @include border-radius(3px);
            padding: 3px;
            font-size: 12px;
            border-width: 1px;
            margin-bottom: 5px;
        }
    }
}

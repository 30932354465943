
@import "../../../sass/imports";

.energy-estimator-container {
    background-color: #fafafa;

    .description {
        margin-bottom: 15px;
    }

    .target-energy-kcal {
        width: calc(100% - 10px);
        text-align: center;

        .number {
            display: inline-block;
            text-align: center;
            font-size: 25px;
            font-weight: bold;
            padding: 0 20px;

            color: $text-navy;
        }

        > label {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .with-label {
        width: calc(100% - 10px);
    }

    .with-label, .with-units {
        text-align: left !important;

        > label {
            font-size: 10px;
            color: $text-navy;
        }
    }

    .select-container, input[type="text"], input[type="number"], input[type="password"], input[type="email"] {
        background-color: white;
        border: solid 0.5px rgba(0, 0, 0, 0.15);

        @include box-shadow(0, 5px, 10px, 0, rgba(0, 0, 0, 0.05));
    }

    .select-container {
        .value {
            color: $text-navy;
            font-weight: normal;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .date-selector-container {
        text-align: left;
        margin: 0;
        width: 100%;

        .month, .day {
            margin: 0 10px 0 0 !important;
            padding: 0 !important;
        }

        .year {
            margin: 0 !important;
            padding: 0 !important;
        }

        label {
            display: none !important;
        }
    }

    .toggle-btn {
        margin: 0 20px 0 0 !important;
    }

    .units-mode {
        margin-top: 31px;
        margin-right: 5px;
        float: right;
        margin-left: 0;
    }

    .height {
        width: calc(100% - 160px);
        max-width: 220px;

        .select-container {
            width: calc(50% - 10px);
            &:first-child {
                margin-left: 10px;
            }
        }
    }

    .weight, .goal-weight {
        max-width: 220px;
    }

    .height-feet {
        width: calc(50% - 5px) !important;
        max-width: 150px;
        margin-right: 10px !important;
    }

    .height-inches {
        width: calc(50% - 5px) !important;
        max-width: 150px;
    }

    > footer {
        height: auto;
    }

}

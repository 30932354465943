@import "../../../../../sass/imports";
@import "../../Modals/Modals.scss";
@import "../../Modals/CoverImageEditorModal.scss";

.plan-to-pdf-container {
    .modal-scroll-container {
        padding-bottom: 95px;
    }

    .modal-action-footer {
        position: absolute;
        bottom: 0;
        background-color: white;
    }
}

.plan-to-pdf-form {
    padding: 0 20px 90px;
    margin: 45px 0 0 0;
    color: $text-gray;

    h1 {
        font-size: 18px;
        text-align: center;
        margin-bottom: 1em;
    }

    h2 {
        font-size: 14px;
        text-align: left;
        margin: 1em 0 0 5px;
    }

    > p {
        margin-bottom: 1em;
    }

    .toggle-group {
        .toggle-btn {
            width: calc(33% - 10px);
            margin: 5px;
        }
    }

    .toggle-btn {
        text-align: left;
        width: 100%;
        border: none;
        background: none;
        color: $text-gray;
        font-size: 12px;
        padding: 3px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;

        &:before {
            @include icon-font;
            font-size: 14px;
            vertical-align: middle;
            margin: 0px 5px 0 0px;
            display: inline-block;
            width: 17px;
            height: 17px;
            color: white;
            content: ' '; // $icon-checkbox-unchecked;
            border: 1px solid $bluey-gray;
            padding: 1px;
        }

        &:disabled {
            opacity: 0.5;
        }

        em {
            margin-right: 5px;
        }

        span {
            font-weight: 300;
        }

        &[data-selected="true"] {
            &:before {
                content: $icon-check;
                background-color: $mint-green3;
            }
        }
    }

    .radio-btn {
        &:before {
            @include border-radius(40px);
            padding: 2px;
            width: 20px;
            height: 20px;
        }
    }
}

.plan-to-pdf-btn {
    @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
    @include border-radius(5em);
    background-color: white;
    border: solid 1px $raspberry;
    margin-right: 10px;
    color: $raspberry;
    height: 36px;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 3px;
    min-width: 180px;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    & > i {
        font-size: 18px;
        position: relative;
        bottom: -2px;
    }
    
    @include transition(all 0.333s ease-in-out);

    &:hover {
        background-color: $raspberry;
        color: white;
    }
}

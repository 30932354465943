@import "../../../../../../sass/imports";

.frequently-used {
    padding: 0 5px;

    @include min-screen(47em) {
        margin: 70px 0 0 0;
        padding: 0 60px;
    }

    .frequently-used-loading {
        text-align: center;
        margin-top: 100px;

        .icon-spinner {
            color: $raspberry;
            display: inline-block;
            font-size: 75px;
        }
    }

    h3 {
        color: $bluey-gray;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        margin: 5px;
    }

    .show-more-toggle {

        > button {
            margin: 20px 0;
        }
    }

    .frequent-list {
        font-size: 14px;
        margin: 20px 0 0 0;

        > li {
            margin: 5px 5px 8px;
            position: relative;
            padding: 0 0 0 40px;
            color: $text-navy;
            cursor: pointer;
            min-width: 150px;
            max-width: 350px;
            display: inline-block;
            text-align: left;
            width: 100%;
            min-height: 35px;

            &[data-food="true"] {
                line-height: 13px;
            }

            @include max-screen(550px) {
                width: 100%;
                max-width: initial;
            }

            @include min-screen(550px) {
                width: 50%;
                max-width: 250px;
            }

            .thumb {
                width: 40px; height: 40px;
                display: inline-block;

                position: absolute;
                top: 50%;
                left: 0;
                @include transform(translate(0, -50%));
                @include border-radius(6px);
            }

            i.thumb {
                font-size: 24px;
                text-align: center;
                color: white;
                padding: 5px 0;
                background-image: $food-card-gradient;
            }

            > span {
                margin: 0 0 0 10px;
            }
        }

        .title, .cals {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            em {
                font-weight: 300;
                display: inline-block;
                margin-right: .25em;
            }
        }

        .title {
            font-weight: normal;
            font-size: 12px;
        }

        .cals {
            color: $bluey-gray;
            font-size: 10px;
        }
    }

    .type-picker {
        li {
            display: inline-block;

            button {
                color: $text-gray;
                border: 1px solid $bluey-gray;
                background: none;
                padding: 5px 10px;
                @include border-radius(3px);

                font-size: 10px;
                margin: 5px;
                width: 75px;
                text-transform: uppercase;

                &[data-active="true"] {
                    color: white;
                    background-color: $bluey-gray;
                    border: 1px solid $bluey-gray;
                }
            }
        }
    }
}

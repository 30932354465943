@import "../../../sass/imports";
@import "react-calendar/dist/Calendar.css";

.custom-calendar {
    .rmdp-react-calendar {
        width: -webkit-fill-available;

        .rmdp-arrow-container {
            box-shadow: none;
            min-width: 44px;
            min-height: 40px;
            padding: 10px;
        }

        .rmdp-arrow-container:hover {
            background-color: $el-raspberry !important;
            box-shadow: none;
        }

        .rmdp-day.rmdp-today {
            div {
                font-weight: 700 !important;
            }

            span:not(.highlight) {
                font-weight: 700;
                background-color: initial !important;
                color: $el-grayish-blue !important;
                box-shadow: none !important;
            }
        }

        .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
            background-color: initial !important;
            color: $el-grayish-blue !important;
        }

        .rmdp-day.rmdp-selected span:not(.highlight) {
            background-color: $el-raspberry !important;
            color: $el-white !important;
            box-shadow: none !important;
        }

        .rmdp-week-day {
            color: $el-raspberry;
            font-weight: 700;
        }

        .rmdp-week {
            justify-content: space-around;
        }

        .rmdp-day, .rmdp-week-day {
            width: 100%;
            width: -moz-available;
            width: -webkit-fill-available;
            width: fill-available;
        }

        .rmdp-day {
            padding: 20px 29px;
            color: inherit !important;

            @media (max-device-width: 500px) {
                padding: 20px 20px;
            }

            div {
                font-weight: 400 !important;

                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }

            &.rmdp-range {
                background-color: $el-raspberry1 !important;
                box-shadow: none !important;

                div {
                    color: $el-grayish-blue !important;
                }
            }

            &.rmdp-range-hover {
                background-color: $el-raspberry1 !important;
            }

            &.start,
            &.end {
                background-color: $el-raspberry !important;

                div {
                    color: $el-white !important;
                }
            }

            &.start {
                border-radius: 20px 0 0 20px !important;

                &.end {
                    border-radius: 20px !important;
                }
            }

            &.end {
                border-radius: 0 20px 20px 0 !important;
            }

            &.rmdp-today {
                font-weight: 700 !important;
            }
        }

        .rmdp-disabled {
            opacity: 0.5;
        }

        .rmdp-day:hover {
            background-color: $el-raspberry1;
        }

        &.rmdp-shadow {
            box-shadow: initial !important;
        }

        &.rmdp-wrapper {
            border: none !important;
            width: auto !important;
            background-color: initial !important;
            touch-action: manipulation;

            button {
                margin: 2px 0 !important;
            }

            .rmdp-top-class,
            .rmdp-day-picker {
                @media (max-device-width: 47em) {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
            }

            .rmdp-toolbar {
                display: flex;
                flex-direction: row-reverse;
            }
        }

        .rmdp-header {
            margin-top: 0;

            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 44px;
                margin-bottom: 1em;
            }

            .rmdp-arrow {
                border: solid $el-raspberry;
                border-width: 0 2px 2px 0;
            }

            .rmdp-header-values {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: 18px;
                font-weight: 700;
                color: $el-grayish-blue;

                :first-child {
                    width: 100%;
                    text-align: right;
                }

                :last-child {
                    width: 100%;
                    text-align: left;
                }
            }

            .rmdp-header-values:hover {
                background-color: $el-gray3 !important;
                box-shadow: none;
            }
        }
    }

    .rmdp-react-calendar__tile {
        background-color: initial !important;
        color: $el-grayish-blue !important;
        font-weight: bold;
        justify-content: center;
        align-items: center;
    }

    .rmdp-react-calendar .rmdp-arrow-container:hover {
        background-color: $el-gray3 !important;
        border-radius: inherit;
        color: $el-raspberry;
        min-width: 44px;
        min-height: 40px;
        padding: 10px;

        .rmdp-arrow {
            border: solid $el-raspberry;
            border-width: 0 2px 2px 0;
        }
    }
}


@import "../../../../sass/imports";

.global-search-tag-group {
    ul {
        display: inline-flex;
        flex-wrap: wrap;
    }

    li {
        width: calc(50% - 10px);
    }
}

.dropdown-content .global-search-tag-group {
    width: 20em;
}


@import "../../../../sass/imports";

.units-mode {
    border: 1px solid $inactive-text-gray !important;
    background: none;
    position: relative;
    cursor: pointer;
    width: 140px;
    display: inline-block;
    @include border-radius(0px);
    overflow: visible;
    margin-left: 10px;
    vertical-align: middle;

    &:after {
        position: absolute;
        z-index: 2;
        content: ' ';

        width: 50%;
        top: -1px;
        bottom: -1px;
        border: 1px solid $dark-violet;
        background-color: $dark-violet;
        @include border-left-radius(0px);
        @include transition(all .1s ease-in-out);
    }

    &[data-mode="metric"] {
        &:after {
            left: calc(50% + 1px);
            @include border-left-radius(0px);
            @include border-right-radius(0px);
        }

        > span.metric {
            color: white;
            background-color: $dark-violet;
        }
    }

    &[data-mode="english"] {
        &:after {
            left: -1px;
        }

        > span.english {
            color: white;
            background-color: $dark-violet;
        }
    }

    > span {
        display: inline-block;
        vertical-align: top;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        color: $text-gray;
        background-color: white;

        padding: 4px 5px;
        text-align: center;
        width: 50%;
        position: relative;
        z-index: 3;
        @include transition(all .1s ease-in-out);
    }

    &:focus {
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
    }
}

@import "../../../sass/imports";
@import '../FeedModals.scss';
@import "../Foods/SearchFoodsModal.scss";

.add-to-collection-modal-container {
    @include min-screen(47em) {
        max-width: 700px;
    }
}

.add-to-collection-confirm {
    p {
        em {
            font-weight: bold;
        }
    }
}

@import "../../../../sass/imports";

.change-password-modal {

    h4 {
        margin-top: 20px;
        text-align: center;
    }

    .logo-container {
        color: $dark-slate-gray;
        font-weight: 700;
        font-size: 18px;
        display: block;
        text-align: center;

        img {
            display: inline-block;
            width: 150px;
            height: 31px;
            margin: 15px auto 20px;
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .change-password-check {
        margin: 40px 0;

        .el-small-circle-icon {
            font-size: 60px;
            border-radius: 60px;
            background: $el-green;
        }
    }

    .el-modal3-body-container {
        .el-labeled-input {
            &:first-child {
                margin: 0 0 20px;
            }
        }
    }

    .confirm-password-btn {
        min-width: 150px;
    }
}


@import "../../../../../sass/imports";

.edit-nutrition-prescription {
    @include max-screen(47em) {
        padding: 0 10px 10px 10px !important;
    }

    .envelope-editors {
        h1 {
            display: inline-block;
            width: 215px;
            font-size: 14px;
            color: $raspberry;
            font-weight: bold;
            margin-left: 10px;
        }
    }

    .envelope-mode {
        width: 200px !important;
        border: none !important;
        padding: 4px;

        .value {
            color: $raspberry;
        }
    }

    .expires-in {
        display: block;
        text-align: left;

        .select-container {
            width: 280px;
        }
    }

    .explainer {
        text-align: center;
        font-size: 12px;
        color: $text-gray;
    }

    .generic-tabs {
        .tab-contents {
            @include max-screen(47em) {
                margin: 0;
            }
            footer {
                text-align: center;

                p.next-update-needed {
                    color: $text-gray;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding: 10px;

                    em {
                        font-weight: bold;
                    }
                }
            }

            .envelope-editor-container[data-blur="true"] {
                filter: blur(5px);
                pointer-events: none;
            }
        }

        .tab-btn {
            @include max-screen(47em) {
                padding: 1.75em 6px;
            }
        }
    }


    .el-toolbar-popup {
        position: unset;

        .triangle, .triangle2 {
            display: none;
        }

        .popup-content {
            margin-top: 20px;
            padding: 30px;
            width: 250px;

            @include min-screen(47em) {
                width: 400px;
            }
        }

        header {
            margin-bottom: 10px;
            text-align: center;
        }

        .el-mealtype-option {
            width: 100%;
            min-width: 120px;
            margin: 10px 0;
        }
    }
}


@import "../../sass/imports";
@import "../Root.scss";

@include max-screen(47em) {
    body {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.root-layout {
    background-color: #fafafa;
    position: relative;
    min-height: 100vh;

    &[data-has-header="true"] {
        padding-top: 109px;

        @include min-screen(47em) {
            padding-top: 83px; // give some room for the fixed header.
        }
    }

    @media print {
        padding-top: 0 !important;
    }

    .cookie-consent-banner {
        position: fixed !important;
    }
}

.recipe-details-page {
    .recipe-details-child-container {
        position: relative;
    }
}
.top-half-circle {
    position: absolute;
    top: 0;
    left: 50%;
    @include transform(translate(-50%,calc(-100% + 110px)));
    @include border-radius(50%);
    z-index: 0;

    @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
    @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));

    width: 145vw;
    height: 370px;

    @include min-screen(700px) {
        width: 110vw;
        height: 200px;
        @include transform(translate(-50%,calc(-100% + 115px)));
    }

    &.no-curve {
        border-radius: unset;
    }
}

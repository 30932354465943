
@import "../../../../sass/imports";

.global-search-clear-filters {
    display: inline-block;
    cursor: pointer;

    padding: 8px 10px;
    margin: 5px 5px;
    font-size: 9px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.8px;
    color: #a30063;

    &:hover {
        text-decoration: underline;
    }
}


@import "../../../../../sass/imports";

.edit-family-member {
    margin-bottom: 20px;

    .remove-btn {
        background: none;
        border: none;
        color: #7c8a99;
        font-size: 11px;

        i {
            border: 1px solid #7c8a99;
            -webkit-border-radius: 20px;
            border-radius: 20px;
            height: 22px;
            padding: 5px;
            width: 22px;
        }
    }

    .with-label {
        margin: 5px 10px;
    }

    input, .select-container {
        width: 100%;
    }

    .name {
        width: calc(100% - 60px);

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    .portion-size {
        width: calc(100% - 20px);
        .select-container {
            min-width: 200px;
            max-width: 250px;
        }
    }

    .inset-menu {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        padding-left: 20px;

        @include transition(opacity 0.3s ease-in-out);

        > footer {
            color: $text-gray;
            font-size: 14px;
            text-align: right;
        }
    }

    .expand-btn {
        border: none;
        padding: 30px 10px 0px 10px;
        background: none;
        color: $text-gray;
        i {
            @include transition(all 0.3s ease-in-out);
            display: inline-block;
        }
    }

    &[data-expanded="true"] {
        .inset-menu {
            max-height: 30em;
            margin: 0 0 10px 0;
            overflow: visible;
            opacity: 1;


        }

        .expand-btn i {
            @include transform(rotateZ(90deg));
        }
    }

    .date-selector-container {
        text-align: left;
    }

    .meal-types-container {
        display: block;

        .toggle-button {
            margin: 10px 20px 10px 0;
        }
    }
}

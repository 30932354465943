@import "../../../sass/imports";

.edit-recipe-groups {
    padding: initial;
    @include min-screen(47em) {
        padding: initial;
    }
}


.el-modal1-recipe-editor-container {
    max-width: 780px;

    .el-modal1-body-container {
        padding: 0;

        @include max-screen(47em) {
            margin-top: 60px;
        }
    }
}

.recipe-editor {
    position: relative;

    display: flex;
    flex-direction: column;
    max-height: 100%;

    .food-nutrition {
        margin: 0;

        .food-nutrition-header {
            padding: 0 40px;
            @include min-screen(47em) {
                padding: 0 70px;
            }
        }
    }

    .delete-btn {
        color: $raspberry;
        text-decoration: underline;
        background: none;
        border: none;
        box-shadow: none;
        display: inline-block;
    }

    .loading {
        p {
            display: block;
            color: $text-gray;
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            margin: 40px 0;
        }

        i {
            color: $raspberry;
            display: block;
            margin: auto;
            font-size: 40px;
            text-align: center;
        }
    }

    .error-msg {
        color: #e51919;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

    .enter-nutrition-btn {
        display: block;
        width: 160px;
        margin: auto;

        border: none;
        background: none;
        color: $raspberry;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;
        padding: 13px;
    }

    .el-list-field {
        > .serving {
            .serving-description {
                max-width: 80px;
                text-align: left;
            }

            .left-paren, .right-paren {
                font-size: 19px;
                color: #81909f;
            }
        }
    }

    .el-list-form {
        flex: 50;
        overflow-y: auto;
        overflow-x: hidden;

        .el-alert {
            position: relative;
            display: block;
            margin: auto;
            max-width: 405px;

            @include min-screen(47em) {
                margin: 10px auto 0;
            }

            .action-button {
                margin-left: 0;
            }

            .remove-backup-btn {
                position: absolute;
                border: none;
                background: transparent;
                padding: 2px 4px;
                top: 3px;
                right: 3px;
                font-size: 14px;
            }
        }

        .with-label .el-select-container {
            .value {
                font-size: 14px;
                color: $el-grayish-blue;
                padding: 8px 20px;
            }

            .placeholder {
                font-size: 14px;
                padding: 8px 20px;
            }
        }
    }

    .recipe-editor-footer {
        flex: 1 1;

        .el-large-btn {
            margin: 0;
            border: none;
            padding: 8px 8px;
        }
    }

    .recipe-fields, .edit-recipe-groups {
        margin: 20px;
    }

    .instructions {
        color: $text-gray;
        font-size: 14px;
    }

    .recipe-fields {
        .with-label {
            margin: 5px 0;
        }

        .file-upload {
            button {
                .uploading-text {
                    padding: 0 0 2px 0;
                }
            }
        }
    }

    .el-list-form-row {
        border-bottom: 1px solid #edeff2;
        width: 100%;

        > * {
            display: inline-block;
        }
    }

    .with-units {
        display: inline-block;
        margin: 0;
        width: 100%;

        label {
            font-size: 10px;
            font-weight: bold;
            color: $text-gray;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-align: right;
            display: block;
            padding: 0 12px 0;
        }

        input[type="number"] {
            width: 100%;
            border: none;
            font-size: 13px;
            padding: 12px 20px 12px 0;
            color: $text-gray;
            -webkit-appearance: none;
            font-family: monospace;
            text-align: right;
            color: $raspberry;
            font-weight: bold;
            @include border-radius(40px);
            @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.2), true);
            @include transition(box-shadow 0.25s ease-in-out);

            &:focus {
                @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
            }

            @include placeholder {
                text-transform: uppercase;
                font-size: 13px;
            }
        }
    }

    .with-label {
        > label {
            display: inline-block;
            padding: 10px 0;
            margin-right: 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 26px;
            color: #324253;
            @include min-screen(47em) {
                font-size: 16px;
            }
            em {
                font-weight: 300;
            }
        }

        > em {
            position: absolute;
            bottom: 5px;
            right: 15px;
            @include transform(translateY(-50%));

            font-size: 10px;
            text-transform: uppercase;
            color: $text-gray;
        }

        .explanation {
            .triangle, .triangle2 {
                left: 50%;
            }

            .dropdown-btn {
                color: $raspberry;
                padding: 0 3px;
                border: none;
                background: none;
            }

            .dropdown-dropdown {
                @include transform(translate(-50%, -5px));
            }

            .dropdown-content {
                min-width: 250px;

                p {
                    color: $text-navy;
                    font-size: 14px;
                    margin: 0.75em 0;
                }
            }
        }
    }

    .toggle-btn {
        background-color: rgba(66, 85, 105, 0.1);
        @include border-radius(40px);
        padding: 5px 10px;
        display: inline-block;
        border: none;
        -webkit-appearance: none;
        margin: 5px;
        font-size: 14px;
        color: $text-navy;

        &[data-active="true"] {
            background-color: $text-navy;
            color: white;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .ingredient-quantity {
        width: 100px;
        margin: 0 10px 0 0;
        color: $raspberry;
        text-align: left;
        cursor: pointer;
        font-size: 14px;

        @include min-screen(47em) {
            font-size: 16px;
        }
    }

    .el-select-container {
        cursor: pointer;
    }

    .combobox-container {
        width: 100%;
        border: 1px solid rgba(66, 85, 105, 0.1);
        @include border-radius(40px);
        padding: 8px 20px;

        .type-ahead {
            width: 100%;
            color: $text-navy;
            font-size: 15px;
            font-weight: bold;
            border: none;
        }
    }

    .recipe-title {
        display: inline-block;
        vertical-align: bottom;
        margin: 10px 0px 0px 0;
    }

    .recipe-tags {
        .select-container {
            .value {
                white-space: initial;
            }
        }
    }

    .edit-recipe-image {
        margin: 0;
        width: 40px;
        height: 40px;
        border: none;
        background-color: $el-gray4;
        margin-top: 3px;

        .default-file-upload-btn {
            background-color: transparent;
            cursor: pointer;
        }

        i {
            font-size: 20px;
        }

        &:hover {
            i {
                color: $raspberry;
            }
        }

        .file-upload button, .new-image-btn {
            padding: 0;
            width: 40px;
            height: 40px;
            color: $dark-slate-gray;
            margin: 0px;
            background-color: transparent;
            border: none;
        }

        > a > img {
            height: 40px;
            width: 40px;
        }
    }

    .recipe-photo {
        margin: 0;
        padding: 0 0 5px;
    }


    .sub-action-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;
    }

    .food-unit-selector {
        .food-amount {
            > label {
                display: none;
            }

            > input {
                height: 40px;
                border-radius: 40px 0 0 40px;
            }
        }

        .food-unit {
            > label {
                display: none;
            }

            > .select-container {
                border-radius: 0 40px 40px 0;
                height: 40px;

                .value {
                }
            }
        }

        .whole-options {
            border-radius: 40px 0 0 40px;
            border-right: 2px solid white;
        }

        .fraction-options {
            border-radius: 0 40px 40px 0;
        }
    }
}

.collection-editor {
    .notice {
        position: absolute;
        top: 13px;
        right: 50px;
        font-size: 12px;
        color: $text-gray;
        white-space: nowrap;
    }

    .share-popup {
        margin: 10px 5px;
    }

    .favorite-btn {
        border: solid 1px rgba(255, 255, 255, 0.3);
        background: none;
        text-align: center;

        @include border-radius(100%);

        width: 30px;
        height: 30px;
        margin: 10px 5px;

        i {
            color: $text-gray;
            font-size: 16px;
        }

        &[data-boarded="true"] {
            i {
                color: $raspberry;
            }
        }
    }

    .collection-to-pdf-btn {
        margin: 0;
    }

    .plan-to-pdf-btn, .share-btn, .cancel-btn {
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
        background-color: white;
        border: solid 0.5px $inactive-text-gray;
        font-size: 14px;

        padding: 10px;
        min-width: 100px;
        width: calc(50% - 20px);
        max-width: 140px;
        margin: 10px;
        text-transform: uppercase;
        color: $icon-inactive;

        border: solid 0.5px $inactive-text-gray;
        @include border-radius(40px);

        @include transition(all 0.333s ease-in-out);

        &:hover {
            background-color: $inactive-text-gray;
            color: white;
        }

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
        }

        &:disabled {
            opacity: 0.25;
        }
    }

    .share-btn {
        border: solid 0.5px $raspberry;
        background-color: $raspberry;
        color: white;

        &:hover {
            background-color: darken($raspberry, 20%);
            border: solid 0.5px darken($raspberry, 20%);
            color: white;
        }
    }
}

.collection-form {
    border: 1px solid transparent;

    &.el-list-form {
        .collection-title {
            width: calc(100% - 75px);
            display: inline-block;
            vertical-align: top;
            margin: 5px 0px 0 0;
        }

        i {
            &.icon-heart, &.feather-share {
                font-size: 24px;
            }
            
        }

        .popup-dropdown {
            z-index: 11;

            .popup-content {
                right: 110px;
            }
        }
    }

    > h2 {
        color: $text-gray;
        font-size: 18px;
        text-align: center;
        margin-bottom: 1em;
    }

    .item-list {
        margin: 15px 0 0 0;
        text-align: center;
        padding-bottom: 90px;

        @include min-screen(47em) {
            max-height: 415px;
            overflow-y: auto;
        }

        li {
            width: 200px;
            height: 127px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin: 5px;

            @include max-screen(47em) {
                width: 300px;
                height: 190px;

                .grid-meal-draggable {
                    height: 190px;
                }
            }
        }
    }

    .add-meal-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        border: 2px dashed $bluey-gray;
        color: $bluey-gray;
        background: none;
        @include border-radius(5px);

        font-size: 40px;

        > i {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
        }
    }
}

.collection-meal-draggable {
    .meal-image {
        width: 100%;
        height: 100%;
    }

    .card-controls {
        text-align: right;
    }

    .delete-item-btn {
        border: none;
        background: none;
        color: $raspberry;
        padding: 5px 7px;
        font-size: 18px;
    }
}

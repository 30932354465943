
@import "../../../../../sass/imports";
@import "../../Patients/Editor/PatientForm.scss";

.not-now-modal {
    top: 50%;
    left: 50%;

    min-width: 350px;
    min-height: 300px;
    @include transform(translate(-50%, -50%));

    z-index: 11;
}

.not-now-modal-container {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; width: 100%;
    background-color: white;

    .modal-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;

        .close-btn {
            position: absolute;
            top: 0; right: 0;
            border: none;
            background: none;
            font-size: 10px;
            padding: 20px;
            z-index: 10;
        }
    }

    .modal-body {
        padding: 20px;
        padding-bottom: 0;
    }

    .patient-editor-working h2 {
        margin: 25px;
    }

    .patient-editor-working p {
        margin: 25px auto 0 auto;
    }

    .patient-info {
        height: 120px;

        .patient-image {
            float: left;
            width: 80px;
            height: 80px;
            text-align: center;
            margin-right: 40px;
            @include box-shadow(0, 5px, 10px, 0, rgba(0, 0, 0, 0.05));
            @include border-radius(80px);
            border: solid 0.5px $light-gray-border;
            overflow: hidden;

            img {
                width: 80px;
                height: 80px;
            }

            > i {
                padding-top: 24px;
                font-size: 32px;
                color: $raspberry;
                display: inline-block;
            }
        }

        h2 {
            color: $text-gray;
            font-size: 18px;
            font-weight: bold;
            padding-top: 18px;
        }

        p {
            font-size: 10px;
            line-height: 1.2;
            letter-spacing: 0.8px;
            color: $icon-inactive;
            text-transform: uppercase;
            margin-top: 10px;

            em {
                color: $yet-another-gray;
                font-weight: bold;
            }
        }
    }

    .modal-footer {
        width: 100%;
        text-align: center;
        padding: 15px;

        .cancel, .submit {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
            background-color: white;
            border: solid 0.5px $inactive-text-gray;
            font-size: 10px;
            font-weight: bold;

            padding: 8px 30px;
            margin: 10px;
            text-transform: uppercase;

            border: solid 0.5px #9b9fb4;

            @include transition(all 0.333s ease-in-out);
            @include border-radius(30px);

            &:hover {
                background-color: #9b9fb4;
                color: white;
            }

            &:focus {
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
            }
        }

        .submit {
            border: solid 0.5px $mint-green3;
            background-color: $mint-green3;
            color: white;

            &:hover {
                background-color: darken($mint-green3, 20%);
                border: solid 0.5px darken($mint-green3, 20%);
                color: white;
            }
        }
    }

    .remind-me {
        display: inline-block;

        border: solid 0.5px $bluey-gray;

        font-size: 16px;
        padding: 16px 17px;
        width: 100%;

        .placeholder {
            font-size: 10px;
            font-weight: bold;
            color: $text-gray;
            padding: 4px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .value {
            font-size: 12px;
            color: $text-gray;

            em {
                font-weight: bold;
            }
        }

        .select-dropdown p {
            text-transform: uppercase;
            width: calc(100% - 32px);
            font-size: 10px;
            color: $text-gray;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

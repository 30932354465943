
@import "../../../sass/imports";


.login-form {
    color: $text-navy;

    .forgot-password-sent {
        > header {
            text-align: center !important;
        }
    }

    .close-modal-btn {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        border: none;
        background: none;
        font-size: 24px;
        color: $bluey-gray;

        @supports (top: constant(safe-area-inset-top)) {
            top: constant(safe-area-inset-top);
        }
        @supports (top: env(safe-area-inset-top)) {
            top: env(safe-area-inset-top);
        }
    }

    .checkbox-btn {
        font-size: 14px;
        color: $text-gray;
        border: none;
        background: none;
        position: relative;
        padding: 0 0 0 28px;
        text-align: left;

        &:before {
            font-size: 12px;
            @include icon-font;
            color: white;
            display: inline-block;
            content: ' ';
            padding-top: .25em;
            padding-left: .25em;
            width: 20px;
            height: 20px;
            margin-right: .5em;
            @include transition(.66s color);
            vertical-align: middle;

            border: solid 0.5px $another-gray;
            position: absolute;
            top: 50%;
            left: 0;
            @include transform(translateY(-50%));
        }

        &[data-active="true"] {
            &:before {
                border: solid 0.5px #1d976b;
                background-color: $mint-green3;
                content: $icon-check;
            }
        }
    }

    .form-container {
        padding: 40px 20px 60px;
        max-width: 640px;
        margin: auto;
        background-color: white;
        color: $text-gray;



        @include min-screen(610px) {
            padding: 40px 100px 100px;
        }

        > header {
            max-width: 405px;
            margin: auto;
            text-align: center;

            h2 {
                font-size: 24px;
                line-height: 34px;
                margin: 1em 0;

                // @include min-screen(930px) {
                //     margin: 0 0 1em 0;
                //     text-align: center;
                // }

                i {
                    color: $mint-green3;
                    display: inline-block;
                    margin: 0 10px;
                }
            }

            p {
                font-size: 16px;

                line-height: 1.25;
                margin: 25px auto;
                max-width: 400px;
            }

            .logo-container {
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                flex-direction: row;
            }

            img {
                max-width: 150px;
            }

            .pro-label {
                padding-left: 8px;
                padding-top: 12px;
                font-size: 18px;
            }
        }

        > footer {
            margin-top: 20px;
        }

        form {
            max-width: 405px;
            margin: auto;

            > footer {
                margin-top: 40px;
            }
        }

        > footer, form > footer {
            text-align: center;

            h2 {
                color: $text-navy;
                margin: 30px auto;
                text-align: center;
                font-size: 12px;
                font-weight: normal;
                display: inline-block;
                position: relative;
                z-index: 2;
                padding: 0 20px;
            }

            hr {
                width: 100%;
                border-bottom: solid 1px $light-blue-grey;
                position: relative;
                z-index: 1;
                top: 37px;
                width: calc(100% - 10px);
                margin: auto;
            }
        }

        h6 {
            text-align: center;
            font-size: 14px;
            margin: 10px 0;
        }


        > p {
            text-align: center;
            font-size: 16px;
        }
    }

    .with-label {
        width: 100%;
        margin: 0 0 20px 0;

        > label {
            font-size: 14px;
            display: block;
            margin: 0 0 8px 0;
            color: $text-navy;
        }
    }

    input[type="text"], input[type="number"], input[type="password"], input[type="email"], input[type="tel"] {
        background-color: white;
        border: none;
        -webkit-appearance: none;
        @include border-radius(8px);
        width: 100%;
        padding: 10px;
        font-size: 14px;

        @include box-shadow(0, 1px, 4px, 0, rgba(0, 0, 0, 0.17), true);

        @include placeholder {
            line-height: 23px;
            color: #81909F;
        }

        &:disabled {
            opacity: 0.5;
        }

        &[data-error="true"] {
            box-shadow: 0px 0px 4px 0px red;
        }
    }

    .select-container {
        background-color: white;
        width: 100%;
        padding: 10px;
        font-size: 14px;
        @include border-radius(8px);

        @include box-shadow(0, 1px, 4px, 0, rgba(0, 0, 0, 0.17), true);

        .placeholder {
            line-height: 23px;
            color: #81909F;
        }

        .dropdown-content > p {
            padding: 5px;
            text-align: center;
        }

        &[data-error="true"] {
            box-shadow: 0px 0px 4px 0px red;
        }
    }

    .toggle-group {
        text-align: center;

        li {
            display: inline-block;
            vertical-align: top;
            margin: 10px;
        }
    }

    .toggle-btn {
        font-size: 16px;
        line-height: 23px;
        border: none;
        background: #FFFFFF;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        text-transform: uppercase;

        padding: 23px;
        width: 182px;
        height: 93px;

        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @include transform(all 0.25s ease-in-out);

        &[data-checked="true"] {
            color: white;
            background-color: $raspberry;
        }
    }

    .select-bubbles {
        li {
            display: inline-block;
            padding: 3px;
            vertical-align: top;

            &[data-disabled="true"] {
                opacity: 0.5;
            }
        }

        li > button {
            border-radius: 40px;
            background-color: white;
            border: solid 1px $bluey-gray;
            padding: 8px 13px;
            font-size: 11px;
            font-weight: 900;
            text-transform: uppercase;
            cursor: pointer;
            color: $bluey-gray;
            position: relative;
            @include transition(all .25s ease-in-out);
            white-space: nowrap;

            &[data-active="true"] {
                background-color: $raspberry;
                border: solid 1px $raspberry;
                color: white;
            }

            i {
                display: inline-block;
                margin: 0 0 0 3px;
            }
        }
    }

    .error-msg {
        background-color: transparent !important;
        color: red;
        font-size: 16px;
        font-weight: bold;
        padding: 8px 20px;
    }

    .forgot-pwd-container {
        text-align: center;
        max-width: 270px;
        margin: 20px auto 10px auto;

        a {
            cursor: pointer;
        }
    }

    .retrieve-invite {
        max-width: 400px;
        text-align: center;
        font-size: 13px;
        color: $bluey-gray;
        margin: 10px auto;
        line-height: 1.2;
        padding: 10px 0 0 0;

        a {
            cursor: pointer;
        }
    }

    .sub-action-btn {
        color: $raspberry;
        background-color: transparent;
        text-decoration: none;
        border: none;
        font-size: 12px;
        line-height: 23px;
        padding: 8px 10px;
        text-align: center;
        cursor: pointer;
    }

    .back-to-sign-in-btn {
        display: block;
        margin: 10px auto 0;
        cursor: pointer;
    }

    .login-btn {
        @include flat-button($raspberry);
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(159, 3, 98, 0.25);
        -webkit-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(159, 3, 98, 0.25);
        -moz-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(159, 3, 98, 0.25);
        padding: 13px;
        width: 100%;
        font-weight: bold;
        margin: auto;
    }

    .amazon-login-btn {
        width: calc(50% - 10px);
        max-width: 300px;
        min-width: 220px;

        i {
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    .login-support-text, .terms-privacy {
        a {
            color: $raspberry;
            margin: 5px;
            cursor: pointer;
        }
    }

    .login-support-text {
        font-size: 13px;
        color: $bluey-gray;
    }

    .terms-privacy {
        color: $text-navy;
        font-size: 12px;
        margin-top: 30px;
        line-height: 1.4;

        a {
            font-size: 12px;
        }

        em {
            color: $orange;
        }
    }
}

.register-form  {
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @include min-screen(930px) {
        flex-direction: row;
    }

    .form-container {
        margin: 0;
        max-width: none;

        @include min-screen(930px) {
            flex: 1;
        }
    }

    .pick-practice-type {
        margin: 20px auto;
        max-width: 405px;
    }

    .sub-action-btn {
        font-size: 16px;
        text-transform: uppercase;
    }

    .featured-on {
        padding: 10px 0;
        text-align: center;

        @include min-screen(930px) {
            padding: 75px 0 0 0;
        }

        h3 {
            font-size: 10px;
        }

        img {
            margin: 12px 20px 0 20px;
            opacity: 0.5;
        }
    }

    .login-support-text {
        margin-top: 40px;
    }
}


.brochure-container {
    padding: 20px;
    flex: 1;
    position: relative;
    overflow: hidden;
    background-color: #fafafa;

    @include min-screen(930px) {
        padding: 100px;
    }

    > * {
        max-width: 640px;
        margin: auto;

        @include min-screen(930px) {
            margin: 0;
        }
    }

    h2, p, li {
        color: $text-gray;
    }

    h2 {
        font-size: 24px;
        line-height: 34px;
        font-weight: bold;
        margin-bottom: 30px;

        @include max-screen(47em) {
            text-align: center;
        }

        @include min-screen(930px) {
            text-align: center;
            max-width: 500px;
            margin: 0px auto 30px;
        }
    }

    p {
        text-transform: uppercase;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 12px;

        @include min-screen(930px) {
            font-size: 14px;
        }
    }

    ul {
        background: #FFFFFF;
        @include box-shadow(0px, 10px, 40px, 0px, rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        padding: 27px 63px 27px 27px;

        position: relative;
        z-index: 5;
    }

    li {
        padding-left: 30px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        font-size: 15px;
        border-bottom: solid 0.5px rgba(255, 255, 255, 0.3);;
        position: relative;

        @include min-screen(930px) {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        &:before {
            @include feather-font;
            content: $feather-check;
            color: $mint-green3;
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            font-size: 24px;
            top: 50%;
            left: 0;
            @include transform(translateY(-85%));
        }

        &:last-child {
            border-bottom: none;
        }
    }

    > footer {
        p {
            color: $orange4;
            text-align: center;
            margin-top: 50px;
            margin-bottom: 50px;

            font-weight: bold;
            font-size: 24px;
            line-height: 34px;
        }
    }

    .icon-leaf2 {
        position: absolute;
        color: #F1F1F1;
        font-size: 50px;
        z-index: 0;
    }

    .deco1 {
        font-size: 250px;
        bottom: -6%;
        left: -3%;
        transform: scaleX(-1) rotate(27deg);
    }

    .deco2 {
        font-size: 200px;
        top: 450px;
        left: 59px;
        transform: scaleX(-1) rotate(203deg);
    }

    .deco3 {
        font-size: 160px;
        top: 300px;
        right: 0px;
        transform: scaleX(-1) rotate(310deg);
    }
}

.retrieve-invite-form {
    .app-admonishment {
        margin: 0;
        padding: 20px;

        background: #E4E6ED;
        border-radius: 10px;
        text-align: center;

        i {
            display: block;
            margin: auto;
            font-size: 43px;
            color: $orange4;
        }

        strong {
            font-weight: bold;
        }

        p {
            margin: .75em 0;
        }

        a {
            font-weight: bold;
            font-size: 20px;
            line-height: 22px;
            text-decoration: underline;
            color: $raspberry;
        }
    }
}

@import "../../../sass/imports";

.household-member-content {
    padding: 20px 20px 180px 20px;

    .toggle-btn {
        white-space: nowrap;
        width: auto;
        min-width: fit-content;
    }

    .household-member-form {
        color: $text-gray;

        .gender {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .child-bearing-container {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .how-many-babies {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .name {
            width: calc(100% - 10px);
        }

        .birthdate .date-selector-container {
            text-align: left;
            margin: 0;
            width: 100%;

            .with-label {
                margin: 0 10px 10px 0 !important;
                padding: 0 !important;
            }
            label {
                display: none !important;
            }
        }

        .height {
            width: calc(100% - 10px);
        }

        .height-feet {
            width: calc(50% - 5px) !important;
            max-width: 110px;
            margin-right: 10px !important;
        }

        .height-inches {
            width: calc(50% - 5px) !important;
            max-width: 110px;
        }

        .weight {
            width: calc(50% - 10px);
        }

        .activity-level {
            width: calc(50% - 10px);
            max-width: 250px;
        }

        .portion {
            width: calc(50% - 10px);
            max-width: 250px;
        }

        .recommended-portion-size {
            font-size: 12px;

            > div {
                width: calc(50% - 20px);
                display: inline-block;
                vertical-align: middle;
                margin: 10px;

                &:first-child {
                    text-align: right;
                }

                &:last-child {
                    text-align: left;
                }
            }

            em {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
}

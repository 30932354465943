
@import "../../../../sass/imports";

.combo-card {
    .combo-card-side-image {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        /*border: 2px solid $inactive-text-gray;*/
        @include box-shadow(-2px, 4px, 6px, 0, rgba(0, 0, 0, 0.5));

        width: 120px;
        height: 50px;
    }
}

@import "../../../../sass/imports";
@import "./CardAbstract.scss";

.food-details-mini-card {
    &.results-mini-card {
        i.thumb {
            background-image: $el-blue-gradient;
        }
    }
}

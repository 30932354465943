$icomoon-font-family: "eatlove" !default;
$icomoon-font-path: "fonts" !default;

$icon-heart-border: "\eaf1";
$icon-barcode2: "\eaed";
$icon-barbell: "\eaee";
$icon-strawberry: "\eaef";
$icon-muscles: "\eaf0";
$icon-messages3: "\eaec";
$icon-bowl2: "\eae6";
$icon-olive-branch: "\eae7";
$icon-plate2: "\eae8";
$icon-personalize: "\eae9";
$icon-brain: "\eaea";
$icon-plate3: "\eaeb";
$icon-leaf2-outline: "\eae5";
$icon-money-plant: "\eae4";
$icon-leaf2: "\eae2";
$icon-unlocked: "\eae0";
$icon-lock: "\eae1";
$icon-color: "\eace";
$icon-undo: "\eacf";
$icon-redo: "\ead0";
$icon-list-unordered: "\ead1";
$icon-list-ordered: "\ead2";
$icon-strikethrough: "\ead3";
$icon-underline: "\ead4";
$icon-italic: "\ead5";
$icon-bold: "\ead6";
$icon-monospace: "\ead7";
$icon-outdent: "\ead8";
$icon-indent: "\ead9";
$icon-image: "\eada";
$icon-font-size: "\eadb";
$icon-align-center: "\eadc";
$icon-align-justify: "\eadd";
$icon-align-left: "\eade";
$icon-align-right: "\eadf";
$icon-hierarchy: "\eacd";
$icon-bed: "\eac2";
$icon-clock4: "\eac3";
$icon-clock5: "\eac4";
$icon-exercise: "\eac5";
$icon-jumprope: "\eac6";
$icon-link: "\eac7";
$icon-plate1: "\eac8";
$icon-silverware5: "\eac9";
$icon-email5: "\eaca";
$icon-exclude: "\eacb";
$icon-phone3: "\eacc";
$icon-barcode: "\eac1";
$icon-clipboard2: "\eaba";
$icon-settings5: "\eabb";
$icon-blender: "\eabc";
$icon-messages2: "\eabd";
$icon-delivery-truck: "\eabe";
$icon-smartphone: "\eabf";
$icon-warning6: "\e9fc";
$icon-calendar71: "\eab7";
$icon-target2: "\eab8";
$icon-target: "\eab9";
$icon-paperclip: "\eab6";
$icon-female4: "\eab4";
$icon-male4: "\eab5";
$icon-male3: "\eaad";
$icon-female3: "\eaae";
$icon-give: "\eaaf";
$icon-dashboard1: "\eab0";
$icon-toolkit1: "\eab1";
$icon-patients1: "\eab2";
$icon-library4: "\eae3";
$icon-learn: "\eab3";
$icon-library3: "\eaaa";
$icon-pencil3: "\eaa8";
$icon-profiles: "\eaa9";
$icon-spinner2: "\ea94";
$icon-page-paperclip: "\ea89";
$icon-logo2-path1: "\ea91";
$icon-logo2-path2: "\ea92";
$icon-logo3: "\eaac";
$icon-curly-que: "\ea93";
$icon-calendar6: "\ea5a";
$icon-calendar5: "\ea3e";
$icon-calendar: "\e9df";
$icon-calendar7: "\ea76";
$icon-schedule: "\e914";
$icon-prescription-rx: "\e911";
$icon-yahoo-path1: "\e912";
$icon-yahoo-path2: "\e953";
$icon-microsoft-path1: "\e9b5";
$icon-microsoft-path2: "\e9be";
$icon-microsoft-path3: "\ea06";
$icon-microsoft-path4: "\ea09";
$icon-google-path1: "\ea15";
$icon-google-path2: "\ea81";
$icon-google-path3: "\ea85";
$icon-google-path4: "\ea86";
$icon-apple-logo: "\ea87";
$icon-apple: "\e937";
$icon-apple1: "\ea03";
$icon-apple2: "\e9e8";
$icon-expand1: "\ea5e";
$icon-collapse: "\ea88";
$icon-clock: "\e951";
$icon-clock1: "\e982";
$icon-clock2: "\e9d6";
$icon-clock3: "\eaab";
$icon-stopwatch: "\e962";
$icon-time: "\e9a0";
$icon-time1: "\e99e";
$icon-timer: "\e9d1";
$icon-schedule2: "\ea49";
$icon-schedule3: "\ea8a";
$icon-location-pin: "\ea8b";
$icon-silverware: "\e9f9";
$icon-silverware1: "\e936";
$icon-silverware2: "\e959";
$icon-silverware3: "\ea38";
$icon-silverware4: "\ea8c";
$icon-heart1: "\ea8d";
$icon-email: "\e942";
$icon-email1: "\e9ba";
$icon-heart: "\e92b";
$icon-heart2: "\e9fe";
$icon-heart3: "\ea32";
$icon-stacks: "\ea8e";
$icon-view-plans: "\ea3a";
$icon-paper: "\ea8f";
$icon-pencil-notebook: "\ea5f";
$icon-pencil: "\e957";
$icon-pencil1: "\e9f2";
$icon-pencil2: "\ea1d";
$icon-email2: "\ea43";
$icon-email3: "\ea18";
$icon-email4: "\ea73";
$icon-email-heart2: "\e901";
$icon-email-heart1: "\e900";
$icon-print: "\e984";
$icon-print2: "\ea47";
$icon-print3: "\ea82";
$icon-trash-can4: "\ea83";
$icon-trash-can3: "\ea74";
$icon-trash-can2: "\ea4b";
$icon-trash: "\e92a";
$icon-trash-can: "\e93d";
$icon-arrow-pointer: "\e924";
$icon-back-arrow: "\ea84";
$icon-right-arrow: "\e929";
$icon-up-arrow: "\e95f";
$icon-down-arrow: "\e9f6";
$icon-arrow-right: "\e947";
$icon-arrow-up: "\e948";
$icon-arrow-down: "\e949";
$icon-arrow-left: "\e94a";
$icon-share-arrow: "\e963";
$icon-arrow-right1: "\e995";
$icon-arrow-down1: "\e9ae";
$icon-arrow-up1: "\e9bc";
$icon-arrow-left1: "\e9fa";
$icon-pulldown-arrows: "\ea2c";
$icon-breakfast2: "\ea66";
$icon-breakfast-white: "\eaa7";
$icon-salad: "\ea67";
$icon-dinner-white: "\ea90";
$icon-snacks3-path1: "\ea68";
$icon-snacks3-path2: "\ea69";
$icon-snacks3-path3: "\ea6a";
$icon-snacks3-path4: "\ea6b";
$icon-snacks3-path5: "\ea6c";
$icon-snacks3-path6: "\ea6d";
$icon-snack-white-path1: "\ea95";
$icon-snack-white-path2: "\ea96";
$icon-snack-white-path3: "\ea97";
$icon-snack-white-path4: "\ea98";
$icon-snack-white-path5: "\ea99";
$icon-snack-white-path6: "\ea9a";
$icon-snack-white-path7: "\ea9b";
$icon-snack-white-path8: "\ea9c";
$icon-snack-white-path9: "\ea9d";
$icon-snack-white-path10: "\ea9e";
$icon-lunch3-path1: "\ea6e";
$icon-lunch3-path2: "\ea6f";
$icon-lunch3-path3: "\ea70";
$icon-lunch3-path4: "\ea71";
$icon-lunch3-path5: "\ea72";
$icon-lunch-white-path1: "\ea9f";
$icon-lunch-white-path2: "\eaa0";
$icon-lunch-white-path3: "\eaa1";
$icon-lunch-white-path4: "\eaa2";
$icon-lunch-white-path5: "\eaa3";
$icon-lunch-white-path6: "\eaa4";
$icon-lunch-white-path7: "\eaa5";
$icon-lunch-white-path8: "\eaa6";
$icon-check-circle2: "\ea7e";
$icon-check-circle: "\e954";
$icon-minus-circle-outline: "\e93e";
$icon-minus-circle-solid: "\e96d";
$icon-add-circle-outline2: "\ea75";
$icon-recently-used: "\ea77";
$icon-restaurant-meals: "\ea78";
$icon-seach-by-ingredients: "\ea79";
$icon-search5: "\ea7a";
$icon-lightbulb: "\ea7b";
$icon-store-bought-meals: "\ea7c";
$icon-camera: "\ea7d";
$icon-warning5: "\ea7f";
$icon-added-to-list: "\ea80";
$icon-add-to-cart: "\ea58";
$icon-bolt: "\ea59";
$icon-cart3: "\ea5b";
$icon-chevron-left-double: "\e979";
$icon-chevron-right-double: "\e97a";
$icon-chevron-right: "\e9b8";
$icon-chevron-left: "\e9b9";
$icon-chevron-up: "\e9fd";
$icon-chevron-down: "\e9f0";
$icon-chevron-right1: "\ea5c";
$icon-ellipsis2: "\ea5d";
$icon-ellipsis1: "\e974";
$icon-ellipsis: "\e978";
$icon-menu2: "\ea60";
$icon-messages: "\ea61";
$icon-servings: "\ea62";
$icon-settings4: "\ea63";
$icon-skip: "\ea64";
$icon-swap: "\ea65";
$icon-pasta-rice-beans: "\ea4f";
$icon-deli: "\ea50";
$icon-beverages2: "\ea51";
$icon-frozen-foods: "\ea52";
$icon-liquor3: "\ea53";
$icon-produce2: "\ea54";
$icon-snacks2: "\ea55";
$icon-baking3: "\ea56";
$icon-bakery: "\ea57";
$icon-baking2: "\ea3d";
$icon-canned-goods: "\ea3f";
$icon-cereals: "\ea40";
$icon-condiment2: "\ea41";
$icon-dairy2: "\ea42";
$icon-ethnic: "\ea44";
$icon-liquor2: "\ea45";
$icon-pasta: "\ea46";
$icon-produce: "\ea48";
$icon-seafood: "\ea4a";
$icon-warning4: "\ea4c";
$icon-warning3: "\e904";
$icon-warning2: "\e932";
$icon-warning1: "\e93a";
$icon-change-menu: "\ea4d";
$icon-invite-friend: "\ea3c";
$icon-toolkit: "\ea3b";
$icon-phone2: "\e902";
$icon-phone1: "\e903";
$icon-amazon: "\e905";
$icon-person: "\e906";
$icon-leaf: "\e907";
$icon-vegetable: "\e91a";
$icon-patients: "\e908";
$icon-user: "\e909";
$icon-search-patient-lens: "\e90a";
$icon-search-patient: "\e90b";
$icon-male2: "\e90c";
$icon-female2: "\e90d";
$icon-library2: "\e90e";
$icon-dashboard: "\e90f";
$icon-sort-by: "\e910";
$icon-bean: "\e913";
$icon-twitter2: "\e915";
$icon-pinterest2: "\e916";
$icon-facebook2: "\e917";
$icon-sugar2: "\e918";
$icon-plan: "\e919";
$icon-groceries: "\e91b";
$icon-search3: "\e91c";
$icon-salt: "\e91d";
$icon-menu: "\e91e";
$icon-library: "\e91f";
$icon-home2: "\e920";
$icon-grains: "\e921";
$icon-book-eatlove: "\e972";
$icon-generate-more: "\e922";
$icon-fruit: "\e923";
$icon-cook: "\e925";
$icon-collection-icon: "\e926";
$icon-wheat: "\e928";
$icon-check: "\e92c";
$icon-close-x: "\e92d";
$icon-check2: "\ea4e";
$icon-onion: "\e92e";
$icon-tags2: "\e92f";
$icon-grid-view: "\e930";
$icon-budget: "\e935";
$icon-search4: "\e938";
$icon-money-bag2: "\e939";
$icon-crab: "\e93b";
$icon-baking1: "\e93c";
$icon-minus-square-solid: "\e965";
$icon-add-square-solid: "\e96f";
$icon-add-circle-solid: "\e97d";
$icon-add-circle-outline: "\e927";
$icon-radio-unchecked: "\e941";
$icon-radio-checked: "\e987";
$icon-checkbox-checked1: "\e931";
$icon-minus-square-outline: "\e933";
$icon-add-square-outline: "\e9a5";
$icon-hourglass: "\e93f";
$icon-grocery-list: "\e940";
$icon-plant: "\e943";
$icon-cart1: "\e944";
$icon-coffee: "\e945";
$icon-soup-pot: "\e946";
$icon-international: "\e94b";
$icon-eggplant: "\e94d";
$icon-star-full: "\e94e";
$icon-money-cash: "\e94f";
$icon-bowl: "\e950";
$icon-liquor: "\e952";
$icon-ewg: "\e955";
$icon-steam-pot: "\e956";
$icon-steak: "\e958";
$icon-tags1: "\e95a";
$icon-bread: "\e95b";
$icon-bookmark: "\e95c";
$icon-notification1: "\e95d";
$icon-line-graph: "\e95e";
$icon-cupcake: "\e960";
$icon-international1: "\e961";
$icon-list2: "\e964";
$icon-facebook: "\e966";
$icon-candy: "\e967";
$icon-spinner: "\e968";
$icon-avocado1: "\e969";
$icon-family: "\e96a";
$icon-cherries: "\e96b";
$icon-star-half: "\e96c";
$icon-pie-chart: "\e96e";
$icon-trophy1: "\e970";
$icon-carrot: "\e971";
$icon-couple: "\e973";
$icon-list-view1: "\e975";
$icon-list1: "\e976";
$icon-list-view: "\e977";
$icon-pan: "\e97b";
$icon-ribbon: "\e97c";
$icon-youtube: "\e97e";
$icon-share: "\e97f";
$icon-female: "\e980";
$icon-analyze: "\e981";
$icon-search2: "\e983";
$icon-notification: "\e985";
$icon-breastfeed: "\e986";
$icon-receipt: "\e988";
$icon-book: "\e989";
$icon-checkbox-checked: "\e98a";
$icon-menus: "\e98b";
$icon-linkedin: "\e9d7";
$icon-bowl1: "\e98c";
$icon-twitter: "\e9d9";
$icon-instagram: "\e9ea";
$icon-facebook1: "\ea05";
$icon-up-caret: "\e98d";
$icon-bellpepper: "\e98e";
$icon-trophy: "\e98f";
$icon-icecream: "\e990";
$icon-beverages: "\e991";
$icon-dairy: "\e992";
$icon-help: "\e993";
$icon-tomato: "\e994";
$icon-fodmap: "\e996";
$icon-rice: "\e997";
$icon-moon: "\e998";
$icon-award1: "\e999";
$icon-coins: "\e99a";
$icon-knife1: "\e99b";
$icon-remove-circle-outline: "\e99c";
$icon-clipboard: "\e99d";
$icon-pinterest: "\e99f";
$icon-beet: "\e9a1";
$icon-stats: "\e9a2";
$icon-pets: "\e9a3";
$icon-knife: "\e9a4";
$icon-funnel: "\e9a6";
$icon-dollar-sign: "\e9a7";
$icon-search1: "\e9a8";
$icon-add-person: "\e9a9";
$icon-fish1: "\e9aa";
$icon-wheat1: "\e9ab";
$icon-bullet: "\e9ac";
$icon-watermelon: "\e9ad";
$icon-cake: "\e9af";
$icon-plus-thick: "\e9b0";
$icon-pregnant: "\e9b1";
$icon-checkbox-box: "\e9b2";
$icon-baking: "\e9b3";
$icon-olives: "\e9b4";
$icon-cart: "\e9b6";
$icon-select: "\e9b7";
$icon-reload: "\e9bb";
$icon-tags: "\e9bd";
$icon-star-empty: "\e9bf";
$icon-condiment1: "\e9c0";
$icon-jar: "\e9c1";
$icon-eggplant2: "\e9c2";
$icon-google-plus: "\e9c3";
$icon-gauge-10: "\e9c4";
$icon-gauge-20: "\e9c5";
$icon-gauge-30: "\e9c6";
$icon-gauge-50: "\e9cd";
$icon-gauge-40: "\e9cc";
$icon-gauge-60: "\e9c8";
$icon-gauge-70: "\e9c9";
$icon-gauge-80: "\e9c7";
$icon-gauge-90: "\e9cb";
$icon-gauge-100: "\e9ca";
$icon-gauge: "\e9ce";
$icon-checkbox-unchecked: "\e9cf";
$icon-pear1: "\e9d0";
$icon-platter: "\e9d2";
$icon-radio-unselected: "\e9d3";
$icon-logo: "\e9d4";
$icon-avocado: "\e9d5";
$icon-lettuce: "\e9d8";
$icon-pan1: "\e9da";
$icon-eggs: "\e9db";
$icon-home1: "\e9dc";
$icon-chard: "\e9dd";
$icon-nutro1: "\e9de";
$icon-bellpepper1: "\e9e0";
$icon-fish2: "\e9e1";
$icon-pear: "\e9e2";
$icon-award: "\e9e3";
$icon-treenuts: "\e9e4";
$icon-home: "\e9e5";
$icon-strike: "\e9e6";
$icon-sugar: "\e9e7";
$icon-recipe: "\e9e9";
$icon-grid-view1: "\e9eb";
$icon-minus-thick: "\e9ec";
$icon-list: "\e9ed";
$icon-fish: "\e9ee";
$icon-meal-plan: "\e9ef";
$icon-condiment: "\e9f1";
$icon-expand: "\e9f3";
$icon-plus-thin: "\e9f5";
$icon-notification2: "\e9f7";
$icon-minus-thin: "\e9f8";
$icon-tupperware: "\e9fb";
$icon-search: "\eac0";
$icon-plate: "\e9ff";
$icon-money-bag: "\ea00";
$icon-bellpepper2: "\ea01";
$icon-info: "\ea02";
$icon-corn: "\ea04";
$icon-warning: "\ea07";
$icon-nutro: "\ea08";
$icon-raddish: "\ea0a";
$icon-eggplant1: "\ea0b";
$icon-lunch: "\ea0c";
$icon-close-box: "\ea0d";
$icon-measuring-cup: "\ea0e";
$icon-radio-selected: "\ea0f";
$icon-soy: "\ea10";
$icon-herbs: "\ea11";
$icon-veggiebowl: "\ea12";
$icon-adults: "\ea13";
$icon-breakfast: "\ea14";
$icon-cart2: "\ea16";
$icon-dinner: "\ea17";
$icon-female-sign: "\ea19";
$icon-kids: "\ea1a";
$icon-lunch2: "\ea1b";
$icon-male-sign: "\ea1c";
$icon-phone: "\ea1e";
$icon-snacks: "\ea1f";
$icon-x: "\ea20";
$icon-gluten-free: "\ea21";
$icon-high-blood: "\ea22";
$icon-lose-weight: "\ea23";
$icon-pediatric-nutrition: "\ea24";
$icon-pregnancy: "\ea25";
$icon-reduce-inflamation: "\ea26";
$icon-renal-disease: "\ea27";
$icon-sports-nutrition: "\ea28";
$icon-food-allergies: "\ea29";
$icon-eat-more-plants: "\ea2a";
$icon-diabetes: "\ea2b";
$icon-baby-bottle: "\ea2d";
$icon-rx: "\ea2e";
$icon-intestine: "\ea2f";
$icon-diabetes1: "\ea30";
$icon-diabetes2: "\ea31";
$icon-high-cholesterol: "\ea33";
$icon-increase-energy: "\ea34";
$icon-mother-child: "\ea35";
$icon-osteoporosis: "\ea36";
$icon-settings3: "\ea37";
$icon-settings2: "\e94c";
$icon-settings1: "\e934";
$icon-settings: "\e9f4";
$icon-smile: "\ea39";
$icon-scale-weight: "\eaf2";


// RWD Image
// -- fluid by height or width

@mixin fluid-image($height: false) {

    @if $height == true {
        height: 100%;
        width: auto;
    } @else {
        width: 100%;
        height: auto;   
    }
    display: block;
    max-width: 100%;
    max-height: 100%;
}

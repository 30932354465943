
@import "../../../sass/imports";

.how-much-to-eat {
    margin: 10px 0 60px;

    @include max-screen(600px) {
        text-align: center;
    }

    .chart {
        margin: 0 20px 0 0;
        width: 120px;
        height: 120px;
        page-break-inside: avoid;
        position: relative;
        display: inline-block;
        vertical-align: top;

        @include min-screen(750px) {
            margin: 0 20px 0 40px;
            width: 200px;
            height: 200px;
        }

        @include max-screen(750px) {
            width: 160px;
        }

        @include max-screen(600px) {
            display: block;
            margin: 0 auto 5px;
        }

        text {
            font-size: 30px;
            font-weight: bold;

            @include max-screen(600px) {
                font-size: 42px;
            }
        }

        > p {
            font-size: 15.4px;
            @include max-screen(600px) {
                max-width: 100%;
            }
            text-align: center;
            display: block;
            margin: 10px auto;
        }
    }

    .portion-key {
        page-break-inside: avoid;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        max-width: calc(100% - 140px);

        @include min-screen(675px) {
            max-width: calc(100% - 260px);
            padding-left: 50px;
        }

        @include max-screen(600px) {
            margin-top: 100px;
            max-width: 100%;

            button {
                font-size: 12px;
            }
        }

        li {
            margin: 5px auto;
            width: 100%;
            font-size: 15.4px;
            display: flex;
            align-items: center;

            &:first-child {
                text-transform: uppercase;
                margin-bottom: 10px;
                font-weight: bold;
            }

            &:nth-child(2) {
                margin-bottom: 15px;
            }

            i {
                display: inline-block;
                float: right;
                font-size: 10px;
                margin-left: 2em;
                padding: 5px;
            }

            span {
                font-weight: normal;

                &.member-name, &.smart-fraction {
                    font-weight: bold;
                }

                &.slice-info {
                    white-space: nowrap;
                }
            }

            > div {
                max-width: calc(100% - 30px);
                font-weight: normal;
            }

            &[data-removable="true"] {
                cursor: pointer;
            }

            .dish-number {
                font-weight: 700;
                min-width: 55px;
            }

            em {
                font-weight: 700;
                margin-left: 5px;
            }
        }

        .change-whos-eating-btn {
            margin: 10px 0;
        }

    }

    .leftovers-disclaimer, surplus-disclaimer {
        font-style: italic;
        font-size: 12px;
        max-width: 500px;
        padding: 20px 0 0px;

        @include max-screen(750px) {
            padding: 30px 0 0px;

        }
    }

    .surplus-disclaimer {
        padding: 10px 0 0px;
    }

    .key-color {
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: middle;
        margin-right: 5px;
    }

    .food-units-selector-outer {
        display: inline-block;
        margin-left: 10px;
        color: $raspberry;
    }
}


@import "../../../../sass/imports";

.nutrition-info-modal-container {
    max-width: 1000px;
    h2, h4 {
        text-align: center;
    }

    .brand {
        font-weight: 300;
        margin-right: .5em;
        vertical-align: middle;
        text-align: center;
    }

    .nutrition-info {
        padding: 20px;
        margin: auto;

        @include min-screen(47em) {
            width: 90vw;
            max-width: 800px;
        }
    }
}


// Simple on/off switch with the text inside the switch.
// Matches this design: https://www.figma.com/file/jf45SWufB2V7ez3d7nLH9R/EatLove-PRO?node-id=608%3A6643&t=WZggfzn385pbHPMU-0
.el-switch1 {
    position: relative;
    padding: 3px 6px 3px 22px;
    height: 24px;
    background-color: $el-gray4;
    border-radius: 24px;
    border: none;
    box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
    color: $el-gray1;
    min-width: 50px;
    text-align: right;

    font-size: 12px;
    text-transform: uppercase;
    @include transition(padding 0.1s ease-in-out);

    &:after {
        content: 'OFF';
    }

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background-color: $el-gray1;
        @include transition(left 0.1s ease-in-out);
    }

    &[data-active="true"] {
        background-color: #891D55; // @todo - color not in palette
        color: $el-white;
        padding: 3px 22px 3px 6px;
        text-align: left;

        &:after {
            content: 'ON';
        }

        &:before {
            left: calc(100% - 21px);
            background-color: $el-white;
        }
    }

    &:disabled {
        opacity: 0.5;
    }
}



// Simple on/off switch with the text to the right of the switch.
// Matches this design: https://www.figma.com/file/Fli6B72a8u9yfMBZ7yVDlr/EatLove-Consumer?node-id=1%3A29732&t=SOzxn7RXO0RpJt0H-0
.el-switch2 {
    position: relative;
    border: none;
    background: none;
    padding-left: 60px;
    min-height: 24px;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        left: 0px;
        width: 50px;
        height: 24px;
        border-radius: 24px;

        background-color: $el-gray4;
        box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
    }

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        left: 3px;

        height: 18px;
        width: 18px;
        border-radius: 18px;

        background-color: $el-gray2;

        @include transition(left 0.1s ease-in-out);
    }

    > span {
        font-size: 16px;
        color: $el-grayish-blue;
        text-transform: uppercase;
    }

    &[data-active="true"] {
        &:before {
            background-color: $el-raspberry;
        }

        &:after {
            background-color: $el-white;
            left: 29px;
        }
    }

    &:disabled {
        opacity: 0.5;
    }
}


@import "../../../../../sass/imports";

.search-types-list > li {
    color: $text-navy;
    font-size: 15px;
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
        border-bottom: none;
    }

    > button {
        padding: 10px 20px;
        width: 100%;
        text-align: left;
        border: none;
        background: none;
        white-space: nowrap;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        color: $raspberry;
        font-size: 22px;
        margin: 0 20px 0 0;
    }

    &[data-active="true"] {
        background-color: rgba(163, 0, 99, 0.05);
    }
}



@import "../../../../../sass/imports";

.profile-modal-container {
    h2 {
        color: $text-gray;
        font-size: 20px;
        text-align: center;
        padding: 10px;

        em {
            font-weight: normal;
            font-style: italic;
        }
    }

    h3 {
        color: $text-gray;
        width: 100%;
        font-size: 16px;
        font-weight: normal;
        padding-left: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $light-gray-border;
        margin-top: 40px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
}

@import "../../../sass/imports";

 .share-popup {
    .popup-content {
        min-width: 185px;
    }

    .copy-to-clipboard {
        width: 100%;
        text-align: center;
        display: flex;

        span {
            display: inline-block;
            margin-top: 10px;
            color: $text-gray;

            @include max-screen(440px) {
                display: block;
                text-align: center;
            }
        }

        input {
            width: calc(100% - 75px);
            height: 30px;
            min-width: 150px;
            margin-top: 10px;
            border: dashed 0.5px $bluey-gray;
            border-right: none;
            font-size: 16px;
            color: darken($raspberry, 10%);
            text-align: left;
            padding: 4px 8px;
            margin-left: 0;
            display: inline-block;
            vertical-align: top;
        }

        .copy {
            padding: 4px 8px;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
            border: 0.5px solid $raspberry;
            background: $raspberry;
            color: white;
            border: none;
            width: 75px;
            height: 30px;
            margin-top: 10px;
            display: inline-block;
            vertical-align: top;
        }

        .copied {
            background: $light-gray-border;
            border: 0.5px solid $light-gray-border;
            color: $bluey-gray;
        }
    }

    .share-popup-content p {
        font-size: 16px;
        color: $text-navy;
        text-align: center;
    }

    .icon-spinner {
        font-size: 30px;
        color: $el-raspberry;
        display: inline-block;

    }
}

@import "./PracticeEditorModal.scss";

.logo-editor-container {
    &.edit-practice-container {
        .user-name {
            padding-top: 20px;
            width: calc(100% - 85px);
        }

        .preview, .user-credentials {
            width: calc(100% - 20px);
        }

        .practice-name {
            width: calc(100% - 20px);
            margin: 10px 10px 0 10px;
            flex-grow: 6;
        }

        .color-picker {
            flex-shrink: 1;
            margin: 20px 10px 0 10px;
        }
    }

    .practice-logo-img {
        margin: auto;
        display: block;
    }
}

.edit-logo-form {
    color: $text-navy;
    padding-bottom: 80px;

    input[type="text"] {
        width: 100%;
    }

    > hr {
        width: 50%;
        height: 1px;
        margin: 30px auto;
    }

    p {
        text-align: left;
        font-size: 14px;
        margin: 0 10px;
    }

    .upload-section {
        text-align: center;

        .file-upload {
            display: inline-block;
        }
    }

    .flex-line {
        display: flex;
    }

    .subtext {
        font-size: 10px;
        font-style: italic;
        text-align: center;
    }

    .edit-recipe-image {
        .file-upload {
            .default-file-upload-btn {
                padding: 10px 15px 0px;
            }
        }
    }

    .color-picker {
        margin: 10px;

        .dropdown-btn {
            border: none;
            background: none;

            label {
                font-size: 10px;
                font-weight: bold;
                color: $text-gray;
                letter-spacing: 1px;
                text-transform: uppercase;
                white-space: nowrap;
                cursor: pointer;
            }
        }

        .block-container {
            padding: 3px;
            border: 1px solid $light-gray-border;
            @include border-radius(3px);
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 0 10px;
            max-height: 35px;
        }

        .block {
            display: inline-block;
            width: 25px;
            height: 25px;
        }

        .dropdown-dropdown {
            @include transform(translate(-60%, -5px));
        }

        .dropdown-content {
            padding: 10px;
        }

        &[data-state="true"] {
            .dropdown-dropdown {
                overflow-y: visible;
                overflow-x: visible;
            }
        }

        .triangle, .triangle2 {
            left: 82%;
        }

        .close-btn {
            display: none;
        }
    }
}

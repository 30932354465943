
@import "../../../sass/imports";
@import "../Recipes/Editor.scss";
@import "../Recipes/Editor/EditTimings.scss";
@import '../MyAccount/PreferencesForm.scss';

.food-unit-selector {
    max-width: 400px;
    width: 100%;
    margin: auto;

    .unit-with-amount {
        display: flex;

        .el-labeled-input {
            flex: 1;

            > input {
                &[data-error="true"] {
                    border: 1px solid $el-error;
                    background-color: rgba($el-error, 0.1);
                }            
            }

            .select-container {
                &[data-error="true"] {
                    border: 1px solid $el-error;
                    background-color: rgba($el-error, 0.1);
                } 
            }
        }
    }

    .el-labeled-input {
        &.food-amount > input {
            border-radius: 10px 0 0 10px;
            border-right: none;
        }

        &.food-unit > .select-container {
            border-radius: 0 10px 10px 0;
            border-left: none;
            padding: 0;
        }
    }

    .error-message {
        font-size: 12px;
        color: $el-error;
    }

}


.food-units-confirm-amount {
    p, h5 {
        text-align: center;
    }
}

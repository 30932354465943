@import "../../../../../sass/imports";

.diet-preferences {
    max-height: calc(100vh - 160px);
    overflow-y: auto;

    @include min-screen(676px) {
        max-height: calc(100vh - 250px);
    }

    .combobox-container .type-ahead {
        width: 100%;
    }

    .select-bubbles {
        li {
            display: inline-block;
            vertical-align: top;
            margin: 5px;
            width: calc(50% - 10px);
            max-width: none;
            padding: 0;

            &[data-disabled="true"] .dropdown-btn {
                opacity: 0.5;
            }
        }

        li > button, .dropdown-btn {
            @include border-radius(50px);
            background-color: rgba(255, 255, 255, 0.2);
            border: solid 1px $bluey-gray;
            font-size: 11px;
            font-weight: 900;
            text-transform: uppercase;
            vertical-align: top;
            margin: 0 auto;
            cursor: pointer;
            color: $bluey-gray;
            position: relative;
            @include transition(all .25s ease-in-out);

            width: 100%;
            border-radius: 50px;
            height: auto;
            padding: 8px 16px;


            &[data-active="true"] {
                background-color: $raspberry;
                border: solid 1px $raspberry;
                color: white;
            }
        }
    }

    .avoidances-selector {
        color: $text-navy;
        text-align: center;

        h3 {
            font-size: 16px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            font-weight: normal;
        }

        .choose-diets {
            max-width: 400px;
            margin: 0 auto 3em auto;

            .select-bubbles {
                li p {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: $bluey-gray;
                }
            }
        }

        .choose-allergens, .choose-dislikes {
            max-width: 500px;
            margin: 0 auto 1em auto;

            .select-bubbles {
                > li {
                    width: calc(33% - 10px);
                    min-width: 100px;
                }

                button {
                    &[data-active="true"] {
                        background-color: $katherine-gray !important;
                        border: solid 0.5px darken($katherine-gray, 10%) !important;
                        color: white !important;
                        position: relative;

                        &:after {
                            @include icon-font;
                            content: $icon-x;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            @include transform(translate(-50%, -50%));
                            display: inline-block;
                            font-size: 20px;
                            color: $super-light-gray;
                        }
                    }
                }
            }
        }

        .choose-dislikes {
            margin: 0 auto 2em auto;
            .select-bubbles {
                > li {
                    width: auto;
                    min-width: 100px;
                }
            }
        }

    }
}


@import "../../../../sass/imports";

.pro-dropdown-container {
    position: relative;
    display: inline-block;
    vertical-align: top;

    > .dropdown-btn {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:after {
            @include icon-font;
            content: $icon-chevron-down;
            display: inline-block;
            padding-top: 2px;
            padding-left: 5px;
            float: right;
        }
    }

    &[data-hide-chevron="true"] {
        > .dropdown-btn:after {
            display: none;
        }
    }

    > .dropdown-dropdown {
        position: absolute;
        top: 100%;
        left: 50%;
        @include transform(translate(-45%, -20px));
        max-height: 0;
        overflow: hidden;
        z-index: 100;

        opacity: 0;
        @include transition(all .25s ease-in-out);

        > .dropdown-container {
            > .triangle {
                position: absolute;
                top: 0;
                left: 50%;
                @include transform(translateX(-50%));
                z-index: 27;

                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #e0e0e0;
            }

            > .triangle2 {
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #FFF;

                position: absolute;
                top: 1px;
                left: calc(50% + 0px);
                @include transform(translateX(calc(-50% + 0px)));
                z-index: 27;
            }

            > .dropdown-content {
                position: relative;
                margin-top: 10px;
                padding: 20px;
                background-color: #ffffff;
                @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));
                will-change: filter;

                border: solid 0.5px #e0e0e0;
                z-index: 26;
                max-width: 100vw;
                > .close-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    border: none;
                    background: none;
                    padding: 1.5em;
                    font-size: 8px;
                    color: #425569;
                }
            }
        }

    }

    &[data-state="true"] {
        .dropdown-dropdown {
            opacity: 1;
            max-height: 50em;
            overflow-y: auto;
            display: block;
        }
    }

    &[data-closing="true"] {
        .dropdown-container {
            opacity: 0;
        }
    }
}


@import "../../../../sass/imports";

.brand-card {

    .recipe-card-image {
        background-image: $product-card-gradient;

        h2 {
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            color: white;
            position: absolute;
            top: 50%;
            @include transform(translate(0, -50%));
            text-align: center;
            width: 100%;
            padding: 0 5px;
        }
    }
}

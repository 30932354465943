
@import "../../sass/imports";

.feed-modal {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
}

.feed-modal-overlay {
    // @include background-image(linear-gradient(top, rgba(72, 80, 90, 1) 0%,rgba(72, 80, 90, 0.76) 30%));
    background: rgba(72, 80, 90, 0.9);
    backdrop-filter: blur(10px);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 10;

    opacity: 0;
    @include transition(all 0.333s ease-in-out);

    &.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

.feed-modal-pointer {
    > header {
        @include min-screen(47em) {
            &:after {
                content: ' ';
                position: absolute;
                top: calc(100% + 5px);
                right: calc(50% - 15vw);
                @include transform(translate(50%, 0));

                width: 0; height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid #FFF;
                z-index: -1;
            }
        }
    }
}

.feed-modal-center-pointer {
    > header {
        @include min-screen(47em) {
            &:after {
                right: 50%;
            }
        }
    }
}

.feed-modal-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    > header {
        position: relative;
        z-index: 20;

        .header-container {
            max-width: 1050px;
            margin: auto;
            position: relative;
            height: 60px;

            h1 {
                position: absolute;
                top: 17px;
                left: 0;
                width: 100%;
                text-align: center;
                color: white;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;

                @include min-screen(47em) {
                    top: 30px;
                }
            }
        }
    }

    @include min-screen(47em) {
        > footer {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 30;
            padding: 10px;
        }
    }

    @include max-screen(47em) {
        > footer {
            position: fixed;
            z-index: 16;
            bottom: 0;
            left: 0;
            right: 0;
            top: auto;
            padding: 0px 10px;
            text-align: center;

            &:after {
                display: block;
                content: ' ';
                position: absolute;
                top: -15px;
                left: 50%;
                @include transform(translate(-50%, 0%));
                @include border-radius(50%);
                z-index: -1;

                // background-color: $hip-gray;
                box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.14);
                background-image: linear-gradient(180deg, #5b7287 0, #324253 45%, #324253);
                width: 125vw;
                height: 68vw;
            }

            .ok-btn {
                min-width: 250px;
            }
        }
    }

    .close-modal-btn {
        color: white;
        display: inline-block;

        border: none;
        padding: 17px 20px 14px 20px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        background: none;
        position: relative;
        z-index: 20;

        @include min-screen(47em) {
            padding: 27px 20px 14px 20px;
        }

        i {
            font-size: 26px;
            vertical-align: middle;
            display: inline-block;
            margin: 0 20px 0 0;
            color: white;

            @include min-screen(47em) {
                border: 1px solid white;
                @include border-radius(20px);
                margin: 0 40px 0 0;
                padding: 0 20px 0 16px;
                color: white;

                &:after {
                    content: 'BACK';
                    font-size: 14px;
                    font-family: Lato;
                    font-weight: bold;
                    color: white;
                    padding: 7px 9px 0 12px;
                    vertical-align: top;
                    display: inline-block;
                }

                &.icon-chevron-left {
                    &:before {
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 28px;
                        vertical-align: top;
                    }
                }

            }
        }
    }

    .top-half-circle {
        @include transform(translate(-50%,calc(-100% + 110px)));

        @include min-screen(47em) {
            display: none;
        }

        @media print {
            display: none;
        }

        &.no-curve {
            border-radius: unset;
        }
    }

    .side-testimonial-container{
        left: calc(590px + 75px + 7%);
        position: relative;
        width: calc(35% - 590px + 510px);
        color: white;

        margin: 30px 0;
        h2 {
            font-size: 24px;
            max-width: 330px;
        }

        > div {
            margin: 20px 0;

            > div {
                border-bottom: 1px solid #FFFFFF;
                margin-bottom: 25px;

                > span {
                    display: flex;
                }
            }
        }
        .avatar {
            width: 41px;
            height: 41px;
            margin: 0;
            background-color: #EAEDEF;
            @include border-radius(80px);
            text-align: center;
            display: inline-block;
            margin: 20px 20px 20px 0;
            vertical-align: bottom;
        }

        .dietitian-title {
            display: inline-block;
            width: 85%;
            margin: 0;
            margin: 20px 0;
            line-height: 22px;

            > p {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

        }
    }

    .modal-scroll-container {
        background-color: white;
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        z-index: 1;
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.25));

        &::-webkit-scrollbar {
           @include max-screen(47em) {
                display: none;
            }
        }

        @include min-screen(47em) {
            top: 80px; bottom: auto;
            left: 50%; right: auto;
            @include transform(translate(-50%, 0));
            min-height: 0;
            max-width: calc(100vw - 30px);
            max-height: calc(100vh -  100px);
            z-index: 10;
            border-radius: 3px;
        }

        &.upgrade-container {
            border: none;
            border-radius: 8px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            max-height: initial;
            top: 60px;
            overflow-y: initial;
            overflow-x: initial;
            -webkit-overflow-scrolling: initial;
            &[data-loading="true"] {
                border-radius: 8px;
            }
            &[data-footer="true"] {
                border-radius: 8px 8px 0 0;
            }

            @include max-screen(47em) {
                top: 0;
            }

            > footer {
                position: absolute;
                width: 100%;
                z-index: 2;
                height: 80px;
                border-radius: 0 0 8px 8px;
                text-align: center;
                padding: 20px 0;
                background: white;


                button {
                    @include flat-button($raspberry);
                    height: 40px;
                    width: 80%;
                    margin: 0;
                }

            }

            @include min-screen(1050px) {
                &[data-off-center="true"] {
                    left: 425px;
                }
            }

            .close-btn {
                i {
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    font-size: 18px;
                    cursor: pointer;
                    z-index: 2;

                    @include max-screen(47em) {
                        top: 125px;
                    }
                }
            }

        }



        // &::-webkit-scrollbar {
        //     display: none;
        // }

        > .inner-slider {

            position: relative;
            z-index: 1;
            padding: 120px 0;

            @include min-screen(47em) {
                padding: 0;
                margin: auto;
            }

            &.upgrade-slider {
                overflow-y: auto;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                height: calc(100vh - 170px);
                padding: initial;
                margin: initial;

               @include max-screen(47em) {
                    height: 100vh;

                    @supports (height: 100dvh) {
                        height: 100dvh;
                    }
                    
                    &[data-footer="true"] {
                        height: calc(100vh - 80px);

                        @supports (height: 100dvh) {
                            height: calc(100dvh - 80px);
                        }
                    }
                }

                @include max-screen(47em) {
                    padding: 110px 0 0;
                }


                &[data-long="true"] {
                    height: initial;
                }

                &[data-loading="true"] {
                    height: calc(100vh - 70px);
                    @include max-screen(47em) {
                        height: 100vh;
                    }
                }

            }


            @media print {
                padding: 0;
            }
        }
    }
}

@import "../../../sass/imports";

.favorite-btn {
	[data-boarded="false"] {
		.icon-heart {
			color: #2E394D;
		}		
	}
	.icon-heart1 {
		&:before {
		    position: relative;
	    	left: 25px;
	    	color: #FFFFFF;
		}
	}
}

.favorites-toggle {
	margin-right: 20px;

	label {
	    margin-right: 5px;
	}
}


@import "../../../../sass/imports";

.activation {
    > p {
        font-size: 12px;
        line-height: 14px;
        color: #B7B7B7;
    }

    .follow-toggle-btn {
        background: none;
        border: none;
        color: #B7B7B7;
        display: block;
        font-size: 16px;
        line-height: 26px;
        text-transform: uppercase;
        padding: 0 0 10px;

        > span {
        	line-height: 24px;
        	vertical-align: middle;
        }

        .toggle {
            width: 50px;
            height: 24px;
            box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
            border-radius: 22px;
            display: inline-block;
            background: #C3C6D3;
            position: relative;
            vertical-align: middle;
            margin: 0 10px 0 0;
            cursor: pointer;

            &:after {
                @include icon-font;
                position: absolute;
                top: 50%;
                @include transform(translate(0, -50%));
                @include transition(right .1s ease-in-out);
                font-size: 18px;
                content: $icon-bullet;
                right: 2px;
                color: #B7B7B7;
            }

            &[data-following="true"] {
                &:after {
                    @include icon-font;
                    position: absolute;
                    top: 50%;
                    @include transform(translate(0, -50%));
                    @include transition(right .1s ease-in-out);
                    font-size: 18px;
                    content: $icon-bullet;
                    right: 30px;
                    color: #FFFFFF;
                }
            }

        }


    }

    &[data-patient-active="true"]{     
        > p {
            color: #FFFFFF;
        }

        .follow-toggle-btn {
            color: #FFFFFF;
            .toggle {
                background: #ECEFF1;
                &[data-following="true"] {
                    background: #2CD296;
                }
            }
        }
    }


     .patient-deactivator {

        .deactivate-btn, .deactivate-working-btn, .reactivate-btn, .inactive-deactivate-btn {
            text-transform: uppercase;
            font-weight: bold;
            border: none;
            min-width: 200px;
        }

        .deactivate-working-btn {
        }

        .reactivate-btn {
        }

        .inactive-deactivate-btn {
            opacity: 0.25;
        }

        p {
            color: white;
            font-size: 12px;
            text-align: center;
            width: 175px;
            margin: 50px auto 10px;
        }
    }


}
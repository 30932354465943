
@import "../../../../sass/imports";

.pro-patients {
    @include min-screen(47em) {
        margin: 0 7%;
    }
    background-color: #eceeef;
    @include max-screen(47em) {
        background: #FAFAFA;
    }
    min-height: calc(100vh - 84px);

    .add-patient-btn {
        color: #fff;
        padding: 8px 14px;
        background-color: #a30063;
        border: none;
        font-size: 12px;
        text-transform: uppercase;
        -webkit-border-radius: 30px;
        border-radius: 30px;
        margin: 0 0 0 15px;
        vertical-align: top;
        right: initial;
        top: initial;
        width: initial;
        height: initial;
        i {
            padding: 0 10px;
        }

        &:before {
            @include icon-font;
            content: $icon-plus-thin;
            display: inline-block;
            padding-right: 10px;
        }

        &[data-search="true"] {
            @include border-radius(30px);
            margin: 0 0 0 15px;
            vertical-align: top;
            position: relative;
            left: calc(100% - 182px - 15px);
            top: -30px;
            width: 182px;
            height: 75px;
            background: #A30063;
            border-radius: 8px;

            @include max-screen(47em) {
                border-radius: 0px;
                z-index: 1;
                height: 75px;
                bottom: 0;
                width: 100%;
                position: fixed;
                margin: 0;
                top: initial;
                left: initial;
                right: initial;
            }
        }
    }

    .search-widget-container{
        position: relative;
        top: 27px;
        left: 565px;

        @include max-screen(1191px){
            position: relative;
            z-index: 1;
            top: 100px;
            left: 30px;
        }


        @include max-screen(47em) {
            position: static;
            display: block;
            margin: 20px auto;
            width: 280px;
        }


        .pro-dropdown-container {
            position: relative;
            display: inline-block;
            vertical-align: top;
            margin-left: 46px;
        }

        .patient-search-toggle {
            position: relative;
            display: inline-block;
            width: 153.25px;
            height: 29px;
            cursor: pointer;
            vertical-align: middle;
            line-height: 29px;

            background: #EAEDEF;
            @include max-screen(47em) {
                background: white;
            }
            border-radius: 40.3101px;

            .toggle-side {
                position: absolute;
                height: 100%;
                width: 50%;
                font-family: Lato;
                font-style: normal;
                font-size: 12px;
                text-align: center;
                color: #324253;
                &[data-active="true"] {
                    background: #B5BEC8;
                    border-radius: 40.3101px;
                    font-weight: bold;
                }
            }

            .toggle-right {
                left: 50%;
            }
        }
    }


    @include min-screen(47em) {
        background-color: #fafafa;
        padding: 40px 0;
    }

    .top-row {
        position: relative;
        z-index: 5;

        .patient-search-header{
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;

            @include max-screen(47em) {
                padding: 20px 20px 10px 20px;
                font-size: 22px;
                background: #FAFAFA;
            }
        }

        @include min-screen(47em) {
            margin: 0 20px 20px 0;


            .patient-count {
                max-width: 375px;
            }

            .keyword-search {
                position: absolute;
                top: 50%;
                right: -48px;
                @include transform(translate(0, -50%));
                background-color: transparent;

                &.team-member-search {
                    right: 5px;

                    > header {
                        width: 100px;
                    }

                     > footer {
                        width: calc(100% - 100px);
                    }
                }
            }
        }
    }

    .training {
        margin: 2em 0;
        font-size: 16px;
    }


    .patient-count {
        position: relative;
        margin-top: 5px;

        @include max-screen(47em) {
            margin: 5px 20px;
        }

    .rectangles {
        display: flex;
        width: 100%;
        gap: 10px;

        .rectangle {
            width: 108px;
            height: 75px;
            border-radius: 10px;
            text-align: center;
            padding: 10px;
            cursor: pointer;
            color: white;
            @include max-screen(47em) {
                width: 65px;
                height: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
            }
    
            .count-number {
                font-size: 24px;
                font-weight: 700;
    
                @include max-screen(47em) {
                    font-size: 16px;
                }
            }
            .count-label {
                font-size: 14px;
                white-space: nowrap;

                @include max-screen(47em) {
                    font-size: 12px;
                    white-space: normal;
                    line-height: 1;
                }
            }
        }

    }


    .rectangle-total {
        &[data-active="false"] {
            background: none;
            border: 2px solid #324253;
            color: #324253;

            @include max-screen(47em) {
                border: 1px solid #324253;
            }
        }
        background: #324253;

    }


    .rectangle-current {
        &[data-active="false"] {
            background: none;
            border: 2px solid #2DCA93;
            color: #2DCA93;

            @include max-screen(47em) {
                border: 1px solid #2DCA93;
            }
        }
        background: #2DCA93;
    }

    .rectangle-expiring-soon {
        &[data-active="false"] {
            background: none;
            border: 2px solid #AD8124;
            color: #AD8124;
            
            @include max-screen(47em) {
                border: 1px solid #AD8124;
            }
        }
        background: #AD8124;
    }


    .rectangle-expired {
        &[data-active="false"] {
            background: none;
            border: 2px solid #C13E0E;
            color: #C13E0E;

            @include max-screen(47em) {
                border: 1px solid #C13E0E;
            }
        }
        background: #C13E0E;
    }


        @include max-screen(47em) {
            text-align: center;
        }

        .search-label{

            i {
                margin: 0 10px 0 0;
            }

            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;

            color: #425569;
            padding: 15px 0;
        }

        h3 {
            font-size: 24px;

            @include max-screen(47em) {
                text-transform: uppercase;
                font-size: 16px;
            }

            em {
                color: $raspberry;
                font-weight: 900;
            }
        }
    }



    .keyword-search {
        @include max-screen(47em) {
            width: 95%;
            max-width: 500px;
            margin: 0 auto;
        }

        > footer {
            width: calc(100% - 125px);
            display: inline-block;
            vertical-align: top;
            position: relative;
            padding: 10px 0 10px 15px;

            &:before {
                position: absolute;
                z-index: 2;
                @include icon-font;
                content: $icon-search5;
                top: 50%;
                left: 29px;
                @include transform(translate(0, -50%));
                color: $raspberry;
                font-size: 18px;
            }
        }

        > header {
            width: 125px;
            display: inline-block;
            vertical-align: top;
            padding: 16px 10px;
            white-space: nowrap;
        }

        input {
            width: 100%;
            border-radius: 25px;
            @include box-shadow(0, 1px, 6px, 0, rgba(0, 0, 0, 0.17), true);
            background-color: white;
            padding: 13px 32.1px 12px 38.9px;
            font-size: 12px;
            position: relative;
            border: none;

            &:placeholder {
                color: #85898c
            }
        }
    }

    .sort-patients-by, .filter-patients, .filter-team-members {
        .dropdown-btn {
            text-transform: uppercase;
            color: $josh-blue-gray;
            border: 1px solid $josh-blue-gray;
            background: white;
            @include border-radius(30px);
        }
    }

    .filter-toggle-btn {
        border: none;
        background: none;
        margin: 7.5px 0;
        color: $text-gray;
        font-size: 14px;

        &:before {
            @include icon-font;
            content: $icon-radio-unchecked;
            font-size: 18px;
            margin: 0 5px 0 0;
            vertical-align: -17%;
        }

        &[data-active="true"] {
            &:before {
                color: $mint-green3;
                content: $icon-radio-checked;
            }
        }
    }

    .sort-patients-by {
        .dropdown-dropdown {
            left: 50%;
            @include transform(translate(-85%, 0));
        }

        .triangle, .triangle2 {
            left: 85%;
        }

        .dropdown-btn {
            padding: 7px 14px;
            margin: 0 5px;
            font-weight: 700;
            font-size: 10px;
        }
    }

    .filter-patients, .filter-team-members {
        .dropdown-btn {
            padding: 7px 7px;
            font-weight: 900;
            font-size: 11px;
            padding: 7px 25px;
        }
    }

    .filter-team-members {
        .dropdown-dropdown {
            left: 50%;
            @include max-screen(47em) {
                left: 172%;
                .triangle, .triangle2 {
                    left: 20%;
                }
            }

            @include transform(translate(-60%, 0));
        }
    }

    .filter-patients {
        .dropdown-dropdown {
            left: 50%;
            @include max-screen(47em) {
                left: 137%;
                .triangle, .triangle2 {
                    left: 25%;
                }
            }

            @include transform(translate(-60%, 0));
        }
    }

    &.patients-loading, .empty {
        text-align: center;
        min-height: calc(100vh + 84px);

        h1 {
            padding: 20px 0 10px 0;
            color: $text-gray;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }

        p {
            color: $text-gray;
            font-size: 14px;
            text-align: center;

            &.add-yourself {
                &:last-child {
                    margin-top: 0;
                }

                @include min-screen(47em) {
                    display: inline-block;
                }           
            }


        }

        .icon-spinner {
            font-size: 40px;
            color: $raspberry;
            display: inline-block;
            margin: auto;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        h3 {
            text-transform: uppercase;
            text-align: center;
        }

        .bulk-import-message {
            font-size: 18px;

            a {
                color: $raspberry;
            }
        }
    }

    .create-new-patient {
        background-color: #ffffff;
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 20px auto;
        padding-bottom: 18px;
        position: relative;
        color: $text-gray;

        display: inline-block;
        width: 441px;
        max-width: 441px;

        @include max-screen(690px) {
            display: block;
            width: calc(100% - 80px);
            margin: 40px auto;
        }

        .icon-search-patient {
            position: absolute;
            top: 0;
            left: 50%;
            @include transform(translateX(-50%));
            display: block;
            font-size: 26px;
            padding: 29px;
        }

        .icon-plus-thick {
            color: $raspberry;

            /*@include transform(translate(15px, 15px));*/

            position: absolute;
            top: 0;
            left: 50%;
            @include transform(translate(calc(-50% + 8px), 15px));
            display: block;
            font-size: 8px;
            padding: 29px;
        }

        h3 {
            padding: 74px 57px 18px 57px;
            text-align: center;
        }

        button {
            display: block;
            margin: 0 auto 0 auto;

            background: $raspberry;
            border: none;
            font-weight: bold;

            font-size: 11px;
            text-transform: uppercase;
            color: white;
            padding: 8px 14px;
            @include border-radius(30px);
        }

        p {
            font-size: 12px;
            margin-top: 20px;
        }

    }

    .show-patients {
        border: none;
        background-color: #ffffff;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
        margin-top: 20px;
        width: 620px;
        height: 40px;

        font-size: 10px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.8px;
        text-align: center;
        text-transform: uppercase;
        color: $raspberry;

        position: relative;

        &:after {
            @include icon-font;
            display: block;
            content: $icon-arrow-right;

            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            @include transition(.333s all ease-in-out);
            font-size: 11px;
            padding: 14px;
        }

        &[data-active="true"] {
            &:after {
                @include transform(translateY(-50%) rotate(90deg));
            }
        }
    }

    .show-more {
        border: none;
        background-color: $text-gray;
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        padding: 5px 25px;
    }

    .patient-results-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 25px 0;

        > footer {
            margin: 0 0 100px 0;
            .show-more {
                margin: 10px auto;
                display: block;
            }

            .no-results-found{
                background: #FFFFFF;
                text-align: center;
                height: 253px;
                > p {
                    line-height: 253px;
                }
            }
        }

        .patient-result-row {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 72px;
            left: 119px;
            top: 308px;
            color: #324253;
            background: #FFFFFF;

            &[data-even="true"] {
                background-color: #fafafa;
            }

            &.patient-header-row {
                height: 50px;
                background-color: #fafafa;
            }

            .header-cell {
                background-color: #fafafa;
                font-size: 16px;
                font-weight: bold;
                color: #425569;
                line-height: initial;
                margin-right: 1px;
            }


            .patient-result-cell {
                align-self: center;
                margin-right: 1px;

                .message-bubble {
                    width: 34px;
                    height: 34px;
                    border-radius: 34px;
                    background: #EAEDEF;

                    .icon-messages {
                        position: relative;
                        font-size: 14px;
                        top: 11px;
                        left: 10px;
                        color: black;
                    }

                    .icon-email4 {
                        position: relative;
                        font-size: 12px;
                        top: 11px;
                        left: 8.5px;
                        color: black;
                    }
                }

                &.avatar-cell {
                    flex: 1;
                    padding: 30px 0 0 0;
                    max-width: 77px;
                    .status-dot {
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                        position: relative;
                        left: 44px;
                        bottom: 15px;
                    }
                }

                &.message-cell {
                    flex: 1.5;
                }

                &.expiration-cell {
                    flex: 3;
                }

                &.last-seen-cell {
                    flex: 2;
                    &[data-deactivated="true"] {
                        color: #C7CED6;
                    }
                }

                &.alert-cell {
                    flex: 4;
                    &.header-cell  {
                        flex: 9;
                    }

                    div > p {
                        display: inline-block;
                    }
                    .last-activity, .expiration {
                        p:first-child {
                            margin-right: 3px;
                        }
                    }
                }

                &.button-cell {
                    flex: 5;
                }

                &.name-cell  {
                    flex: 4;
                    &.header-cell  {
                        flex: 5;
                    }
                }

                > * {
                     display: inline-block;
                     font-size: 12px;
                }

                &.header-cell {
                    background-color: #fafafa;
                    > * {
                        font-size: 16px;
                    }
                }

                .left-side {
                    padding: 7px 0 0 0;

                    .patient-name {
                        font-size: 14px;
                        line-height: 25px;
                    }

                    .condition {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        color: #97A4B1;
                    }
                }

                .next-step-btn, .renew-btn, .extend-btn {
                    @include outline-button($raspberry);
                    &[data-urgent="true"] {
                        @include flat-button(#FF7735);
                        vertical-align: middle;
                        font-size: 9px;
                        margin: 0;
                        padding: 10px 10px;
                    }
                    vertical-align: middle;
                    font-size: 9px;
                    margin: 0;
                    padding: 10px 10px;
                }


                .avatar {
                    width: 39px;
                    height: 39px;
                    background-color: #EAEDEF;
                    @include border-radius(80px);
                    text-align: center;
                    margin: 0 15px;
                }

                i.avatar {
                    font-size: 22px;
                    padding: 7px;
                    color: $text-gray;
                }
            }
        }

    }

    .patient-results {
        width: 100%;
        margin-top: 25px;
        @include max-screen(47em) {
            margin-top: 15px;
        }
        padding: 0 0 50px 0;
        @include transition(opacity 0.333s ease-in-out);

        > header {
            margin-top: 40px;
            padding: 0 20px 0 0px;

            @include max-screen(690px) {
                padding: 0;
            }
        }

        > footer {
            text-align: center;
            margin-top: 3em;
            margin-bottom: 3em;

            .no-results-found{
                background: #FFFFFF;
                text-align: center;
                height: 253px;
                > p {
                    line-height: 253px;
                }
            }
        }

        h3 {
            font-size: 16px;
            color: $text-gray;
            opacity: 0;
            whitespace: nowrap;
            @include max-screen(450px) {
                clear: both;
                padding-top: 20px;
                text-align: center;
                width: 100%;
            }

            em {
                color: $raspberry;
                font-weight: 800;
            }

            &[data-active="true"] {
                opacity: 1;
            }
        }

        .raw-results {
            margin-top: 20px;
            min-height: 10em;
            text-align: center;

            @include min-screen(47em) {
                margin-top: 70px;
            }
        }
    }

    .footer {
        margin-top: 5em;
    }

    .result-container {
        width: 334px;
        margin: 0 10px;
        @include min-screen(708px) {
           margin: 10px;
        }
        display: inline-block;

        @include min-screen(47em) {
            margin: 0 30px 50px 0;
        }
    }

    .patient-card {
        .avatar {
            @include min-screen(47em) {
                width: 77px;
                height: 77px;
                left: 50%;
                top: -38px;
                @include transform(translate(-50%, 0));
            }
        }

        i.avatar {
            @include min-screen(47em) {
                padding: 23px;
                font-size: 30px;
            }
        }
    }

}

.leaf {
    position: fixed;
    color: #F1F1F1;

    &.top-left  {
        font-size: 80px;
        width: 73.64px;
        height: 81.22px;
        left: -5px;
        top: 250px;
        transform: matrix(-0.75, -0.67, -0.67, 0.75, 0, 0);
    }

    &.bottom-left  {
        font-size: 350px;
        width: 311.97px;
        height: 344.09px;
        left: 35px;
        bottom: -40px;
        transform: matrix(-.92,.39,.39,.92,0,0);
    }

    &.top-right  {
        font-size: 269px;
        width: 234.61px;
        height: 258.76px;
        right: -37px;
        top: 300px;
        transform: matrix(0.2, 0.98, 0.98, -0.2, 0, 0);
    }

    &.bottom-right  {
        font-size: 170px;
        width: 140.91px;
        height: 155.41px;
        right: 200px;
        bottom: -20px;
        transform: matrix(-0.84, 0.55, 0.55, 0.84, 0, 0);
    }
}


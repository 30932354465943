
@import "../../../../../../sass/imports";

.nutrient-entry {
    > input[type="text"] {
        padding-right: 50px;

        @include max-screen(47em) {
            padding-right: 30px;
        }
    }

    .drop-down {
        position: absolute;
        z-index: 10;
        top: 100%;
        width: 100%;
        min-width: 220px;
        left: 50%;
        @include transform(translateX(-50%));

        opacity: 0;
        max-height: 0;
        overflow: hidden;
        @include transition(all .333s ease-in-out);
        background-color: white;
        border: 1px solid $inactive-text-gray;
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));

        li {
            font-size: 12px;
            color: $text-gray;
            text-align: right;
            padding: 10px;
            font-weight: bold;
            cursor: pointer;

            em {
                text-align: left;
                float: left;
                font-weight: normal;
            }

            &:hover {
                background-color: $dinner-color;
                color: white;
            }
        }
    }

}

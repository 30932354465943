@import "../../../../sass/imports";

.collection-card {

    &.recipe-card .recipe-card-text {
        top: 50%;
    }

    a h2 {
        text-align: left;
        font-size: 13px;
        font-weight: bold;
        color: $raspberry;
        margin: 0 0px 1px 0;
    }

    h3 {
        overflow: visible;
    }

    .fan1, .fan2 {
        @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.45));
        border: 5px solid white;
        position: absolute;
        top: 7px;
        left: 7px;
        width: 106px;
        height: 106px;
    }

    .fan1 {
        background-color: #9da1b1;
        @include transform(rotate(6deg));
        z-index: 0;
    }

    .fan2 {
        background-color: #9da1b1;
        @include transform(rotate(3deg));
        z-index: 1;
    }

    .collection-card-image {
        top: 7px;
        left: 7px;
        width: 106px;
        height: 106px;
    }
}

@import "../../../sass/imports";

.recipe-to-pdf-dropdown {

    button {
        i {
            vertical-align: middle;
        }
    }

    .popup-content {
        min-width: 185px;
    }
}

.recipe-to-pdf-form {
    color: $text-navy;

    > p {
        margin-bottom: 1em;
    }

    h2 {
        font-size: 14px;
        text-align: left;
        margin: 1em 0 0 5px;
        color: #203152;
    }

    .toggle-btn {
        text-align: left;
        width: 100%;
        border: none;
        background: none;
        color: $text-gray;
        font-size: 12px;
        padding: 3px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;

        &:before {
            @include icon-font;
            font-size: 14px;
            vertical-align: middle;
            margin: 0px 5px 0 0px;
            display: inline-block;
            width: 17px;
            height: 17px;
            color: white;
            content: ' '; // $icon-checkbox-unchecked;
            border: 1px solid $bluey-gray;
            padding: 1px;
        }

        &:disabled {
            opacity: 0.5;
        }

        em {
            margin-right: 5px;
        }

        span {
            font-weight: 300;
        }

        &[data-selected="true"] {
            &:before {
                content: $icon-check;
                background-color: $mint-green3;
            }
        }
    }

    .radio-btn {
        &:before {
            @include border-radius(40px);
            padding: 2px;
            width: 20px;
            height: 20px;

        }
    }
}

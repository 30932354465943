
@import "../../../../sass/imports";

.patient-details-loading {
    text-align: center;
    min-height: 100vh;

    h1 {
        margin-top: 60px;
        font-size: 20px;
        color: $text-gray;
    }

    p {
        padding-top: 60px;
        color: $text-gray;
        font-size: 14px;
    }

    .icon-spinner {
        font-size: 40px;
        color: $raspberry;
        display: inline-block;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.patient-details-agreement {

    .agreement-footer {
        position: fixed;
    }
    .editor-scrollable {
        position: relative;
        top: 0;
    }
}

.patient-details {

    .patient-page-title {
        text-transform: capitalize;
    }

    @include max-screen(47em) {
        .child-content {
            padding: 0;
        }
    }

    @include min-screen(47em) {
        position: relative;

        .patient-details-wrapper {
            display: flex;
        
            .patient-page-nav, .child-content {
            }

            .patient-page-nav {
                width: 315px;
                position: absolute;
                top: 0; left: 0;
            }

            .child-content {
                flex-grow: 1;
                padding: 0 0 0 315px;
            }

        }
    }
}

.patient-page-nav {
    @include max-screen(47em) {
        position: fixed;
        width: 100vw;
        top: 0;
        z-index: 10;

        .back-to-search-btn {
            position: absolute;
            top: 0;
            left: 0;
            padding: 17.5px 20px;
            color: $bluey-gray;
            font-size: 22px;
            border: none;
            background: none;
        }

        > header {
            position: relative;
            padding: 10px 0px;

            .headshot {
                width: 45px;
                height: 45px;
                @include border-radius(45px);
                margin: 0 10px 0 0;
                display: inline-block;
            }

            i.headshot {
                font-size: 24px;
                border: 1px solid #fafafa;
                padding: 9px 11px;
                color: $raspberry;
                background-color: white;
            }

            > p {
                text-align: center;
                color: white;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 1.13;
                letter-spacing: 1.3px;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 80px);
                margin: auto;
                white-space: nowrap;

                &:after {
                    @include icon-font;
                    content: $icon-chevron-down;
                    font-size: 11px;
                    vertical-align: middle;
                    padding: 8px;
                }
            }
        }

        > footer {
            display: none;
        }

        .patient-info-card {
            display: none;
        }

        .nav-links {
            text-align: center;
            position: relative;
            margin: 0 7px;
            @include transform(translateY(-8px));

            li {
                display: inline-block;

                a {
                    padding: 4px 12px;
                    background-color: rgba(16, 17, 18, 0.3);
                    display: inline-block;
                    margin: 3px 3px;
                    @include border-radius(30px);
                    font-size: 10.8px;
                    color: white;

                    &.active {
                        background-image: linear-gradient(288deg, #bc9b60, #d2ba86);
                    }
                }
            }
        }

        .select-container {
            width: calc(100% - 40px);
            border: solid 0.5px rgba(0, 0, 0, 0.05);
            background-color: #ffffff;
            @include border-radius(4px);
            @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.1));

            p.value {
                font-size: 15px;
                color: $text-navy;
                padding: 10px 22px;
            }
        }

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            top: 18px;
            left: 50%;
            @include transform(translate(-50%,calc(-100% + 135px)));
            @include border-radius(50%);
            z-index: 0;

            @include background-image(linear-gradient(180deg, #5b7287 26%,#324253 100%));
            @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));

            width: 170vw;
            height: 170px;
        }
    }

    @include min-screen(47em) {
        @include border-radius(4px);
        @include background-image(linear-gradient(156deg, #5b7287, #495e73));

        &[data-full-page="true"] {
            max-height: calc(100vh - 60px);
            overflow-y: auto;
        }

        > header {
            display: none;
        }

        > footer {
            padding: 35px 32px;


        }

        .deactivate-patient-btn {
        }

        .nav-links > ul {
            border-top: 1px solid rgba(255, 255, 255, 0.25);
            li {
                position: relative;

                a {
                    color: white;
                    display: block;
                    padding: 14px 32px;
                    font-size: 15px;
                    background-color: transparent;
                    @include transition(all .25s ease-in-out);

                    &.active {
                        background-color: $raspberry;
                    }
                }

                border-bottom: 1px solid rgba(255, 255, 255, 0.25);

                .nav-badge {
                    display: inline-block;
                    background-color: $orange4;
                    width: 22px;
                    height: 22px;
                    @include border-radius(20px);
                    text-align: center;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 3px 7px;
                    margin-left: 15px;
                    position: absolute;
                }
            }
        }

        .back-to-search-btn {
            display: none;
        }
    }

}

.patient-card-modal {
    position: absolute;
    top: 40px;
    left: 50%;
    @include transform(translate(-50%, 0));
}


.nutrition-export-modal-container {
    @include border-radius(3px);
    overflow: hidden;
    min-width: 320px;
    background: white;
    padding: 10px;

    .close-modal-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        color: $josh-blue-gray;
        background: none;
        border: none;
        z-index: 5;
    }
}

.patient-card-overlay {
    background: rgba(72, 80, 90, 0.9);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    opacity: 0;
    @include transition(all 0.1s ease-in-out);

    &.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

.patient-card-modal-container {
    @include background-image(linear-gradient(to bottom, #5b7287, #3d4854));
    @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.5));
    @include border-radius(3px);
    overflow: hidden;
    min-width: 320px;

    .close-modal-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        color: $josh-blue-gray;
        background: none;
        border: none;
        z-index: 5;
    }

    .activation {
        padding: 30px 30px 60px;
        text-align: left;
        border-top: 0.5px solid #B7B7B7;

        .patient-deactivator {
            text-align: center;
        }
    }
}


@import "../../../../../../sass/imports";

.nutrition-analysis {
    white-space: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    * {
        white-space: initial;
    }

    .macros-pie-chart {
        display: inline-block;
        vertical-align: top;
    }

    .calories-left, .calories-over, .macro-left, .macro-over {
        display: inline-block;
        font-size: 11px;
        color: $text-navy;
        vertical-align: top;
        text-align: center;

        em {
            font-size: 15px;
            font-weight: bold;
            display: block;
        }
    }

    .calories-left, .calories-over {
        em {
            font-size: 22px;
        }
    }

    .macro-left, .macro-over {
        padding: 0 15px 0 0;
        border-right: 1px solid $bluey-gray;
        margin: 0 15px 0 0;

        &:last-child {
            border-right: none;
        }
    }

    .calories-over, .macro-over {
        em {
            color: $orange4;
        }
    }

    .small-progress-bar {
        display: block;
        width: 53px;
        height: 4px;
        @include border-radius(8px);

        background-color: #818995;
        margin: 2px auto 5px auto;
    }

    .small-progress-bar-fill {
        height: 4px;
        @include border-radius(8px);
        background-image: linear-gradient(to right, #bcbcbc, #fbfafa);
    }
}

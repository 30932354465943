
@import "../../../../sass/imports";
@import "../../Widgets/VariancesPopup.scss";

.nutrition-analysis-container {
    text-align: right;
}

.nutrition-comparison-body {
    padding: 0 0 80px;
    max-height: calc(100vh);
    overflow-y: auto;

    @include min-screen(376px) {
        max-height: 95vh;
    }

    > footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        background-color: white;
    }

    .generic-tabs {
        .tabs {
            text-align: center;
        }

        .tab-btn {
            text-transform: none;
            font-style: normal;
            font-size: 14px;
            padding: 15px 20px;
            color: $dark-slate-gray;
            font-weight: 400;
            opacity: 1;
        }

        .active-tab-btn {
            font-weight: 700;
            border-bottom: 2px solid $raspberry;
        }

        .tab-contents {
            margin: 0;
            padding: 15px 25px;
        }
    }

    .mismatches {
        color: $dark-slate-gray;;

        h3 {
            text-align: center;
            font-size: 14px;
            line-height: 26px;
        }

        ul {
            margin: 10px 0 0 0;

            > li {
                display: flex;
                font-weight: normal;
                font-size: 14px;
                line-height: 26px;
                margin: 3px 0;

                span {
                    flex: 5 1;
                }

                em {
                    text-align: right;
                    flex: 2;
                }

                .over, .under {
                    text-align: right;
                    flex: 1 1;
                    margin: 0 0 0 20px;
                }

                .over {
                    color: $raspberry;
                }

                .under {
                    color: #6E89D0;
                }
            }
        }
    }

    .meal-variance {
        margin: 10px 0;

        header {
            font-size: 16px;
            font-weight: bold;
            line-height: 26px;
        }
    }

    .macros-pie-chart {
        white-space: nowrap;

        .chart {
            width: 170px;
            height: auto;
            vertical-align: middle;
            margin: 0 0px 0 0;
        }

        .macros-legend {
            vertical-align: middle;
            margin: 0 0 0 7px;

            li > span {
                text-align: right;
                font-weight: normal;
                font-size: 12px;
                line-height: 21px;
                width: 31px;
            }

            .color-key {
                text-align: left;
                font-size: 13px;
                width: auto;
                margin: 0 5px 0 0;
                padding: 0;
                display: inline-block;
                vertical-align: middle;
            }

            .name {
                text-align: left;
                width: 40px;
            }
        }
    }

    .nutrition-info {
        > header {
            margin: 20px 0;
        }

        .per, .bar {
            display: none;
        }

        .display-list {
            margin: 30px 3px;
            gap: 0;
        }

        .display-row {
            margin: 1px 0;
            max-width: none;
        }

        .amt {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            align-content: space-between;
            justify-content: space-between;

            > span {
                text-align: left;
                font-weight: normal;
            }

            > em {
                text-align: right;
                text-transform: none;
                font-weight: normal;
                font-size: 14px;
                line-height: 26px;
            }
        }
    }
}

@import "../../../../../../sass/imports";

.restaurant-menu-filters-modal {

    .toggle-group {
        display: flex;
        flex-wrap: wrap;

        > li {
            width: calc(50% - 10px);
            margin: 0 10px 0 0;
        }
    }

    .nutrient-range-filters-container .nutrient-range-filters:after {
        background-image: linear-gradient(to bottom, rgba($el-gray5,0) 0%, rgba($el-gray5,1) 100%);
    }
}

@import "../../../sass/imports";

.try-it-out-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .icon-spinner2 {
        display: block;
        color: $raspberry;
        font-size: 50px;;
    }
}

.try-it-form {

    .with-label {
        width: calc(100% - 10px);
        clear: both;
        margin-bottom: 15px;

        > label {
            font-size: 10px;
            color: $text-navy;
        }
    }


    .select-container, input[type="text"], input[type="number"], input[type="password"], input[type="email"] {
        background-color: white;
        border: solid 0.5px rgba(0, 0, 0, 0.15);

        @include box-shadow(0, 5px, 10px, 0, rgba(0, 0, 0, 0.05));
    }

    .select-container {
        .value {
            color: $text-navy;
            font-weight: normal;
        }

        .icon-lock {
            margin: 0 0 0 3px;
        }
    }

    .add-avoidances-btn, .calc-energy-btn {
        border: none;
        background: none;
        color: $raspberry;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        border-width: 0px;
        text-decoration: none;
        text-align: center;
        margin-top: 5px;
        margin-left: 5px;
    }

    .selected-avoidances {
        padding: 5px 0 0 0;

        .add-avoidances-btn {
            float: right;
        }
    }

    .avoid-btn {
        position: relative;
        margin: 0 5px 5px 0;

        padding: 3px 25px 3px 5px;
        font-size: 12px;
        color: $text-navy;
        background: none;
        border: 1px solid rgba(32, 49, 82, 0.3);


        &:after {
            @include icon-font;
            content: $icon-close-x;
            font-size: 10px;
            position: absolute;
            top: 50%;
            right: 0;
            padding: 5px;
            @include transform(translateY(-50%));
        }
    }

    .calories, .diets {
        > footer {
            text-align: right;
        }
    }

    .adults, .children, .skill-level, .plan-size {
        width: calc(50% - 10px) !important;
        min-width: 100px;
    }

    .adults {
        margin-right: 5px;
    }

    .error-msg {
        background: none;
    }
}

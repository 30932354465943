
@import "../../../../sass/imports";

.date-selector-container {
    width: calc(100% - 10px);
    text-align: center;
    margin: 5px;

    .with-label {
        margin: 5px;
    }

    label {
        text-align: left;
    }

    .select-container {
        margin-top: 0 !important;
        width: 100%;
    }

    .day, {
        width: 80px !important;
        max-width: calc(27% - 10px);
    }

    .year {
        width: 100px !important;
        max-width: calc(27% - 5px);
    }

    .month {
        width: 220px !important;
        max-width: calc(46% - 10px);
    }
}

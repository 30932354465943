
@import "../../../sass/imports";

// Shared Styles
.account-settings-heading {
    font-size: 1.25em;
    color: $grayscale7;
    text-transform: uppercase;
}

.account-expansion {
    height: 0;
    min-height: 0;
    overflow: hidden;
    @include transition(.33s all ease-in);
    > .inner-container {
        width: 90%;
        padding: 1em 0;
    }
    &[data-status="true"] {
        height: auto;
        min-height: 25em;
        border-bottom: 1px solid #ddd;
        background-color: $light-gray;
    }
    @include min-screen(47em) {
        height: 0 !important;
        min-height: 0 !important;
        max-height: 0 !important;
        border: 0 !important;
    }
}


// Layout Rules
.pro-my-account {
    min-height: 30em;
    max-width: 1000px;
    margin: 0 auto;

    .account-tabs {
        display: block;
        width: 100%;
    }

    .account-main {
        display: none;
    }

    @include min-screen(47em) {
        display: table;
        width: 100%;
        box-sizing: border-box;
        table-layout: fixed;

        .account-tabs {
            display: table-cell;
            width: 25%;
            border: 1px solid transparent;
            border-top: 0;
            border-bottom: 0;
            .account-tab {
                padding: 2em 0;
                > .inner-container:after { content: none; }
            }
        }

        .active-tab {
            border-bottom: 2px solid $raspberry;
        }

        .account-main {
            display: table-cell;
            width: 75%;
            background-color: $light-gray;
            > .inner-container {
                width: 90%;
            }
        }
    }
}

// Account Tabs
.account-tabs {
    a {
        display: block;
        text-decoration: none;
    }
    .account-tab {
        display: block;
        border: 0;
        padding: 1em 0;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #ddd;
        background-color: transparent;
        >.inner-container {
            position: relative;
            width: 90%;
            &:after {
                @include icon-font;
                content: $icon-chevron-down;
                line-height: 2;
                color: $grayscale5;
                font-size: 1em;
                position: absolute;
                top: 0;
                right: .5em;
                @include transition(.33s all ease-in-out);
            }
            div {
                display: inline-block;
                vertical-align: top;
            }
        }
        i {
            display: inline-block;
            vertical-align: top;
            font-size: 2em;
            color: $grayscale8;
            margin-right: 5px;
            @include transition(.15s all ease-in-out);
        }

        .icon-dashboard1 {
            font-size: 1.2em;
        }

        p {
            color: $grayscale8;
            font-size: 1.125em;
            line-height: 1;
            display: block;
            @include transition(.15s all ease-in-out);
        }
        span {
            color: $grayscale5;
            font-size: 1em;
            line-height: 1.3;
            display: block;
            @include transition(.33s all ease-in-out);
        }
    }
    .active-tab {
        font-size: 1em;
        p, i { color: $raspberry; }
        span { color: $grayscale8; }
        >.inner-container:after { content: $icon-chevron-up; }
    }
    @include min-screen(47em) {
        border-right: 3px solid $raspberry;
    }
}

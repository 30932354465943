
@import "../../../../sass/imports";

.patient-info-card {
    color: white;
    
    .personal-info {
        padding: 28px 32px;
    }

    .name {
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 0.8;
        letter-spacing: 1.3px;
        margin: 0 0 8px;
    }

    .patient-number {
        font-size: 10px;
        line-height: 1.4;
        letter-spacing: 0.8px;
        color: #d0d0cf;
        margin: 0 0 10px;
    }

    .height-weight {
        span {
            display: inline-block;
            margin: 0 .75em 0 0;
        }
    }

    .contact {
        padding: .1px;
        margin: 0 0 8px 0;

        .headshot {
            display: inline-block;
            vertical-align: top;
            width: 70px;
            height: 70px;
            margin: 0 16px 0 0;
            @include border-radius(70px);
        }

        i.headshot {
            font-size: 24px;
            border: 1px solid #fafafa;
            padding: 21px 24px;
            color: $raspberry;
            background-color: white;
        }

        p {
            font-size: 11px;
            line-height: 1.09;
            letter-spacing: 1px;
            margin: 8px 0 8px 0;
            white-space: nowrap;
        }

        a, button {
            color: #fac3e4;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 1;
            letter-spacing: 1px;

            border: none;
            background: none;
        }
    }

    .row2 {
        display: inline-block;
        width: calc(100% - 86px);
        vertical-align: top;
    }

    .bio {
        clear: left;
        margin: 13px 0 0 0;

        p {
            text-transform: uppercase;
            font-size: 10px;
            line-height: 1.8;
        }

        em {
            font-weight: bold;
        }
    }

    > footer {
        padding: 10px 19px;

        > div {
            display: inline-block;
        }

        button {
            margin: 0px 5px;
            border: 1px solid $bluey-gray;
            background: none;
            font-size: 11px;
            font-weight: 900;
            line-height: 1.27;
            letter-spacing: 1px;
            color: #d0d0cf;
            padding: 8px 16px;
            text-transform: uppercase;
            max-width: 191px;
            margin-bottom: 10px;
            @include border-radius(25px);
        }

        .dot {
            display: inline-block;
            margin: 0 5px 2px 0px;
            vertical-align: middle;
            line-height: 2px;
            height: 5px;
            width: 5px;
            border-radius: 50%;
        }

        .expiration-text {
            display: block;
            margin: 0 5px 20px;
            font-size: 13px;
            max-width: 200px;

            &[data-expiration="extend-btn"] {
                color: #AD8124;

                .dot {
                    background-color: #AD8124;
                }
            }

            &[data-expiration="renew-btn"] {
                color: #C13E0E;

                .dot {
                    background-color: #C13E0E;
                }
            }

            &[data-expiration="active-until"] {
                color: #12D574;

                .dot {
                    background-color: #12D574;
                }
            }

        }

        .extend-btn, .renew-btn, .active-until {
            font-size: 11px;
            max-width: 200px;
            margin: 0px 5px;
        }

        .extend-btn{
            background-color: #AD8124;
            color: white;
        }

        .renew-btn {
            background-color: #C13E0E;
            color: white;
        }

        .active-until {
            background-color: none;
            border: none;
            color: #12D574;
        }
    }
}


.feed-present-menu {
    .drawer-menu-modal-container {
        .inner-container {
            background-image: linear-gradient(352deg, #bc9b60, #d2ba86);
        }

        .menu-item {
            color: white;

            i {
                color: #d2ba86;
            }
        }
    }
}



// Global Declarations
body {
    @include body-font;
}
.touch-events {
    @include user-select('none');
}

.inner-container {
    @include inner-container;
}

.accessible-text,
.assistive-text {
    @include assistive-text;
}

@import "../../../sass/imports";

.postal-code-field {
    margin: auto;
    max-width: 280px;
    position: relative;
    
    input {
        border-radius: 40px;
        width: 100%;
        border: none;

        @include max-screen(47em) {
            border: 1px solid $light-gray-border;
        }

        @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.2), true);
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        padding: 10px 0 10px 30px;
        color: $text-gray;

        @include placeholder {
            color: darken($text-gray, 10%);
            text-transform: uppercase;
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
        }

        &:focus {
            @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
        }

        &::selection {
            background: rgba($mint-green3, 0.2);
            color: rgba($raspberry, 0.5);
        }

        &:invalid {
            border: red solid 0.5px;
        }
    }


    &:before {
        @include icon-font;
        color: $raspberry;
        content: $icon-location-pin;
        position: absolute;
        padding: 13px 0 0 15px;
    }
}


@import "../../../../../sass/imports";

.prescription-mismatch-container {
    float: right;

    .dropdown-btn {
        border: none;
        background: none;

        &:after {
            display: none !important;
        }
    }
}

.prescription-mismatch-list {

    .prescription-mismatch-part {
        margin-top: 1em;

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        color: $text-gray;
        font-size: 16px;
        font-weight: bold;
    }

    ul {
        li {
            display: inline-block;
            width: 260px;
            color: $text-gray;

            font-size: 14px;
            padding-left: 2em;
            margin-top: 20px;

            em {
                font-weight: bold;
            }
        }
    }
}

.prescription-mismatch-btn {
    border: 1px solid $warning-yellow;
    height: 80px;
    padding: 25px 0 0 41px;
    width: 274px;
    text-align: left;

    p {
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        color: $text-gray;
        padding-top: 0;
        line-height: 1;
    }

    i {
        display: inline-block;
        font-size: 24px;
        padding-top: 3px;
        padding-right: 5px;
        color: $orange4;
        vertical-align: top;
        float: left;
    }
}

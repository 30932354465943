
@import "../../../../../sass/imports";

.customizer-nutrition-modal {
}

.customizer-nutrition-container {

    .modal-scroll-container {
        padding: 0;
    }

    tr {
        border-bottom: 1px solid $grayscale3;
    }

    th {
        font-size: 14px;
        font-weight: bold;
        color: $el-grayish-blue;
        text-align: center;
    }

    td {
        font-size: 14px;
        color: $el-grayish-blue;
        text-align: right;
        white-space: nowrap;

        a {
            color: $grayscale8;
        }
    }

    th, td {
        padding: 5px 10px;

        &:nth-child(even) {
            background-color: #f6f6f6;
        }
    }

    em {
        text-align: left;
        display: inline-block;
        min-width: 2em;
        font-size: 10px;
    }

    .nutrient-name {
        text-align: left;
    }
}

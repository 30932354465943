@import "../../../sass/imports";

.el-popup {
    position: relative;
    display: inline-block;
    vertical-align: top;

    .popup-btn {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:after {
            @include icon-font;
            content: $icon-chevron-down;
            display: inline-block;
            padding-top: 2px;
            padding-left: 5px;
            float: right;
        }
    }

    &[data-hide-chevron="true"] {
        .popup-btn:after {
            display: none;
        }
    }

    .popup-dropdown {
        position: absolute;
        z-index: 10;
        max-height: 0;
        overflow: hidden;

        opacity: 0;
        @include transition(all .25s ease-in-out);
    }

    .triangle {
        position: absolute;
        z-index: 20;

        width: 0;
        height: 0;

    }

    .triangle2 {
        position: absolute;
        z-index: 20;

        width: 0;
        height: 0;
    }

    .popup-content {
        position: relative;
        margin-top: 10px;
        padding: 17px 20px 10px 10px;
        background-color: $el-white;
        @include box-shadow(0, 4px, 12px, 0, rgba(0, 0, 0, 0.15));
        @include border-radius(10px);
        will-change: filter;

        border: solid 0.5px #e0e0e0;
        max-width: 100vw;

        > footer {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 20px 20px 0 20px;
            text-align: center;
        }
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: none;
        padding: 10px;
        font-size: 10px;
        color: $el-grayish-blue;
    }

    &[data-state="true"] {
        .popup-dropdown {
            opacity: 1;
            max-height: 50em;
            overflow: visible;
            display: block;
        }
    }

    &[data-closing="true"] {
        .popup-container {
            opacity: 0;
        }
    }

    &.el-popup-dark {
        .popup-content {
            background-color: $el-grayish-blue;
            border: solid 0.5px $el-grayish-blue;
            color: white;
        }

        .close-btn {
            color: white;
        }
    }
}

.el-popup-top-left, .el-popup-top-left-center, .el-popup-top-center, .el-popup-top-right-center, .el-popup-top-right {
    .popup-dropdown {
        top: 100%;
    }
    .triangle {
        top: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #e0e0e0;
    }
    .triangle2 {
        top: 1px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #FFF;
    }

    &.el-popup-dark {
        .triangle, .triangle2 {
            border-bottom: 10px solid $el-grayish-blue;
        }
    }
}

.el-popup-bottom-left, .el-popup-bottom-left-center, .el-popup-bottom-center, .el-popup-bottom-right-center, .el-popup-bottom-right {
    .popup-dropdown {
        bottom: 100%;
    }
    .popup-content {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .triangle {
        bottom: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #e0e0e0;
    }

    .triangle2 {
        bottom: 1px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #FFF;
    }

    &.el-popup-dark {
        .triangle, .triangle2 {
            border-top: 10px solid $el-grayish-blue;
        }
    }
}


.el-popup-top-left, .el-popup-bottom-left {
    .popup-dropdown {
        left: 50%;
        @include transform(translate(-15%, 0));
    }
    .triangle, .triangle2 {
        left: 15%;
        @include transform(translate(-50%, 0));
    }
}
.el-popup-top-left-center, .el-popup-bottom-left-center {
    .popup-dropdown {
        left: 32.5%;
        @include transform(translate(-32.5%, 0));
    }
    .triangle, .triangle2 {
        left: 32.5%;
        @include transform(translate(-32.5%, 0));
    }

}
.el-popup-top-center, .el-popup-bottom-center {
    .popup-dropdown {
        left: 50%;
        @include transform(translate(-50%, 0));
    }
    .triangle, .triangle2 {
        left: 50%;
        @include transform(translate(-50%, 0));
    }
}
.el-popup-top-right-center, .el-popup-bottom-right-center {
    .popup-dropdown {
        left: 67.5%;
        @include transform(translate(-67.5%, 0));
    }
    .triangle, .triangle2 {
        left: 67.5%;
        @include transform(translate(-67.5%, 0));
    }

}
.el-popup-top-right, .el-popup-bottom-right {
    .popup-dropdown {
        right: 50%;
        @include transform(translate(15%, 0));
    }
    .triangle, .triangle2 {
        right: 15%;
        @include transform(translate(50%, 0));
    }
}








.el-popup-left-top, .el-popup-left-top-center, .el-popup-left-center, .el-popup-left-bottom-center, .el-popup-left-bottom {
    .popup-dropdown {
        left: 100%;
    }
    .popup-content {
        margin-top: 0;
        margin-left: 10px;
    }
    .triangle {
        left: 0;
        border-bottom: 10px solid transparent;
        border-left: none;
        border-right: 10px solid #e0e0e0;
        border-top: 10px solid transparent;
    }
    .triangle2 {
        left: 1px;
        border-bottom: 10px solid transparent;
        border-left: none;
        border-right: 10px solid #fff;
        border-top: 10px solid transparent;
    }

    &.el-popup-dark {
        .triangle, .triangle2 {
            border-right: 10px solid $el-grayish-blue;
        }
    }
}

.el-popup-right-top, .el-popup-right-top-center, .el-popup-right-center, .el-popup-right-bottom-center, .el-popup-right-bottom {
    .popup-dropdown {
        right: 100%;
    }
    .popup-content {
        margin-top: 0;
        margin-right: 10px;
    }
    .triangle {
        right: 3px;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #e0e0e0;
        border-right: none;
        border-top: 10px solid transparent;
    }
    .triangle2 {
        right: 3px;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #fff;
        border-right: none;
        border-top: 10px solid transparent;
    }

    &.el-popup-dark {
        .triangle, .triangle2 {
            border-left: 10px solid $el-grayish-blue;
        }
    }
}


.el-popup-left-top, .el-popup-right-top {
    .popup-dropdown {
        top: 50%;
        @include transform(translate(0, -15%));
    }
    .triangle, .triangle2 {
        top: 15%;
        @include transform(translate(0, -50%));
    }
}
.el-popup-left-top-center, .el-popup-right-top-center {
    .popup-dropdown {
        top: 50%;
        @include transform(translate(0, -32.5%));
    }
    .triangle, .triangle2 {
        top: 32.5%;
        @include transform(translate(0, -50%));
    }
}
.el-popup-left-center, .el-popup-right-center {
    .popup-dropdown {
        top: 50%;
        @include transform(translate(0, -50%));
    }
    .triangle, .triangle2 {
        top: 50%;
        @include transform(translate(0, -50%));
    }
}
.el-popup-left-bottom-center, .el-popup-right-bottom-center {
    .popup-dropdown {
        top: 50%;
        @include transform(translate(0, -67.5%));
    }
    .triangle, .triangle2 {
        top: 67.5%;
        @include transform(translate(0, -50%));
    }
}
.el-popup-left-bottom, .el-popup-right-bottom {
    .popup-dropdown {
        top: 50%;
        @include transform(translate(0, -85%));
    }
    .triangle, .triangle2 {
        top: 85%;
        @include transform(translate(0, -50%));
    }
}







@import "../../../../sass/imports";

.board-picker-modal {
}

.board-picker-modal-container {
    max-height: 0;
    overflow: hidden;
    background-color: white;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    @include transition(.25s all ease-in-out);
    max-height: 0;

    @include min-screen(47em) {
        bottom: auto;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        width: calc(100vw - 40px);
        max-width: 345px;
    }

    &[data-drawer="true"] {
        max-height: 50em;
    }

    .board-picker {
        > header h4 {
            padding: 2em .5em;
        }
    }
}

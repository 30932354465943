
@import "../../../sass/imports";
@import "../Widgets/Tabs.scss";

.ingredients-container {
    position: relative;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    .recipe-title {
        text-transform: uppercase;
    }

    .scaling-button-toggle {
        margin: 0 auto 1em 0;
    }

    .ingredients-list {
        margin: 10px 0 0 0;

        li {
            margin: 5px 0;
        }

        .notes {
             list-style-type: disc;
             color: #7c8a99;
             padding-left: 30px;

             ul {
                margin: 0;
             }

             li {
                margin: 0;
                font-size: 14px;
             }
        }
    }

    .subrecipe {
        margin: 30px 0 0 0;
    }

    .amt {
        display: inline-block;
        font-weight: bold;
        margin: 0 .35em 0 0;
    }

    .uom, .name, .prep {
        margin: 0 .20em 0 0;
    }

    .brand {
        display: inline-block;
        font-weight: 300;
    }
}


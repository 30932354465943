
// Typography: Font Families
$lato-font-path : 'https://static.chewba.info/assets/fonts/lato';

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-light/lato-light.eot');
  src: local('Lato Light'),
       local('Lato-Light'),
       url('#{$lato-font-path}/lato-light/lato-light.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-light/lato-light.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-light/lato-light.woff') format('woff'),
       url('#{$lato-font-path}/lato-light/lato-light.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-light/lato-light.svg#lato') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-regular/lato-regular.eot');
  src: local('Lato Regular'),
       local('Lato-Regular'),
       url('#{$lato-font-path}/lato-regular/lato-regular.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-regular/lato-regular.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-regular/lato-regular.woff') format('woff'),
       url('#{$lato-font-path}/lato-regular/lato-regular.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-regular/lato-regular.svg#lato') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-medium/lato-medium.eot');
  src: local('Lato Medium'),
       local('Lato-Medium'),
       url('#{$lato-font-path}/lato-medium/lato-medium.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-medium/lato-medium.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-medium/lato-medium.woff') format('woff'),
       url('#{$lato-font-path}/lato-medium/lato-medium.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-medium/lato-medium.svg#lato') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-semibold/lato-semibold.eot');
  src: local('Lato Semi Bold'),
       local('Lato-SemiBold'),
       url('#{$lato-font-path}/lato-semibold/lato-semibold.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-semibold/lato-semibold.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-semibold/lato-semibold.woff') format('woff'),
       url('#{$lato-font-path}/lato-semibold/lato-semibold.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-semibold/lato-semibold.svg#lato') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-bold/lato-bold.eot');
  src: local('Lato Bold'),
       local('Lato-Bold'),
       url('#{$lato-font-path}/lato-bold/lato-bold.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-bold/lato-bold.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-bold/lato-bold.woff') format('woff'),
       url('#{$lato-font-path}/lato-bold/lato-bold.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-bold/lato-bold.svg#lato') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-black/lato-black.eot');
  src: local('Lato Black'),
       local('Lato-Black'),
       url('#{$lato-font-path}/lato-black/lato-black.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-black/lato-black.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-black/lato-black.woff') format('woff'),
       url('#{$lato-font-path}/lato-black/lato-black.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-black/lato-black.svg#lato') format('svg');
  font-weight: 800;
  font-style: normal;
}

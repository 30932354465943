
@import "../../../sass/imports";

.stripe-invoice {
    max-width: 500px;
    margin: auto;

    > header {
        text-align: center;
        border-bottom: 1px solid $another-gray;
        padding-bottom: 20px;

        .logo {
            display: block;
            width: 72px;
            margin: auto;
            font-size: 50px;
            padding: 10px;
        }

        .error-msg {
            border: 1px solid #c13e0e;
            font-size: 18px;
            border-radius: 10px;
            padding: 10px 10px 10px 20px;
            color: #c8552b;
            max-width: 490px;
            text-align: left;
            margin: 10px auto 20px;
        }

        .green-circle{
            width: 44px;
            height: 44px;
            border-radius: 44px;
            background: #2DCA93;
            display: block;
            margin: auto;
            font-weight: bold;
            padding: 7px 0px 0px 1px;

            i {
                font-size: 30px;
                color: white;
            }
        }


        h1 {
            font-size: 24px;
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: normal;
            padding: 0 20px 20px 20px;

        }

        .confirmation-subtitle {
            display: block;
            p,b {
                display: inline-block;
            }
        }

    }

    .refund-confirmation {
        padding: 5px;
        color: $text-gray;
    }

    .charge-card-info {
        margin-bottom: 10px;
        img {
            vertical-align: middle;
            border: 0;
        }

        span {
            display: inline-block;
            margin-left: 8px;
            font-size: 14px;
            font-family: 'Lucida Console', monospace;
            vertical-align: middle;
        }
    }

    .invoice-items {
        text-align: left;

        .invoice-ident {
            background-color: $grayscale3;
        }

        .text-right {
            text-align: right;
        }

        th {
            white-space: nowrap;
        }

        td, th {
            padding: 5px;
            font-size: 14px;
        }

        thead th, tfoot td {
            font-size: 14px;
            font-weight: bold;
        }

        tbody {
            td {
                color: black;
                button {
                    background: none;
                    text-decoration: underline;
                    cursor: pointer;
                    border: none;
                    margin-left: 10px;
                    color: $raspberry;
                }
            }
            border-bottom: 1px solid #e3e4e9;

            tr {
                &:last-child {
                    height: 25px;
                }
            }
        }

        tfoot {
            td {
                text-align: right;

                &:first-child {
                    text-align: left;
                }

                em {
                    color: black;
                }
            }
        }

        .big-number {
            font-size: 16px;
        }
    }


    .add-a-coupon {
        padding: 10px 5px;

        > input[type=text] {
            width: 100%;
            color: $raspberry;
            text-align: left;
            padding: 3px;
            font-size: 14px;
            background-color: #fff;
            border: none;
            border-radius: 8px;
            padding: 10px;
            font-size: 14px;
            @include box-shadow(0px, 1px, 6px, 0, rgba(0, 0, 0, 0.168139));
        }
    }

    .card-outline {
        width: 42px;
        height: 33px;
        position: relative;
        background: #EAEDEF;
        border-radius: 4px;
        display: inline-block;
        vertical-align: sub;

        .card-chip {
            position: absolute;
            width: 8px;
            height: 6px;
            background: #203152;
            opacity: 0.34;
            border-radius: 2px;
            right: 5px;
            top: 8px;
        }

        .card-strip {
            position: absolute;
            bottom: 6px;
            width: 100%;
            height: 5px;
            background: #A5ADBA;
        }
    }

    .change-card-btn {
        cursor: pointer;
        font-size: 14px;
        color: #a30063;
        font-weight: 400;
        line-height: 0px;
        vertical-align: top;
    }

    .paying-from {
        display: inline-block;
        height: 46px;
        margin-left: 20px;
        p {
            display: block;
            &:first-child{
                font-weight: normal;
            }
        }
    }

    .add-coupon-btn {
        border: none;
        background: none;
        font-size: 14px;
        color: $raspberry;
        font-weight: normal;
    }

    .amount-col {
        text-align: right;
    }

    .coupon-breakdown {
        text-align: right;
        font-weight: 300 !important;
        font-style: italic !important;
    }

    .add-on-container {
        margin: 25px 0 0 0;
        height: 190px;
        background: #EAEDEF;
        border-radius: 10px;
        padding: 20px;
        font-size: 14px;
        position: relative;

       @include max-screen(47em) {
            height: 240px;
        }

        div{
            line-height: 23px;
            p {
                &:first-child {
                    font-weight: bold;
                }
            }
        }


        .bottom-add-on-container {
            display: flex;
            margin-top: 25px;

           @include max-screen(47em) {
                margin-top: 15px;
            }

            header {
                p {
                    font-weight: bold;
                }
            }

            footer {
                text-align: right;
                flex-grow: 5;
            }

            p {
                margin-right: 5px;
                display: inline-block;
            }
        }

        .add-on-button{
            @include flat-button(#354251);
        }
    }
}

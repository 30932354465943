@import "../../../sass/imports";
@import "../Search/Results.scss";

.collection-viewer {
    padding-bottom: 20px;
    position: relative;
    width: 650px;
    max-width: 100%;

    .favorite-btn {
        border: none;
        background: none;
        font-size: 18px;
        margin-right: 10px;

        &[data-boarded="true"] {
            color: $raspberry;
        }
    }


    h2 {
        color: $text-gray;
        font-size: 22px;
        margin: 1em 0;
        text-align: center;
    }

    .loading {
        p {
            display: block;
            color: $text-gray;
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            margin: 40px 0;
        }

        i {
            color: $raspberry;
            display: block;
            margin: auto;
            font-size: 40px;
            text-align: center;
        }
    }

}

.collection-sign-in-modal {
    footer {
        a {
            &:hover {
                cursor: pointer;
            }
        }
        @include max-screen(47em) {
            .el-medium-btn {
                font-size: 12px;
            }
        }
    }
}

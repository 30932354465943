
@import "../../../../../sass/imports";

.try-it-out-card {
    .plan-card-text {
        background: none !important;
        text-align: center;
        height: auto !important;
        top: 0;

        h3 {
            font-size: 11px;
            max-height: none !important;
            line-height: 1.18;
            letter-spacing: 0.51px;
            font-weight: 600;
            width: calc(100% - 30px);
            margin: auto 15px;
        }
    }

    .icon-logo2 {
        font-size: 64px;
        color: white;
        display: inline-block;
        margin: 15px;

        .path1:before,
        .path2:before {
            color: white;
            opacity: 0.7;
        }
    }

    .plan-card-image {
        bottom: 0;
        background-image: none;
        background-color: $text-gray;
    }
}

@import "../../../sass/imports";

.grocery-meal-item {
    &.recipe-list-item {
        position: relative;
        min-height: 65px;
        padding: 10px 0;
        list-style-type: none;


        img {
            width: 65px;
            height: 65px;
            @include border-radius(4px);
            @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));
        }

        .meal-info {
            position: absolute;
            top: 40%;
            width: 100%;
            left: 75px;
            max-width: calc(100% - 55px);
            @include transform(translateY(-50%));

            p {
                font-size: 12px;
                color: $text-gray;
                overflow: hidden;
                max-height: 30px;
            }

            em {
                font-weight: bold;
            }
        }

        button {
            @include outline-button($text-gray);
            font-size: 8px;
            padding: 4px 15px;
            position: absolute;
            top: 65%;
            left: 50%;
        }
    }
}


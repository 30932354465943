
@import "../../../../../../sass/imports";

.nutrient-editor {
    display: flex;
    flex-direction: row;

    .col-1 {
        flex: 3;
        word-break: break-word;
        vertical-align: middle;
        line-height: 37px;
    }

    .col-2 {
        flex: 7;

        input {
            width: 100%;
        }

        .percentage, .percentage-total {

            input {
                width: 40%;
                padding: 4px;
            }
        }
    
    }

    .col-3 {
        flex: 1;
        text-align: center;
    
    }

    .col-4 {
        flex: 7;
        input {
            width: 100%;
        }

        .minimum, .maximum {
            width: 100%;
        }

        .minimum2, .maximum2 {
            width: 45%;
        }

        .separator {
            width: 10%;
        }
    
    }

    .col-5 {
        flex: 3;
        word-break: break-word;
        vertical-align: middle;
        line-height: 40px;
    }

    &.macro-nutrient, &.calories {
        @include max-screen(500px) {

            .col-2, .col-4 {
                flex: 8;
            }

            .col-5 {
                flex: 0;
            }

        }
    }

    &.nutrient-two-lines{
        .entry-container {
            padding: 4px 0 8px;
        }
        .col-3, .col-4, .col-5{
            margin-top: 23px;
        }
    }

    label {
        display: inline-block;
        font-size: 11px;
        font-weight: bold;
        color: $text-gray;
        line-height: 16px;
        vertical-align: middle;

        .icon-lock {
            font-size: 11px;
        }

    }

    input[type="number"] {
        @include max-screen(47em) {
            font-size: 14px;
        }
    }

    .minimum, .maximum {
        input[type="number"] {
            margin-right: 0;
            margin-left: 0;
            padding: 4px 30px 4px 8px;


            @include transition(all 0.333s ease-in-out);

        }
    }

    .minimum2, .maximum2 {
        input[type="number"] {
            margin-right: 0;
            margin-left: 0;
            @include transition(all 0.333s ease-in-out);
            padding: 4px 30px 4px 0;


        }
    }

    .updated {
        input[type="number"] {
            border: 1px solid blue;

            @include box-shadow(0, 2px, 6px, 0, $mint-green3);
        }
    }

    .percentage, .percentage-total {
        text-align: center;

        input[type="number"] {
            margin-right: 0;
            margin-left: 0;

            @include transition(all 0.333s ease-in-out);

            @include min-screen(47em) {
                padding: 4px 8px 4px 0px;
            }
        }

    }

    .percentage-total {
        border-top: 2px solid black;
    }

    .min-percent, .max-percent {

        input[type="number"] {
            margin-right: 0;
            margin-left: 0;

            @include transition(all 0.333s ease-in-out);
        }
    }

    .separator {
        font-size: 20px;
        display: inline-block;
        font-weight: bold;
        color: $text-gray;
        text-align: center;
        vertical-align: middle;
        line-height: 44px;
    }

    .unit {
        font-size: 12px;
        font-weight: bold;
        color: $text-gray;
        padding-left: 6px;
    }

    .entry-container {
        display: inline-block;
        position: relative;

        padding: 8px 0;

        input {
            position: relative;
            z-index: 1;
            border: 1px solid transparent;
        }

        .label {
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            right: 0;
            font-size: 10px;
            color: $inactive-text-gray;
            letter-spacing: 1px;
            text-transform: uppercase;
            z-index: 0;
            padding: 28px 2px;
        }

        &[data-empty="true"] {
            .label {
                opacity: 0;
            }
        }

        &[data-conflict="true"] {
            input {
                @include box-shadow(0, 2px, 6px, 0, #f6a623);
                border: solid 0.5px #f6a623 !important;
            }
        }
    }

    .fodmap-mode {
        display: inline-block;
        position: relative;
        width: 300px;
        margin: 6px auto 6px 80px;

        > button {
            background: none;
            border: none;
            text-transform: uppercase;
            color: $text-gray;
            font-size: 12px;
            padding: 5px;
            width: 50%;
            border: 1px solid $inactive-text-gray;
            position: relative;
            z-index: 2;
            @include transition(all 0.333s ease-in-out);
        }

        .reduce-btn {
            @include border-top-radius(5px);
            @include border-bottom-radius(5px);
            @include border-left-radius(5px);
            @include border-right-radius(0px);
            border-right: none;
        }

        .reintroduce-btn {
            @include border-top-radius(5px);
            @include border-bottom-radius(5px);
            @include border-left-radius(0px);
            @include border-right-radius(5px);
            border-left: none;
        }

        &:before {
            display: inline-block;
            width: 50%;
            content: ' ';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            border: 1px solid $raspberry;
            background-color: $raspberry;
            height: 100%;
            @include transition(all 0.333s ease-in-out);
            @include border-top-radius(5px);
            @include border-bottom-radius(5px);
            @include border-left-radius(0px);
            @include border-right-radius(5px);
        }

        &[data-mode="reduce"] {
            .reduce-btn {
                color: white;
            }

            &:before {
                left: 0%;
                @include border-top-radius(5px);
                @include border-left-radius(5px);
                @include border-bottom-radius(5px);
                @include border-right-radius(0);
            }
        }

        &[data-mode="reintroduce"] {
            .reintroduce-btn {
                color: white;
            }
        }
    }
}

@import './SharePopup.scss';

.multi-share-popup {

    .popup-content {

        padding: 17px 20px 20px 20px;

        .share-popup-content {
    
            .copy-url-container {
                margin-top: 20px;
    
                .copy-to-clipboard {

                    margin-top: 5px;
    
                    input, button {
                        margin-top: 0;
                    }
                }
    
                .title {
                    font-size: 13px;
                    font-weight: 700;
                }
            }
        }
    }

}


@import "../../../../sass/imports";

.dropdown-content .document-type-filter {
    label {
        min-width: 16em;
    }
}

.document-types-filter-btn {
    display: inline-block;
}

.dropdown-btn .document-types-filter-btn {
    text-align: right;
}

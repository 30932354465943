
@import "../../../sass/imports";

.edit-meal-servings-container {

    .el-modal2-body-container {
        max-width: 520px;
        
        padding: 25px 40px 40px;

        .yield-info {
            padding-bottom: 10px;

            > h2 {
                font-size: 17px;
                color: $text-gray;
                line-height: 25px;
                font-weight: 700;
                margin-top: 10px;
            }

            > p {
                font-size: 15px;
                line-height: 8px;
                font-weight: normal;
                margin-top: 5px;
            }
            > h2, p {
                text-align: center;
                
                em {
                    color: $raspberry;
                }
            }
        }

        .el-alert {
            font-weight: normal;

            ul {
                list-style: disc;
            }
        }
    }

    p {
        color: $text-gray;
        text-align: center;
        font-size: 14px;
        margin: 0.75em 0;
    }


    .participants-list {
        margin: 0 0 0px 0;
        text-align: center;

        li {
            cursor: pointer;
            display: inline-block;
            border: 1px solid $light-gray-border;
            padding: 8px 12px;
            font-size: 14px;
            color: $text-gray;
            margin: 5px;
        }
    }

    .edit-days {
        font-size: 14px;
        font-weight: bold;
        color: $text-gray;
        text-align: left;
        margin-top: 10px;

        .days-select {
            margin: 20px 0 10px;
            display: flex;
            align-items: center;

            h6 {
                display: inline-block;
                padding: 5px 10px 10px 0;
                color: $text-gray;
                font-size: 16px;
                white-space: nowrap;
                vertical-align: middle;
                font-weight: normal;
                font-weight: 700;
            }

            > div {
                display: inline-block;

                .days-dropdown-wrapper {
                    width: 100px;

                    .el-select-container {
                        p {
                            padding: 0;
                            margin: 0.6em 0;
                            font-weight: normal;
                        }

                    }
                }
            }
        }
    }

    .select-container {
        font-size: 14px;
        vertical-align: top;
        padding: 0 30px 0 5px;

        .select-dropdown {
            min-width: 200px;
        }

        p {
            text-align: left;
            margin: 0;
        }

        .placeholder {
            font-size: 10px;
            font-weight: bold;
            color: $raspberry;
            padding: 4px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .value {
            font-size: 14px;
            color: darken($raspberry, 10%);

            em {
                color: $text-gray;
            }
        }
    }

    .edit-meal-servings  {

        h5 {
            color: $text-gray;
        }

        h3 {
            font-size: 16px;
            color: $text-gray;
            text-align: center;
            margin: 1em 0 0 0;
            @include max-screen(750px) {
                margin: 1.3em 0 0;
            }
            text-align: left;
            font-weight: normal;
        }

        > ul {
            text-align: left;
            padding-left: 20px;
        }

        li {
            display: block;
            margin: 0 10px 5px 0;
        }

        .participants-select {
            display: inline-block;
            left: -20px;
            position: relative;

            h6 {
                display: inline-block;
                line-height: 36px;
                color: $text-gray;
                font-size: 14px;
                font-weight: normal;
                position: relative;
                right: 10px;
            }

            > div {
                display: inline-block;
                margin-right: 20px;

                .participants-dropdown-wrapper {
                    width: 44px;

                    .el-select-container {
                        p {
                            padding: 0;
                            margin: .6em 6px;
                            text-align: left;
                        }

                        li {
                            display: block;
                            text-align-last: center;
                        }

                        ul {
                            height: 100px;
                        }

                        .chevron {
                            top: -4px;
                            transform: rotate(180deg);
                        }

                        .select-dropdown {
                            word-wrap: normal;
                        }
                    }
                }
            }
        }
    }

    .el-checkbox, .name-no-checkbox {
        font-size: 14px;
        color: $el-grayish-blue;
        min-width: 100px;
        margin: 0px 10px 0px 0;
        text-transform: none;
        position: relative;
        background: none;
        border: none;
        padding: 10px 25px 10px 23px;
    }

    .portion-label {
        opacity: 0.6;
        font-size: 12px;
        margin-left: 5px;
    }

    .name-no-edit {
        font-size: 14px;
        color: $el-grayish-blue;
    }

    .el-alert {
        display: block;

        ul {
            margin-top: 10px;
        }
    }
}

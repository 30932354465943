
@import "../../../sass/imports";
@import "../Planner/Modals/LogPortionsModal.scss";

.food-units-selector-outer  {

    .open-modal-btn {
        border: none;
        background: none;
        -webkit-appearance: none !important;

        font-size: 14px;
        font-weight: normal;

        i {
            font-size: 10px;
            padding: 7px;
        }
    }

    .replace-chevron-text {
        margin-left: 10px;
        color: $raspberry;
    }

    .read-only-food-units-selector {
        > span {
            padding: 0 5px 0 0;
        }
    }
}


@import "../../../../sass/imports";

.search-keywords-filter {
    position: relative;

    input {
        width: calc(100% - 35px);

        border: none;
        @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.05));
        font-size: 12px;
        padding: 8px 0 8px 35px;
        color: #425569;
        @include transition(box-shadow 0.25s ease-in-out);

        &:focus {
            @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.2));
        }
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 0;
        @include transform(translateY(-50%));
        @include icon-font;
        content: $icon-search3;
        color: #425569;
        font-size: 12px;
        padding: 5px;
        padding-left: 10px;
    }

    .clear-search-btn {
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
        font-size: 12px;
        padding: 12px;
        color: $text-gray;
        opacity: 0;
        @include transition(opacity .25s ease-in-out);
        border: none;
        background: none;
    }

    &[data-has-terms="true"] {
        .clear-search-btn {
            opacity: 1;
        }
    }
}


@import "../../../../../sass/imports";

.patient-conditions-loader {
    display: flex;
    justify-content: center;
    padding: 40px;

    i {
        font-size: 40px;
        color: $raspberry;
        padding: 20px;
        vertical-align: middle;
    }
}

.patient-conditions {

    .patient-conditions-loader {
        padding: 40px;
        font-size: 12px;
        text-align: center;

        i {
            font-size: 40px;
            color: $raspberry;
            padding: 20px;
            vertical-align: middle;
        }
    }

    .condition-avoidances {
        padding: 10px;
        color: $text-gray;
    }

    .condition-info {
        color: $text-navy;
        margin: 25px 10px;
        background: #eaedef;
        border-radius: 10px;
        padding: 20px 20px 60px;
        font-size: 14px;
        position: relative;

        ul {
            list-style: disc;
            padding: 0 30px 0;
        }

        footer {
            font-size: 12px;
            font-weight: 300;
        }

        .avoidances-heading {
            display: block;
            margin: 15px 0;
        }
    }

    .half-width {
        width: calc(50% - 20px);
    }

    .full-width {
        width: calc(100% - 20px);
    }

    .fetus-count {
        text-align: center;
    }

    .condtion-select {
        .icon-lock {
            display: inline-block;;
            margin: 0 0 0 3px;
        }
    }

    h6 {
        margin-top: 20px;
        text-align: center;
        color: $text-gray;
    }

    > footer {
        text-align: center;
        padding-top: 20px;

        button {
            border: none;
            background: none;
            cursor: pointer;

            font-family: Lato;
            font-size: 10px;
            font-weight: bold;
            color: $raspberry;
            text-transform: uppercase;
            padding: 8px 22px 8px 52px;

            position: relative;

            &:before {
                @include icon-font;
                content: $icon-add-square-solid;

                position: absolute;
                top: 50%;
                @include transform(translateY(-50%));
                left: 0;
                padding: 12px;
                font-size: 16px;
                color: $raspberry;
            }

            &:focus {
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
            }
        }
    }

    .safe-mode-switches {
        margin-top: 20px;
        padding: 10px;

        color: $text-gray;

        p {
            display: block;
            font-size: 10px;
            font-weight: bold;
            color: $text-gray;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
}


@import "../../../../../sass/imports";

.patient-multi-select {
    padding: 20px;
    padding-top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    p {
        text-align: center;
        padding: 20px 0;
        color: $text-gray;
        font-size: 14px;
    }

    ul {
        text-align: center;

        li {
            display: inline-block;
            border: solid 1px $text-gray;
            width: 90px;
            height: 90px;
            @include border-radius(100%);
            margin: 5px;

            position: relative;
            text-align: center;
            cursor: pointer;
            color: $text-gray;

            @include transition(all .25s ease-in-out);

            > span {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                max-width: 90%;

                font-size: 10px;
                font-weight: bold;
                text-transform: uppercase;
            }

            &:hover {
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.07));
            }

            &[data-state="love"], &[data-checked="true"] {
                background-color: $mint-green3;
                color: white;
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.07));
            }

            &[data-state="hate"] {
                background-color: $raspberry;
                color: white;
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.07));

                > span {
                    opacity: 1;
                }

                &:after {
                    @include icon-font;
                    content: $icon-x;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
                    font-size: 42px;
                    color: rgba(255, 255, 255, .2);
                    z-index: 5;
                }
            }
        }
    }
}

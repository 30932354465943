
@import "../../../../sass/imports";

.patient-nutrition-prescription {
    padding: 0 20px;

    .prescription-part {
        margin-top: 1em;

        &:first-child {
            margin-top: 0;
        }
    }
    h3 {
        text-align: center;
        font-weight: normal;
        font-size: 12px;
        color: $inactive-text-gray;
        margin: 20px auto;

        &:before {
            display: none;
        }
    }

    h5 {
        color: $text-gray;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
    }



    p {
        color: $text-gray;
        font-size: 12px;

        em {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    ul {
        li {
            display: inline-block;
            vertical-align: top;
            max-width: 260px;
            width: 50%;
            color: $text-gray;

            font-size: 12px;
            margin-bottom: 20px;

            em {
                font-weight: bold;
            }
        }
    }

    .nutrients-list {
        margin-left: 20px;
    }
}

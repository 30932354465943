// >> After a Link
@mixin raquo() {
    &:after {
        content: "\00BB";
        margin-left: .15em;
        font-size: 1.45em;
        line-height: .5;
    }
}

// << Before a Link
@mixin laquo() {
    &:before {
        content: "\00ab";
        margin-right: .15em;
        font-size: 1.45em;
        line-height: .5;
    }
}

@mixin button-base() {
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    font-weight: 400;
    outline: 0;
    margin: 0 5px;
    line-height: 1;
    padding: 8px 30px;
    -webkit-apperance: none;
    @include user-select(none);
    @include border-radius(40px);
    i {
        @include transition(1s background-color ease, 1s color ease);
    }
    @include transition(1s background-color ease, 1s color ease);
}

@mixin outline-button(
    $outline-primary-color: $bluey-gray,
    $outline-secondary-color: white,
    $outline-font-size: 1em
){
    @include button-base;

    border: 1px solid $outline-primary-color;
    color: $outline-primary-color;
    background-color: $outline-secondary-color;

    &:hover, &:active {
        background-color: $outline-primary-color;
        color: $outline-secondary-color;
        text-decoration: none;
        i { color: $outline-secondary-color; }
    }

    &:focus {
        border-color: $outline-primary-color;
    }
}

@mixin flat-button(
    $flat-bg: $mint-green3,
    $flat-text-color: white
){
    @include button-base;

    border: 1px solid $flat-bg;
    background-color: $flat-bg;
    color: $flat-text-color;

    &:hover, &:active {
        color: darken($flat-text-color, 10%);
        background-color: darken($flat-bg, 20%);
        border: 1px solid darken($flat-bg, 20%);
    }
    &:focus {
        background-color: darken($flat-bg, 25%);
        border: 1px solid darken($flat-bg, 25%);
    }
}

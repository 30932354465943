
// inputs
.el-labeled-input, .el-unlabeled-input {
    > label {
        display: block;
        font-size: 14px;
        line-height: 26px;
        margin: 0 0 6px 0;
        text-align: left;
        color: $el-grayish-blue;
    }

    > input[type="text"], .el-input-container > input[type="text"],
    > input[type="number"], .el-input-container > input[type="number"],
    > input[type="password"], .el-input-container > input[type="password"],
    > textarea, .el-input-container > textarea,
    .pseudo-input {
        width: 100%;
        box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
        -webkit-appearance: none;
        border-radius: 10px;
        background-color: $el-white;
        border: 1px solid transparent;
        color: $el-grayish-blue3;

        font-size: 14px;
        line-height: 26px;
        padding: 7px 10px;

        &:focus {
            border: 1px solid $el-raspberry !important;
        }

        &:disabled {
            background-color: $el-gray4;
            color: $el-gray2;
            border: 1px solid transparent;
        }

        @include placeholder {
            color: #98A2BA; // @todo COLOR IS NOT IN PALETTE
        }
    }

    input[type="number"] {
        text-align: right;
    }

    .el-input-icon {
        margin-left: -30px;
        cursor: pointer;
    }

    &[data-error="true"] {
        > input[type="text"], input[type="number"], input[type="password"], textarea, .pseudo-input {
            border: 1px solid $el-error;
            background-color: rgba($el-error, 0.1);
        }

        > p {
            color: $el-error;
            font-size: 12px;
            line-height: 26px;
            margin: 4px 0 0 10px;
        }
    }

    &[data-complete="true"] {
        > input[type="text"], input[type="number"], input[type="password"], textarea, .pseudo-input {
            border: 1px solid $el-success;
            background-color: rgba($el-success, 0.1);
        }
    }
}

.el-input-with-unit {
    .el-input-container {
        display: flex;
        align-items: center;

        input {
            flex: 10 1;
        }

        em {
            flex: 1 1;
            text-align: center;
        }
    }
}

.el-input-with-icon {
    position: relative;

    input[type="text"], input[type="number"], input[type="password"], textarea {
        padding: 7px 10px 7px 40px;
    }

    .left-icon {
        position: absolute;
        top: 13px;
        left: 15px;
        font-size: 18px;
        color: $el-raspberry;
    }
}

.el-search-input {
    input[type="text"], input[type="number"], input[type="password"], textarea {
        border-radius: 40px;
    }
}

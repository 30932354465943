//---------- Box Sizing ------------//
// http://www.paulirish.com/2012/box-sizing-border-box-ftw/
// https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing
//----------------------------------//
//  Usage : @extend %content-border;
//  We use placeholders since we might use these styles a lot.
//  Be careful using placeholders with nested elements (check your CSS output)
//----------------------------------//

// FTW Version
@mixin border-box-ftw {
    @include prefixer(box-sizing, border-box, webkit moz spec);
    &:before, &:after {
        @include prefixer(box-sizing, border-box, webkit moz spec);
    }
}

// Content Box
@mixin content-box {
    @include prefixer(box-sizing, content-box, webkit moz spec);
}

// Border Box
@mixin border-box {
    @include prefixer(box-sizing, border-box, webkit moz spec);
}
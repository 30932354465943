
@import "../../../../sass/imports";

.dashboard-recent-activity {
    > header {
        margin-bottom: 20px;
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        color: $text-gray;
        text-align: left;

        @include min-screen(47em) {
            text-align: left;
        }
    }

    h3 {
        margin: 0 0 1em 0;
        white-space: nowrap;
        text-align: center;
        font-size: 14px;
        color: $text-gray;
        text-align: center;

        @include min-screen(47em) {
            text-align: left;
        }

        em {
            color: $mint-green3;
        }
    }

    .update-prefs {
        h6 > span {
            display: inline-block;
            padding-right: 10px;
        }
    }

    .loading-activity {
        padding: 40px;
        font-size: 12px;
        color: $text-gray;
        text-align: center;

        i {
            font-size: 40px;
            color: $raspberry;
            padding: 20px;
            vertical-align: middle;
        }
    }

    > footer {
        text-align: center;
        padding: 20px;
    }

    .show-more-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 15px;
        border-width: 0px;
        text-decoration: underline;
        margin: 5px;
    }
}

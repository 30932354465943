
@import "../../../../sass/imports";

.pro-expander-container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 100%;

    .expander-btn {
        width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border: none;
        background: none;
        padding: 16px 20px;
        text-align: left;

        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        color: $inactive-text-gray;
        text-transform: uppercase;

        @include transition(all .333s ease-in-out);

        em {
            text-transform: none;
            color: $icon-inactive;
            font-weight: normal;
        }

        &:after {
            @include icon-font;
            display: inline-block;
            content: $icon-chevron-right;
            margin-left: 3em;
            font-size: 12px;
            @include transition(all .333s ease-in-out);
        }
    }

    .edit-btn {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: none;
        padding: 18px 22px;
        font-size: 12px;
        color: $raspberry;

        @include transition(all .333s ease-in-out);
        /*opacity: 0;*/
    }

    .expander-expander {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        @include transition(all .333s ease-in-out);

        .expander-content {
            padding: 10px;
        }
    }

    &[data-state="true"] {
        .expander-btn {
            color: $text-gray;
            &:after {
                @include transform(rotateZ(90deg));
            }
        }

        .expander-expander {
            opacity: 1;
            max-height: 99em;
            overflow-y: auto;
        }

        .edit-btn {
            opacity: 1;
        }
    }

    &[data-collapsible="false"] {
        .expander-btn:after {
            display: none;
        }
    }
}

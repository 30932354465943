@import './LogoEditorModal.scss';

.cover-image-editor-container {
    color: $text-gray;
}

.cover-image-picker {
    li {
        cursor: pointer;
        display: inline-block;
        margin: 5px;
        padding: 5px;
        border: 1px solid $bluey-gray;
        @include border-radius(5px);
        max-width: calc(50% - 10px);

        &[data-selected="true"] {
            background-color: $orange4;
        }

        img {
            max-width: 100%;
            max-height: 130px;
        }
    }
}

.cover-image-form {
    padding: 20px;

    > header {
        text-align: center;
        margin: 0 0 20px 0;

        h1 {
            font-size: 18px;
        }
    }

    > hr {
        width: 50%;
        height: 1px;
        margin: 30px auto;
    }

    p {
        text-align: left;
        font-size: 14px;
        margin: 0 10px;
    }

    .upload-section {
        text-align: center;

        .file-upload {
            display: inline-block;
        }
    }
}

@import "./Modals.scss";
@import "../../../../sass/imports";
@import "../Patients/Editor/PatientForm.scss";

.edit-practice-modal {

    .error-msg {
        opacity: 0;

        color: red;
        font-weight: bold;
        padding: 0 40px;

        &[data-active="true"] {
            opacity: 1;
        }
    }
}

.edit-practice-container {


    .modal-scroll-container {
        padding-bottom: 75px;
    }

    .modal-action-footer {
        position: absolute;
        bottom: 0;
        background-color: white;
    }
}

.edit-practice-form {
    color: $text-gray;

    > header {
        h1 {
            font-size: 18px;
            text-align: center;
        }
    }

    input[type="text"] {
        width: 100%;
    }

    .practice-name {
        width: calc(100% - 20px);
    }

    .practice-address, .practice-address-country,
    .practice-logo-image, .practice-cover-image, .practice-type, .inhibit-jabber {
        width: calc(50% - 20px);
    }

    .practice-address-city,
    .practice-address-postalcode {
        width: calc(32% - 20px);
    }

    .practice-address-state {
        width: calc(36% - 20px);
    }

}


@import "../../../../sass/imports";

.pro-register-form {
    .brochure-container {
        > footer p {
            margin-bottom: 0;
        }

        .featured-on {
            padding-top: 20px !important;
        }
    }
}

.meal-plan-customizer {

    .customizer-scroll-container {
        position: absolute;
        top: 0; bottom: 60px;
        left: 0; right: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        -webkit-transform: translate3d(0, 0, 0);
        padding-top: 25px;
        padding-bottom: 100px;
        background-color: #fafafa;

        @include min-screen(47em) {
            top: 0;
            bottom: 0;
            padding-top: 35px;
        }

        > header {
            display: block;
            position: relative;
            max-width: 940px;
            margin: auto;
            margin-left: 70px;
        }
    }

    .primary-actions {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        background-color: white;
        @include box-shadow(0, -5px, 5px, 0, rgba(0, 0, 0, 0.1));
        padding: 10px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @include min-screen(47em) {
            top: 23px;
            right: 58px;
            bottom: auto;
            left: unset;
            background-color: transparent;
            box-shadow: none;
            width: auto;
            z-index: 8;
        }

        .footer-icons-row {
            padding-bottom: 10px;
        }

        button, .share-popup {
            vertical-align: middle;

            &.share-popup {
                margin: 0 10px;

                .el-text-btn {
                    font-size: 24px;

                }
            }

            &.favorite-btn {
                color: #a8acbe;
                display: inline-block;
                padding: 5px 0 0;
                border: none;
                background: none;
                line-height: 20px;
                margin-right: 10px;

                &[data-boarded="true"] {
                    color: $raspberry;
                }

                i { 
                    font-size: 24px;
                }
            }
        }
    }

    .error-msg {
        &:before {
            @include icon-font;
            content: $icon-warning1;
            font-size: 18px;
        }

        color: $orange3;
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
    }

    .working-veil {
        position: absolute;
        z-index: 11;
        top: 0; bottom: 0;
        left: 0; right: 0;
        text-align: center;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.85);
        padding-top: 250px;

        h2 {
            color: white;
            text-align: center;
            width: 100%;
        }

        i {
            font-size: 40px;
            color: $raspberry;
            display: inline-block;
        }
    }

    .send-to-patient {
        @include flat-button($raspberry);
        @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.1));
        @include border-radius(30px);
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 20px;
        margin: 5px;
        vertical-align: middle;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: auto;

        @include min-screen(47em) {
            margin-right: 15px;
            min-width: 230px;
        }

        i {
            color: white;
            display: inline-block;
        }
    }

    .recommend-to:focus {
            background-color: $raspberry;
            border: 1px solid $raspberry;
    }

    h1 {
        color: $text-gray;
        font-size: 20px;
        font-weight: bold;
        padding: 5px;
        margin: 10px 10px 20px 10px;
        width: calc(100% - 240px);

        @include max-screen(47em) {
            width: calc(100% - 70px);
        }
    }

    h2 {
        color: $text-navy;
        font-size: 22px;
        padding: 5px;
        margin: 0px;
        width: calc(100% - 350px);
        font-weight: normal;
        em {
            font-weight: bold;
        }

        @include max-screen(47em) {
            font-size: 19px;
            width: calc(100% - 20px);
            margin: 10px auto 10px;
            text-align: center;
        }
    }

    input.plan-title {
        color: $text-navy;
        font-size: 22px;
        padding: 5px;
        margin: 0px;
        width: calc(100% - 150px);
        font-weight: normal;
        border: 1px solid transparent;
        -webkit-appearance: none;
        background-color: transparent;

        @include max-screen(47em) {
            font-size: 19px;
            width: calc(100% - 20px);
            margin: 10px;
            text-align: center;
        }

        &:hover {
            border: 1px solid $bluey-gray;
        }

        @include placeholder {
            color: $text-navy;
            font-style: italic;
            opacity: 0.5;
        }

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }
    }

    h3 {
        padding: 0 5px;
        font-size: 20px;
        color: $el-gray1;
        display: inline-block;
        font-weight: 700;

        @include max-screen(47em) {
            max-width: calc(100% - 20px);
            text-align: center;
        }
    }

    h4 {
        text-transform: uppercase;
        text-align: center;
        color: $text-gray;
        font-size: 14px;
        font-weight: bold;
    }

    .sub-action-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;
    }

    .view-more-plans {
        border: 1px solid $raspberry;
        text-decoration: none;
    }

    .secondary-controls {
        text-align: center;

        button {
            .icon-spinner {
                margin-left: 3px;
            }
        }
    }

    .pro-dropdown-container {
        .dropdown-btn:after {
            display: none;
        }
    }

    .meal-plan-insights {
        margin-top: 40px;

        header {
            position: relative;
            margin: 0 0 20px 0;
        }
    }

    .icon-warning1 {
        color: $warning-yellow;
    }

    .icon-print {
        font-size: 18px;
    }

    .favorite-pill-btn {
        margin: 3px 10px 0 0;
    }

    .mobile-grid {
        margin-top: 10px;
    }

}

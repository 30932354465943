
@import "../../../sass/imports";

.instruction-step-container {
    display: table;
    width: 100%;
    font-size: .875em;
    line-height: 1.2;
    margin-bottom: .5em;
    > header, > section {
        display: table-cell;
        vertical-align: top;
    }
    > section {
        padding-left: 5px;
        font-size: 16px;
        line-height: 1.2;
    }
    > header {
        font-weight: 600;
        text-transform: uppercase;
        color: #4a494d;
        line-height: 1.3;
        font-size: 16px;
        width: 16px;
    }
}

.preparation-group {
    label {
        color: $text-gray;
        font-size: 18px;
        font-weight: bold;
    }
}

.recipe-instructions-container {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    h5 {
        font-size: 16px;
        font-weight: bold;
        text-decoration: underline;
    }

    .subrecipe {
        margin: 30px 0 0 0;

        h2 {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
        }
    }

    .time-header {
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        font-size: 14.5px;
        margin: 0 0 30px;
        gap: 30px;
        
        > p {
            font-size: 16px;
        }
    }
}

.subscribe-for-instructions {
    text-align: center;
    p {
        color: #4a494d;
        font-size: 1em;
    }

    footer {
        margin-top: 2em;
        text-align: center;

        p {
            margin-top: 2em;
        }

        button {
            @include flat-button($raspberry);
        }

        .sign-in {
            @include outline-button($dark-slate-gray);
            margin: 10px 0px 0px 0px;
        }
    }
}

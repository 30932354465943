@import "../sass/imports";

body {
    // overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    -webkit-transition-property: top, bottom;
    transition-property: top, bottom;
    -webkit-transition-duration: .2s, .2s;
    transition-duration: .2s, .2s;
    -webkit-transition-timing-function: linear, linear;
    transition-timing-function: linear, linear;
    // -webkit-overflow-scrolling: touch;
    // -webkit-backface-visibility: hidden;

    &::-webkit-scrollbar {
        @include max-screen(47em) {
            display: none;
        }
    }

    @media print {
        background-color: white;
    }
}

.main-layout {
    position: relative;
    z-index: 2;
}

.error-loading-eatlove {
    margin: 60px 20px;
    color: $text-gray;
    font-size: 18px;
    padding: 20px;
    max-width: 500px;
    background-color: white;
    text-align: left;
    border: 0.5px solid $light-gray-border;
    @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.2));

    @include min-screen(540px) {
        margin: 60px auto;
    }

    h1 {
        margin: .75em 0;
        font-size: 24px;
        text-align: center;
    }

    p {
        margin-bottom: 1em;
        font-size: 14px;
    }

    .icon-logo, img {
        display: block;
        width: 50px;
        margin: 10px auto 25px auto;
        font-size: 48px;
        color: $raspberry;
    }

    ul {
        li {
            list-style-type: disc;
            margin-left: 1.5em;
            font-size: 14px;
        }
    }

    pre {
        font-size: 14px;
        padding: 5px;
        overflow: auto;
        max-width: 500px;
        margin: 20px auto;
        background-color: $lighter-gray;
    }

    footer {
        padding: 20px;
        text-align: center;

        button {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
            background-color: white;
            border: solid 0.5px $orange3;
            background-color: $orange3;
            color: white;
            font-size: 14px;

            padding: 10px;
            min-width: 100px;
            width: calc(50% - 20px);
            max-width: 200px;
            margin: 10px;
            text-transform: uppercase;

            @include transition(all 0.333s ease-in-out);

            &:hover {
                background-color: $inactive-text-gray;
                color: white;
            }

            &:focus {
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
            }
        }
    }
}


.vg-webview {
    margin-bottom: 50px;
    .registration .carousel-item>footer {
        margin-bottom: 50px;
    }
}

@import "../../../../sass/imports";

.global-search-loading {
    text-align: center;

    h2 {
        margin: 20px auto;
        font-size: 18px;
        line-height: 1.1;
        color: #4a494d;
    }

    i {
        display: inline-block;
        font-size: 36px;
        color: $raspberry;
    }
}

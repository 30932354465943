
@import "../../../sass/imports";

.meal-type-radio {
    text-align: center;
    margin: 20px auto;

    > label {
        display: block;
        font-size: 14px;
        color: $text-gray;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 0 10px 0;
        width: 100%;
    }

    > button {
        color: $text-gray;
        background: none;
        font-size: 12px;
        padding: 8px 0;
        border: none;
        border: 1px solid $light-gray-border;
        text-transform: uppercase;
        width: 25%;
        white-space: nowrap;
    }

    .active {
        color: white;
        font-weight: bold;
        background-color: $text-gray;
        border: 1px solid $text-gray;
    }
}

.custom-calendar {
    .rmdp-calendar {
        display: block;
        margin: auto;
    }
}

.el-modal {
    .el-modal-canecl-btn, .el-modal-ok-btn {
        padding: 6px 28px;
    }

    .el-modal2-container {
        > footer {
            padding: 20px 10px;
        }    
    }


}

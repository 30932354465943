
@import "../../../../sass/imports";

.pro-login-required {
    min-height: 25em;
    text-align: center;
    padding: 40px 0px;
    position: relative;

    h2 {
        color: $text-gray;
        font-size: 18px;
        margin-top: 80px;
        padding: 10px;
        font-weight: 300;

        em {
            font-weight: 900;
        }
    }

    p {
        margin: 10px;
        color: $text-gray;
        font-size: 14px;
    }

    footer {
        margin-top: 40px;

        > button {
            @include outline-button($bluey-gray);
        }

        a button {
            @include flat-button($orange3);
        }
    }

    .login-form .form-container {
        @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.1));

        position: relative;
    }

    .return-to-lp {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        padding: 10px 20px;
        border: none;
        background: none;
        text-decoration: none;

        color: $bluey-gray;
        font-size: 20px;
    }
}

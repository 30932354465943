
// These are global layout styles for React Modal
// && the modal confirmation styles
// Individual styles within .modal-main should be done elsewhere

body.ReactModal__Body--open {
    overflow: hidden;
    // position: fixed;
}

.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
    z-index: 100;
}

// main parent of modal
.modal-parent {
    border: 0;
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    z-index: 11;
    background-color: white;

    @include min-screen(47em) {
        top: 5%;
        left: 5%;
        right: 5%;
        bottom: 5%;
    }
    @include min-screen(60em) {
        top: 5%;
        left: 15%;
        right: 15%;
        bottom: 5%;
    }
    @include min-screen(70em) {
        top: 5%;
        left: 20%;
        right: 20%;
        bottom: 5%;
    }
    @include min-screen(80em) {
        top: 5%;
        left: 30%;
        right: 30%;
        bottom: 5%;
    }
    &.wide-modal {
        @include min-screen(600px) {
            top: 5%;
            right: 5%;
            left: 5%;
            bottom: 5%;
        }
        @include min-screen(47em) {
            top: 5%;
            right: 5%;
            left: 5%;
            bottom: 5%;
        }
        @include min-screen(60em) {
            top: 5%;
            right: 5%;
            left: 5%;
            bottom: 5%;
        }
        @include min-screen(80em) {
            top: 5%;
            right: 5%;
            left: 5%;
            bottom: 5%;
        }
        @include min-screen(1400px) {
            top: 5%;
            right: 5%;
            left: 5%;
            bottom: 5%;
        }
    }
    .the-modal-header { // DEPRECATED - DO NOT USE!!!
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4.5em;
        overflow: hidden;
        padding-top: 1.25em;
        @include box-shadow(0, 0, 15px, 5px, white);
        > .inner-container { max-width: 90%; }
        h1 {
            color: $grayscale9;
            font-size: 1.5em;
            line-height: 1.1;
            padding-right: 1.25em;
        }
        button {
            z-index: 10;
            position: absolute;
            top: .25em;
            right: 0;
            display: block;
            border: 0;
            padding: 1em;
            text-align: center;
            background-color: transparent;
            i {
                font-size: 1.125em;
                display: inline-block;
                color: $grayscale5;
            }
        }
    }
    .modal-scroll-container { // DEPRECATED - DO NOT USE!!!
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .modal-main {
        /*position: relative;*/
        height: 100%;
        padding: 0;
        > .inner-container { width: 90%; }
    }
}

.modal-notifier {
    border: 0;
    z-index: -1;
    @include border-radius(.25em);
    // make sure left and right are set to screen width
    left: 5%;
    right: 5%;
    @include min-screen(400px) { left: 10%; right: 10%; }
    @include min-screen(500px) { left: 15%; right: 15%; }
    @include min-screen(600px) { left: 20%; right: 20%; }
    @include min-screen(700px) { left: 25%; right: 25%; }
    // make sure top and bottom are set according to screen height
    top: 15%;
    bottom: 35%;
    @include max-screen-height(800px) { bottom: 35%; }
    @include max-screen-height(667px) { bottom: 25%; }
    @include max-screen-height(568px) { bottom: 15%; }
    @include max-screen-height(480px) { bottom: 15%; }

    .modal-scroll-container {
        top: 0;
    }
}

.modal-confirmation-overlay {
    // @include background-image(linear-gradient(top, rgba(72, 80, 90, 1) 0%,rgba(72, 80, 90, 0.76) 30%));
    background: rgba(72, 80, 90, 0.9);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    opacity: 0;
    @include transition(all 0.333s ease-in-out);

    &.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

// modal confirmation dialog
.modal-confirmation {
    border: 0;
    z-index: 15;
    // make sure left and right are set to screen width
    // max-width: 750px;
    // min-width: 530px;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    background-color: white;
    position: absolute;
    @include border-radius(8px);
    @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.25));

    .modal-main {
        padding: 30px 30px 15px;
        overflow-y: auto;
        max-height: 100vh;
        min-width: 320px;

        h1 {
            font-size: 20px;
            line-height: 1.25;
            color: $text-gray;
            margin: .75em;
            text-align: center;
            max-width: 500px;
        }

        h2 {
            font-size: 20px;
            line-height: 1;
            color: $text-gray;
            margin: .75em;
            text-align: center;
        }

        p {
            margin: .75em;
            font-size: 1em;
            color: $grayscale7;
            text-align: center;

            em {
                font-weight: bold;
            }
        }

        pre {
            margin: 0.75em;
            font-size: 1em;
            color: $grayscale7;
        }

        .error-msg {
            font-weight: bold;
            color: red;
        }

        .alert-msg {
            font-weight: bold;
            color: $mint-green3;
        }

        footer {
            width: 100%;
            text-align: center;
            padding: 15px 15px 0;
            white-space: nowrap;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .reject {
                @include flat-button($bluey-gray);
            }

            .accept {
                @include flat-button($raspberry);
            }

            .reject, .accept {
                margin-bottom: 15px;
            }
        }
    }
}

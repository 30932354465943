
@import "../../../sass/imports";

.global-search-browser {

    .results-suggestion {
        max-width: 940px;
        margin: 1em auto;
        font-size: 16px;

        @include max-screen(1011px) {
            margin: 1em 20px;
        }

        b, span {
            margin: auto 2px;
        }
    }

    .did-you-mean {
        cursor: pointer;
        text-decoration: underline;
        color: $raspberry;
    }
}

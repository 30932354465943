
@import "../../../../../sass/imports";

.customizer-meal-draggable {
    width: 100%;
    height: 127px;
    overflow: hidden;
    cursor: pointer;

    vertical-align: top;
    position: relative;
    z-index: 1;
    @include transition(all 0.2s ease-in-out);

    &[data-dragging="true"] {
        overflow: hidden;
        opacity: .5;
    }

    &[data-deleted="true"] {
        border: 2px dashed red;
    }

    &[data-meal-type="leftover"] {
        .meal-image {
            @include filter(saturate(0) brightness(1) grayscale(1));
            opacity: 1;
        }
    }

    &[data-meal-type="product"] {
        .meal-image {
            background-image: $product-card-gradient;
        }
    }

    &[data-meal-type="custom"] {
        .meal-image {
            background-image: $custom-card-gradient;
        }
    }

    &[data-meal-type="food"] {
        .meal-image {
            background-image: $food-card-gradient;
        }
    }


    &[data-dnd-disabled="false"] {
        &:hover {
            @include box-shadow(0, 2px, 16px, 0, rgba(0, 0, 0, 0.35));
            cursor: move;
        }
    }

    .image-overlay-text {
        z-index: 1;
        position: absolute;
        top: 15px;
        left: 40%;
        color: white;
        width: 200px;

        h1 {
            display: inline-block;
            position: relative;
            right: 10px;
        }

        i {
            display: inline-block;
        }

        .feather-loader {
            animation-duration: 1s;
            animation-iteration-count: 120;
            animation-name: spinning;
            animation-timing-function: linear;
            font-size: 40px;
            position: relative;
            top: 45px;
            left: 70px;
        }

    &[data-loading="true"] {
        background-color: rgba(71, 86, 114);
        height: 170px;
        top: 0px;
        left: 0px;
        opacity: 0.96;
    }


    }

    .meal-image {
        background-color: $grayscale5;

        position: absolute;
        top: -1%;
        bottom: -1%;
        left: -1%;
        right: -1%;

        background-size: 100%;
        background-repeat: repeat;
        background-position-y: 0px;

        vertical-align: top;
    }

    .meal-image {
        z-index: 1;
    }

    .meal-info {
        position: absolute;
        bottom: 0;

        font-size: 13px;
        color: white;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px;
        padding-top: 8px;
        width: 100%;
        height: 35px;
        z-index: 2;
        height: 5.2em;

        vertical-align: middle;

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            width: calc(100% - 10px);
            text-align: center;
            @include transform(translate(-50%, -50%));
            line-height: 1.2;
        }

        .fix-ios-overflow-bug {
            max-height: calc(3.5em + 1px);
            overflow: hidden;
            width: 100%;
        }

        em {
            font-weight: bold;
            text-transform: uppercase;
            display: block;
            text-align: center;
            color: lighten($bluey-gray, 10%);
        }

        i {
            bottom: 0px;
            left: 5px;
            font-size: 16px;
            position: absolute;
            padding: 5px 20px 5px 5px;

            @include min-screen(47em) {
                padding: 5px;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.77);
                    color: #425569;
                    border-radius: 15px;
                }
            }
        }
    }

    .drawer-menu-btn {
        color: $text-gray;
        border: none;
        font-size: 8px;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: 0.8px;
        background-color: rgba(255, 255, 255, 0.77);
        padding: 9px;
        border: none;
        margin: 0 4px;
        text-transform: uppercase;
        vertical-align: top;
        @include border-radius(40px);
        @include transition(all .2s ease-in-out);

        &:hover {
            background-color: rgba(0, 0, 0, 0.77);
            color: white;
        }

        &:focus {
            background-color: black;
            color: white;
        }
    }

    .undo-delete {
        @include flat-button($orange);
        @include border-radius(0);
        padding: 8px 15px;
        font-size: 10px;
        width: auto; height: auto;
        margin: 6px;

        @include min-screen(47em) {
            font-size: 14px;
        }
    }

    > footer {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        display: block;
        text-align: right;
        white-space: nowrap;
        padding: 5px 5px 0 0;
    }
}

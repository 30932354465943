@import "../../../../sass/imports";

.agreement-modal-overlay {
}

.agreement-modal {
    .editor-modal-container .editor-scrollable {
        top: 0;
    }

    .editor-modal-container .modal-header {
        height: 0;
    }
}

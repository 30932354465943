@import "../../../../sass/imports";

.container-prompt-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    border-radius: 4px;

    .container {
        display: flex;
        flex-direction: row;
        background-color: $el-raspberry5;
        color: $el-raspberry;
        margin: 47px 5px 0;
        border-radius: 10px;
        height: 148px;
        padding: 10px;

        .content {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .header {
                display: flex;
                text-align: initial;
                font-size: 14px;
                flex-direction: row;
                gap: 20px;
                align-items: flex-end;
                margin-left: 10px;

                > i {
                    font-size: 30px;
                }

                .titles {
                    .title {
                        color: $text-gray;
                        font-weight: 700;
                    }

                    .subtitle {
                        font-size: 12px;
                    }
                }
            }

            .options {
                display: flex;
                flex-direction: row;
                gap: 4px;
                flex-wrap: wrap;
                justify-content: center;

                button {
                    min-width: initial;
                    margin: 0;
                    font-size: 10px;
                    height: 24px;
                    padding: 0px 16px;
                }
            }
        }
    }
}

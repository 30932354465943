
@import "../../../../sass/imports";

.markdown {
    h1, h2, h3 {
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 16px;
    }

    ul, ol {
        margin: .5em 1.5em;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    p, li, pre {
        font-size: 14px;
    }

    blockquote {
        border-left: 2px solid $text-gray;
        margin: .5em;
        padding-left: .25em;
    }

    hr {
        height: 1px;
    }

    p {
        margin: .25em 0;
    }

    pre {
        font-family: monospace;
        padding: .5em;
        font-size: 14px;
    }

    strong, b {
        font-weight: bold;
    }

    em, i {
        font-weight: normal;
        font-style: italic;
    }
}

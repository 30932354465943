
@import "../../../../sass/imports";
@import "./EditTimings.scss";

.edit-recipe-image {
    display: inline-block;
    border: 1px solid $bluey-gray;
    width: 62px;
    height: 62px;
    vertical-align: bottom;
    margin: 10px 0 0 0;
    // overflow: hidden;
    @include border-radius(5px);

    > a > img {
        width: 62px;
        height: 62px;
    }


    > .file-upload button, .new-image-btn {
        width: 62px;
        height: 62px;
        font-size: 22px;
        border: none;
        text-decoration: none;
        background-color: $el-white;
        color: $el-raspberry;
        @include transition(background-color .25s ease-in-out);
    }
}

.edit-image-modal-body {
    position: relative;

    .recipe-image-full {
        max-width: 100%;
        margin: auto;
        display: block;
    }
}

.edit-recipe-upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 30px auto;

    > input[type="text"] {
        height: 0px;
        overflow: hidden;
        opacity: 0;
    }

    .file-upload {
        width: auto;
    }

    > i {
        font-size: 80px;
        color: $el-gray3;
        display: inline-block;
    }
}

.scanning-text {

    @include max-screen(47em) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        height: 80px;
        width: 80px;
        padding-bottom: 10px;
    }
    
    .uploading-text {
        @include min-screen(47em) {
            display: block;
            position: relative;
            padding: 3px;
            bottom: 10px;
        }
        text-transform: none;
        font-size: 12px;
        color: initial;
        background-color: initial;
    }

    .upload-bar-background {
        @include min-screen(47em) {
            top: 20px;
        }
        
        position: relative;
        display: block;
        margin: 0 auto;
        width: 67.5%;
        height: 6px;
        background: rgba(50, 66, 83, .12);
        border-radius: 15px;

        .upload-bar-fill {
            position: absolute;
            left: 0;
            width: 50%;
            height: 6px;
            background: #A30063;
            border-radius: 15px;
        }
    }
}


@import "../../../../sass/imports";

.meal-plan-customizer-modal-container {

    .loading-meal-plan-customizer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 100%;

        .icon-spinner {
            display: block;
            font-size: 36px;
            width: 36px;
            margin: auto;
            color: $raspberry;
        }
    }
}

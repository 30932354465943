
@import "../../../sass/imports";

.saved-recipes {
    padding: 80px 0 0 0;

    .param-item {
        display: none;
    }

    @include min-screen(47em) {
        padding: 0;
    }

    .full-browser-keyword-search {
        margin: 0;
        @include transition(width .25s ease-in-out);
        flex-grow: 5;

        input {
            width: calc(100% - 40px);

            @include placeholder {
                text-transform: none;
                color: #85898C;
                font-size: 12px;
            }
        }

        &:before {
            left: 8px;
        }
    }

    .new-recipe-btn-container {
        text-align: right;
    }

    > header {
        position: absolute;
        left: 50%;
        @include transform(translate(-50%, 0));
        width: 100%;
        z-index: 6;
        text-align: center;
        white-space: nowrap;
        max-width: 335px;
        margin: auto;
        @include transition(padding 0.33s ease-in-out);
        will-change: padding;
        display: flex;
        top: 130px;

        @include min-screen(47em) {
            position: relative;
            left: initial;
            @include transform(translate(0, 0));
            top: initial;
        }
    }

    .advanced-filters-btn {
        color: white;
        font-size: 20px;
        flex-shrink: 1;

        border: none;
        background: none;
        padding: 0 0 0 10px;
        vertical-align: middle;
        @include transition(opacity 0.33s ease-in-out);

        @include min-screen(47em) {
            color: $text-gray;
        }
    }
}


@import "../../sass/imports";

.terms-conditions {
    .underline {
        text-decoration: underline;
    }

    .center {
        text-align: center;
    }

    ol.roman {
        list-style-type: upper-roman;
    }

    ol.lower-roman {
        list-style-type: lower-roman;
    }

    ol.letters {
        list-style-type: lower-alpha;
    }
}


@import "../../../../../sass/imports";

.try-it-out-modal-container {

    &.el-modal2-container {
        
        @include min-screen(47em) {
            max-width: unset;
            max-height: unset;
            width: 700px !important;
            height: 700px !important;
        }

        > header {     
            h2 {
                padding: 25px 50px 10px;

                @include max-screen(47em) {
                    font-size: 16px;
                }
            }
        }

        .mode-toggle {
            background-color: #f6f7f7;
            &:hover {
                background-color: $raspberry;
            }
        }
    }

    .el-modal2-body-container {
        padding: 10px 5px 20px;
        height: calc(100vh - 160px);

        &[data-prescription="true"] {
            height: calc(100vh - 190px);
        }        
        
    }

    > footer {


        .el-modal-ok-btn {

            i {
                display: inline-block;
                padding: 0 8px;
                vertical-align: middle;
            }

            &[data-flash="1"], &[data-flash="5"] {
                background-color: $mint-green3;
                @include transform(scale(1.1));
            }

            &[data-flash="3"] {
                background-color: $raspberry;
                @include transform(scale(1.1));
            }
        }
    }

    &.editor-modal-container {
        .modal-header h2 {
            margin-top: 42px;
        }
    }

    .modal-badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px 14px;
        background-color: $raspberry;
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        z-index: 5;
        border-top-left-radius: 10px;
    }
}


@import "../../../../../sass/imports";

.add-swap-full-browser {
    width: calc(100vw - 40px);
    max-width: 1070px;
    padding: 10px 10px 120px;
    min-height: 85vh;

    @include max-screen(600px) {
        padding: 0;
    }

    @include max-screen(47em) {
        width: 100%;
        &:before {
            content: ' ';
            position: absolute;
            z-index: 5;
            left: 50%;
            width: 145vw;
            height: 305px;

            top: 0;
            @supports (top: constant(safe-area-inset-top)) {
                top: calc(constant(safe-area-inset-top));
            }

            @supports (top: env(safe-area-inset-top)) {
                top: calc(env(safe-area-inset-top));
            }

            @include transform(translate(-50%,calc(-100% + 193px)));
            @include border-radius(50%);

            @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
            @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));
        }
    }

    > header {
        position: absolute;
        left: 50%;
        @include transform(translate(-50%, 0));
        width: 100%;
        z-index: 6;
        text-align: center;
        white-space: nowrap;
        max-width: 335px;
        margin: auto;
        @include transition(padding 0.33s ease-in-out);
        will-change: padding;
        display: flex;

        @include min-screen(47em) {
            margin: 20px auto;
            position: relative;
            left: initial;
            @include transform(translate(0, 0));
        }

        > * {
            white-space: initial;
        }
    }

    > footer {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));

        &.search-foods-and-recipes-footer {
            z-index: 2;
            @include transform(translate(-50%, 0));
            height: 72px;
            width: 100%;
            background: #FFFFFF;
            position: absolute;
            bottom: 0;
            top: initial;
            justify-content: space-evenly;
            display: flex;
            padding: 16px 0;
            @include box-shadow(0, 0, 40px, 0, rgba(0, 0, 0, 0.1));
            overflow-x: auto;

            @include max-screen(600px) {
                align-items: flex-start;
                padding: 16px 0 0 0;
            }

            > div {
                text-align: center;
                cursor: pointer;

                @include max-screen(600px) {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    height: 100%;
                }
            }

            span {
                display: inline-block;
            }

            @include max-screen(47em) {
                background: #F4F4F4;
                height: 110px;
                position: fixed;
                box-shadow: none;
                @include border-radius(10px);

                span {
                    display: block;
                }
            }

            .pink-square {
                background: rgba(161, 12, 99, 0.1);
                border-radius: 4px;
                padding: 7px;
                font-size: 20px;
                width: 40px;
                height: 40px;
                margin: 0 auto;

                i {
                    color: $raspberry;
                }

            }

            .pink-square-text {
                color: $raspberry;
                font-weight: bold;
                font-size: 14px;
                margin: 0 0 0 5px;
                line-height: 40px;
                vertical-align: top;

                @include max-screen(47em) {
                    width: 80px;
                    margin: 5px 0 0;
                    line-height: initial;
                    vertical-align: initial;
                }
            }
        }

    }

    &[data-dirty="false"] {
        > header {
            @include min-screen(47em) {
            }
        }

        .global-search-browser {
            max-height: 0;
        }

        .doc-type-params {
            opacity: 0;
            display: none;
        }
    }

    .doc-type-params {
        @include transition(opacity .333s ease-in-out);
        opacity: 1;
    }

    .full-browser-keyword-search {
        margin: 0;
        @include transition(width .25s ease-in-out);
        flex-grow: 5;

        input {
            width: calc(100% - 40px);

            @include placeholder {
                text-transform: none;
                color: #85898C;
                font-size: 12px;
            }
        }

        &:before {
            left: 8px;
        }

    }

    .advanced-filters-btn {
        color: white;
        font-size: 20px;
        flex-shrink: 1;

        border: none;
        background: none;
        padding: 0 0 0 10px;
        vertical-align: middle;
        @include transition(opacity 0.33s ease-in-out);

        @include min-screen(47em) {
            color: $text-gray;
        }
    }

    .scan-barcode-btn {
        color: white;
        border: none;
        background: none;
        font-size: 20px;
        padding: 0 0px 0 10px;

        i {
            display: inline-block;
        }

        @include min-screen(47em) {
            color: $text-gray;
        }
    }

    .pink-square {
        .scan-barcode-btn {
            padding: 0;
        }
    }

    .global-search-browser {
        @include max-screen(47em) {
            padding: 80px 0 0 0;
        }
    }
}

.barcode-capture-form {
    padding: 20px;
    text-align: center;

    > .barcode-instuctions {
        max-width: 340px;
        margin: auto;
        font-size: 14px;
        text-align: center;
    }

    > .el-alert.error {
        max-width: 340px;
        margin: 10px auto;
        display: block;
    }

    > div {
        display: flex;
        justify-content: center;

        i {
            font-size: 40px;
            padding: 10px 15px 10px 10px;
        }

        input {
            width: 100%;
            margin: 10px 0;
            height: 40px;
            max-width: 260px;

            background-color: transparent;
            border: solid 0.5px $bluey-gray;
            font-size: 16px;
            color: darken($raspberry, 10%);
            padding: 8px;
            @include border-radius(10px);
            @include transition(all 0.25s ease-in-out);
            -webkit-appearance: none;
            min-width: 60px;

            box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);

            &:focus {
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
            }

            &:disabled {
                opacity: 0.5;
            }

            @include placeholder {
                font-style: italic;
                color: $el-grayish-blue2;
                font-size: 14px;
            }
        }
    }

}

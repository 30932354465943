
@import "../../../../sass/imports";


.login-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(135, 139, 158, 0.8);
    z-index: 10;

    opacity: 0;
    @include transition(all 0.333s ease-in-out);

    &.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

.login-modal {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
}

.login-form-scroll-container {
    overflow-y: auto;
    width: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    @include max-screen(47em) {
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.login-modal-container > .login-form {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 640px;
    padding: 0;
    background-color: white;

    @supports (padding-top: constant(safe-area-inset-top)) {
        padding-top: calc(20px + constant(safe-area-inset-top));
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: calc(20px + env(safe-area-inset-top));
    }

    @include min-screen(640px) {
        left: 50%;
        bottom: auto;
        background-color: transparent;
        @include border-radius(8px);
        @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.5));
        @include transform(translateX(-50%));
        overflow: hidden;
        margin-bottom: 50px;
        padding: 0;
        padding-top: 0;

        top: 95px;
        @supports (top: constant(safe-area-inset-top)) {
            top: calc(95px + constant(safe-area-inset-top)); /* iOS 11.0 */
        }
        @supports (top: env(safe-area-inset-top)) {
            top: calc(95px + env(safe-area-inset-top)); /* iOS 11.2 */
        }
    }

    &.register-form {
        max-width: 1120px;

        @include max-screen(1170px) {
            max-width: 880px;
        }

        @include max-screen(930px) {
            max-width: 640px;
        }

        @include min-screen(640px) {
            top: 45px;
            @supports (top: constant(safe-area-inset-top)) {
                top: calc(45px + constant(safe-area-inset-top)); /* iOS 11.0 */
            }
            @supports (top: env(safe-area-inset-top)) {
                top: calc(45px + env(safe-area-inset-top)); /* iOS 11.2 */
            }
        }
    }

    .login-form > .form-container {
        min-width: 340px;
    }
}

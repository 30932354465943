@import "../../../../../sass/imports";

.editor-dietitian-banner {
    margin: 0 0 20px 20px;
    padding: 0 0 20px 0;

    text-align: left;
    font-weight: 300;
    color: $text-navy;
    border-bottom: 1px solid $another-gray;

    @include min-screen(47em) {
        margin: 20px 0 20px 20px;
        padding: 20px 0 20px 0;

    }

    img {
        @include border-radius(50px);
        vertical-align: middle;
        margin: 0 20px;
        margin-left: 0;
        width: 50px;
        height: 50px;

        @include min-screen(47em) {
            vertical-align: middle;
        }
    }

    span {
        display: inline-block;
        max-width: calc(100% - 90px);
        font-size: 19px;
        vertical-align: middle;

        @include min-screen(47em) {
            font-size: 25px;
        }
    }

    em {
        font-weight: bold;
    }

    @include min-screen(47em) {
        text-align: center;
        margin: 40px;
    }
}

@import "../../../../../sass/imports";

.meal-plan-customizer .mobile-grid {
    .day-column {
        width: 200px;
    }

    .meal-types-column {
        width: 40px;
        margin-top: 56px;
        margin-left: 16px;
        display: inline-block;
        vertical-align: top;
    }

    .meal-types-row {
        border: 1px solid $light-gray-border;
        position: relative;
        background-color: #f5f7fa;
        text-align: left;
        margin-bottom: 14px;
        min-height: 149px;
        @include border-radius(4px);

        .meal-details {
            @include transition(all 0.3s ease-in-out);
            @include transform(rotate(90deg) translate(-50%, -50%));
            position: absolute;
            width: 127px;
            text-align: center;
            line-height: 1;
            left: 50%;
            top: 50%;
            transform-origin: 0 0;
            color: $text-gray;
            font-size: 11px;
            padding: 6px;
            font-weight: bold;
            text-transform: uppercase;

            > span {
                display: inline-block;
            }

            .range {
                font-weight: normal;
                margin-top: 3px;
            }
        }
    }

    .customizer-meal-cell {
        position: static !important;
    }

    .meals-column-viewport {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        transform: translate3d(0px, 0px, 0px);
        position: relative;
        width: calc(100% - 40px - 16px);
        display: inline-block;
        margin-top: 10px;
    }

    .meals-column-container {
        padding-left: 14px;
    }

    .meals-column-cell {
        display: table-cell;
        vertical-align: top;
    }

    .day-offset-header {
        border: 1px solid #e7e7e7;
        @include border-radius(4px);
        margin-bottom: 14px;
        margin-right: 14px;
        padding: 4px;
        text-align: center;
    }

    .meals-column-inner-container {
        border: 1px solid #e7e7e7;
        @include border-radius(4px);
        margin-right: 14px;
        margin-bottom: 14px;
        position: relative;
    }


    .day-offset-header .pro-dropdown-container {
        float: right;

        .triangle, .triangle2 {
            left: 85%;
        }

        .dropdown-dropdown {
            @include transform(translate(-85%,-5px));
            max-width: 230px;
        }

        .dropdown-btn {
            border: none;
            background: none;
            -webkit-appearance: none;
        }

        li {
            padding-left: 0;
        }
    }
}


@import "../../../sass/imports";

.household-member-modal-container {
    color: $text-gray;

    footer {
        display: flex;
        flex-direction: column;
    }

}

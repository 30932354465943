
@import "../../../sass/imports";

.taste-preferences, .cuisine-preferences {
    text-align: center;

    h3 {
        padding-bottom: 5px;
    }

    p {
        padding: 1em 5px;
        color: $text-navy;
        font-size: 14px;
    }

    .subtext {
        padding: 5px;
        font-size: 14px;
        line-height: 8px;

        @include max-screen(400px) {
            line-height: 14px;
        }

        &[data-pro="true"] {
            padding: 0;
            line-height: 15px;
        }
    }

    &.preferences-form {
        .preference-group {
            padding-top: 12px;
        }    
    }
}

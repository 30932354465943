
@import "../../../../sass/imports";
@import "../../MealDetails/SpringHero.scss";
@import '../../../pages/MealDetails.scss';

.food-details-editor-modal-container {
    .recipe-editor-footer {
        display: none !important;
    }
}


@import "../../../sass/imports";

.pro-dashboard {
    min-height: calc(100vh - 60px);
    padding-bottom: 5em;
    margin: auto;

    .dashboard-loading {
        text-align: center;
        margin-top: 10em;

        .icon-spinner {
            color: $raspberry;
            font-size: 40px;
            display: inline-block;
            margin: 40px auto;
        }
    }

    .col1, .col2, .col3 {
        z-index: 1;
        position: relative;
        text-align: left;
        display: inline-block;
        vertical-align: top;
    }

    .col1 {
        width: 285px;
        h2, em {
            color: $raspberry;
        }
    }

    .col2 {
        width: 350px;
        max-width: 100%;

        @include min-screen(47em) {
            width: 390px;
            padding: 0 0 0 40px;
        }
    }

    .col3 {
        width: 100%;

        @include min-screen(47em) {
            min-width: 350px;
            max-width: 390px;
            width: calc(100% - 275px - 390px);
            padding: 0 0 0 40px;
        }
    }

    .dashboard-mobile-header {
        display: flex;
        justify-content: space-evenly;
        padding: 0 0 50px;
        position: relative;
        z-index: 9;

        .dashboard-header-item {
            padding: 10px 0 0;
            background: #FFFFFF;
            box-shadow: 0px 20px 54px rgba(146, 146, 146, 0.1);
            width: 105px;
            height: 120px;
            position: relative;
            cursor: pointer;
            h2, p {
                padding: 0 10px 10px;
            }
            h2 {
                font-weight: bold;
                font-size: 18px;
                color: $raspberry;
            }
            p {
                font-size: 12px;
                color: black;
            }
            footer {
                position: absolute;
                bottom: 0;
                width: 100%;
                button {
                    width: 100%;
                    background: $raspberry;
                    font-size: 11px;
                    font-weight: bold;
                    color: #FEFEFE;
                    text-align: center;
                    text-transform: uppercase;
                    border: none;
                    padding: 5px;

                    i {
                        margin-right: 1px;
                    }

                }
            }
        }
    }

}

.pro-dashboard-container {
    margin: 20px;
    text-align: center;

    @include min-screen(1055px) {
        display: flex;
        justify-content: space-evenly;
    }

     @include max-screen(47em) {
        margin: 20px auto;
        width: 350px;
     }

    .leaf {
        position: fixed;
        color: #F1F1F1;

        &.desktop-left  {
            font-size: 250px;
            width: 235.45px;
            height: 259.69px;
            left: -30px;
            top: 300px;
            transform: matrix(-0.87, -0.49, -0.49, 0.87, 0, 0);
        }

        &.mobile-left  {
            font-size: 175px;
            width: 165.88px;
            height: 182.95px;
            left: -10px;
            bottom: -25px;
            transform: matrix(-0.95, 0.31, 0.31, 0.95, 0, 0);
        }

        &.desktop-right  {
            font-size: 425px;
            width: 411.99px;
            height: 454.4px;
            right: -20px;
            bottom: -100px;
            transform: matrix(-0.36, 0.93, 0.93, 0.36, 0, 0);
        }

        &.mobile-right  {
            font-size: 100px;
            width: 91.71px;
            height: 101.15px;
            right: -5px;
            top: 215px;
            transform: matrix(0, 1, 1, 0, 0, 0);
        }
    }

    .dashboard-pdf-booklet-notification {
        background-color: rgb(163, 0, 99, 0.05);
        height: 120px;
        font-size: 13px;
        color: $text-gray;
        padding: 0px 0px 0 15px;
        text-align: left;

        p, svg{
            display: inline-block;
        }

        svg {
            bottom: 30px;
            position: relative;
        }

        p {
            line-height: 16px;
            max-width: 230px;
            padding: 25px 0px 10px 15px;

            a {
                color: $raspberry;
            }
        }
    }
}

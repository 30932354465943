@font-face {
    font-family: 'feather';
    src: url('https://static.chewba.info/assets/fonts/feather/feather.ttf') format('truetype'),
         url('https://static.chewba.info/assets/fonts/feather/feather.woff') format('woff'),
         url('https://static.chewba.info/assets/fonts/feather/feather.svg#feather') format('svg');
    font-weight: normal;
    font-style: normal;
}

i.feather {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'feather' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feather-alert-octagon:before { content: $feather-alert-octagon; }
.feather-alert-circle:before { content: $feather-alert-circle; }
.feather-activity:before { content: $feather-activity; }
.feather-alert-triangle:before { content: $feather-alert-triangle; }
.feather-align-center:before { content: $feather-align-center; }
.feather-airplay:before { content: $feather-airplay; }
.feather-align-justify:before { content: $feather-align-justify; }
.feather-align-left:before { content: $feather-align-left; }
.feather-align-right:before { content: $feather-align-right; }
.feather-arrow-down-left:before { content: $feather-arrow-down-left; }
.feather-arrow-down-right:before { content: $feather-arrow-down-right; }
.feather-anchor:before { content: $feather-anchor; }
.feather-aperture:before { content: $feather-aperture; }
.feather-arrow-left:before { content: $feather-arrow-left; }
.feather-arrow-right:before { content: $feather-arrow-right; }
.feather-arrow-down:before { content: $feather-arrow-down; }
.feather-arrow-up-left:before { content: $feather-arrow-up-left; }
.feather-arrow-up-right:before { content: $feather-arrow-up-right; }
.feather-arrow-up:before { content: $feather-arrow-up; }
.feather-award:before { content: $feather-award; }
.feather-bar-chart:before { content: $feather-bar-chart; }
.feather-at-sign:before { content: $feather-at-sign; }
.feather-bar-chart-2:before { content: $feather-bar-chart-2; }
.feather-battery-charging:before { content: $feather-battery-charging; }
.feather-bell-off:before { content: $feather-bell-off; }
.feather-battery:before { content: $feather-battery; }
.feather-bluetooth:before { content: $feather-bluetooth; }
.feather-bell:before { content: $feather-bell; }
.feather-book:before { content: $feather-book; }
.feather-briefcase:before { content: $feather-briefcase; }
.feather-camera-off:before { content: $feather-camera-off; }
.feather-calendar:before { content: $feather-calendar; }
.feather-bookmark:before { content: $feather-bookmark; }
.feather-box:before { content: $feather-box; }
.feather-camera:before { content: $feather-camera; }
.feather-check-circle:before { content: $feather-check-circle; }
.feather-check:before { content: $feather-check; }
.feather-check-square:before { content: $feather-check-square; }
.feather-cast:before { content: $feather-cast; }
.feather-chevron-down:before { content: $feather-chevron-down; }
.feather-chevron-left:before { content: $feather-chevron-left; }
.feather-chevron-right:before { content: $feather-chevron-right; }
.feather-chevron-up:before { content: $feather-chevron-up; }
.feather-chevrons-down:before { content: $feather-chevrons-down; }
.feather-chevrons-right:before { content: $feather-chevrons-right; }
.feather-chevrons-up:before { content: $feather-chevrons-up; }
.feather-chevrons-left:before { content: $feather-chevrons-left; }
.feather-circle:before { content: $feather-circle; }
.feather-clipboard:before { content: $feather-clipboard; }
.feather-chrome:before { content: $feather-chrome; }
.feather-clock:before { content: $feather-clock; }
.feather-cloud-lightning:before { content: $feather-cloud-lightning; }
.feather-cloud-drizzle:before { content: $feather-cloud-drizzle; }
.feather-cloud-rain:before { content: $feather-cloud-rain; }
.feather-cloud-off:before { content: $feather-cloud-off; }
.feather-codepen:before { content: $feather-codepen; }
.feather-cloud-snow:before { content: $feather-cloud-snow; }
.feather-compass:before { content: $feather-compass; }
.feather-copy:before { content: $feather-copy; }
.feather-corner-down-right:before { content: $feather-corner-down-right; }
.feather-corner-down-left:before { content: $feather-corner-down-left; }
.feather-corner-left-down:before { content: $feather-corner-left-down; }
.feather-corner-left-up:before { content: $feather-corner-left-up; }
.feather-corner-up-left:before { content: $feather-corner-up-left; }
.feather-corner-up-right:before { content: $feather-corner-up-right; }
.feather-corner-right-down:before { content: $feather-corner-right-down; }
.feather-corner-right-up:before { content: $feather-corner-right-up; }
.feather-cpu:before { content: $feather-cpu; }
.feather-credit-card:before { content: $feather-credit-card; }
.feather-crosshair:before { content: $feather-crosshair; }
.feather-disc:before { content: $feather-disc; }
.feather-delete:before { content: $feather-delete; }
.feather-download-cloud:before { content: $feather-download-cloud; }
.feather-download:before { content: $feather-download; }
.feather-droplet:before { content: $feather-droplet; }
.feather-edit-2:before { content: $feather-edit-2; }
.feather-edit:before { content: $feather-edit; }
.feather-edit-1:before { content: $feather-edit-1; }
.feather-external-link:before { content: $feather-external-link; }
.feather-eye:before { content: $feather-eye; }
.feather-feather:before { content: $feather-feather; }
.feather-facebook:before { content: $feather-facebook; }
.feather-file-minus:before { content: $feather-file-minus; }
.feather-eye-off:before { content: $feather-eye-off; }
.feather-fast-forward:before { content: $feather-fast-forward; }
.feather-file-text:before { content: $feather-file-text; }
.feather-film:before { content: $feather-film; }
.feather-file:before { content: $feather-file; }
.feather-file-plus:before { content: $feather-file-plus; }
.feather-folder:before { content: $feather-folder; }
.feather-filter:before { content: $feather-filter; }
.feather-flag:before { content: $feather-flag; }
.feather-globe:before { content: $feather-globe; }
.feather-grid:before { content: $feather-grid; }
.feather-heart:before { content: $feather-heart; }
.feather-home:before { content: $feather-home; }
.feather-github:before { content: $feather-github; }
.feather-image:before { content: $feather-image; }
.feather-inbox:before { content: $feather-inbox; }
.feather-layers:before { content: $feather-layers; }
.feather-info:before { content: $feather-info; }
.feather-instagram:before { content: $feather-instagram; }
.feather-layout:before { content: $feather-layout; }
.feather-link-2:before { content: $feather-link-2; }
.feather-life-buoy:before { content: $feather-life-buoy; }
.feather-link:before { content: $feather-link; }
.feather-log-in:before { content: $feather-log-in; }
.feather-list:before { content: $feather-list; }
.feather-lock:before { content: $feather-lock; }
.feather-log-out:before { content: $feather-log-out; }
.feather-loader:before { content: $feather-loader; }
.feather-mail:before { content: $feather-mail; }
.feather-maximize-2:before { content: $feather-maximize-2; }
.feather-map:before { content: $feather-map; }
.feather-map-pin:before { content: $feather-map-pin; }
.feather-menu:before { content: $feather-menu; }
.feather-message-circle:before { content: $feather-message-circle; }
.feather-message-square:before { content: $feather-message-square; }
.feather-minimize-2:before { content: $feather-minimize-2; }
.feather-mic-off:before { content: $feather-mic-off; }
.feather-minus-circle:before { content: $feather-minus-circle; }
.feather-mic:before { content: $feather-mic; }
.feather-minus-square:before { content: $feather-minus-square; }
.feather-minus:before { content: $feather-minus; }
.feather-moon:before { content: $feather-moon; }
.feather-monitor:before { content: $feather-monitor; }
.feather-more-vertical:before { content: $feather-more-vertical; }
.feather-more-horizontal:before { content: $feather-more-horizontal; }
.feather-move:before { content: $feather-move; }
.feather-music:before { content: $feather-music; }
.feather-navigation-2:before { content: $feather-navigation-2; }
.feather-navigation:before { content: $feather-navigation; }
.feather-octagon:before { content: $feather-octagon; }
.feather-package:before { content: $feather-package; }
.feather-pause-circle:before { content: $feather-pause-circle; }
.feather-pause:before { content: $feather-pause; }
.feather-percent:before { content: $feather-percent; }
.feather-phone-call:before { content: $feather-phone-call; }
.feather-phone-forwarded:before { content: $feather-phone-forwarded; }
.feather-phone-missed:before { content: $feather-phone-missed; }
.feather-phone-off:before { content: $feather-phone-off; }
.feather-phone-incoming:before { content: $feather-phone-incoming; }
.feather-phone:before { content: $feather-phone; }
.feather-phone-outgoing:before { content: $feather-phone-outgoing; }
.feather-pie-chart:before { content: $feather-pie-chart; }
.feather-play-circle:before { content: $feather-play-circle; }
.feather-play:before { content: $feather-play; }
.feather-plus-square:before { content: $feather-plus-square; }
.feather-plus-circle:before { content: $feather-plus-circle; }
.feather-plus:before { content: $feather-plus; }
.feather-pocket:before { content: $feather-pocket; }
.feather-printer:before { content: $feather-printer; }
.feather-power:before { content: $feather-power; }
.feather-radio:before { content: $feather-radio; }
.feather-repeat:before { content: $feather-repeat; }
.feather-refresh-ccw:before { content: $feather-refresh-ccw; }
.feather-rewind:before { content: $feather-rewind; }
.feather-rotate-ccw:before { content: $feather-rotate-ccw; }
.feather-refresh-cw:before { content: $feather-refresh-cw; }
.feather-rotate-cw:before { content: $feather-rotate-cw; }
.feather-save:before { content: $feather-save; }
.feather-search:before { content: $feather-search; }
.feather-server:before { content: $feather-server; }
.feather-scissors:before { content: $feather-scissors; }
.feather-share-2:before { content: $feather-share-2; }
.feather-share:before { content: $feather-share; }
.feather-shield:before { content: $feather-shield; }
.feather-settings:before { content: $feather-settings; }
.feather-skip-back:before { content: $feather-skip-back; }
.feather-shuffle:before { content: $feather-shuffle; }
.feather-sidebar:before { content: $feather-sidebar; }
.feather-skip-forward:before { content: $feather-skip-forward; }
.feather-slack:before { content: $feather-slack; }
.feather-slash:before { content: $feather-slash; }
.feather-smartphone:before { content: $feather-smartphone; }
.feather-square:before { content: $feather-square; }
.feather-speaker:before { content: $feather-speaker; }
.feather-star:before { content: $feather-star; }
.feather-stop-circle:before { content: $feather-stop-circle; }
.feather-sun:before { content: $feather-sun; }
.feather-sunrise:before { content: $feather-sunrise; }
.feather-tablet:before { content: $feather-tablet; }
.feather-tag:before { content: $feather-tag; }
.feather-sunset:before { content: $feather-sunset; }
.feather-target:before { content: $feather-target; }
.feather-thermometer:before { content: $feather-thermometer; }
.feather-thumbs-up:before { content: $feather-thumbs-up; }
.feather-thumbs-down:before { content: $feather-thumbs-down; }
.feather-toggle-left:before { content: $feather-toggle-left; }
.feather-toggle-right:before { content: $feather-toggle-right; }
.feather-trash-2:before { content: $feather-trash-2; }
.feather-trash:before { content: $feather-trash; }
.feather-trending-up:before { content: $feather-trending-up; }
.feather-trending-down:before { content: $feather-trending-down; }
.feather-triangle:before { content: $feather-triangle; }
.feather-type:before { content: $feather-type; }
.feather-twitter:before { content: $feather-twitter; }
.feather-upload:before { content: $feather-upload; }
.feather-umbrella:before { content: $feather-umbrella; }
.feather-upload-cloud:before { content: $feather-upload-cloud; }
.feather-unlock:before { content: $feather-unlock; }
.feather-user-check:before { content: $feather-user-check; }
.feather-user-minus:before { content: $feather-user-minus; }
.feather-user-plus:before { content: $feather-user-plus; }
.feather-user-x:before { content: $feather-user-x; }
.feather-user:before { content: $feather-user; }
.feather-users:before { content: $feather-users; }
.feather-video-off:before { content: $feather-video-off; }
.feather-video:before { content: $feather-video; }
.feather-voicemail:before { content: $feather-voicemail; }
.feather-volume-x:before { content: $feather-volume-x; }
.feather-volume-2:before { content: $feather-volume-2; }
.feather-volume-1:before { content: $feather-volume-1; }
.feather-volume:before { content: $feather-volume; }
.feather-watch:before { content: $feather-watch; }
.feather-wifi:before { content: $feather-wifi; }
.feather-x-square:before { content: $feather-x-square; }
.feather-wind:before { content: $feather-wind; }
.feather-x:before { content: $feather-x; }
.feather-x-circle:before { content: $feather-x-circle; }
.feather-zap:before { content: $feather-zap; }
.feather-zoom-in:before { content: $feather-zoom-in; }
.feather-zoom-out:before { content: $feather-zoom-out; }
.feather-command:before { content: $feather-command; }
.feather-cloud:before { content: $feather-cloud; }
.feather-hash:before { content: $feather-hash; }
.feather-headphones:before { content: $feather-headphones; }
.feather-underline:before { content: $feather-underline; }
.feather-italic:before { content: $feather-italic; }
.feather-bold:before { content: $feather-bold; }
.feather-crop:before { content: $feather-crop; }
.feather-help-circle:before { content: $feather-help-circle; }
.feather-paperclip:before { content: $feather-paperclip; }
.feather-shopping-cart:before { content: $feather-shopping-cart; }
.feather-tv:before { content: $feather-tv; }
.feather-wifi-off:before { content: $feather-wifi-off; }
.feather-minimize:before { content: $feather-minimize; }
.feather-maximize:before { content: $feather-maximize; }
.feather-gitlab:before { content: $feather-gitlab; }
.feather-sliders:before { content: $feather-sliders; }
.feather-star-on:before { content: $feather-star-on; }
.feather-heart-on:before { content: $feather-heart-on; }
.feather-archive:before { content: $feather-archive; }
.feather-arrow-down-circle:before { content: $feather-arrow-down-circle; }
.feather-arrow-up-circle:before { content: $feather-arrow-up-circle; }
.feather-arrow-left-circle:before { content: $feather-arrow-left-circle; }
.feather-arrow-right-circle:before { content: $feather-arrow-right-circle; }
.feather-bar-chart-line-:before { content: $feather-bar-chart-line-; }
.feather-bar-chart-line:before { content: $feather-bar-chart-line; }
.feather-book-open:before { content: $feather-book-open; }
.feather-code:before { content: $feather-code; }
.feather-database:before { content: $feather-database; }
.feather-dollar-sign:before { content: $feather-dollar-sign; }
.feather-folder-plus:before { content: $feather-folder-plus; }
.feather-gift:before { content: $feather-gift; }
.feather-folder-minus:before { content: $feather-folder-minus; }
.feather-git-commit:before { content: $feather-git-commit; }
.feather-git-branch:before { content: $feather-git-branch; }
.feather-git-pull-request:before { content: $feather-git-pull-request; }
.feather-git-merge:before { content: $feather-git-merge; }
.feather-linkedin:before { content: $feather-linkedin; }
.feather-hard-drive:before { content: $feather-hard-drive; }
.feather-more-vertical-:before { content: $feather-more-vertical-; }
.feather-more-horizontal-:before { content: $feather-more-horizontal-; }
.feather-rss:before { content: $feather-rss; }
.feather-send:before { content: $feather-send; }
.feather-shield-off:before { content: $feather-shield-off; }
.feather-shopping-bag:before { content: $feather-shopping-bag; }
.feather-terminal:before { content: $feather-terminal; }
.feather-truck:before { content: $feather-truck; }
.feather-zap-off:before { content: $feather-zap-off; }
.feather-youtube:before { content: $feather-youtube; }

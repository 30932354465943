
@import "../../../sass/imports";


.expander-container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 1em;

    .expander-btn {
        width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border: none;
        background: none;
        padding: 7px 10px;
        text-align: left;

        font-size: 14px;
        line-height: 1.33;
        color: #425569;
        text-transform: uppercase;

        &:after {
            @include icon-font;
            display: inline-block;
            content: $icon-chevron-right;
            float: right;
            @include transition(all .333s ease-in-out);
        }
    }

    .expander-expander {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        @include transition(all .333s ease-in-out);

        .expander-content {
            padding: 20px;
        }
    }

    &[data-state="true"] {
        .expander-btn:after {
            @include transform(rotateZ(90deg));
        }

        .expander-expander {
            opacity: 1;
            max-height: 99em;
            overflow: visible;
        }
    }

    &[data-collapsible="false"] {
        .expander-btn:after {
            display: none;
        }
    }
}


@import "../../../../sass/imports";
@import "./LogPortionsModal.scss";

.leftover-offsets-container {
    .checkbox-btn {
        background: none;
        border: none;

        &:before {
            content: ' ';
            float: left;
            width: 30px;
            height: 30px;
            border: 1px solid $bluey-gray;
            @include border-radius(30px);
            margin: 0 12px 10px 0;
        }

        &[data-selected="true"] {
            &:before {
                background-image: radial-gradient(circle at 50% 50%, #57f1b9, #14bd80);
            }
        }
    }

    &.log-portions-modal-container {
        > footer {
            padding: 20px;
        }
    }

    .log-portions-body {
        padding: 20px;

        > p {
            margin: 0 0 1em 0;
        }
    }

    .cancel-btn {
        @include outline-button($bluey-gray);
    }
}

@import "../../../../sass/imports";

.collection-details-modal {
}

.collection-editor-modal-container {
    @include min-screen(47em) {
    	min-width: 750px;
    }

    @include max-screen(465px) {
        footer {
            &[data-three="true"] {
                button {
                    padding: 6px 18px
                }

                &[data-pro="true"] {
                    button {
                        padding: 6px 10px
                    }
                }
            }        
        }
    }

    .notice {
        position: relative;
        left: 40px;
        font-size: 16px;
        color: $el-green4;
        white-space: nowrap;

        &[data-error="true"] {
            color: red;
        }
    }

}

.el-list-form {
    .with-label {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #edeff2;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;

        > label {
            display: inline-block;
            padding: 10px 0;
            font-weight: bold;
            font-size: 14px;
            line-height: 26px;
            color: $dark-slate-gray;
            flex: 1 1;
            @include min-screen(47em) {
                font-size: 16px;
            }
            em {
                font-weight: 300;
            }
        }

        > input {
            border: none;
            display: inline-block;
            line-height: 40px;
            text-align: right;
            color: $raspberry;
            font-size: 14px;
            background-color: inherit;
            flex-grow: 1;

            @include min-screen(47em) {
                font-size: 16px;
            }

            @include placeholder {
                color: #81909F;

            }
        }

        > input[type="number"] {
            float: none;
            text-align: right;
            font-family: monospace;
            font-weight: bold;
            color: $raspberry;
            font-size: 14px;
            background-color: inherit;
            flex-grow: 0;

            @include min-screen(47em) {
                font-size: 16px;
            }
        }

        > textarea {
            width: 100%;
            border: none;
            font-size: 15px;
            font-weight: normal;
            letter-spacing: 0.25px;
            padding: 13px;
            color: $text-gray;
            -webkit-appearance: none;
            border: 1px solid #EDEFF2;
            min-height: 15em;
            @include border-radius(7px);

            &:focus {
                @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
            }

            @include placeholder {
                text-transform: uppercase;
                font-size: 13px;
            }
        }

        .el-select-container {
            background: #fff;
            border-radius: 0;
            box-shadow: none;
            display: inline-block;
            position: relative;
            width: auto;
            flex-grow: 1;
            color: $raspberry;
            background-color: inherit;

            .select-dropdown {
                right: 0;
                width: max-content;
                left: auto;
            }

            &[data-state="true"] {
                border: 0px !important;
            }

            &[data-focus="true"] {
                border: 0px !important;
            }

            > p {
                text-align: right;
            }

            .value {
                color: $raspberry;
                padding: 5px 20px;
            }

            .chevron {
                padding: 0;
            }
        }

        > em {
            position: absolute;
            bottom: 5px;
            right: 15px;
            @include transform(translateY(-50%));

            font-size: 10px;
            text-transform: uppercase;
            color: $text-gray;
        }

        .explanation {
            .triangle, .triangle2 {
                left: 50%;
            }

            .dropdown-btn {
                color: $raspberry;
                padding: 0 3px;
                border: none;
                background: none;
            }

            .dropdown-dropdown {
                @include transform(translate(-50%, -5px));
            }

            .dropdown-content {
                min-width: 250px;

                p {
                    color: $text-navy;
                    font-size: 14px;
                    margin: 0.75em 0;
                }
            }
        }

        &[data-error="true"] {
            border-bottom: 1px solid #E51919;

            label {
                color: #E51919;
            }
        }
    }

    .error-msg {
        text-align: left;
    }
}

@import "../../../../sass/imports";
@import "../../../pro/components/Plans/Editor/DietitianBanner.scss";

.collection-banner {
    &.editor-dietitian-banner {
        margin: 0 10px 20px;
        padding: 0 0 20px;

        @include min-screen(47em) {
            margin: 0 20px 20px;
        }
    }
}

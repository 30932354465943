@import "../../../sass/imports";


.add-recipe-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .add-recipe-form {
        padding: 40px 20px;

        button {
            margin: 20px 0;
        }

        .el-labeled-input {
            label {
                text-align: center;
                font-size: 18px;
                font-weight: 700;
            }

            input {
                color: $dark-magenta;

                &::placeholder {
                    color: $text-gray;
                }
            }

            .el-alert.warning {
                width: 100%;
            }
        }

        .loading-container {
            margin: 30px auto;
            display: block;
            text-align: center;

            .icon-spinner2 {
                font-size: 75px;
                display: inline-block;
                text-align: center;
            }

            .loading-text {
                color: $el-raspberry;
                display: block;
                margin-top: 20px;
            }
        }

        > footer {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;

            .el-link-btn {
                @include outline-button($raspberry);
                @include border-radius(0);
                font-size: 10px;
                padding: 8px 15px;
                border-width: 0px;
                text-decoration: underline;
                margin: 5px;
            }
        }
    }

    .toggle-btn-group {
        width: 290px;
        height: 39px;
        margin: 20px auto;
        text-align: center;
        white-space: nowrap;
        border: 1px solid $raspberry;
        overflow: hidden;
        @include border-radius(5px);
        @include box-shadow(0, 2px, 4px, 0, rgba(0,0,0,.1));

        button {
            border: none;
            color: $raspberry;
            background-color: white;
            width: 50%;
            max-width: 200px;
            font-size: 15px;
            padding: 8px 0;

            &[data-active="true"] {
                color: white;
                background-color: $raspberry;
            }
        }
    }

    &[data-has-toggles="true"] {
        .recipe-editor {
            max-height: calc(100% - 80px);
        }
    }
}

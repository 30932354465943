
@import "../../../../../../sass/imports";

.card-abstract {
    display: inline-block;
    vertical-align: top;

    max-width: 319px;
    min-width: 250px;
    width: calc(100% - 20px);
    margin: 15px;

    position: relative;

    .card-image {
        width: 289px;
        height: 214px;

        position: absolute;
        top: 0px;
        left: 50%;
        z-index: 3;
        background-image: linear-gradient(120deg, $bluey-gray, #f0f0f0);
        background-size: 100% auto;
        background-position: 0% 50%;
        @include transform(translateX(-50%));
        @include border-radius(6px);
        @include transition(all 0.25s ease-in-out);
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));
    }

    .card-overlay {
        position: absolute;
        width: 289px;
        max-height: 214px;

        top: 112px;
        left: 50%;
        @include transform(translate(-50%, -50%));

        z-index: 5;

        color: white;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        line-height: 1.5;
        letter-spacing: 2px;

        text-shadow: 0px 0px 5px black;
    }

    .card-info {
        position: relative;
        width: 100%;

        z-index: 2;
        padding: 62% 0 0 0;
        margin: 30px 0 10px 0;

        background-color: white;
        @include transition(all 0.25s ease-in-out);
        @include border-radius(4px);
        @include box-shadow(0, 10px, 20px, 0, rgba(0, 0, 0, 0.1));

        h3 {
            text-align: center;
            font-size: 17px;
            color: $text-navy;
            margin: 0 10px;
            overflow: hidden;
            height: 4em;
            font-weight: normal;
        }

        button {
            border: none;
            background-color: $orange4;
            padding: 13px;
            width: calc(100% - 40px);
            margin: 20px;
            color: white;
            font-size: 12px;
            text-transform: uppercase;
            @include border-radius(22.5px);
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(255, 119, 53, 0.2);

            .icon-lock {
                display: inline-block;
                margin: 0 5px;
                font-size: 12px;
            }
        }
    }

    .provider-logo {
        display: block;
        height: 21px;
        margin: auto;
    }
}


@import "../../../../../../sass/imports";

.add-visit-container {

    .visit-notes {
        position: absolute;
        top: 102px;
        bottom: 170px;
        left: 40px;
        right: 40px;

        border: 1px solid $inactive-text-gray;

        > textarea {
            position: absolute;
            top: 0; bottom: 0;
            left: 0;
            width: 100%;
            font-size: 12px;
            color: $text-gray;
            padding: 17px;
            border: none;
            resize: none;
        }
    }

    .visit-provider-id {
        position: absolute;
        height: 40px;
        bottom: 110px;
        left: 40px;
        right: 40px;

        border: 1px solid $inactive-text-gray;

        > input {
            position: absolute;
            top: 0; bottom: 0;
            left: 0;
            width: 100%;
            font-size: 12px;
            color: $text-gray;
            padding: 17px;
            border: none;
        }
    }
}

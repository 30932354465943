
@import "../../../../sass/imports";
@import "../../../../sass/style-guide";

.set-password-form {
    position: relative;
    text-align: center;
    color: $text-navy;

    .working {
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        padding-top: 15%;

        background-color: white;
        z-index: 1;

        i {
            display: inline-block;
            color: $raspberry;
            font-size: 35px;
        }
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
    }

    footer {
        p {
            margin: 10px;
        }
    }

    .password-header p {
        white-space: normal;
    }

    a {
        color: $raspberry;
    }

    .error-msg {
        color: red;
        font-weight: bold;
    }
}

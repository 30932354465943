@import "../../../../sass/imports";

.tooltip-container {
    position: relative;
    display: inline-block;
    max-width: 100%;
    width: inherit;

    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        cursor: pointer;
        position: relative;
    }

    .text-ellipsis.show-tooltip {
        cursor: pointer;
    }

    .tooltip {
        position: absolute;
        bottom: 100%;
        background-color: $el-grayish-blue4;
        color: $el-white;
        padding: 5px;
        border-radius: 3px;
        z-index: 999;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        margin-bottom: 5px;
        text-wrap: wrap;
    }

    .tooltip::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent $el-grayish-blue4 transparent;
        transform: rotate(180deg);
    }

    .text-ellipsis.show-tooltip:hover + .tooltip {
        opacity: 1;
    }
}


@import "../../../sass/imports";

.global-search-filters-mobile {
    padding: 0 20px;
}

.global-search-filters {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05), inset 0 -0.5px 0 0 rgba(203, 203, 203, 0.5);
    background-color: #ffffff;
    max-width: 940px;
    margin: auto;

    @include max-screen(1011px) {
        margin: auto 20px;
    }

    .filters {
        padding-left: 15px;
        text-align: center;
    }

    .dropdown-btn {
        border: none;
        background: none;
        padding: 20px 8px;
        display: inline-block;

        color: #425569;

        font-size: 12px;
        line-height: 1.33;

        i {
            display: inline-block;
            font-size: 11px;
            padding: 0 .5em;
        }
    }

    .dropdown-content {
        text-align: left;
    }

    > footer {
        text-align: center;
        display: block;
        border-top: solid 0.5px #e1e1e1;
    }

    .my-library-toggle {
        max-width: 12em;
        padding: 8px;
        display: inline-block;
        vertical-align: top;
    }

    .global-search-show-advanced-options {
        display: inline-block;

        button {
            border: none;
            background: none;
            text-align: center;
            font-size: 9px;
            line-height: 1.33;
            letter-spacing: 0.8px;
            color: #a30063;
            padding: 24px 15px;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .global-search-hidable-filter {
        display: inline-block;
        vertical-align: top;

        .dropdown-container > button {
            display: inline-block;
        }
    }
}

.global-search-hideable-filter {
    .dropdown-container > button, .expander-container > button {
        opacity: 0.3;

        @include transition(all .5s ease-in-out);
    }

    &[data-active="true"] {
        .dropdown-container > button, .expander-container > button  {
            opacity: 1;
        }
    }
}

.global-search-filter-btn {

    em {
        display: inline-block;
        width: 16px;
        height: 16px;
        padding-top: .25em;
        background-color: #2cd296;
        @include border-radius(100%);
        color: white;
        font-size: 9px;
        font-weight: bold;

        max-width: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;

        @include transition(all .333s ease-in-out);

        &[data-active="true"] {
            max-width: 16px;
            max-height: 16px;
            opacity: 1;
        }
    }
}

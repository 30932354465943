@import "../../../../sass/imports";

.groceries-control {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .feather-loader {
        animation-duration: 1s;
        animation-iteration-count: 120;
        animation-name: spinning;
        animation-timing-function: linear;
    }

    .groceries-btn {
        background-color: $orange4;

        &.added-to-groceries {
            background-color: lighten($orange4, 20%);
            padding: 0;
            color: white;
            font-size: 10px;

            .alert {
                background-image: linear-gradient(115deg, #2cd296, #18c092);
            }
        }

        &.groceries-purchased {
            background-color: $text-gray;
            padding: 0;
            color: white;
            font-size: 10px;

            .alert {
                background-image: linear-gradient(115deg, #8aa1b6, #5a6d82);
            }

            i.icon-check {
                border: 1px solid white;
                font-size: 18px;
                padding: 4px;
                @include border-radius(40px);
            }
        }

        .alert {
            position: absolute;
            border: none;
            @include box-shadow(0, 10px, 20px, 0, rgba(0, 0, 0, 0.1));
            @include border-radius(5px);
            background-image: linear-gradient(115deg, #ff7735, #ed6417);
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            padding: 5px 9px;

            font-size: 13px;
            color: white;
            font-weight: bold;
            white-space: normal;
            text-align: center;
            line-height: 1;
            letter-spacing: normal;

            min-width: 70px;
        }
    }

    .grocery-helper-text {
        position: absolute;
        z-index: 10;
        bottom: 0px;
        left: 50%;
        @include transform(translate(-50%, 55px));

        > p,
        .dropdown-btn > p {
            font-size: 9px;
            line-height: 1.56;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            text-transform: uppercase;
        }

        .added-to-groceries {
            color: #ff7735;
        }

        .groceries-purchased {
            color: $text-gray;
        }

        &.grocery-order-popup {
            .popup-dropdown {
                @include transform(translate(-50%, -70px));
            }

            .popup-content {
                min-width: 240px;
            }

            .dropdown-btn {
                border: none;
                background: none;
            }

            .feather-info {
                color: $raspberry;
                font-size: 16px;
                display: inline-block;
                margin: 0 0 0 5px;
            }
        }
    }


}

.grocery-btn-style-1 {
    .groceries-are-purchased {
        &.grocery-order-popup {
            .popup-dropdown {
                @include transform(translate(-50%, -32px));
            }
        }
    }

    .groceries-btn {
        display: inline-block;
        position: absolute;
        z-index: 2;
        border: none;
        text-align: center;
        width: 65px;
        height: 65px;
        margin: 0;
        bottom: 0;
        left: 50%;

        padding: 0;
        @include transform(translate(-50%, 50%));
        @include border-radius(10em);
        @include transition(background-color 0.25s ease-in-out);

        &.groceries-purchased {
            &:hover,
            &:focus {
                background-color: darken($text-gray, 10%);
            }
        }

        &.added-to-groceries {
            &:hover,
            &:focus {
                background-color: lighten($orange4, 10%);
            }
        }

        i {
            font-size: 30px;
            margin-right: 0;
            color: white;
        }

        &:hover,
        &:focus {
            background-color: darken($orange4, 10%);
        }
    }
}

.grocery-btn-style-2 {
    .groceries-btn {
        width: 52px;
        height: 52px;
        @include border-radius(52px);
        border: none;
        color: white;
        font-size: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.added-to-groceries {
            font-size: 25px;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                0 4px 4px 0 rgba(255, 119, 53, 0.2);
        }

        &.groceries-purchased {
            background-color: $josh-blue-gray;
            padding: 0 0 3px 0;
            font-size: 25px;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                0 4px 4px 0 rgba(168, 172, 190, 0.2);
        }
    }
}

@import "imports";


/**
 * Style Guide classes: TEMPORARY LOCATION: should be moved to docs/sass classes
 */

 .el-pro-fonts {
    color: $el-grayish-blue;

    h1 {
        font-size: 48px;
        margin: 0 0 10px 0;
    }

    h2 {
        font-size: 38px;
        margin: 0 0 8px 0;
    }

    h3 {
        font-size: 32px;
        margin: 0 0 4px 0;
    }

    h4 {
        font-size: 24px;
        margin: 0 0 4px 0;
    }

    h5 {
        font-size: 20px;
        margin: 0 0 4px 0;
    }

    h6 {
        font-size: 16px;
        margin: 0 0 4px 0;
    }

    .t1 {
        font-size: 22px;
    }

    .t2 {
        font-size: 18px;
    }

    .t3 {
        font-size: 14px;
    }

    p, .p1 {
        font-size: 12px;
    }

    .p2 {
        font-size: 14px;
    }

    .p3 {
        font-size: 16px;
    }

    .p4 {
        font-size: 18px;
    }
 }

.el-pro-form {
    .error-msg, .success-msg {
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        margin: 8px 0;
    }

    .error-msg {
        color: $el-error;
    }

    .success-msg {
        color: $el-success;
    }
}

// inputs
.el-pro-labeled-input {
    > label {
        display: block;
        font-size: 14px;
        line-height: 26px;
        margin: 0 0 6px 0;
    }
}

.el-pro-labeled-input, .el-pro-unlabeled-input {
    > input[type="text"], textarea {
        width: 100%;
        box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
        border-radius: 10px;
        background-color: $el-white;
        border: 1px solid transparent;

        font-size: 14px;
        line-height: 26px;
        padding: 7px 10px;

        &:focus {
            border: 1px solid $el-raspberry !important;
        }

        &:disabled {
            background-color: $el-gray4;
            color: $el-gray2;
            border: 1px solid transparent;
        }

        @include placeholder {
            color: #98A2BA; // @todo COLOR IS NOT IN PALETTE
        }
    }

    &[data-error="true"] {
        > input[type="text"], textarea {
            border: 1px solid $el-error;
            background-color: rgba($el-error, 0.1);
        }

        > p {
            color: $el-error;
            font-size: 12px;
            line-height: 26px;
            margin: 4px 0 0 10px;
        }
    }

    &[data-complete="true"] {
        > input[type="text"], textarea {
            border: 1px solid $el-success;
            background-color: rgba($el-success, 0.1);
        }

    }
}

// Circle Icons
.el-pro-small-circle-icon {
    color: white;
    background-color: $el-green;
    border-radius: 50px;
    padding: 5px;
    font-size: 25px;
}


// buttons
.el-pro-text-btn {
    font-size: 16px;
    line-height: 23px;
    border: none;
    background: none;
    text-decoration: underline;
    color: $el-raspberry;
}

.el-pro-large-btn, .el-pro-medium-btn, .el-pro-small-btn {
    text-align: center;
    text-transform: uppercase;
    border-radius: 25px;
    margin: 0 10px;
    transition: border 0.25s, background-color 0.25s, color 0.25s;
    white-space: nowrap;

    @include min-screen(620px) {
        margin: 0 20px;
    }
}

.el-pro-btn-icon-left {
    i {
        display: inline-block;
        margin: 0 10px 0 0;
    }
}

.el-pro-btn-icon-right {
    i {
        display: inline-block;
        margin: 0 0 0 10px;
    }
}


.el-pro-btn-icon-alone {
    i {
        display: inline-block;
    }
}

.el-pro-full-width-btn {
    width: 100%;
}

.el-pro-wide-btn {
    @include min-screen(620px) {
        min-width: 200px;
    }
}

.el-pro-medium-wide-btn {
    @include min-screen(620px) {
        min-width: 150px;
    }
}

.el-pro-large-btn {
    font-size: 20px;
    line-height: 24px;
    padding: 8px 16px;
    text-align: center;
}

.el-pro-medium-btn {
    font-size: 14px;
    line-height: 17px;
    padding: 6px 16px;
    text-align: center;
}

.el-pro-small-btn {
    font-size: 12px;
    line-height: 14px;
    padding: 0 16px;
    text-align: center;
}

.el-pro-orange-btn {
    border: 1px solid $el-orange;
    background-color: $el-orange;
    color: $el-white;

    &:hover {
        background-color: $el-orange3;
        border: 1px solid $el-orange3;
    }

    &:focus {
        background-color: $el-orange4;
        border: 1px solid $el-orange4;
    }
}
.el-pro-green-btn {
    border: 1px solid $el-green;
    background-color: $el-green;
    color: $el-white;

    &:hover {
        background-color: $el-green3;
        border: 1px solid $el-green3;
    }

    &:focus {
        background-color: $el-green4;
        border: 1px solid $el-green4;
    }
}
.el-pro-raspberry-btn {
    border: 1px solid $el-raspberry;
    background-color: $el-raspberry;
    color: $el-white;

    &:hover {
        background-color: $el-raspberry3;
        border: 1px solid $el-raspberry3;
    }

    &:focus {
        background-color: $el-raspberry4;
        border: 1px solid $el-raspberry4;
    }
}
.el-pro-grayish-blue-btn {
    border: 1px solid $el-grayish-blue;
    background-color: $el-grayish-blue;
    color: $el-white;

    &:hover {
        background-color: $el-grayish-blue3;
        border: 1px solid $el-grayish-blue3;
    }

    &:focus {
        background-color: $el-grayish-blue4;
        border: 1px solid $el-grayish-blue4;
    }
}
.el-pro-gray-btn {
    border: 1px solid $el-grayish-blue2;
    background-color: $el-grayish-blue2;
    color: $el-grayish-blue4;

    &:hover {
        background-color: $el-grayish-blue3;
        border: 1px solid $el-grayish-blue3;
        color: white;
    }

    &:focus {
        background-color: $el-grayish-blue4;
        border: 1px solid $el-grayish-blue4;
        color: white;
    }
}
.el-pro-grayish-blue-outline-btn {
    border: 1px solid $el-grayish-blue;
    background-color: $el-white;
    color: $el-grayish-blue;

    &:hover {
        border: 1px solid $el-grayish-blue3;
        background-color: $el-grayish-blue3;
        color: white;
    }

    &:focus {
        border: 1px solid $el-grayish-blue4;
        background-color: $el-grayish-blue4;
        color: white;
    }
}
.el-pro-raspberry-outline-btn {
    border: 1px solid $el-raspberry;
    background-color: $el-white;
    color: $el-raspberry;

    &:hover {
        border: 1px solid $el-raspberry3;
        background-color: $el-raspberry3;
        color: white;
    }

    &:focus {
        border: 1px solid $el-raspberry4;
        background-color: $el-raspberry4;
        color: white;
    }
}
.el-pro-white-outline-btn {
    border: 1px solid $el-white;
    background-color: $el-grayish-blue;
    color: $el-white;

    &:hover {
        background-color: $el-white;
        color: $el-grayish-blue;
    }

    &:focus {
        background-color: $el-grayish-blue2;
        color: $el-grayish-blue;
    }
}

// toggles
.el-pro-flat-pill-toggle-btn {
    font-size: 12px;
    border: 1px solid $el-gray3;
    background-color: $el-gray3;
    color: $text-gray;

    transition: border 0.25s, background-color 0.25s, color 0.25s;
    padding: 6px 11px;
    border-radius: 25px;
    margin: 0 10px 10px 0;
    white-space: nowrap;

    &[data-active="true"] {
        color: $el-white;
        background-color: $el-grayish-blue;
        border: 1px solid $el-grayish-blue;
    }
}

// modals
.el-pro-modal {

}

.el-pro-modal-overlay {
    background: rgba(72, 80, 90, 0.9);
    backdrop-filter: blur(10px);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 10;

    opacity: 0;
    @include transition(all 0.333s ease-in-out);

    &.ReactModal__Overlay--after-open {
        opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
        opacity: 0;
    }
}

.el-pro-modal-container {
    > header {
        .el-pro-modal-close-x {
            position: absolute;
            top: 0;
            right: 0;


            border: none;
            background: none;
            padding: 10px;
        }
    }

    > footer {

    }
}

.el-pro-modal-body-container {
    max-width: 100vw;
}

/**
 * EL Modal Type Specific Classes
 */
.el-pro-modal1 {
    // @todo
}

.el-pro-modal2-scrollable {
    // @todo
}

.el-pro-modal2-body-container {
    // @todo
}




.el-pro-modal3-fixed {
    position: fixed;
    bottom: 0;
    left: 0;

    @include min-screen(400px) {
        top: 50%;
        left: 50%;
        bottom: auto;
        @include transform(translate(-50%, -50%));
    }

    .el-pro-modal-ok-btn, .el-pro-modal-cancel-btn {
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 25px;
        margin: 0 10px;
        transition: border 1s, background-color 1s, color 1s;

        @include min-screen(620px) {
            min-width: 200px;
            margin: 0 20px;
        }

        i {
            display: inline-block;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    .el-pro-modal-cancel-btn {
        border: 1px solid $el-grayish-blue;
        background-color: white;

        color: $el-grayish-blue;

        &:hover {
            background-color: $el-grayish-blue3;
            color: white;
            border: 1px solid $el-grayish-blue3;
        }

        &:focus {
            background-color: $el-grayish-blue4;
            color: white;
            border: 1px solid $el-grayish-blue4;
        }
    }

    .el-pro-modal-ok-btn {
        border: 1px solid $el-raspberry;
        background-color: $el-raspberry;

        color: white;

        &:hover {
            background-color: $el-raspberry3;
        }

        &:focus {
            background-color: $el-raspberry4;
        }
    }
}

.el-pro-modal3-container {
    background-color: white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 10px);
    min-width: 100vw;

    @include min-screen(400px) {
        border-radius: 10px;
        min-width: 400px;
    }

    @include min-screen(620px) {
        max-width: 600px;
        min-width: 420px;
    }

    @supports (max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))) {
        max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    }

    @supports (max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))) {
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }

    > header {
        min-height: 40px;
        padding: 20px 0 0 0;

        h2 {
            font-size: 24px;
            font-weight: bold;
            line-height: 29px;
            color: $el-grayish-blue;
            padding: 10px 40px;
            text-align: center;;
        }
    }

    > footer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 30px;
        text-align: center;
    }
}

.el-pro-modal3-body-container {
    padding: 20px;
}



// fancy boxes
.el-pro-fancy-box {
    padding: 30px 30px 0 30px;
    background: white;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 16px;

    footer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 30px;
        text-align: center;
    }
}

.el-pro-checkbox {
        background: none;
        border: none;
        display: block;
        position: relative;
        margin: 0.8em 0 0.8em 20px;
        padding-left: 10px;
        position: relative;
        border: none;
        background: none;
        text-align: left;

        &:before {
            position: absolute;
            left: -20px;
            background: #FFFFFF;
            @include feather-font;
            content: $feather-check;
            box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
            border-radius: 4px;
            height: 24px;
            width: 24px;
            display: inline-block;
            color: #FFFFFF;
            font-size: 22px;
            text-align: center;
        }

        &[data-locked="true"] {
            padding-left: 30px;
            &:after {
                @include icon-font;
                content: $icon-lock;
                position: absolute;
                line-height: 1.5;
                top: 0;
                left: 10px;
            }
        }

        &[data-checked="true"] {
            &:before {
                box-shadow: none;
                background: $mint-green3;
            }
        }  

  
}


@import "../../../sass/imports";

.global-search-paginator {
    text-align: center;
    padding: 20px;

    button {
        @include flat-button($text-gray);
        font-size: 12px;

        i {
            display: inline-block;
        }
    }
}


@import "../../../../sass/imports";

.global-search-checkbox {
    position: relative;
    display: block;
    margin: .5em 0;
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        @include opacity(0);
    }
    span {
        display: inline-block;
        font-size: 12px;
        color: #425569;

        &:before {
            font-size: 12px;
            @include icon-font;
            color: white;
            display: inline-block;
            content: ' ';
            padding-top: .25em;
            padding-left: .25em;
            width: 20px;
            height: 20px;
            margin-right: .5em;
            @include transition(.66s color);
            vertical-align: middle;

            border: solid 0.5px #e3e4e9;
        }
    }
    input:checked + span {
        &:before {
            border: solid 0.5px #1d976b;
            background-color: #2cd296;
            content: $icon-check;
        }
    }
}

.global-search-radio {
    span:before {
        @include border-radius(25em);
    }
}

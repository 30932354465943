.el-validation-list {
    margin-top: 5px;
    padding: 20px;
    text-align: left;

    .el-validation-title {
        display: flex;
        font-size: 14px;
        font-weight: 700;
    }

    .el-password-validations {
        display: flex;
        padding-top: 15px;

        p {
            font-size: 14px;
            font-weight: 400;
        }

        .feather-check {
            color: #12D574;
        }

        .feather-x {
            color: #FF1B1B;
        }
    }

    p {
        margin-top: 0px;
    }
}

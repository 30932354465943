@import "../../../sass/imports";

.food-editor {
    .food-nutrition {
        .food-nutrition-header {
            padding: 30px 0 0;
            @include min-screen(47em) {
                padding: 30px 70px 0 70px;
            }
        }
    }

    .el-list-form {
        .editor-scanning-text {
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin: 10px;

            p {
                font-size: 20px;
            }

            .icon-spinner {
                color: $raspberry;
                font-size: 40px;
            }
        }

        .recipe-fields {
            @include min-screen(47em) {
                padding: 0 70px;
            }
        }

        .image-editor {
            background-color: #edeff2;
            padding: 30px 0 120px;
            margin: 0 -10px -20px -10px;

            &[data-error="true"] {
                border: 1px solid $el-error;
            }

            .error-msg {
                color: $el-error;
            }

            p {
                width: 75%;
                text-align: center;
                margin: 0 auto 15px;
                font-size: 12px;
            }

            .submission-tips-toggle {
                color: #a30063;
                display: block;
                width: 60%;
                margin: 0 auto;
                text-align: center;

                .sub-action-btn {
                    text-transform: none;
                    text-decoration: none;
                    font-size: 14px;

                    @include outline-button($raspberry);
                    @include border-radius(0);
                    background-color: transparent;
                    font-size: 10px;
                    text-transform: uppercase;
                    padding: 8px 10px;
                    border-width: 0px;
                    text-decoration: underline;
                    text-align: center;
                    margin: 5px;
                }

                i {
                    font-size: 10px;
                    line-height: 46px;
                    vertical-align: bottom;
                }
            }

            .photo-tips {
                width: 90%;
                max-width: 400px;
                margin: 10px auto;
                color: $text-gray;
                font-size: 18px;
                padding: 20px;
                max-width: 500px;
                background-color: white;
                text-align: left;
                border: 0.5px solid $light-gray-border;
                @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.2));

                p {
                    text-align: center;
                }

                li {
                    list-style-type: disc;
                    margin: 0.5em 0 0.5em 1.5em;
                    font-size: 14px;
                }
            }

            .image-inputs {
                display: flex;
                justify-content: space-between;
                width: 90%;
                max-width: 400px;
                margin: 0 auto;
            }

            .image-input {
                display: inline-block;

                .edit-recipe-image {
                    float: none;
                    display: block;
                    border: none;

                    .new-image-btn {
                        width: 80px;
                        height: 80px;

                        > span {
                            .feather-camera, p {
                                color: $raspberry;
                            }
                        }
                    }

                    &.with-image-label {
                        background-color: #fff;
                        height: 80px;
                        width: 80px;
                        text-align: center;
                    }

                    > a > img {
                        width: 80px;
                        height: 80px;
                    }

                    > .file-upload {
                        z-index: 1;

                        button {
                            padding: 10px 0 0;
                            i {
                                top: 25px;
                            }

                            p {
                                margin: 30px auto 0;
                            }

                            .uploading-text {
                                > span {
                                    .feather-camera, p {
                                        color: $raspberry;
                                    }
                                }
                            }
                        }

                        &:hover {
                            button {
                                .uploading-text {
                                    i,
                                    p {
                                        color: $raspberry;
                                    }
                                }
                            }
                        }
                    }

                    > .file-upload button, .new-image-btn {
                        height: 80px;
                        width: 80px;
                        margin: 0 auto;

                        .uploading-text {
                            font-size: 12px;
                            bottom: 20px;

                            &[data-uploading="true"] {
                                i,
                                p {
                                    color: #4f6479;
                                }
                            }
                        }

                        i {
                            position: relative;
                            font-size: 20px;
                            color: #324253;
                        }

                        p {
                            font-size: 12px;
                            text-transform: none;
                            width: 80%;
                            text-align: center;
                            color: #324253;
                        }
                    }

                    .new-image-btn {
                        padding: 5px 0 0 0;
                    }
                }

                label {
                    font-size: 12px;
                    width: 80px;
                    text-align: center;
                    margin: 10px auto;
                    color: #324253;
                }
            }
        }

        .food-category, .package-size, .gtin-upc {
            position: relative;

            > label {
                display: inline-block;
                padding: 10px 0;
                vertical-align: top;
            }

            > .select-container {
                width: 200px;
                display: inline-block;
            }

            > input[type="number"] {
                width: 141px;
                padding: 9px 65px 9px 9px;
            }

            > input[type="text"] {
                width: 180px;
                @include min-screen(47em) {
                    width: 300px;
                }
            }

            > em {
                position: absolute;
                top: 45%;
                right: 10px;
                @include transform(translateY(-50%));

                font-size: 10px;
                color: $text-gray;

                &[data-units="metric"] {
                    text-transform: none;
                }
            }
        }

        .gtin-upc {
            > label {
                white-space: nowrap;

                > em {
                    font-size: 12px;
                }
            }
        }

        .food-category {
            .select-container {
                .value, .placeholder {
                    text-align: right;
                }
            }
        }

        .food-nutrition {
            label {
                display: inline-block;
                vertical-align: middle;

                > span {
                    .nutrition-header-text {
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 1.25;
                    }

                    p {
                        em {
                            font-size: 12px;
                            line-height: 1;
                        }
                    }
                }
            }

            .toggle-btn {
                min-width: 50px;
                font-size: 12px;
                padding: 10px 20px;
                margin: 0 10px 0 0;
                width: 150px;
                height: 54px;
                background: #edeff2;
                border-radius: 4px;
                border: 1px solid #edeff2;

                @include min-screen(47em) {
                    width: calc(50% - 10px);
                }

                i {
                    vertical-align: sub;
                    font-size: 20px;
                    line-height: 15px;
                    margin: 0 10px 0 0;
                }

                &[data-inactive="true"] {
                    background-color: white;
                    border: 0;
                }
            }

            .toggle-btn-group {
                white-space: nowrap;
            }
        }
    }

    .sizes-container {
        .with-label {
            margin: 0;
        }
    }

    .serving {
        > * {
            display: inline-block;
        }

        .amount {
            input[type="number"] {
                border-radius: 40px 0 0 40px;
                min-width: 60px;
                @include max-screen(47em) {
                    max-width: 60px;
                }
            }
        }

        .description {
            input[type="text"] {
                max-width: 60px;
            }
        }

        .serving-description {
            margin-left: 5px;
            max-width: 130px;
            text-align: left;

            @include max-screen(47em) {
                max-width: 100px;
            }
        }
    }

    .package-size {
        .sizes {
            display: inline-block;
        }
    }

    .sizes {
        .amount {
            flex-shrink: 1;
            max-width: 100px;
        }

        .description {
            flex-grow: 6;
        }

        .separator {
            flex-shrink: 1;
            padding: 0px 5px 0;
            font-size: 24px;
        }

        .size {
            flex-shrink: 1;
            min-width: 125px;
        }
    }

    .barcode-warning {
        padding-bottom: 200px;

        i {
            width: 150px;
            height: 150px;
            display: block;
            background: #f1f1f1;
            border-radius: 100px;
            display: block;
            font-size: 40px;
            margin: 0 auto;
            font-size: 66px;
            padding: 42px 42px;
        }

        label {
            width: 194px;
            height: 50px;
            background: #f1f1f1;
            border-radius: 10px;
            display: block;
            font-size: 14px;
            font-weight: bold;
            margin: 10px auto;
            text-align: center;
            padding: 13px 0;
        }
    }
}

.with-units {
    width: 100%;
    white-space: nowrap;

    input[type="number"] {
        width: calc(100% - 150px);
        margin: 0 5px 5px 0;
        text-align: right;
        font-family: monospace;
        max-width: 250px;
        @include max-screen(47em) {
            width: 150px;
        }
        min-width: 100px;
    }

    label {
        width: 120px;
        display: inline-block;
    }
}

.food-pics {
    .photo-tips {
        color: $text-gray;
        font-size: 18px;
        padding: 20px;
        max-width: 500px;
        background-color: white;
        text-align: left;
        border: 0.5px solid $light-gray-border;
        @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.2));

        p {
            text-align: center;
        }

        li {
            list-style-type: disc;
            margin: 0.5em 0 0.5em 1.5em;
            font-size: 14px;
        }
    }
}


@import "../../sass/imports";

.content-details-modal-container {
    @include min-screen(47em) {
        max-width: calc(100vw - 40px);
    }

    @include min-screen(1040px) {
        max-width: 1000px;
    }

    .el-modal1-body-container {
        padding: 0;

        > header {
            height: 60px;
            max-height: none;
        }
    }

    .el-modal-controls {
        position: absolute;
        top: 110px;
        left: 50%;
        transform: translateX(-50%);
    }

    // Move the gray curve at the top down a bit
    &:before {
        height: 290px;
        @include transform(translate(-50%, calc(-100% + 155px)));
    }

    .meals-scroll-hero {
        z-index: 0;

        @include max-screen(47em) {
            padding: 100px 0 0 0;
            min-height: calc(260px + 100px);
        }

        @include min-screen(47em) {
            padding: 15px 0 0 35px;
            min-height: calc(350px + 25px);

            background-image: none;
            background-color: white;
        }
    }

    .details-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        .icon-spinner2 {
            display: block;
            color: $raspberry;
            width: 50px;
            height: 50px;
            font-size: 50px;;
        }
    }

    .details-header {
        padding: 15px 42px 40px;
        position: relative;
        z-index: 1;
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.25));

        h6 {
            text-transform: uppercase;
            font-weight: normal;

            em {
                font-weight: 700;;
            }
        }

        > header {
            position: absolute;
            left: 50%;
            bottom: -22px;
            z-index: 5;
            @include transform(translate(-50%));
        }
    }

    .edit-meal-servings {
        margin: 10px 0 0 0;
        height: 44px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @include min-screen(47em){
            justify-content: flex-start;
        }
    }

    .timings {
        display: inline-block;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.17;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        margin: 0 5px 11px 0;
        color: white;
        text-align: left;
        white-space: nowrap;

        @include min-screen(47em) {
            color: #657e95;
        }
    }

    .hands-on-time {
        color: $text-navy;
        font-size: 12px;

        em {
            font-size: 13px;
        }
    }

    .serves {
        @include border-radius(6px);
        background: rgba(16, 17, 18, 0.31);;
        font-size: 9px;
        font-weight: bold;
        line-height: 1.17;
        letter-spacing: 0.9px;
        color: white;
        padding: 6px 11px;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 11px 11px 0;
        cursor: pointer;
        vertical-align: top;
        white-space: nowrap;
        text-align: center;
        width: calc(33% - 11px);
        max-width: 145px;

        @include transition(all 0.25s ease-in-out);

        @include min-screen(47em) {
            background: white;
            border: 1px solid #657e95;
            color: #657e95;
            @include border-radius(30px);
            width: 100px;
            font-weight: 900;

            &:hover {
                background-color: #657e95;
                color: white;
            }
        }

        em {
            font-size: 13px;
            font-weight: bold;
            line-height: 0.81;
            letter-spacing: 1.3px;
            vertical-align: -1px;
        }

        span {
            display: block;
            font-weight
            &:first-child {
                margin-bottom: 3px;
            }
        }
    }

    .control-bar-container {
        .el-toolbar-btn, .el-toolbar-popup .dropdown-btn {
            text-transform: uppercase;

            @include max-screen(47em) {
                i {
                    display: none;
                }
            }
        }

        .swap-popup {
            .popup-content {
                min-width: 275px;
            }

            h6 {
                color: $el-grayish-blue;
            }
        }

        .swap-popup-btn {
            border: 2px dashed green;
            width: 100%;

            font-size: 13px;
            font-weight: 700;
            text-align: left;
            color: $el-grayish-blue;

            background: none;
            border: 1px solid $el-grayish-blue1;
            padding: 5px 15px;
            margin: 5px 0;
            border-radius: 5px;
            transition: border 0.25s, background-color 0.25s, color 0.25s;

            &:hover {
                background-color: $el-grayish-blue2;
            }

            &:focus {
                background-color: $el-grayish-blue4;
                color: white;
            }
        }
    }

    .details-section {
        padding: 24px 20px 70px;

        > header {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include min-screen(47em) {
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
            }

            .t2, .t3, .t4 {
                text-align: left;
            }

            .with-merchant {
                .dropdown-btn {
                    border: none;
                    background: none;
                }
            }
        }

        .section-title {
            flex: 20 1;

            > p, .dropdown-btn {
                color: white;
                font-weight: normal;

                > span, .feather-info {
                    white-space: nowrap;
                    opacity: 0.6;
                }

                > em {
                    color: white;
                    opacity: 1;
                    font-weight: normal;
                }
            }
        }

        .section-body {
            padding: 32px 34px;
            background-color: white;
            margin-top: 21px;

            @include border-radius(8px);
            @include box-shadow(0, 1px, 4px, 0, rgba(0, 0, 0, 0.36));

            .food-units{
                display: flex;
                position: relative;
                align-items: center;
            }

            .price-per-serving {
                text-align: center;

                @include min-screen(47em) {
                    text-align: left;
                    padding: 0;
                }

                > p {
                    background-color: $el-blue4;
                    color: white;
                    border-radius: 3.7px;
                    display: inline-block;
                    padding: 4px 7px 3px;
                }
            }

            .recipe-instructions-container {

                h6 {
                    font-size: 20px;
                    margin: 30px 0 15px;
                }

                .time-header {
                    justify-content: left;
                }

            }

            .nutrition-title {
                font-size: 20px;
                text-align: center;
            }

            .description {
                margin: 0 0 10px 0;
                font-size: 16px;
            }
        }

        .nutrition-portion {
            display: flex;
            justify-content: center;
            margin: 0 0 20px 0;

            > * {
                margin: 0 5px;
            }

            .food-units-selector-outer .open-modal-btn {
                font-size: 16px;
            }
        }

        .meal-title {
            text-transform: uppercase;
            text-align: center;
            margin: 0;

            @include min-screen(47em) {
                text-align: left;
            }
        }

        .description {

        }

        .modifications {

        }

        &.first {
            background-image: linear-gradient(180deg, #da67ad, #b7498c);

            .image-inputs {
                display: flex;
                gap: 25px;
            }
        }

        &.second {
            background-image: linear-gradient(327deg, #e97a99, #eb959e);

            .section-body {
                .t3 {
                    margin-bottom: 15px;
                }
            }
        }

        &.third {
            background-image: linear-gradient(309deg, #137eab, #2eabe4);

            .section-body {
                min-height: 400px;
            }
        }

        &.fourth {
            background-image: linear-gradient(315deg, #bc9b60, #d2ba86);
        }

        &.fifth {
            background-image: linear-gradient(315deg, #bc9b60, #d2ba86);
        }
    }

    .meal-control-btns {
        display: flex;

        margin: 20px 0 0 0;

        @include min-screen(47em) {
            margin: 0;
        }

        button {
            color: white;
            text-transform: uppercase;
            font-size: 9px;
            border: none;
            background: none;
            padding: 0px 15px;
            font-weight: normal;
            text-decoration: none;
            display: inline-block;
            @include transition(color 0.25s ease-in-out);

            @include min-screen(47em) {
                font-size: 12px;
            }

            em {
                text-decoration: none;
            }

            i {
                display: block;
                margin: auto;
                font-size: 16px;
                vertical-align: middle;
                margin-bottom: 5px;
                color: white;
                padding: 2px;
                width: 20px;
                height: 20px;
            }

            &:hover {
                color: #ccc;
            }
        }
    }

    .ingredients-list {
        list-style: none;

        li {
            margin: 0 0 8px 0;
            font-size: 16px;
        }
    }

    .leftover-badge {
        padding: 5px 3px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        margin: 0 10px 10px 0;
        display: inline-block;
        @include border-radius(3px);
        @include background-image($leftover-card-gradient);
    }

    .merchant-dropdown {
        .dropdown-btn {
            border: none;
            background: none;
            white-space: normal;
        }

        .popup-content {
            max-width: 350px;
        }
    }

    .scaling-button-toggle {
        @include min-screen(47em) {
            position: absolute;
            top: 0;
            right: 0;
            max-width: 200px;
        }
    }

    .details-section .orders-container {
        background-color: $el-raspberry1;
        padding: 15px;
        @include border-radius(10px);
        margin: 0 auto 10px auto;
        display: flex;
        flex-direction: column;
        max-width: 571px;

        @include min-screen(600px) {
            align-items: flex-end;
            flex-direction: row;
        }

        .order-info {
            margin: 0 0 20px 0;

            @include min-screen(600px) {
                margin: 0 10px 0 0;
                max-width: 250px;
            }

            .t4 {
                text-transform: uppercase;
                margin: 0 0 5px 0;
            }

            p {
                display: block;
                text-align: left;
            }

            span {
                margin: 0 2px 0 0;
            }
        }

        > div {
            flex: 1;
        }

        a {
            color: $el-raspberry2;
        }
    }

}

.meal-details {
    .generic-tabs .tab-btn {
        text-transform: uppercase;
        max-width: 185px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:after {
            display: none;
        }
    }
}

.edit-meal-modal-container {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    min-width: 300px;
    width: calc(100vw - 10px);
    max-width: 550px;
    max-height: calc(100vh - 10px);
    overflow: hidden;

    @include max-screen(500px) {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: auto;
        max-height: none;
        @include transform(translate(0, 0));

        @supports (padding-top: constant(safe-area-inset-top)) {
            padding-top: constant(safe-area-inset-top);
            margin-bottom: constant(safe-area-inset-bottom);
        }
        @supports (padding-top: env(safe-area-inset-top)) {
            padding-top: env(safe-area-inset-top);
            margin-bottom: env(safe-area-inset-bottom);
        }
    }

    > header {
        background-color: white;
        @include box-shadow(0, 7px, 30px, 0, rgba(0,0,0,.1));

        h1 {
            color: $text-navy;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            padding: 10px;

            &.food-edit-header{
                position: absolute;
                top: 17px;
                left: 0;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 700;
            }
        }

        .top-half-circle {
            z-index: 2;
        }

        .close-modal-btn {
            color: white;
            display: inline-block;

            border: none;
            padding: 17px 20px 14px 20px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            background: none;
            position: relative;
            z-index: 20;

            i {
                font-size: 26px;
                vertical-align: middle;
                display: inline-block;
                margin: 0 20px 0 0;
                color: $bluey-gray;
            }
        }

        .header-container {
            position: relative;
            z-index: 2;
        }
    }

    > footer {
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: white;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        text-align: center;
        padding: 13px 13px 13px 13px;

        .error-msg {
            font-weight: bold;
            color: red;
            font-size: 14px;
        }
    }

    .modal-scroll-container {
        max-height: calc(100vh - 10px);
        overflow-y: auto;
        padding: 0 0 0 0;

        @supports (max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))) {
            max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
        }

        @supports (max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))) {
            max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        }
    }

    .recipe-editor {

        &.collection-editor {
            padding: 20px 20px 140px;
            max-height: 92vh;

            @include min-screen(47em) {
                padding: 20px 20px 140px 20px;
            }
        }
    }

    .recipe-editor, .custom-meal-form, .collection-editor, .modal-utils {
        > header {
            position: absolute;
            z-index: 5;

            top: 0;
            right: 0;
        }

        > footer {
            position: absolute;
            z-index: 10;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            background-color: white;
            @include box-shadow(0, -7px, 30px, 0, rgba(0,0,0,.1));

            @include min-screen(47em) {
                display: flex;
                justify-content: space-evenly;
            }

        }
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: hsla(0,0%,100%,.5);
        border-radius: 100%;
        font-size: 14px;
        padding: 8px 10px;
        margin: 5px 10px;
        z-index: 10;

        @supports (top: constant(safe-area-inset-top)) {
            top: constant(safe-area-inset-top);
        }
        @supports (top: env(safe-area-inset-top)) {
            top: env(safe-area-inset-top);
        }
    }


    .ok-btn {
        @include flat-button($raspberry);

        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
        text-align: center;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        padding: 12px 49px;
        margin: 0;

        @include min-screen(500px) {
            font-size: 14px;
        }

        &:disabled {
            opacity: 0.5;
        }
    }


}


@import "../../../../sass/imports";

.global-search-avoidances {

    @import '../../Preferences/AvoidanceSelect.scss';

    .select-bubbles {
        margin: 10px 0 0 0;

        > li {
            margin: 5px;
            width: calc(50% - 10px);
            max-width: none;
            padding: 0;
            display: inline-block;

            button {
                border-radius: 50px;
                background-color: rgba(255, 255, 255, 0.2);
                border: solid 1px $bluey-gray;
                width: 100%;
                font-size: 11px;
                font-weight: 900;
                text-transform: uppercase;
                padding: 8px 16px;
                vertical-align: top;
                margin: 0 auto;
                cursor: pointer;
                color: $bluey-gray;
                position: relative;
                @include transition(all .25s ease-in-out);

                &[data-active="true"] {
                    background-color: $raspberry;
                    border: solid 1px $raspberry;
                    color: white;
                }
            }
        }
    }
    .choose-diets,
    .choose-allergens {
        h3 {
            font-size: 16px;
        }

        p {
            font-size: 14px;
        }
    }

    .choose-diets {
        max-width: 400px;
        margin: auto;
        margin-bottom: 3em;

        .select-bubbles {
            li p {
                font-size: 12px;
                text-transform: uppercase;
                color: $bluey-gray;
            }
        }
    }

    .choose-allergens {
        max-width: 500px;
        margin: 0 auto 3em auto;

        .select-bubbles {

            > li {
                width: calc(33% - 10px);
                min-width: 100px;
            }

            button {
                &[data-active="true"] {
                    background-color: $katherine-gray !important;
                    border: solid 0.5px darken($katherine-gray, 10%) !important;
                    color: white !important;
                    position: relative;

                    &:after {
                        @include icon-font;
                        content: $icon-x;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        @include transform(translate(-50%, -50%));
                        display: inline-block;
                        font-size: 20px;
                        color: $super-light-gray;
                    }
                }

            }
        }
    }

    .sub-action-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 12px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;
    }

}

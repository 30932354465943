$feather-alert-octagon: "\e81b";
$feather-alert-circle: "\e81c";
$feather-activity: "\e81d";
$feather-alert-triangle: "\e81e";
$feather-align-center: "\e81f";
$feather-airplay: "\e820";
$feather-align-justify: "\e821";
$feather-align-left: "\e822";
$feather-align-right: "\e823";
$feather-arrow-down-left: "\e824";
$feather-arrow-down-right: "\e825";
$feather-anchor: "\e826";
$feather-aperture: "\e827";
$feather-arrow-left: "\e828";
$feather-arrow-right: "\e829";
$feather-arrow-down: "\e82a";
$feather-arrow-up-left: "\e82b";
$feather-arrow-up-right: "\e82c";
$feather-arrow-up: "\e82d";
$feather-award: "\e82e";
$feather-bar-chart: "\e82f";
$feather-at-sign: "\e830";
$feather-bar-chart-2: "\e831";
$feather-battery-charging: "\e832";
$feather-bell-off: "\e833";
$feather-battery: "\e834";
$feather-bluetooth: "\e835";
$feather-bell: "\e836";
$feather-book: "\e837";
$feather-briefcase: "\e838";
$feather-camera-off: "\e839";
$feather-calendar: "\e83a";
$feather-bookmark: "\e83b";
$feather-box: "\e83c";
$feather-camera: "\e83d";
$feather-check-circle: "\e83e";
$feather-check: "\e83f";
$feather-check-square: "\e840";
$feather-cast: "\e841";
$feather-chevron-down: "\e842";
$feather-chevron-left: "\e843";
$feather-chevron-right: "\e844";
$feather-chevron-up: "\e845";
$feather-chevrons-down: "\e846";
$feather-chevrons-right: "\e847";
$feather-chevrons-up: "\e848";
$feather-chevrons-left: "\e849";
$feather-circle: "\e84a";
$feather-clipboard: "\e84b";
$feather-chrome: "\e84c";
$feather-clock: "\e84d";
$feather-cloud-lightning: "\e84e";
$feather-cloud-drizzle: "\e84f";
$feather-cloud-rain: "\e850";
$feather-cloud-off: "\e851";
$feather-codepen: "\e852";
$feather-cloud-snow: "\e853";
$feather-compass: "\e854";
$feather-copy: "\e855";
$feather-corner-down-right: "\e856";
$feather-corner-down-left: "\e857";
$feather-corner-left-down: "\e858";
$feather-corner-left-up: "\e859";
$feather-corner-up-left: "\e85a";
$feather-corner-up-right: "\e85b";
$feather-corner-right-down: "\e85c";
$feather-corner-right-up: "\e85d";
$feather-cpu: "\e85e";
$feather-credit-card: "\e85f";
$feather-crosshair: "\e860";
$feather-disc: "\e861";
$feather-delete: "\e862";
$feather-download-cloud: "\e863";
$feather-download: "\e864";
$feather-droplet: "\e865";
$feather-edit-2: "\e866";
$feather-edit: "\e867";
$feather-edit-1: "\e868";
$feather-external-link: "\e869";
$feather-eye: "\e86a";
$feather-feather: "\e86b";
$feather-facebook: "\e86c";
$feather-file-minus: "\e86d";
$feather-eye-off: "\e86e";
$feather-fast-forward: "\e86f";
$feather-file-text: "\e870";
$feather-film: "\e871";
$feather-file: "\e872";
$feather-file-plus: "\e873";
$feather-folder: "\e874";
$feather-filter: "\e875";
$feather-flag: "\e876";
$feather-globe: "\e877";
$feather-grid: "\e878";
$feather-heart: "\e879";
$feather-home: "\e87a";
$feather-github: "\e87b";
$feather-image: "\e87c";
$feather-inbox: "\e87d";
$feather-layers: "\e87e";
$feather-info: "\e87f";
$feather-instagram: "\e880";
$feather-layout: "\e881";
$feather-link-2: "\e882";
$feather-life-buoy: "\e883";
$feather-link: "\e884";
$feather-log-in: "\e885";
$feather-list: "\e886";
$feather-lock: "\e887";
$feather-log-out: "\e888";
$feather-loader: "\e889";
$feather-mail: "\e88a";
$feather-maximize-2: "\e88b";
$feather-map: "\e88c";
$feather-map-pin: "\e88e";
$feather-menu: "\e88f";
$feather-message-circle: "\e890";
$feather-message-square: "\e891";
$feather-minimize-2: "\e892";
$feather-mic-off: "\e893";
$feather-minus-circle: "\e894";
$feather-mic: "\e895";
$feather-minus-square: "\e896";
$feather-minus: "\e897";
$feather-moon: "\e898";
$feather-monitor: "\e899";
$feather-more-vertical: "\e89a";
$feather-more-horizontal: "\e89b";
$feather-move: "\e89c";
$feather-music: "\e89d";
$feather-navigation-2: "\e89e";
$feather-navigation: "\e89f";
$feather-octagon: "\e8a0";
$feather-package: "\e8a1";
$feather-pause-circle: "\e8a2";
$feather-pause: "\e8a3";
$feather-percent: "\e8a4";
$feather-phone-call: "\e8a5";
$feather-phone-forwarded: "\e8a6";
$feather-phone-missed: "\e8a7";
$feather-phone-off: "\e8a8";
$feather-phone-incoming: "\e8a9";
$feather-phone: "\e8aa";
$feather-phone-outgoing: "\e8ab";
$feather-pie-chart: "\e8ac";
$feather-play-circle: "\e8ad";
$feather-play: "\e8ae";
$feather-plus-square: "\e8af";
$feather-plus-circle: "\e8b0";
$feather-plus: "\e8b1";
$feather-pocket: "\e8b2";
$feather-printer: "\e8b3";
$feather-power: "\e8b4";
$feather-radio: "\e8b5";
$feather-repeat: "\e8b6";
$feather-refresh-ccw: "\e8b7";
$feather-rewind: "\e8b8";
$feather-rotate-ccw: "\e8b9";
$feather-refresh-cw: "\e8ba";
$feather-rotate-cw: "\e8bb";
$feather-save: "\e8bc";
$feather-search: "\e8bd";
$feather-server: "\e8be";
$feather-scissors: "\e8bf";
$feather-share-2: "\e8c0";
$feather-share: "\e8c1";
$feather-shield: "\e8c2";
$feather-settings: "\e8c3";
$feather-skip-back: "\e8c4";
$feather-shuffle: "\e8c5";
$feather-sidebar: "\e8c6";
$feather-skip-forward: "\e8c7";
$feather-slack: "\e8c8";
$feather-slash: "\e8c9";
$feather-smartphone: "\e8ca";
$feather-square: "\e8cb";
$feather-speaker: "\e8cc";
$feather-star: "\e8cd";
$feather-stop-circle: "\e8ce";
$feather-sun: "\e8cf";
$feather-sunrise: "\e8d0";
$feather-tablet: "\e8d1";
$feather-tag: "\e8d2";
$feather-sunset: "\e8d3";
$feather-target: "\e8d4";
$feather-thermometer: "\e8d5";
$feather-thumbs-up: "\e8d6";
$feather-thumbs-down: "\e8d7";
$feather-toggle-left: "\e8d8";
$feather-toggle-right: "\e8d9";
$feather-trash-2: "\e8da";
$feather-trash: "\e8db";
$feather-trending-up: "\e8dc";
$feather-trending-down: "\e8dd";
$feather-triangle: "\e8de";
$feather-type: "\e8df";
$feather-twitter: "\e8e0";
$feather-upload: "\e8e1";
$feather-umbrella: "\e8e2";
$feather-upload-cloud: "\e8e3";
$feather-unlock: "\e8e4";
$feather-user-check: "\e8e5";
$feather-user-minus: "\e8e6";
$feather-user-plus: "\e8e7";
$feather-user-x: "\e8e8";
$feather-user: "\e8e9";
$feather-users: "\e8ea";
$feather-video-off: "\e8eb";
$feather-video: "\e8ec";
$feather-voicemail: "\e8ed";
$feather-volume-x: "\e8ee";
$feather-volume-2: "\e8ef";
$feather-volume-1: "\e8f0";
$feather-volume: "\e8f1";
$feather-watch: "\e8f2";
$feather-wifi: "\e8f3";
$feather-x-square: "\e8f4";
$feather-wind: "\e8f5";
$feather-x: "\e8f6";
$feather-x-circle: "\e8f7";
$feather-zap: "\e8f8";
$feather-zoom-in: "\e8f9";
$feather-zoom-out: "\e8fa";
$feather-command: "\e8fb";
$feather-cloud: "\e8fc";
$feather-hash: "\e8fd";
$feather-headphones: "\e8fe";
$feather-underline: "\e8ff";
$feather-italic: "\e900";
$feather-bold: "\e901";
$feather-crop: "\e902";
$feather-help-circle: "\e903";
$feather-paperclip: "\e904";
$feather-shopping-cart: "\e905";
$feather-tv: "\e906";
$feather-wifi-off: "\e907";
$feather-minimize: "\e88d";
$feather-maximize: "\e908";
$feather-gitlab: "\e909";
$feather-sliders: "\e90a";
$feather-star-on: "\e90b";
$feather-heart-on: "\e90c";
$feather-archive: "\e90d";
$feather-arrow-down-circle: "\e90e";
$feather-arrow-up-circle: "\e90f";
$feather-arrow-left-circle: "\e910";
$feather-arrow-right-circle: "\e911";
$feather-bar-chart-line-: "\e912";
$feather-bar-chart-line: "\e913";
$feather-book-open: "\e914";
$feather-code: "\e915";
$feather-database: "\e916";
$feather-dollar-sign: "\e917";
$feather-folder-plus: "\e918";
$feather-gift: "\e919";
$feather-folder-minus: "\e91a";
$feather-git-commit: "\e91b";
$feather-git-branch: "\e91c";
$feather-git-pull-request: "\e91d";
$feather-git-merge: "\e91e";
$feather-linkedin: "\e91f";
$feather-hard-drive: "\e920";
$feather-more-vertical-: "\e921";
$feather-more-horizontal-: "\e922";
$feather-rss: "\e923";
$feather-send: "\e924";
$feather-shield-off: "\e925";
$feather-shopping-bag: "\e926";
$feather-terminal: "\e927";
$feather-truck: "\e928";
$feather-zap-off: "\e929";
$feather-youtube: "\e92a";


@import "../../../../sass/imports";

.grid-meal-draggable {
    width: 100%;
    height: 127px;
    margin-bottom: 10px;
    cursor: pointer;

    vertical-align: top;
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    z-index: 10;
    @include transition(all 0.2s ease-in-out);

    .hero-tag {
        position: absolute;
        top: 40px;
        right: -10px;
        z-index: 3;
    }

    .image-container {
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        overflow: hidden;

        @include border-radius(6px);
        background-image: $default-card-gradient;

        .image-overlay-text {
            position: absolute;
            top: 15px;
            left: 40%;
            color: white;
            width: 200px;

            h1 {
                display: inline-block;
                position: relative;
                right: 10px;
            }

            i {
                display: inline-block;
            }

            .feather-loader {
                animation-duration: 1s;
                animation-iteration-count: 120;
                animation-name: spinning;
                animation-timing-function: linear;
                font-size: 40px;
                position: relative;
                top: 45px;
                left: 78px;
            }

        &[data-loading="true"] {
            background-color: rgba(71, 86, 114);
            height: 170px;
            top: 0px;
            left: 0px;
            opacity: 0.96;
        }


        }
    }

    .controls-container {
        top: 0; bottom: 0;
        left: 0; right: 0;
        overflow: hidden;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .brand-name {
        color: white;
        font-weight: bold;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &[data-dragging="true"] {
        // overflow: hidden;
        opacity: .5;
    }

    &[data-meal-type="leftover"] {
        .meal-image {
            background-image: $leftover-card-gradient;
            opacity: 0.7;
        }
    }
    &[data-meal-type="food"] {
        .meal-image {
            background-image: $food-card-gradient;
        }
    }

    &[data-dnd-disabled="false"] {
        &:hover {
            @include box-shadow(0, 2px, 16px, 0, rgba(0, 0, 0, 0.35));
            cursor: move;
            border-radius: inherit;
        }
    }

    &[data-is-logged="true"] {
        .card-controls {
            top: auto;
            bottom: 0;
        }
    }

    .meal-image {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-size: 100%;
        background-repeat: repeat;
        background-position-y: 0px;
        width: 100%;

        &[data-loading="true"] {
            background: rgba(71, 86, 114, 0.96);
        }
    }

    .meal-info {
        width: 100%;
        position: absolute;
        font-size: 12px;
        color: white;
        font-weight: normal;
        background: rgba(46, 57, 77, 0.63);
        padding: 10px;
        line-height: 1.33;
        min-height: 70px;
        bottom: 0;
        z-index: 2;
        border-radius: 5px;

        img {
            margin: 0 0 3px 0;
        }

        p {
            height: 32px;
            // min-height: 32px;
            // max-height: 4em;
            overflow: hidden;
        }

        em {
            font-weight: bold;
            text-transform: uppercase;
            color: white;
        }

        &.meal-info-logged {
            background: linear-gradient(to bottom, rgba(#596a79, 0.85), rgba(#3c4c5c, 0.85));

            > p:before {
                content: 'LOG';
                margin: 0 5px 0 0;
                color: white;
                font-size: 9px;
                background-color: #5b7287;
                padding: 2px 6px;
                @include border-radius(2px);
            }
        }

        i {
            position: relative;
            top: 5px;
            font-size: 14px;
            padding: 5px 20px 5px 5px;

            @include min-screen(47em) {
                padding: 5px;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.77);
                    color: #425569;
                    border-radius: 15px;
                }
            }
        }
    }

    .did-you-eat-this {
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        width: 100%;
        background-image: linear-gradient(119deg, rgba(#5b6679, .85), rgba(#a9afb3, .85));
        text-align: center;

        h3 {
            color: white;
            font-size: 15px;
            text-transform: uppercase;
            line-height: 1.45;
            letter-spacing: 1.1px;
            font-weight: 800;
            margin: 22px 0 0 0;
        }

        p {
            color: white;
            font-size: 12px;
            margin: 0px 6px 15px 12px;
        }

        > footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px;
            text-align: center;
        }

        button {
            color: white;
            padding: 9px 12px;
            border: none;
            text-transform: uppercase;
            @include border-radius(40px);
            box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(174, 174, 174, 0.2);
            font-size: 8px;
            line-height: 1.22;
            letter-spacing: 0.7px;
        }

        .yes-btn {
            background-color: $raspberry;
            margin-right: 10px;
        }

        .no-btn {
            background-color: $bluey-gray;
        }
    }

    .container-prompt-overlay {
        background-color: initial;

        .container {
            margin: initial;
            padding: 5px;
            height: 65%;
            border-radius: 5px;

            .content {
                .header {
                    align-items: center;
                    gap: initial;
                    margin-left: initial;

                    > i {
                        font-size: 18px;
                        margin-right: 8px;
                    }

                    .titles {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .title {
                            font-size: 12px;
                        }

                        .subtitle {
                            font-size: 10px;
                        }
                    }
                }

                .options {
                    button {
                        width: 60px;

                        &.el-link-raspberry-btn {
                            width: 40px;
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }

    .drawer-menu-btn, .edit-log-btn, .undo-delete {
        color: $text-gray;
        border: none;
        font-size: 8px;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: 0.8px;
        background-color: rgba(255, 255, 255, 0.77);
        padding: 9px 13px;
        border: none;
        margin: 0 5px;
        text-transform: uppercase;
        @include border-radius(40px);
        @include transition(all .2s ease-in-out);

        &:hover {
            background-color: rgba(0, 0, 0, 0.77);
            color: white;
        }

        &:focus {
            background-color: black;
            color: white;
        }
    }

    .groceries-control {
        position: absolute;
        z-index: 5;
        bottom: -6px;
        right: -6px;

        .groceries-btn {
            width: 41px;
            height: 41px;
            margin: 0;
            font-size: 23px;
        }
    }

    .grocery-order-popup .popup-content {
        min-width: 240px;
    }

    .card-controls {
        padding: 7px 0 0px 0;
        text-align: center;
        position: relative;

        &[data-show-hover="true"] {
            display: none;

        }

        &[data-hover="true"] {
            display: initial;
        }


    }
}


@import "../../../../sass/imports";
@import "../../FeedModals.scss";
@import "../../MyAccount/PreferencesForm.scss";
@import "../../Recipes/Editor.scss";

.log-portions-modal {

}

.log-portions-overlay {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;

    background: rgba(0, 0, 0, .75);
}

.log-portions-modal-container {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    min-width: 300px;
    width: 98vw;
    max-width: 375px;
    max-height: 95vh;
    overflow-y: auto;
    overflow-x: hidden;

    @supports (max-height: constant(safe-area-inset-top)) {
        max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom) - 50px);
    }

    @supports (max-height: env(safe-area-inset-top)) {
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 50px);
    }

    color: $text-navy;

    > header {
        position: relative;
    }

    > footer {
        padding: 20px;
        text-align: center;
        width: 100%;
        background-color: white;
        @include box-shadow(0, -3px, 12px, 0, rgba(0, 0, 0, .1));

        h2 {
            font-size: 15px;
            text-transform: uppercase;
        }
    }

    .close-modal-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px 15px;
        border: none;
        background: none;
        color: $bluey-gray;
    }

    .ok-btn {
        @include flat-button($raspberry);
        padding: 12px 30px;
        min-width: 100px;

        &:disabled {
            opacity: 0.5;
        }
    }
}

.log-portions-body {
    padding: 30px 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    @supports (max-height: calc(100vh - constant(safe-area-inset-top) - constant(safe-area-inset-bottom))) {
        max-height: calc(100vh - 200px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
    }

    @supports (max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))) {
        max-height: calc(100vh - 200px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }

    h3 {
        text-align: center;
        font-size: 22px;
        margin: 0 0 20px 0;

        em {
            font-weight: normal;
        }
    }

    h4 {
        font-size: 30px;
        line-height: 1;
        padding: 0;
    }

    h5 {
        font-size: 14px;
        font-weight: 300;
    }

    .data-source {
        margin: 0 10px 0 0;
        text-align: right;

        > a {
            text-decoration: underline;
            color: $bluey-gray;
            font-size: 14px;
            margin: 10px;
            display: inline-block;
            vertical-align: top;
        }

        > button {
            margin: 10px;
            padding: 0;
            text-decoration: none;
        }
    }

    .nutrients-in-portions {
        text-align: center;

        .macros-pie-chart {
            display: inline-block;
            margin: 0 0 20px 0;
        }

        .cal, .rest {
            display: inline-block;
            vertical-align: top;
        }

        .cal {
            width: 100px;
            text-align: center;
        }

        .rest {
            width: calc(100% - 50px);
            font-size: 12px;

            em, span {
                display: inline-block;
                vertical-align: top;
            }

            em {
                width: 75px;
            }

            span {
                width: calc(100% - 75px);
                text-align: right;
            }
        }
    }

    .ingredient-list {
        margin: 1em 0;
        font-size: 12px;
        color: $bluey-gray;
        font-style: italic;

        em {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.unit-selector-list {
    > li {
        margin: 15px 0;

    }

    .not-usable {
        max-width: 270px;
        margin: 0 auto 20px;

        p {
            margin: 0.5em 0;
            font-size: 12px;
            font-weight: normal;

            &:first-child {
                color: $orange4;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}


@import "../../../../sass/imports";

.recipe-card {
    cursor: pointer;
    position: relative;
    height: 120px;
    @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.25));
    @include border-radius(3px);

    &[data-in-collection="true"] {
        background: #eaedef;
    }

    a:hover {
        text-decoration: none;
    }

    // Covers the entire div, we'll put a non-blurry one on top
    .recipe-card-image {
        background-color: $grayscale5;

        position: absolute;
        top: 0px;
        left: 0px;
        width: 120px;
        height: 120px;
        @include border-radius(3px);
        z-index: 2;

        background-size: cover;
        background-position: center;
        overflow: hidden;

        &[data-has-thumb="true"] {
            @include filter(blur(10px));
            @include transform(filter .25s ease-in-out);

            &.img-loaded {
                @include filter(none);
            }
        }

        h2 {
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            color: white;
            position: absolute;
            top: 50%;
            @include transform(translate(0, -50%));
            text-align: center;
            width: 100%;
            padding: 0 5px;
        }
    }

    .image-overlay {
        position: absolute;
        top: 50%;
        left: 0px;
        width: 120px;
        z-index: 5;
        @include transform(translateY(-50%));

        color: white;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        line-height: 1.5;
        letter-spacing: 2px;

        text-shadow: 0px 0px 5px black;
    }

    // text over the recipe image (title, merchant, meal type)
    .recipe-card-text {
        position: absolute;
        top: 39%;
        left: 120px;
        width: calc(100% - 120px);
        z-index: 1;
        cursor: pointer;
        padding: .7em .75em 1em 1em;
        @include transform(translate(0, -50%));
        @include user-select(none);
        @include transition(.33s padding ease-in-out);

        h3 {
            font-size: 15px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.07;
            text-align: left;
            color: $text-gray;
            width: 100%;

            max-height: 2.2em;
            overflow: hidden;
            text-align: left;

            em {
                font-weight: 300;
                font-style: italic;
            }
        }

        h4, h5 {
            font-size: 10px;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.2;

            padding-top: 4px;
            text-align: left;
            display: inline-block;
            vertical-align: top;
        }

        h4 {
            width: 55%;
            font-weight: 900;
            color: $text-gray;
        }

        h5 {
            width: 45%;
            text-align: right;
            font-weight: normal;
            color: $bluey-gray;
        }
    }

    .submenu-dropdown {
        z-index: 8;
        bottom: 50%;
        @include transform(translate(0, 50%));
    }

    .delete-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;

        border: none;
        background: $raspberry;
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        @include transition(all .2s ease-in-out);
        width: 26px;
        height: 26px;
        overflow: hidden;
        text-align: center;

        padding: 2px 5px;
        font-size: 16px;
        z-index: 15;

        &:hover {
            background: lighten($raspberry, 10%);
        }

    }

    .favorite-btn, .publisher-submenu {
        position: absolute;
        z-index: 8;
        bottom: 0;
        right: 0;
        border: none;
        background: rgba(255, 255, 255, 0.3);
        text-align: center;

        @include border-radius(100%);

        width: 30px;
        height: 30px;
        margin: 5px;
        z-index: 2;

        i {
            color: $bluey-gray;
            font-size: 18px;
            display: inline-block;
            padding: 5px 0 0 0;
        }

        &[data-boarded="true"] {
            i {
                color: $raspberry;
            }
        }
    }


    .publish-status {
        display: inline-block;
        padding: 3px 8px;
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        background-color: $text-gray;
        @include border-radius(3px);

        @include max-screen(47em) {
            margin: 0 1em 0 0;
        }

        &.live {
            background-color: darken($raspberry, 0%);
        }
    }

}

@font-face {
    font-family: 'eatlove';
    src: url('https://static.chewba.info/assets/fonts/33/eatlove.ttf') format('truetype'),
         url('https://static.chewba.info/assets/fonts/33/eatlove.woff') format('woff'),
         url('https://static.chewba.info/assets/fonts/33/eatlove.svg#eatlove') format('svg');
    font-weight: normal;
    font-style: normal;
}

i { @include icon-font; }

.icon-heart-border {
  &:before {
    content: $icon-heart-border;
  }
}
.icon-barcode2 {
  &:before {
    content: $icon-barcode2;
  }
}
.icon-barbell {
  &:before {
    content: $icon-barbell;
  }
}
.icon-strawberry {
  &:before {
    content: $icon-strawberry;
  }
}
.icon-muscles {
  &:before {
    content: $icon-muscles;
  }
}
.icon-messages3 {
  &:before {
    content: $icon-messages3;
  }
}
.icon-bowl2 {
  &:before {
    content: $icon-bowl2;
  }
}
.icon-olive-branch {
  &:before {
    content: $icon-olive-branch;
  }
}
.icon-plate2 {
  &:before {
    content: $icon-plate2;
  }
}
.icon-personalize {
  &:before {
    content: $icon-personalize;
  }
}
.icon-brain {
  &:before {
    content: $icon-brain;
  }
}
.icon-plate3 {
  &:before {
    content: $icon-plate3;
  }
}
.icon-leaf2-outline {
  &:before {
    content: $icon-leaf2-outline;
  }
}
.icon-money-plant {
  &:before {
    content: $icon-money-plant;
  }
}
.icon-leaf2 {
  &:before {
    content: $icon-leaf2;
  }
}
.icon-unlocked {
  &:before {
    content: $icon-unlocked;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-color {
  &:before {
    content: $icon-color;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;
  }
}
.icon-redo {
  &:before {
    content: $icon-redo;
  }
}
.icon-list-unordered {
  &:before {
    content: $icon-list-unordered;
  }
}
.icon-list-ordered {
  &:before {
    content: $icon-list-ordered;
  }
}
.icon-strikethrough {
  &:before {
    content: $icon-strikethrough;
  }
}
.icon-underline {
  &:before {
    content: $icon-underline;
  }
}
.icon-italic {
  &:before {
    content: $icon-italic;
  }
}
.icon-bold {
  &:before {
    content: $icon-bold;
  }
}
.icon-monospace {
  &:before {
    content: $icon-monospace;
  }
}
.icon-outdent {
  &:before {
    content: $icon-outdent;
  }
}
.icon-indent {
  &:before {
    content: $icon-indent;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-font-size {
  &:before {
    content: $icon-font-size;
  }
}
.icon-align-center {
  &:before {
    content: $icon-align-center;
  }
}
.icon-align-justify {
  &:before {
    content: $icon-align-justify;
  }
}
.icon-align-left {
  &:before {
    content: $icon-align-left;
  }
}
.icon-align-right {
  &:before {
    content: $icon-align-right;
  }
}
.icon-hierarchy {
  &:before {
    content: $icon-hierarchy;
  }
}
.icon-bed {
  &:before {
    content: $icon-bed;
  }
}
.icon-clock4 {
  &:before {
    content: $icon-clock4;
  }
}
.icon-clock5 {
  &:before {
    content: $icon-clock5;
  }
}
.icon-exercise {
  &:before {
    content: $icon-exercise;
  }
}
.icon-jumprope {
  &:before {
    content: $icon-jumprope;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-plate1 {
  &:before {
    content: $icon-plate1;
  }
}
.icon-silverware5 {
  &:before {
    content: $icon-silverware5;
  }
}
.icon-email5 {
  &:before {
    content: $icon-email5;
  }
}
.icon-exclude {
  &:before {
    content: $icon-exclude;
  }
}
.icon-phone3 {
  &:before {
    content: $icon-phone3;
  }
}
.icon-barcode {
  &:before {
    content: $icon-barcode;
  }
}
.icon-clipboard2 {
  &:before {
    content: $icon-clipboard2;
  }
}
.icon-settings5 {
  &:before {
    content: $icon-settings5;
  }
}
.icon-blender {
  &:before {
    content: $icon-blender;
  }
}
.icon-messages2 {
  &:before {
    content: $icon-messages2;
  }
}
.icon-delivery-truck {
  &:before {
    content: $icon-delivery-truck;
  }
}
.icon-smartphone {
  &:before {
    content: $icon-smartphone;
  }
}
.icon-warning6 {
  &:before {
    content: $icon-warning6;
  }
}
.icon-calendar71 {
  &:before {
    content: $icon-calendar71;
  }
}
.icon-target2 {
  &:before {
    content: $icon-target2;
  }
}
.icon-target {
  &:before {
    content: $icon-target;
  }
}
.icon-paperclip {
  &:before {
    content: $icon-paperclip;
  }
}
.icon-female4 {
  &:before {
    content: $icon-female4;
  }
}
.icon-male4 {
  &:before {
    content: $icon-male4;
  }
}
.icon-male3 {
  &:before {
    content: $icon-male3;
  }
}
.icon-female3 {
  &:before {
    content: $icon-female3;
  }
}
.icon-give {
  &:before {
    content: $icon-give;
  }
}
.icon-dashboard1 {
  &:before {
    content: $icon-dashboard1;
  }
}
.icon-toolkit1 {
  &:before {
    content: $icon-toolkit1;
  }
}
.icon-patients1 {
  &:before {
    content: $icon-patients1;
  }
}
.icon-library4 {
  &:before {
    content: $icon-library4;
  }
}
.icon-learn {
  &:before {
    content: $icon-learn;
  }
}
.icon-library3 {
  &:before {
    content: $icon-library3;
  }
}
.icon-pencil3 {
  &:before {
    content: $icon-pencil3;
  }
}
.icon-profiles {
  &:before {
    content: $icon-profiles;
  }
}
.icon-spinner2 {
  &:before {
    content: $icon-spinner2;
  }
}
.icon-page-paperclip {
  &:before {
    content: $icon-page-paperclip;
  }
}
.icon-logo2 .path1 {
  &:before {
    content: $icon-logo2-path1;
    color: rgb(18, 213, 116);
  }
}
.icon-logo2 .path2 {
  &:before {
    content: $icon-logo2-path2;
    margin-left: -0.7998046875em;
    color: rgb(32, 49, 82);
  }
}
.icon-logo3 {
  &:before {
    content: $icon-logo3;
  }
}
.icon-curly-que {
  &:before {
    content: $icon-curly-que;
  }
}
.icon-calendar6 {
  &:before {
    content: $icon-calendar6;
  }
}
.icon-calendar5 {
  &:before {
    content: $icon-calendar5;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-calendar7 {
  &:before {
    content: $icon-calendar7;
  }
}
.icon-schedule {
  &:before {
    content: $icon-schedule;
  }
}
.icon-prescription-rx {
  &:before {
    content: $icon-prescription-rx;
  }
}
.icon-yahoo .path1 {
  &:before {
    content: $icon-yahoo-path1;
    color: rgb(77, 43, 175);
  }
}
.icon-yahoo .path2 {
  &:before {
    content: $icon-yahoo-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-microsoft .path1 {
  &:before {
    content: $icon-microsoft-path1;
    color: rgb(232, 108, 96);
  }
}
.icon-microsoft .path2 {
  &:before {
    content: $icon-microsoft-path2;
    margin-left: -1em;
    color: rgb(114, 196, 114);
  }
}
.icon-microsoft .path3 {
  &:before {
    content: $icon-microsoft-path3;
    margin-left: -1em;
    color: rgb(67, 166, 221);
  }
}
.icon-microsoft .path4 {
  &:before {
    content: $icon-microsoft-path4;
    margin-left: -1em;
    color: rgb(239, 211, 88);
  }
}
.icon-google .path1 {
  &:before {
    content: $icon-google-path1;
    color: rgb(217, 79, 61);
  }
}
.icon-google .path2 {
  &:before {
    content: $icon-google-path2;
    margin-left: -1em;
    color: rgb(242, 192, 66);
  }
}
.icon-google .path3 {
  &:before {
    content: $icon-google-path3;
    margin-left: -1em;
    color: rgb(80, 133, 237);
  }
}
.icon-google .path4 {
  &:before {
    content: $icon-google-path4;
    margin-left: -1em;
    color: rgb(87, 167, 92);
  }
}
.icon-apple-logo {
  &:before {
    content: $icon-apple-logo;
  }
}
.icon-apple {
  &:before {
    content: $icon-apple;
  }
}
.icon-apple1 {
  &:before {
    content: $icon-apple1;
  }
}
.icon-apple2 {
  &:before {
    content: $icon-apple2;
  }
}
.icon-expand1 {
  &:before {
    content: $icon-expand1;
  }
}
.icon-collapse {
  &:before {
    content: $icon-collapse;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-clock1 {
  &:before {
    content: $icon-clock1;
  }
}
.icon-clock2 {
  &:before {
    content: $icon-clock2;
  }
}
.icon-clock3 {
  &:before {
    content: $icon-clock3;
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-time1 {
  &:before {
    content: $icon-time1;
  }
}
.icon-timer {
  &:before {
    content: $icon-timer;
  }
}
.icon-schedule2 {
  &:before {
    content: $icon-schedule2;
  }
}
.icon-schedule3 {
  &:before {
    content: $icon-schedule3;
  }
}
.icon-location-pin {
  &:before {
    content: $icon-location-pin;
  }
}
.icon-silverware {
  &:before {
    content: $icon-silverware;
  }
}
.icon-silverware1 {
  &:before {
    content: $icon-silverware1;
  }
}
.icon-silverware2 {
  &:before {
    content: $icon-silverware2;
  }
}
.icon-silverware3 {
  &:before {
    content: $icon-silverware3;
  }
}
.icon-silverware4 {
  &:before {
    content: $icon-silverware4;
  }
}
.icon-heart1 {
  &:before {
    content: $icon-heart1;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-email1 {
  &:before {
    content: $icon-email1;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-heart2 {
  &:before {
    content: $icon-heart2;
  }
}
.icon-heart3 {
  &:before {
    content: $icon-heart3;
  }
}
.icon-stacks {
  &:before {
    content: $icon-stacks;
  }
}
.icon-view-plans {
  &:before {
    content: $icon-view-plans;
  }
}
.icon-paper {
  &:before {
    content: $icon-paper;
  }
}
.icon-pencil-notebook {
  &:before {
    content: $icon-pencil-notebook;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-pencil1 {
  &:before {
    content: $icon-pencil1;
  }
}
.icon-pencil2 {
  &:before {
    content: $icon-pencil2;
  }
}
.icon-email2 {
  &:before {
    content: $icon-email2;
  }
}
.icon-email3 {
  &:before {
    content: $icon-email3;
  }
}
.icon-email4 {
  &:before {
    content: $icon-email4;
  }
}
.icon-email-heart2 {
  &:before {
    content: $icon-email-heart2;
  }
}
.icon-email-heart1 {
  &:before {
    content: $icon-email-heart1;
  }
}
.icon-print {
  &:before {
    content: $icon-print;
  }
}
.icon-print2 {
  &:before {
    content: $icon-print2;
  }
}
.icon-print3 {
  &:before {
    content: $icon-print3;
  }
}
.icon-trash-can4 {
  &:before {
    content: $icon-trash-can4;
  }
}
.icon-trash-can3 {
  &:before {
    content: $icon-trash-can3;
  }
}
.icon-trash-can2 {
  &:before {
    content: $icon-trash-can2;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-trash-can {
  &:before {
    content: $icon-trash-can;
  }
}
.icon-arrow-pointer {
  &:before {
    content: $icon-arrow-pointer;
  }
}
.icon-back-arrow {
  &:before {
    content: $icon-back-arrow;
  }
}
.icon-right-arrow {
  &:before {
    content: $icon-right-arrow;
  }
}
.icon-up-arrow {
  &:before {
    content: $icon-up-arrow;
  }
}
.icon-down-arrow {
  &:before {
    content: $icon-down-arrow;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-share-arrow {
  &:before {
    content: $icon-share-arrow;
  }
}
.icon-arrow-right1 {
  &:before {
    content: $icon-arrow-right1;
  }
}
.icon-arrow-down1 {
  &:before {
    content: $icon-arrow-down1;
  }
}
.icon-arrow-up1 {
  &:before {
    content: $icon-arrow-up1;
  }
}
.icon-arrow-left1 {
  &:before {
    content: $icon-arrow-left1;
  }
}
.icon-pulldown-arrows {
  &:before {
    content: $icon-pulldown-arrows;
  }
}
.icon-breakfast2 {
  &:before {
    content: $icon-breakfast2;
    color: #a30063;
  }
}
.icon-breakfast-white {
  &:before {
    content: $icon-breakfast-white;
    color: #fff;
  }
}
.icon-salad {
  &:before {
    content: $icon-salad;
    color: #a30063;
  }
}
.icon-dinner-white {
  &:before {
    content: $icon-dinner-white;
    color: #fff;
  }
}
.icon-snacks3 .path1 {
  &:before {
    content: $icon-snacks3-path1;
    color: rgb(163, 0, 99);
  }
}
.icon-snacks3 .path2 {
  &:before {
    content: $icon-snacks3-path2;
    margin-left: -1em;
    color: rgb(245, 229, 239);
  }
}
.icon-snacks3 .path3 {
  &:before {
    content: $icon-snacks3-path3;
    margin-left: -1em;
    color: rgb(163, 0, 99);
  }
}
.icon-snacks3 .path4 {
  &:before {
    content: $icon-snacks3-path4;
    margin-left: -1em;
    color: rgb(163, 0, 99);
  }
}
.icon-snacks3 .path5 {
  &:before {
    content: $icon-snacks3-path5;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-snacks3 .path6 {
  &:before {
    content: $icon-snacks3-path6;
    margin-left: -1em;
    color: rgb(163, 0, 99);
  }
}
.icon-snack-white .path1 {
  &:before {
    content: $icon-snack-white-path1;
    color: rgb(255, 255, 255);
  }
}
.icon-snack-white .path2 {
  &:before {
    content: $icon-snack-white-path2;
    margin-left: -1.0224609375em;
    color: rgb(212, 22, 137);
  }
}
.icon-snack-white .path3 {
  &:before {
    content: $icon-snack-white-path3;
    margin-left: -1.0224609375em;
    color: rgb(255, 255, 255);
  }
}
.icon-snack-white .path4 {
  &:before {
    content: $icon-snack-white-path4;
    margin-left: -1.0224609375em;
    color: rgb(255, 255, 255);
  }
}
.icon-snack-white .path5 {
  &:before {
    content: $icon-snack-white-path5;
    margin-left: -1.0224609375em;
    color: rgb(255, 255, 255);
  }
}
.icon-snack-white .path6 {
  &:before {
    content: $icon-snack-white-path6;
    margin-left: -1.0224609375em;
    color: rgb(163, 0, 99);
  }
}
.icon-snack-white .path7 {
  &:before {
    content: $icon-snack-white-path7;
    margin-left: -1.0224609375em;
    color: rgb(255, 255, 255);
  }
}
.icon-snack-white .path8 {
  &:before {
    content: $icon-snack-white-path8;
    margin-left: -1.0224609375em;
    color: rgb(255, 255, 255);
  }
}
.icon-snack-white .path9 {
  &:before {
    content: $icon-snack-white-path9;
    margin-left: -1.0224609375em;
    color: rgb(255, 255, 255);
  }
}
.icon-snack-white .path10 {
  &:before {
    content: $icon-snack-white-path10;
    margin-left: -1.0224609375em;
    color: rgb(255, 255, 255);
  }
}
.icon-lunch3 .path1 {
  &:before {
    content: $icon-lunch3-path1;
    color: rgb(163, 0, 99);
  }
}
.icon-lunch3 .path2 {
  &:before {
    content: $icon-lunch3-path2;
    margin-left: -1em;
    color: rgb(163, 0, 99);
  }
}
.icon-lunch3 .path3 {
  &:before {
    content: $icon-lunch3-path3;
    margin-left: -1em;
    color: rgb(163, 0, 99);
  }
}
.icon-lunch3 .path4 {
  &:before {
    content: $icon-lunch3-path4;
    margin-left: -1em;
    color: rgb(245, 229, 239);
  }
}
.icon-lunch3 .path5 {
  &:before {
    content: $icon-lunch3-path5;
    margin-left: -1em;
    color: rgb(163, 0, 99);
  }
}
.icon-lunch-white .path1 {
  &:before {
    content: $icon-lunch-white-path1;
    color: rgb(255, 255, 255);
  }
}
.icon-lunch-white .path2 {
  &:before {
    content: $icon-lunch-white-path2;
    margin-left: -1.044921875em;
    color: rgb(255, 255, 255);
  }
}
.icon-lunch-white .path3 {
  &:before {
    content: $icon-lunch-white-path3;
    margin-left: -1.044921875em;
    color: rgb(255, 255, 255);
  }
}
.icon-lunch-white .path4 {
  &:before {
    content: $icon-lunch-white-path4;
    margin-left: -1.044921875em;
    color: rgb(255, 255, 255);
  }
}
.icon-lunch-white .path5 {
  &:before {
    content: $icon-lunch-white-path5;
    margin-left: -1.044921875em;
    color: rgb(255, 255, 255);
  }
}
.icon-lunch-white .path6 {
  &:before {
    content: $icon-lunch-white-path6;
    margin-left: -1.044921875em;
    color: rgb(212, 22, 137);
  }
}
.icon-lunch-white .path7 {
  &:before {
    content: $icon-lunch-white-path7;
    margin-left: -1.044921875em;
    color: rgb(255, 255, 255);
  }
}
.icon-lunch-white .path8 {
  &:before {
    content: $icon-lunch-white-path8;
    margin-left: -1.044921875em;
    color: rgb(255, 255, 255);
  }
}
.icon-check-circle2 {
  &:before {
    content: $icon-check-circle2;
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}
.icon-minus-circle-outline {
  &:before {
    content: $icon-minus-circle-outline;
  }
}
.icon-minus-circle-solid {
  &:before {
    content: $icon-minus-circle-solid;
  }
}
.icon-add-circle-outline2 {
  &:before {
    content: $icon-add-circle-outline2;
  }
}
.icon-recently-used {
  &:before {
    content: $icon-recently-used;
  }
}
.icon-restaurant-meals {
  &:before {
    content: $icon-restaurant-meals;
  }
}
.icon-seach-by-ingredients {
  &:before {
    content: $icon-seach-by-ingredients;
  }
}
.icon-search5 {
  &:before {
    content: $icon-search5;
  }
}
.icon-lightbulb {
  &:before {
    content: $icon-lightbulb;
  }
}
.icon-store-bought-meals {
  &:before {
    content: $icon-store-bought-meals;
  }
}
.icon-camera {
  &:before {
    content: $icon-camera;
  }
}
.icon-warning5 {
  &:before {
    content: $icon-warning5;
  }
}
.icon-added-to-list {
  &:before {
    content: $icon-added-to-list;
  }
}
.icon-add-to-cart {
  &:before {
    content: $icon-add-to-cart;
  }
}
.icon-bolt {
  &:before {
    content: $icon-bolt;
  }
}
.icon-cart3 {
  &:before {
    content: $icon-cart3;
  }
}
.icon-chevron-left-double {
  &:before {
    content: $icon-chevron-left-double;
  }
}
.icon-chevron-right-double {
  &:before {
    content: $icon-chevron-right-double;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-right1 {
  &:before {
    content: $icon-chevron-right1;
  }
}
.icon-ellipsis2 {
  &:before {
    content: $icon-ellipsis2;
  }
}
.icon-ellipsis1 {
  &:before {
    content: $icon-ellipsis1;
  }
}
.icon-ellipsis {
  &:before {
    content: $icon-ellipsis;
  }
}
.icon-menu2 {
  &:before {
    content: $icon-menu2;
  }
}
.icon-messages {
  &:before {
    content: $icon-messages;
  }
}
.icon-servings {
  &:before {
    content: $icon-servings;
  }
}
.icon-settings4 {
  &:before {
    content: $icon-settings4;
  }
}
.icon-skip {
  &:before {
    content: $icon-skip;
  }
}
.icon-swap {
  &:before {
    content: $icon-swap;
  }
}
.icon-pasta-rice-beans {
  &:before {
    content: $icon-pasta-rice-beans;
  }
}
.icon-deli {
  &:before {
    content: $icon-deli;
  }
}
.icon-beverages2 {
  &:before {
    content: $icon-beverages2;
  }
}
.icon-frozen-foods {
  &:before {
    content: $icon-frozen-foods;
  }
}
.icon-liquor3 {
  &:before {
    content: $icon-liquor3;
  }
}
.icon-produce2 {
  &:before {
    content: $icon-produce2;
  }
}
.icon-snacks2 {
  &:before {
    content: $icon-snacks2;
  }
}
.icon-baking3 {
  &:before {
    content: $icon-baking3;
  }
}
.icon-bakery {
  &:before {
    content: $icon-bakery;
  }
}
.icon-baking2 {
  &:before {
    content: $icon-baking2;
  }
}
.icon-canned-goods {
  &:before {
    content: $icon-canned-goods;
  }
}
.icon-cereals {
  &:before {
    content: $icon-cereals;
  }
}
.icon-condiment2 {
  &:before {
    content: $icon-condiment2;
  }
}
.icon-dairy2 {
  &:before {
    content: $icon-dairy2;
  }
}
.icon-ethnic {
  &:before {
    content: $icon-ethnic;
  }
}
.icon-liquor2 {
  &:before {
    content: $icon-liquor2;
  }
}
.icon-pasta {
  &:before {
    content: $icon-pasta;
  }
}
.icon-produce {
  &:before {
    content: $icon-produce;
  }
}
.icon-seafood {
  &:before {
    content: $icon-seafood;
  }
}
.icon-warning4 {
  &:before {
    content: $icon-warning4;
  }
}
.icon-warning3 {
  &:before {
    content: $icon-warning3;
  }
}
.icon-warning2 {
  &:before {
    content: $icon-warning2;
  }
}
.icon-warning1 {
  &:before {
    content: $icon-warning1;
  }
}
.icon-change-menu {
  &:before {
    content: $icon-change-menu;
  }
}
.icon-invite-friend {
  &:before {
    content: $icon-invite-friend;
  }
}
.icon-toolkit {
  &:before {
    content: $icon-toolkit;
  }
}
.icon-phone2 {
  &:before {
    content: $icon-phone2;
  }
}
.icon-phone1 {
  &:before {
    content: $icon-phone1;
  }
}
.icon-amazon {
  &:before {
    content: $icon-amazon;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-leaf {
  &:before {
    content: $icon-leaf;
  }
}
.icon-vegetable {
  &:before {
    content: $icon-vegetable;
  }
}
.icon-patients {
  &:before {
    content: $icon-patients;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-search-patient-lens {
  &:before {
    content: $icon-search-patient-lens;
  }
}
.icon-search-patient {
  &:before {
    content: $icon-search-patient;
  }
}
.icon-male2 {
  &:before {
    content: $icon-male2;
  }
}
.icon-female2 {
  &:before {
    content: $icon-female2;
  }
}
.icon-library2 {
  &:before {
    content: $icon-library2;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-sort-by {
  &:before {
    content: $icon-sort-by;
  }
}
.icon-bean {
  &:before {
    content: $icon-bean;
  }
}
.icon-twitter2 {
  &:before {
    content: $icon-twitter2;
  }
}
.icon-pinterest2 {
  &:before {
    content: $icon-pinterest2;
  }
}
.icon-facebook2 {
  &:before {
    content: $icon-facebook2;
  }
}
.icon-sugar2 {
  &:before {
    content: $icon-sugar2;
  }
}
.icon-plan {
  &:before {
    content: $icon-plan;
  }
}
.icon-groceries {
  &:before {
    content: $icon-groceries;
  }
}
.icon-search3 {
  &:before {
    content: $icon-search3;
  }
}
.icon-salt {
  &:before {
    content: $icon-salt;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-library {
  &:before {
    content: $icon-library;
  }
}
.icon-home2 {
  &:before {
    content: $icon-home2;
  }
}
.icon-grains {
  &:before {
    content: $icon-grains;
  }
}
.icon-book-eatlove {
  &:before {
    content: $icon-book-eatlove;
  }
}
.icon-generate-more {
  &:before {
    content: $icon-generate-more;
  }
}
.icon-fruit {
  &:before {
    content: $icon-fruit;
  }
}
.icon-cook {
  &:before {
    content: $icon-cook;
  }
}
.icon-collection-icon {
  &:before {
    content: $icon-collection-icon;
  }
}
.icon-wheat {
  &:before {
    content: $icon-wheat;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-close-x {
  &:before {
    content: $icon-close-x;
  }
}
.icon-check2 {
  &:before {
    content: $icon-check2;
  }
}
.icon-onion {
  &:before {
    content: $icon-onion;
  }
}
.icon-tags2 {
  &:before {
    content: $icon-tags2;
  }
}
.icon-grid-view {
  &:before {
    content: $icon-grid-view;
  }
}
.icon-budget {
  &:before {
    content: $icon-budget;
  }
}
.icon-search4 {
  &:before {
    content: $icon-search4;
  }
}
.icon-money-bag2 {
  &:before {
    content: $icon-money-bag2;
  }
}
.icon-crab {
  &:before {
    content: $icon-crab;
  }
}
.icon-baking1 {
  &:before {
    content: $icon-baking1;
  }
}
.icon-minus-square-solid {
  &:before {
    content: $icon-minus-square-solid;
  }
}
.icon-add-square-solid {
  &:before {
    content: $icon-add-square-solid;
  }
}
.icon-add-circle-solid {
  &:before {
    content: $icon-add-circle-solid;
  }
}
.icon-add-circle-outline {
  &:before {
    content: $icon-add-circle-outline;
  }
}
.icon-radio-unchecked {
  &:before {
    content: $icon-radio-unchecked;
  }
}
.icon-radio-checked {
  &:before {
    content: $icon-radio-checked;
  }
}
.icon-checkbox-checked1 {
  &:before {
    content: $icon-checkbox-checked1;
  }
}
.icon-minus-square-outline {
  &:before {
    content: $icon-minus-square-outline;
  }
}
.icon-add-square-outline {
  &:before {
    content: $icon-add-square-outline;
  }
}
.icon-hourglass {
  &:before {
    content: $icon-hourglass;
  }
}
.icon-grocery-list {
  &:before {
    content: $icon-grocery-list;
  }
}
.icon-plant {
  &:before {
    content: $icon-plant;
  }
}
.icon-cart1 {
  &:before {
    content: $icon-cart1;
  }
}
.icon-coffee {
  &:before {
    content: $icon-coffee;
  }
}
.icon-soup-pot {
  &:before {
    content: $icon-soup-pot;
  }
}
.icon-international {
  &:before {
    content: $icon-international;
  }
}
.icon-eggplant {
  &:before {
    content: $icon-eggplant;
  }
}
.icon-star-full {
  &:before {
    content: $icon-star-full;
  }
}
.icon-money-cash {
  &:before {
    content: $icon-money-cash;
  }
}
.icon-bowl {
  &:before {
    content: $icon-bowl;
  }
}
.icon-liquor {
  &:before {
    content: $icon-liquor;
  }
}
.icon-ewg {
  &:before {
    content: $icon-ewg;
  }
}
.icon-steam-pot {
  &:before {
    content: $icon-steam-pot;
  }
}
.icon-steak {
  &:before {
    content: $icon-steak;
  }
}
.icon-tags1 {
  &:before {
    content: $icon-tags1;
  }
}
.icon-bread {
  &:before {
    content: $icon-bread;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon-notification1 {
  &:before {
    content: $icon-notification1;
  }
}
.icon-line-graph {
  &:before {
    content: $icon-line-graph;
  }
}
.icon-cupcake {
  &:before {
    content: $icon-cupcake;
  }
}
.icon-international1 {
  &:before {
    content: $icon-international1;
  }
}
.icon-list2 {
  &:before {
    content: $icon-list2;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-candy {
  &:before {
    content: $icon-candy;
  }
}
.icon-spinner {
  &:before {
    content: $icon-spinner;
  }
}
.icon-avocado1 {
  &:before {
    content: $icon-avocado1;
  }
}
.icon-family {
  &:before {
    content: $icon-family;
  }
}
.icon-cherries {
  &:before {
    content: $icon-cherries;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart;
  }
}
.icon-trophy1 {
  &:before {
    content: $icon-trophy1;
  }
}
.icon-carrot {
  &:before {
    content: $icon-carrot;
  }
}
.icon-couple {
  &:before {
    content: $icon-couple;
  }
}
.icon-list-view1 {
  &:before {
    content: $icon-list-view1;
  }
}
.icon-list1 {
  &:before {
    content: $icon-list1;
  }
}
.icon-list-view {
  &:before {
    content: $icon-list-view;
  }
}
.icon-pan {
  &:before {
    content: $icon-pan;
  }
}
.icon-ribbon {
  &:before {
    content: $icon-ribbon;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-female {
  &:before {
    content: $icon-female;
  }
}
.icon-analyze {
  &:before {
    content: $icon-analyze;
  }
}
.icon-search2 {
  &:before {
    content: $icon-search2;
  }
}
.icon-notification {
  &:before {
    content: $icon-notification;
  }
}
.icon-breastfeed {
  &:before {
    content: $icon-breastfeed;
  }
}
.icon-receipt {
  &:before {
    content: $icon-receipt;
  }
}
.icon-book {
  &:before {
    content: $icon-book;
  }
}
.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked;
  }
}
.icon-menus {
  &:before {
    content: $icon-menus;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-bowl1 {
  &:before {
    content: $icon-bowl1;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-facebook1 {
  &:before {
    content: $icon-facebook1;
  }
}
.icon-up-caret {
  &:before {
    content: $icon-up-caret;
  }
}
.icon-bellpepper {
  &:before {
    content: $icon-bellpepper;
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy;
  }
}
.icon-icecream {
  &:before {
    content: $icon-icecream;
  }
}
.icon-beverages {
  &:before {
    content: $icon-beverages;
  }
}
.icon-dairy {
  &:before {
    content: $icon-dairy;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-tomato {
  &:before {
    content: $icon-tomato;
  }
}
.icon-fodmap {
  &:before {
    content: $icon-fodmap;
  }
}
.icon-rice {
  &:before {
    content: $icon-rice;
  }
}
.icon-moon {
  &:before {
    content: $icon-moon;
  }
}
.icon-award1 {
  &:before {
    content: $icon-award1;
  }
}
.icon-coins {
  &:before {
    content: $icon-coins;
  }
}
.icon-knife1 {
  &:before {
    content: $icon-knife1;
  }
}
.icon-remove-circle-outline {
  &:before {
    content: $icon-remove-circle-outline;
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-beet {
  &:before {
    content: $icon-beet;
  }
}
.icon-stats {
  &:before {
    content: $icon-stats;
  }
}
.icon-pets {
  &:before {
    content: $icon-pets;
  }
}
.icon-knife {
  &:before {
    content: $icon-knife;
  }
}
.icon-funnel {
  &:before {
    content: $icon-funnel;
  }
}
.icon-dollar-sign {
  &:before {
    content: $icon-dollar-sign;
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1;
  }
}
.icon-add-person {
  &:before {
    content: $icon-add-person;
  }
}
.icon-fish1 {
  &:before {
    content: $icon-fish1;
  }
}
.icon-wheat1 {
  &:before {
    content: $icon-wheat1;
  }
}
.icon-bullet {
  &:before {
    content: $icon-bullet;
  }
}
.icon-watermelon {
  &:before {
    content: $icon-watermelon;
  }
}
.icon-cake {
  &:before {
    content: $icon-cake;
  }
}
.icon-plus-thick {
  &:before {
    content: $icon-plus-thick;
  }
}
.icon-pregnant {
  &:before {
    content: $icon-pregnant;
  }
}
.icon-checkbox-box {
  &:before {
    content: $icon-checkbox-box;
  }
}
.icon-baking {
  &:before {
    content: $icon-baking;
  }
}
.icon-olives {
  &:before {
    content: $icon-olives;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-select {
  &:before {
    content: $icon-select;
  }
}
.icon-reload {
  &:before {
    content: $icon-reload;
  }
}
.icon-tags {
  &:before {
    content: $icon-tags;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty;
  }
}
.icon-condiment1 {
  &:before {
    content: $icon-condiment1;
  }
}
.icon-jar {
  &:before {
    content: $icon-jar;
  }
}
.icon-eggplant2 {
  &:before {
    content: $icon-eggplant2;
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus;
  }
}
.icon-gauge-10 {
  &:before {
    content: $icon-gauge-10;
  }
}
.icon-gauge-20 {
  &:before {
    content: $icon-gauge-20;
  }
}
.icon-gauge-30 {
  &:before {
    content: $icon-gauge-30;
  }
}
.icon-gauge-50 {
  &:before {
    content: $icon-gauge-50;
  }
}
.icon-gauge-40 {
  &:before {
    content: $icon-gauge-40;
  }
}
.icon-gauge-60 {
  &:before {
    content: $icon-gauge-60;
  }
}
.icon-gauge-70 {
  &:before {
    content: $icon-gauge-70;
  }
}
.icon-gauge-80 {
  &:before {
    content: $icon-gauge-80;
  }
}
.icon-gauge-90 {
  &:before {
    content: $icon-gauge-90;
  }
}
.icon-gauge-100 {
  &:before {
    content: $icon-gauge-100;
  }
}
.icon-gauge {
  &:before {
    content: $icon-gauge;
  }
}
.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked;
  }
}
.icon-pear1 {
  &:before {
    content: $icon-pear1;
  }
}
.icon-platter {
  &:before {
    content: $icon-platter;
  }
}
.icon-radio-unselected {
  &:before {
    content: $icon-radio-unselected;
  }
}
.icon-logo {
  &:before {
    content: $icon-logo;
  }
}
.icon-avocado {
  &:before {
    content: $icon-avocado;
  }
}
.icon-lettuce {
  &:before {
    content: $icon-lettuce;
  }
}
.icon-pan1 {
  &:before {
    content: $icon-pan1;
  }
}
.icon-eggs {
  &:before {
    content: $icon-eggs;
  }
}
.icon-home1 {
  &:before {
    content: $icon-home1;
  }
}
.icon-chard {
  &:before {
    content: $icon-chard;
  }
}
.icon-nutro1 {
  &:before {
    content: $icon-nutro1;
  }
}
.icon-bellpepper1 {
  &:before {
    content: $icon-bellpepper1;
  }
}
.icon-fish2 {
  &:before {
    content: $icon-fish2;
  }
}
.icon-pear {
  &:before {
    content: $icon-pear;
  }
}
.icon-award {
  &:before {
    content: $icon-award;
  }
}
.icon-treenuts {
  &:before {
    content: $icon-treenuts;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-strike {
  &:before {
    content: $icon-strike;
  }
}
.icon-sugar {
  &:before {
    content: $icon-sugar;
  }
}
.icon-recipe {
  &:before {
    content: $icon-recipe;
  }
}
.icon-grid-view1 {
  &:before {
    content: $icon-grid-view1;
  }
}
.icon-minus-thick {
  &:before {
    content: $icon-minus-thick;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-fish {
  &:before {
    content: $icon-fish;
  }
}
.icon-meal-plan {
  &:before {
    content: $icon-meal-plan;
  }
}
.icon-condiment {
  &:before {
    content: $icon-condiment;
  }
}
.icon-expand {
  &:before {
    content: $icon-expand;
  }
}
.icon-plus-thin {
  &:before {
    content: $icon-plus-thin;
  }
}
.icon-notification2 {
  &:before {
    content: $icon-notification2;
  }
}
.icon-minus-thin {
  &:before {
    content: $icon-minus-thin;
  }
}
.icon-tupperware {
  &:before {
    content: $icon-tupperware;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-plate {
  &:before {
    content: $icon-plate;
  }
}
.icon-money-bag {
  &:before {
    content: $icon-money-bag;
  }
}
.icon-bellpepper2 {
  &:before {
    content: $icon-bellpepper2;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-corn {
  &:before {
    content: $icon-corn;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-nutro {
  &:before {
    content: $icon-nutro;
  }
}
.icon-raddish {
  &:before {
    content: $icon-raddish;
  }
}
.icon-eggplant1 {
  &:before {
    content: $icon-eggplant1;
  }
}
.icon-lunch {
  &:before {
    content: $icon-lunch;
  }
}
.icon-close-box {
  &:before {
    content: $icon-close-box;
  }
}
.icon-measuring-cup {
  &:before {
    content: $icon-measuring-cup;
  }
}
.icon-radio-selected {
  &:before {
    content: $icon-radio-selected;
  }
}
.icon-soy {
  &:before {
    content: $icon-soy;
  }
}
.icon-herbs {
  &:before {
    content: $icon-herbs;
  }
}
.icon-veggiebowl {
  &:before {
    content: $icon-veggiebowl;
  }
}
.icon-adults {
  &:before {
    content: $icon-adults;
  }
}
.icon-breakfast {
  &:before {
    content: $icon-breakfast;
  }
}
.icon-cart2 {
  &:before {
    content: $icon-cart2;
  }
}
.icon-dinner {
  &:before {
    content: $icon-dinner;
  }
}
.icon-female-sign {
  &:before {
    content: $icon-female-sign;
  }
}
.icon-kids {
  &:before {
    content: $icon-kids;
  }
}
.icon-lunch2 {
  &:before {
    content: $icon-lunch2;
  }
}
.icon-male-sign {
  &:before {
    content: $icon-male-sign;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-snacks {
  &:before {
    content: $icon-snacks;
  }
}
.icon-x {
  &:before {
    content: $icon-x;
  }
}
.icon-gluten-free {
  &:before {
    content: $icon-gluten-free;
  }
}
.icon-high-blood {
  &:before {
    content: $icon-high-blood;
  }
}
.icon-lose-weight {
  &:before {
    content: $icon-lose-weight;
  }
}
.icon-pediatric-nutrition {
  &:before {
    content: $icon-pediatric-nutrition;
  }
}
.icon-pregnancy {
  &:before {
    content: $icon-pregnancy;
  }
}
.icon-reduce-inflamation {
  &:before {
    content: $icon-reduce-inflamation;
  }
}
.icon-renal-disease {
  &:before {
    content: $icon-renal-disease;
  }
}
.icon-sports-nutrition {
  &:before {
    content: $icon-sports-nutrition;
  }
}
.icon-food-allergies {
  &:before {
    content: $icon-food-allergies;
  }
}
.icon-eat-more-plants {
  &:before {
    content: $icon-eat-more-plants;
  }
}
.icon-diabetes {
  &:before {
    content: $icon-diabetes;
  }
}
.icon-baby-bottle {
  &:before {
    content: $icon-baby-bottle;
  }
}
.icon-rx {
  &:before {
    content: $icon-rx;
  }
}
.icon-intestine {
  &:before {
    content: $icon-intestine;
  }
}
.icon-diabetes1 {
  &:before {
    content: $icon-diabetes1;
  }
}
.icon-diabetes2 {
  &:before {
    content: $icon-diabetes2;
  }
}
.icon-high-cholesterol {
  &:before {
    content: $icon-high-cholesterol;
  }
}
.icon-increase-energy {
  &:before {
    content: $icon-increase-energy;
  }
}
.icon-mother-child {
  &:before {
    content: $icon-mother-child;
  }
}
.icon-osteoporosis {
  &:before {
    content: $icon-osteoporosis;
  }
}
.icon-settings3 {
  &:before {
    content: $icon-settings3;
  }
}
.icon-settings2 {
  &:before {
    content: $icon-settings2;
  }
}
.icon-settings1 {
  &:before {
    content: $icon-settings1;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-smile {
  &:before {
    content: $icon-smile;
  }
}
.icon-scale-weight {
  &:before {
    content: $icon-scale-weight;
  }
}

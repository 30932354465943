@import "../../../../../sass/imports";

.smart-choices-picker {
    max-width: 1070px;
    margin: auto;
    width: calc(100vw - 30px);
    min-height: 50vh;

    > header {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        max-width: 335px;
        margin: auto;
        @include transition(padding 0.33s ease-in-out);

        @include min-screen(47em) {
            margin: 10px auto;
            position: relative;
        }

        &[data-dirty="false"] {
            @include min-screen(47em) {
                padding: 80px 0 0 0;
            }
        }

        > * {
            white-space: initial;
        }

        > h3 {
            padding: 40px 0;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.33;
            color: $text-gray;

            strong {
                font-weight: 800;
            }
        }

        .alert-smart-choices {
            z-index: 5;

            .dropdown-content {
                @include max-screen(380px) {
                    min-width: 300px;
                }

                .close-btn {
                    i {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    p {
        padding: 10px 20px;
        text-align: center;
        font-size: 14px;
        line-height: 1.33;
        color: $text-gray;
    }

    .results-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    > footer {
        text-align: center;
        // padding: 10px;

        .browser-virtual-pager {
            display: none;
        }

        .browser-pager {
            @include flat-button($mint-green3);
            @include border-radius(3px);
            font-size: 12px;
            margin: 10px;
        }

        .toggle-mode {
            color: $raspberry;
            border: none;
            background: none;
            margin: 10px;
            text-decoration: underline;
            font-size: 12px;
            text-transform: uppercase;
        }
    }

    .sub-action-btn {
        border: none;
        background: none;
        padding: 8px;
        font-size: 14px;
        color: $text-navy;
        text-decoration: none;
        max-width: 250px;
        text-align: center;

        em {
            color: $raspberry;
            text-decoration: underline;
        }
    }

    .browser-pager {
        background-color: $raspberry !important;
        border: none !important;
        padding: 13px !important;
        width: 255px !important;
        @include border-radius(23px !important);
        color: $white !important;

        &:disabled {
            opacity: 0.2;
        }
    }

    .ingredient-search input {
        @include placeholder {
            text-transform: none;
        }
    }

    .mode-picker {
        li {
            display: inline-block;

            button {
                color: $text-gray;
                border: 1px solid $el-grayish-blue;
                @include border-radius(4px);

                background: none;
                padding: 5px 10px;

                font-size: 12px;
                margin: 5px;
                text-transform: uppercase;

                &[data-active="true"] {
                    color: white;
                    background-color: $el-grayish-blue;
                    border: 1px solid $el-grayish-blue;
                }
            }
        }
    }
}

.smart-choices-picker-loading {
    text-align: center;

    h2 {
        padding: 20px 20px 0px;
        color: #425569;
        font-size: 28px
    }

    i {
        display: block;
        margin: auto;
        font-size: 3em;
        color: $raspberry;
    }
}

.smart-choices-question {
    text-align: center;

    .el-alert {
        min-width: 325px;
        max-width: 350px;
    }
}


@import "../../../../sass/imports";

.combo-details-modal {
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;

    z-index: 11;
}

.combo-details-modal-container {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; width: 100%;
    background-color: white;
    z-index: 1;

    > header {
        z-index: 3;
        position: absolute;
        top: 0;
        width: 100%;

        button {
            border: none;
            background: none;
            float: right;
            padding: 5px 8px;
            margin-top: 10px;
            margin-right: 10px;
            z-index: 3;
            color: white;
            background: black;

            @include border-radius(100%);
        }
    }

    .modal-scroll-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        z-index: 2;
    }
}

@import "../../../../sass/imports";

.meal-repeat-modal {

}

.meal-repeat-modal-container {
    color: $text-navy;

    &.edit-meal-modal-container {
        .modal-scroll-container {
            padding: 15px 15px 180px 15px;

            > header {
                text-align: center;
                padding: 20px;

                h1 {
                    font-size: 20px;
                    margin: 0 0 20px 0;
                }

                h3 {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }

    .more-dates-btn {
        width: 100%;
        border: none;
        background: none;
        text-align: center;
        padding: 4px 0;
        color: $text-gray;
        font-size: 14px;
        text-decoration: underline;

        &:hover {
            background-color: $light-gray-border;
        }
    }
}

.upcoming-dates-picker {
    h4 {
        color: $text-gray;
        font-size: 14px;
    }

    ul {
        margin-bottom: 15px;
    }

    li {
        vertical-align: top;
        white-space: nowrap;
        position: relative;
    }

    .meal-toggle-btn {
        text-align: left;
        width: 100%;
        border: none;
        background: none;
        color: $text-gray;
        font-size: 12px;
        padding: 3px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;

        &:before {
            @include icon-font;
            font-size: 14px;
            vertical-align: middle;
            margin: 0px 5px 0 0px;
            display: inline-block;
            width: 17px;
            height: 17px;
            color: white;
            content: ' '; // $icon-checkbox-unchecked;
            border: 1px solid $bluey-gray;
            padding: 1px;
        }

        &:disabled {
            opacity: 0.5;
        }

        em {
            margin-right: 5px;
        }

        span {
            font-weight: 300;
        }

        &[data-selected="true"] {
            &:before {
                content: $icon-check;
                background-color: $mint-green3;
            }
        }
    }

    .grid-meal-draggable {
        top: 30px;
        height: auto;
        bottom: 0;
        margin-bottom: 0;
        cursor: pointer;
    }

    .empty-meal {
        position: absolute;
        top: 30px;
        height: 100px;
        width: 100%;
        border: 1px dashed $bluey-gray;
        @include border-radius(3px);

        text-align: center;
        padding: 35px 0;
        color: $bluey-gray;
        font-size: 14px;
        cursor: pointer;
    }
}

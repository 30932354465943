@import "../../../sass/imports";
@import '../MyAccount/PreferencesForm.scss';
@import "../Preferences/Avoidances.scss";

.avoidances-modal-container {
    background-color: #fafafa;

    .avoidances-selector {
        margin: 0 0 5em 0;
    }

    .avoidances-page {
        text-align: center;

        i {
            display: block;
            font-size: 22px;
            color: $raspberry;
            width: 22px;
            margin: 2em auto;
        }
    }
}

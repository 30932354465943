@import "../../../../../sass/imports";

.taxonomy-filter {
    display: flex;

    .remove-taxonomy-filter-btn {
        border: none;
        background: none;
        font-size: 12px;
        padding: 8px;
        color: $bluey-gray;
    }
}

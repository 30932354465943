
@import "../../../../sass/imports";

.board-picker {

    > header {
        width: 100%;

        img {
            @include border-radius(100%);
            width: 5em;
            height: 5em;
            margin: auto;
            display: block;
            border: 2px solid $text-gray;
        }

        > h4 {
            color: $text-gray;
            text-align: center;
            font-size: .875em;
            padding: 2em .5em 1em .5em;
        }
    }

    > footer {
        text-align: center;
        padding: 20px 0 0 0;

    }

    .close-btn, .cancel-btn {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 1.2px;
        color: white;
        background-color: $raspberry;
        @include border-radius(40px);
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
        min-width: 100px;
        padding: 13px 23px;
        border: none;
        margin: 15px;

        @include transition(all 0.333s ease-in-out);

        &:hover {
            background-color: darken($raspberry, 20%);
            border: solid 0.5px darken($raspberry, 20%);
            color: white;
        }

        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
        }

        &:disabled {
            opacity: 0.3;
        }
    }


    .cancel-btn {
        background-color: $bluey-gray;
        box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .remove-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;
    }

    .board-scrollable {
        width: 100%;
        overflow-x: scroll;

        @include min-screen(47em) {
            height: auto;
            max-height: 25em;
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }

    .inner-slider {
        display: table;

        @include min-screen(47em) {
            display: block;
            padding-bottom: .5em;
        }

        &:last-child {
            padding-right: .5em;
        }
    }

    .board-container {
        display: table-cell;

        @include min-screen(47em) {
            display: inline-block;
        }
    }

    .board, .create-new-board {
        @include border-radius(.5em);
        padding: .75em;
        margin: .5em 0 0 .5em;
        cursor: pointer;
        width: 10em;
        height: 10em;
        background-size: cover;
        display: inline-block;
        vertical-align: top;

        > h5, > p {
            color: $grayscale2;
        }

        > i {
            float: right;
            font-size: 1.5em;
        }

        &[data-boarded="true"] > i {
            color: $raspberry;
        }
    }

    .create-new-board {
        @include border-radius(.5em);
        border: 1px dashed $grayscale4;
        background-color: $grayscale2;
        text-align: center;

        > i {
            float: none;
            font-size: 2em;
            color: $mint-green3;
            display: block;
            padding-top: 1em;
        }

        > span {
            display: inline-block;
            padding-top: 1em;
        }
    }

    .create-new-board-form {
        padding: .75em;

        > input {
            width: calc(100%);
            border: 1px solid $grayscale8;
            font-size: 16px;
            margin-top: 2em;
        }

        > button {
            @include flat-button($mint-green3);
            display: block;
            width: auto;
            margin: .5em auto;
        }
    }
}

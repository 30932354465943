
@import "../../../sass/imports";

.location-info {
    margin: 21px auto 5px auto;

    padding: 20px;
    max-width: 760px;

    @include min-screen(47em) {
        margin: 20px auto;
        padding: 0 0 15px 0;
    }

    h2 {
        font-size: 18px;
        color: $text-navy;
        font-weight: 800;
        margin: 0 0 4px 0;
    }

    p {
        color: $text-gray;
        font-size: 14px;
    }

    a {
        color: $raspberry;
        font-size: 15px;
        line-height: 1.4;
        font-weight: 600;
        letter-spacing: normal;
    }

    .col3 {
        padding: 8px 0 0 0;
    }

    @include min-screen(47em) {
        .col1, .col3 {
            display: inline-block;
            vertical-align: top;
        }
        .col1 {
            width: 55%;

            h2, p {
                display: inline-block;
            }

            h2 {
                margin: 0 10px 4px 0px;
            }
        }
        .col3 {
            padding: 0;
            width: 45%;
        }
    }
}


@import "../../../sass/imports";
@import "../../../sass/pro-style-guide";


.file-upload, .add-daily-log-photo-btn {
    position: relative;
    width: 100%;

    .error {
        font-weight: 800;
        color: $red;
    }

    .default-file-upload-btn {
        background-color: transparent;
        border-width: 0px;
        @include border-radius(0);
        color: #a30063;
        display: inline-block;
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
        outline: 0;
        text-transform: uppercase;
        text-align: center;
        text-decoration: underline;
        padding: 8px 10px;
        @include outline-button($raspberry);
        margin: 5px;      
    }

    &.add-daily-log-photo-btn {
        .default-file-upload-btn {
            text-decoration: none;
            padding: unset;
            margin: unset;
        }
    }

    &.medium-upload-btn {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    font-size: 8px;
                }
            }

            .upload-bar-background {
                top: 12px;
            }
        }
    }


    &.large-upload-btn {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    font-size: 10px;
                }
            }

            .upload-bar-background {
                top: 15px;
            }
        }
    }

    &.extra-large-upload-btn {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    font-size: 12px;
                }
            }

            .upload-bar-background {
                top: 18px;
            }
        }
    }

    &.footer-upload-btn {

        button {
            @include max-screen(470px) {
                width: 100%;
                margin: 0;
            }
        }
    }

    button {
        .uploading-text {
            text-transform: none;
            display: block;
            position: relative;

            &[data-uploading="true"]{
                bottom: 10px;
                font-size: 6px;
                background-color: initial;
                color: initial;
            }
        }

        .upload-bar-background {
            width: 67.5%;
            height: 6px;
            background: rgba(50, 66, 83, .12);
            border-radius: 15px;
            position: relative;
            margin: 0 auto;
            top: 10px;
            display: block;

            .upload-bar-fill {
                position: absolute;
                left: 0;
                width: 50%;
                height: 6px;
                background: #A30063;
                border-radius: 15px;
            }
        }
    }

    &.add-daily-log-photo-btn {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    bottom: 15px;
                    font-size: 8px;
                    background-color: initial;
                    color: initial;
                }
            }

        }

        .upload-bar-background {
            width: 75%;
            height: 8px;
            top: 14px;
        }
    }

    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        width: 100%;
    }

    &[data-uploading="true"] {
        &:hover {
            button {
                background-color: initial;
                color: initial;
            }
        }
    }
}

@import '../../../sass/imports';
@import '../FeedModals.scss';

.search-foods-modal-container {
    @include min-screen(47em) {
        max-width: 655px;
    }

    .global-search-results-params {
        margin-top: 20px;
    }
}

.search-foods-and-recipes-footer {
    padding: 0;
    align-items: center;
}

.add-swap-full-browser {
    header {
        .scan-barcode-btn,
        .advanced-filters-btn {
            padding: 0;
            width: 40px;
            height: 40px;
        }
    }
}

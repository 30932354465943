
@import "../../../../../../sass/imports";

.add-remove-nutrients-modal {
    top: 10%;
    left: 50%;
    bottom: 10%;

    width: 100%;
    max-width: 563px;
    @include transform(translateX(-50%));

    z-index: 11;
}

.add-remove-nutrients-container {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; width: 100%;
    background-color: white;

    .editor-scrollable {
        top: 170px !important;
        bottom: 120px !important;
        left: 40px;
        right: 40px;
        width: auto !important;
        border: 1px solid $inactive-text-gray;
        padding: 5px 20px;
    }

    .search {
        position: absolute;
        top: 102px;
        left: 40px;
        right: 40px;
        border: 1px solid $inactive-text-gray;

        &:before {
            @include icon-font;
            content: $icon-search3;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            left: 0;
            font-size: 16px;
            padding: 16px 21px;
            color: $text-gray;
        }

        > input {
            width: 100%;
            font-size: 12px;
            color: $text-gray;
            padding: 17px 0 17px 56px;
            border: none;
        }
    }

    ul li {
        list-style: none;
        margin: 10px auto;
    }

    .global-search-checkbox {
        span {
            font-size: 12px;
            font-weight: 900;
            text-transform: uppercase;

            &:before {
                margin-right: 20px;
            }

            .icon-lock {
                margin-left: 10px;
                font-size: 11px;
            }
        }

        input {
            cursor: pointer;
        }
    }

    > footer {
        position: absolute;
        bottom: 0;
        padding: 30px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;


        text-align: center;

        button {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
            background-color: white;
            border: solid 0.5px $inactive-text-gray;
            font-size: 10px;

            padding: 14px;
            width: 50%;
            max-width: 230px;
            margin: 10px;
            text-transform: uppercase;

            border: solid 0.5px #9b9fb4;

            @include transition(all 0.333s ease-in-out);

            &:hover {
                background-color: #9b9fb4;
                color: white;
            }

            &:focus {
                @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
            }
        }

        .accept {
            border: solid 0.5px $mint-green3;
            background-color: $mint-green3;
            color: white;

            &:hover {
                background-color: darken($mint-green3, 20%);
                border: solid 0.5px darken($mint-green3, 20%);
                color: white;
            }
        }
    }
}

.add-remove-nutrients {
    display: inline-block;
    margin: 10px;

    > .nutrient-btn{
        @include flat-button($raspberry);
        cursor: pointer;

        font-family: Lato;
        font-size: 10px;
        font-weight: bold;
        color: white;
        text-transform: uppercase;
        padding: 8px 22px 8px 40px;

        position: relative;

        .icon-lock {
            padding-right: 10px;
            margin-left: -20px;
        }


        &:before {
                @include icon-font;
                content: $icon-plus-thin;
                position: absolute;
                top: 50%;
                @include transform(translateY(-50%));
                left: 0;
                padding: 12px;
                font-size: 16px;
                color: white;
        }


        &:focus {
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
        }
    }
}


@import "../../../../sass/imports";

.accept-license {
    color: $text-gray;
    padding: 0 0 50px 0;

    .agreement-footer {
        position: absolute;
        bottom: 0;
        width: 100vw;
        text-align: center;
        padding: 20px;
        background: white;

        @include min-screen(675px) {
            width: 100%;
        }
    }

    .editor-scrollable {
        padding: 0 20px 120px 20px;
        top: 0px;
    }

    .decline-btn {
        @include outline-button($bluey-gray);
    }

    .agree-btn {
        @include flat-button($mint-green3);
    }

    .why-required {
        margin-top: 1em;
        font-size: 16px;
        font-weight: 300;
        width: calc(100% - 30px);
        color: white;
        padding: 10px;

        text-align: center;
        background-color: $text-gray;
        text-align: center;
        cursor: pointer;
        @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.2));

        p {
            margin: 0 !important;
            font-weight: 900;
            font-size: 18px !important;
            color: white !important;
        }

        em {
            font-weight: bold;
        }

    }

    > header {
        text-align: center;
        padding: 0 20px;

        p {
            margin-top: 0.25em;
        }
    }

    h1 {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin: 1em;
        margin-bottom: 0;
    }

    h2 {
        text-transform: uppercase;
        margin: 1.5em 0 .5em 0;
        text-align: left !important;
        font-size: 16px;

        em {
            display: inline-block;
            margin-right: .5em;
        }
    }

    h3 {
        margin-left: 2em;
        text-decoration: underline;
        font-size: 14px;
    }

    p {
        margin: .75em 0;
        font-size: 14px;
    }

    .recitals-heading {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }

    ol {
        li {
            margin: 1em 0;

            em {
                display: inline-block;
                margin: 0 2em;
            }
        }
    }

    .bold {
        font-weight: bold;
    }

    .italic {
        font-style: italic;
    }

    .underline {
        text-decoration: underline;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .indent1 {
        margin-left: 2em;
    }

    .indent2 {
        margin-left: 4em;
    }

    .subsection-heading {
        display: block;
        font-weight: bold;
        text-decoration: underline;
    }

    .signatures {
        .signature {
            margin: 20px;
            width: calc(50% - 40px);
            min-width: 300px;
            display: inline-block;
            vertical-align: top;
        }

        li {
            margin: .75em 0;
        }

        img {
            max-height: 50px;
        }
    }

    .sub-action-btn {
        @include outline-button($raspberry);
        @include border-radius(0);
        background-color: transparent;
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        text-align: center;
        margin: 5px;
    }
}


@import "../../../../sass/imports";

.food-card {
    .recipe-card-image {
        background-image: $food-card-gradient;
    }

    .icon-logo2 {
        position: absolute;
        top: 40%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        font-size: 60px;
        opacity: .2;

        .path1:before {
            color: $text-navy;
        }
    }
}

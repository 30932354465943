
@import "../../../../sass/imports";

.edit-recipe-servings {
    white-space: nowrap;

    .placeholder {
    	text-align: right;
    	padding-left: 34px;
        @include min-screen(47em) {
            padding-left: 29px;
        }
    }

    &[data-enter="true"] {
        widtH: 150px;

        input {
            width: 80px;
        }

        label {
            display: inline-block;
             margin: 0 0 6px 10px;
             font-size: 16px;
             color: $el-raspberry;
        }
    }
}


@import "../../../../sass/imports";
@import "../../FeedModals.scss";
@import "./LogPortionsModal.scss";
@import "./LeftoverOffsetsModal.scss";
@import "../../MyAccount/PreferencesForm.scss";

.confirm-overwrite-container {

    > header h2 {
        margin: 20px;
        text-align: center;
    }

    &.leftover-offsets-container {

        @include max-screen(430px) {
            top: 0;
            left: 0;
            right: 0;
            max-width: none;
            max-height: none;
            width: auto;
        }

        @include min-screen(429px) {
            width: calc(100vw - 20px);
            max-width: 675px;
            max-height: 100vh;
        }

        overflow-y: auto;
        overflow-x: hidden;

        .checkbox-btn {
            margin: 10px;

            &:after {
                margin: 0 5px 10px 0;
            }
        }
    }

    .overlap-list > li {
        margin: 15px;
        @include max-screen(752px) {
            margin: 15px 15px 15px 10vw;
        }

        &:not(:last-child) {
            padding-bottom: 30px;
            border-bottom: 1px solid #d6d7d9;       
        }

        > header {
            text-align: center;
        }

        > footer {
            display: inline-block;
            min-width: 250px;
            margin: 0 auto 40px auto;
        }
    }

    .grid-meal-draggable {
        white-space: initial;
        text-align: left;
        position: static;
        display: inline-block;

        width: 150px;
        position: relative;
        vertical-align: middle;

        @include max-screen(752px) {
            .controls-container {
                display: none;
            }
            width: 75px;
            height: 75px;
            margin: 0;
        }
    }

    .meals-container {
        display: block;
        text-align: center;        
        white-space: nowrap;
        min-width: 340px;
        position: relative;

        @include max-screen(752px) {
            display: inline-block;
            text-align: left;
        }

        h4 {
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            color: $text-navy;
            max-width: calc(100% - 150px);
            margin: 10px 0px 20px 85px;

            @include max-screen(752px) {
                font-size: 16px;
                margin: 10px 0 20px 0;
            }
        }

        .select-container {
            width: 150px;
            margin: 10px;
            vertical-align: middle;

            @include max-screen(752px) {
                margin: 15px 0;
                display: block;
                width: 130px;
                padding: 3px 5px;

                .value {
                    font-size: 14px;

                }
            }
        }

        .meals-row {
            width: 100%;
            display: flex;
        }

        .meals-titles-wrapper {
            line-height: 75px;
            vertical-align: middle;
            width: calc(100% - 90px);

            .meals-titles {
                display: inline-block;
                vertical-align: middle;
                line-height: 20px;
                font-size: 14px;
                margin-left: 20px;
                font-weight: bold;
                word-break: break-word;
                white-space: normal;
            }
        }


    }

    .meal-type-radio {
        > button {
            width: 33%;
            white-space: nowrap;
            padding: 8px 0;
        }
    }

    .confirm-overwrite-form {
        padding: 0;
        min-height: 250px;
    }
}


@import "../../../../../../sass/imports";

.remove-nutrients-container {

    .editor-scrollable {
        top: 100px !important;
        bottom: 120px !important;
        left: 40px;
        right: 40px;
        width: auto !important;
        border: 1px solid $inactive-text-gray;
        padding: 20px;
    }

    .confirm-remove {
        position: absolute;
        top: 100px !important;
        bottom: 120px !important;
        width: 100%;
        overflow-y: auto;

        text-align: center;

        h2 {
            font-size: 18px;
            color: $text-gray;
            margin: 40px auto;
        }

        i {
            font-size: 46px;
            color: $text-gray;
            display: block;
        }

        span {
            display: inline-block;
            border: 1px solid $inactive-text-gray;
            font-size: 10px;
            text-transform: uppercase;
            padding: 18px 20px;
            margin: 5px;
        }
    }

    ul li {
        list-style: none;
        margin: 10px auto;
    }
}

.add-remove-nutrients {
        &.remove-nutrients {

         > .nutrient-btn {
            &:before {
                content: $icon-minus-thin;
            }      
        }
    }
}

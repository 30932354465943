
@import "../../../sass/imports";
@import "../../../sass/pro-style-guide";

.file-upload-popup, .add-daily-log-photo-btn-popup, .food-editor-photo-btn-popup, .recipe-editor-photo-btn-popup {
    position: relative;
    width: 100%;

    .triangle, .triangle2, .close-btn {
        display: none !important;
    }

    .error {
        font-weight: 800;
        color: $red;
    }

    .dropdown-btn {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .popup-content {
        width: 200px;
        padding: 0;
        opacity: 0.9;
        color: #000000;
    }

    .photo-options {
        width: 100%;
        font-size: 18px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .default-file-upload-btn {
        background-color: transparent;
        display: inline-block;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }

    &.recipe-editor-photo-btn-popup {
        .default-file-upload-btn {
            height: 40px;
            width: 40px;
        }
    }

    &.food-editor-photo-btn-popup {
        .default-file-upload-btn {
            padding-top: 10px;
            height: 80px;
            width: 80px;
            color: $raspberry;
        }
    }

    &.add-daily-log-photo-btn-popup {
        .default-file-upload-btn {
            margin: unset;
            padding: unset;
            height: 62px;
            width: 62px;
            font-size: 24px;
            color: $raspberry;
        }
    }

    &.medium-upload-btn {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    font-size: 8px;
                }
            }

            .upload-bar-background {
                top: 12px;
            }
        }
    }


    &.large-upload-btn {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    font-size: 10px;
                }
            }

            .upload-bar-background {
                top: 15px;
            }
        }
    }

    &.extra-large-upload-btn {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    font-size: 12px;
                }
            }

            .upload-bar-background {
                top: 18px;
            }
        }
    }

    &.footer-upload-btn {

        button {
            @include max-screen(470px) {
                width: 100%;
                margin: 0;
            }
        }
    }

    button {
        border: none;
        background: none;

        .uploading-text {
            text-transform: none;
            display: block;
            position: relative;

            p {
                line-height: 1;
            }

            &[data-uploading="true"]{
                bottom: 20px;
                font-size: 12px;
                background-color: initial;
                color: initial;
            }
        }

        .upload-bar-background {
            width: 67.5%;
            height: 6px;
            background: rgba(50, 66, 83, .12);
            border-radius: 15px;
            position: relative;
            margin: 0 auto;
            top: 12px;
            display: block;

            .upload-bar-fill {
                position: absolute;
                left: 0;
                width: 50%;
                height: 6px;
                background: #A30063;
                border-radius: 15px;
            }
        }
    }

    &.recipe-editor-photo-btn-popup {
        button {
            .upload-bar-background {
                top: 18px;
            }
            .uploading-text {
                &[data-uploading="true"]{
                    bottom: 2px;
                    font-size: 8px;
                }
            }

        }

        button > i {
                color: unset !important;
        }
    }

    &.add-daily-log-photo-btn-popup {
        button {
            .uploading-text {
                &[data-uploading="true"]{
                    bottom: 15px;
                    font-size: 8px;
                }
            }

        }

        .upload-bar-background {
            width: 75%;
            height: 8px;
            top: 14px;
        }
    }

    input[type="file"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        top: unset !important;
        bottom: unset !important;
        left: unset !important;
        right: unset !important;
    }

}

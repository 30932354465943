@import "../../../sass/imports";

.postal-code-modal-container {
    color: $text-gray;

    .postal-code-form {
        margin: 15px 0;
        text-align: center;

        > p {
            margin: 0 0 10px 0;
        }
    }

    footer {
        button {
            i {
                position: relative;
                right: 5px;
            }
        }
    }
}

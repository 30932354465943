
@import "../../../sass/imports";

.global-search-results {
    max-width: 960px;
    margin: auto;
    margin-bottom: 2em;
    text-align: center;

    @include max-screen(1031px) {
        margin: auto 20px;
    }

    .result-container {
        display: inline-block;
        width: calc(100% - 14px);
        max-width: 300px;
        margin: 7px;
        vertical-align: top;
        position: relative;

        @include screen(540px, 680px) {
            width: calc(50% - 14px);
            min-width: 300px;
        }

        @include min-screen(47em) {
            text-align: left;
        }

        > footer {
            position: absolute;
            bottom: 10px;
            left: calc(50% + 60px);
            @include transform(translate(-50%, 0));

            .already-in-collection{
                font-size: 12px;
                p {
                    font-size: 12px;
                    text-align: right;
                }

                i {
                    float: left;
                    font-size: 23px;
                    color: green;
                    margin: 2px 0 0 0;
                }
            }

            button {
                @include border-radius(32px);
                border: 1px solid $light-gray-border;
                color: $text-gray;
                background: none;
                font-size: 12px;
                text-transform: uppercase;
                padding: 5px 20px;
                font-weight: bold;
                white-space: nowrap;

                .icon-lock {
                    display: inline-block;
                    margin: 0 5px;
                }

                .icon-spinner2 {
                    display: inline-block;
                }
            }
        }

        .false-collection-result {
            background-color: rgb(163, 0, 99, 0.05);
            height: 120px;
            font-size: 13px;
            color: $text-gray;
            padding: 0px 0px 0 15px;
            text-align: left;

            p, svg{
                display: inline-block;

            }

            svg {
                bottom: 30px;
                position: relative;

                @include max-screen(600px) {
                    bottom: 40px;
                }

                @include max-screen(540px) {
                    bottom: 30px;
                }

            }

            p {
                line-height: 16px;
                max-width: 245px;
                padding: 28px 0px 10px 20px;

                a {
                    color: $raspberry;
                }

                @include max-screen(660px) {
                    padding: 20px 0px 10px 20px;
                    max-width: 220px;
                }

                @include max-screen(625px) {
                    padding: 15px 0px 10px 20px;
                    max-width: 200px;
                }

                @include max-screen(575px) {
                    padding: 10px 0px 10px 20px;
                    max-width: 190px;
                }

                @include max-screen(550px) {
                    max-width: 180px;
                }

                @include max-screen(540px) {
                    padding: 28px 0px 10px 20px;
                    max-width: 245px;
                }
            }
        }
    }
}


@import "../../../sass/imports";

.feed-swap-menu {
    .drawer-menu-modal-container {
        .inner-container {
            @include box-shadow(10px, 0, 60px, 0, rgba(0, 0, 0, 0.1));
            background-image: linear-gradient(170deg, #da67ad, #b7498c);
        }

        .menu-item {
            color: white;

            i {
                color: #b7498c;
            }
        }
    }
}


@import "../../../sass/imports";

.amazon-login-btn {
    margin: 5px;
    padding: 6px 13px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;
    color: black;
    border-color: #B48E30; // #690 #466900 #3d5c00;
    border-width: 1px;
    border-style: solid;
    background: linear-gradient(to bottom,#FEE499,#F1BC37);
    text-align: center;

    i {
        font-size: 20px;
    }

    &[data-working="false"] {
        opacity: 0.5;
    }
}

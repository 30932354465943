
@import "../../../../../../sass/imports";

.envelope-editor {
    display: flex;
    flex-direction: column;

    .add-nutrient, .remove-nutrient {
        border: 1px solid $raspberry;
        color: $raspberry;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        width: 160px !important;
        height: 40px;
        padding: 12px 22px 15px 42px;

        &:after {
            content: $icon-add-square-solid;
            left: 0;
            right: initial;
            font-size: 16px;
            padding: 12px;
            color: $raspberry;
        }
    }

    .remove-nutrient {
        border: 1px solid $text-gray;
        color: $text-gray;

        &:after {
            content: $icon-minus-square-solid;
            color: $text-gray;
        }
    }

    .remove-rx-btn {
        @include outline-button($text-gray);
        @include border-radius(0);
        border-width: 1px;
        text-align: center;
        font-size: 10px;
        padding: 12px 22px;
    }

    > footer {
        text-align: center;
        padding: 20px;
    }

    &[data-edit-mode="true"] {
        .entry-container {
            input {
                border: 1px solid $inactive-text-gray;
            }

            &[data-empty="true"] {
                .label {
                    opacity: 1 !important;
                }
            }
        }
    }
}


@import "../../../../sass/imports";

.patient-card {
    min-height: 127px;
    background-color: #ffffff;
    position: relative;
    cursor: pointer;

    &.unread-message-card {
        background-color: rgb(163, 0, 99, 0.05);

        .alert-subtext {
            min-height: 32px;
        }
    }

    &.alert-card {
        margin-bottom: 10px;
        box-shadow: 0px 20px 54px rgba(146, 146, 146, 0.1);


        .alert-info {
            display: inline-block;
            padding: 7px 10px 0px 75px;

            h4 {
                line-height: 2;
            }

            .alert-subtext {

                .message-sender {
                    font-size: 13px;
                    color: $raspberry;
                }

                .condition {
                    font-size: 14px;
                    line-height: 0.5;
                    margin-top: 3px;
                    margin-bottom: 10px;
                }

                .last-activity, .expiration {
                    line-height: 2;
                    > p {
                        display: inline-block;

                        &:first-child {
                            margin-right: 5px;
                        }
                    }
                }

            }

            .next-step-btn, .renew-btn, .extend-btn, .view-messages-btn {
                font-size: 11px;
            }
        }

    }

    &[data-even="true"] {
        @include max-screen(708px) {
            background-color: #fafafa;
        }
    }

    .status-dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        position: absolute;
        left: 39px;
        top: 45px;
    }

    .following-btn {
        position: absolute;
        bottom: 6px;
        left: 25%;
        border: none;
        background: none;
        max-width: 50%;
        padding: 5px;

        @include transform(translate(-50%, 0));

        > span {
            display: inline-block;
            max-width: 50px;
            vertical-align: middle;
            text-transform: uppercase;

            font-size: 10px;
            line-height: 1.4;
            letter-spacing: 0.8px;
            color: $bluey-gray;

        }

        &:before {
            @include icon-font;
            content: ' ';
            color: $text-gray;
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            color: white;

            padding-top: .25em;
            padding-left: .25em;
            margin-right: 5px;
            width: 20px;
            height: 20px;
            border: solid 0.5px #e3e4e9;
            @include border-radius(20px);
        }

        &[data-checked="true"] {
            &:before {
                border: solid 0.5px #1d976b;
                background-color: #2cd296;
                content: $icon-check;
            }
        }

        &[data-working="true"] {
            &:before {
                content: $icon-spinner !important;
                color: $raspberry !important;
            }
        }
    }

    .next-step-btn, .renew-btn, .extend-btn, .view-messages-btn {
        @include outline-button($raspberry);
        font-size: 9px;
        white-space: nowrap;
        padding: 8px 15px;
        font-size: 9px;
        margin: 10px 0;
    }


    .view-messages-btn {
        background-color: rgba(163,0,99,.05);

        &:focus {
            background-color: $raspberry;
        }
    }

    a {
        text-decoration: none;
        display: block;
        width: 100%;
    }

    h4 {
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: $text-gray;
        max-height: 33px;
        overflow: hidden;
    }

    .avatar, .icon-messages {
        width: 39px;
        height: 39px;
        position: absolute;
        text-align: center;
    }

    .icon-messages {
        top: 8px;
        left: 12px;
    }

    .avatar {
        top: 13px;
        left: 10px;
        background-color: #EAEDEF;
        @include border-radius(80px);    
    }

    i.avatar, i.icon-messages {
        font-size: 22px;
        padding: 7px;
        color: $text-gray;
        display: block;
    }

    i.icon-messages {
        font-weight: bold;
        font-size: 28px;
        color: $raspberry;
    }

    .left-side, .right-side {
        display: inline-block;
        vertical-align: top;
        margin: 15px 0;
        max-height: 75px;
        width: 50%
    }

    .last-active-date {
        text-align: left;
        font-size: 12px;
        color: #425569;
        &[data-deactivated="true"] {
            color: #C7CED6;
        }
    }

    .left-side {
        position: relative;
        padding: 5px 10px 0px 60px;

        .patient-name{
            font-size: 14px;
        }

    }

    .right-side {
        padding: 0 25px 25px 20px;
        max-height: none;
        height: 127px;

        .message-bubble {
            width: 34px;
            height: 34px;
            border-radius: 34px;
            background: #EAEDEF;
            position: absolute;
            bottom: 15px;
            left: 275px;

            .icon-messages {
                font-size: 14px;
                position: absolute;
                top: 5px;
                left: -2px;
                color: black;

                @include min-screen(47em) {
                    bottom: 9px;
                    left: 10px;
                }
            }

            .icon-email4 {
                font-size: 12px;
                position: absolute;
                bottom: 10px;
                left: 9px;
                color: black;
            }
        }


        &:before {
            content: ' ';
            height: 127px;
            width: 1px;
            position: absolute;
            right: 50%;
            top: 50%;
            @include transform(translate(0, -50%));
            border-right: 1px solid #EAEDEF;
            @include min-screen(47em) {
                top: 75%;
            }
        }
        &[data-even="true"] {
            @include max-screen(708px) {
                &:before {
                    border-right: 1px solid #B5BEC8;
                }
            }
        }

    }



    .condition {
        font-size: 12px;
        line-height: 1.4;
        letter-spacing: 0.8px;
        color: $bluey-gray;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 15px;

        em {
            font-weight: 900;
            font-style: normal;
        }

        &[data-risk="low"] em {
            color: #2cd296;
        }
        &[data-risk="medium"] em {
            color: #f5a623;
        }
        &[data-risk="high"] em {
            color: #f61f67;
        }
    }

    .invite-not-accepted {
        font-size: 9px;
        line-height: 1.33;
        letter-spacing: 0.8px;
        text-align: center;
        color: $raspberry;
        text-transform: uppercase;
        margin: 29px auto 20px auto;
    }

    .last-activity, .expiration {
        text-align: center;
        line-height: 1.17;

        p:first-child {
            color: #8894a0;
            font-size: 12px;
        }

        p:last-child {
            font-size: 12px;
            font-weight: bold;
            color: $text-gray;
            line-height: 1.33;
            letter-spacing: 0.5px;
            white-space: nowrap;
        }
    }
}


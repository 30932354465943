
@import "../../../sass/imports";

.el-popup {
    &.variances-popup {
        .dropdown-btn {
            border: none;
            background: none;
            color: $orange4;

            .icon-warning5 {
                font-size: 22px;
                padding-right: 5px;
            }
        }

        .popup-content {
            min-width: 260px;
        }
    }
}

.variances-popup-content {
    text-align: left;
    min-width: 175px;
    min-height: 2.5em;

    h3 {
        color: white;
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 10px 0;
    }

    p {
        color: white;
        font-size: 14px;
        text-align: center;
        margin: .75em 0;

        em {
            font-weight: bold;
        }
    }

    ul > li {
        font-size: 14px;
        color: white;
        white-space: nowrap;
        text-align: right;

        span {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            display: inline-block;
            margin: 0 0 0 5px;
        }

        em {
            display: inline-block;
            text-align: right;
            padding: 0 0.5em;
            white-space: nowrap;
            font-family: monospace;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }

    .over {
        color: $orange4;
    }

    .under {
        color: $blue2;
    }

    .meal-variance {
        margin: 0 0 15px 0;

        > header {
            color: white;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid rgba(255, 255, 255, .2);
        }
    }

    .disclaimer {
        margin: .75em 0 0 0;
        color: white;

        p {
            font-size: 12px;
            text-align: left;
        }
    }

    > footer {
        text-align: center;
        padding: 10px 0 0 0;
    }

    .add-swap-btn {
        @include outline-button($light-gray-border);
        background-color: transparent;
    }
}


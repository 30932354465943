
@import "../../../../../docs/sass/imports";
@import "../../../../../docs/sass/style-guide";

.el-alert {
    margin-top: 20px;
    position: relative;
    padding: 13px 20px;
    &[data-has-icon="true"] {
        padding: 13px 20px 13px 68px;
    }
    text-align: left;
    border-radius: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;

    h6, span{
        color: #425569;
    }

    .action-button {
        border-radius: 25px;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        padding: 3px 23px;
        border: none;
        margin-left: 15px;
        @include min-screen(47em) {
            margin-left: 30px;

        }
    }

    span {
        text-align: left;
        font-size: 14px;
        line-height: 16px;
        display: inline-block;
    }

    &[data-has-button="true"] {
        span {
            width: 160px;
            @include min-screen(47em) {
                width: 200px;

            }
        }

        i {
            display: inline-block;
        }
    }
    .el-alert--hint {
        position: absolute;
        top: 50%;
        left: 22px;
        @include transform(translate(0, -50%));
        .dropdown-btn {
            background-color: transparent;
            border: none;
        }

        .dropdown-dropdown {
            @include transform(translate(-10%, 0px));
            
            @include max-screen(47em) {
                > .dropdown-container {
                    > .triangle, > .triangle2 {
                        left: 10%;
                    }
                }
            }
            @include min-screen(47em) {
                @include transform(translate(-50%, 0px));
            }
        }

        .dropdown-content {
            min-width: 350px;
            
            p {
                text-align: left;
            }
        }
    }
    .el-alert--icon {
        width: 24px;
        height: 24px;
        &.btn {
            cursor: pointer;
        }
        i {
            font-size: 24px;
        }
    }
    &.question {
        background-color: #FFE4D7;
        .el-alert--icon i {
            color: #FF7735;
        }
        .action-button {
            background-color: #FF7735;
        }
    }
    &.info {
        background-color: #DDEEFC;
        .el-alert--icon i {
            color: #55ACEE;
        }
        .action-button {
            background-color: #55ACEE;
        }
    }
    &.error {
        background-color: #FFD1D1;
        .el-alert--icon i {
            color: #FF1B1B;
        }
        .action-button {
            background-color: #FF1B1B;
        }
    }
    &.success {
        background-color: #D5F6EA;
        .el-alert--icon i {
            color: #2CD296;
        }
        .action-button {
            background-color: #2CD296;
        }
    }
    &.warning {
        background-color: #F8F1E1;
        .el-alert--icon i {
            color: #DCB76B;
        }
        .action-button {
            background-color: #DCB76B;
        }
    }
    
}

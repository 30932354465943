
// Buttons and Button Styles
.el-text-btn {
    font-size: 16px;
    line-height: 23px;
    border: none;
    background: none;
    color: $el-raspberry;
    cursor: pointer;
    text-decoration: none;
}

.el-large-btn, .el-medium-btn, .el-small-btn {
    text-align: center;
    text-transform: uppercase;
    border-radius: 25px;
    margin: 0 10px;
    transition: border 0.25s, background-color 0.25s, color 0.25s;
    white-space: nowrap;

    @include min-screen($breakpoint) {
        margin: 0 20px;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.el-btn-icon-left {
    i {
        display: inline-block;
        margin: 0 10px 0 0;
    }
}

.el-btn-icon-right {
    i {
        display: inline-block;
        margin: 0 0 0 10px;
    }
}


.el-btn-icon-alone {
    i {
        display: inline-block;
    }
}

.el-full-width-btn {
    width: 100%;
}

.el-wide-btn {
    @include min-screen($breakpoint) {
        min-width: 200px;
    }
}

.el-medium-wide-btn {
    @include min-screen($breakpoint) {
        min-width: 150px;
    }
}

.el-large-btn {
    font-size: 20px;
    line-height: 24px;
    padding: 8px 16px;
    text-align: center;
}

.el-medium-btn {
    font-size: 14px;
    line-height: 17px;
    padding: 6px 16px;
    text-align: center;
}

.el-small-btn {
    font-size: 12px;
    line-height: 14px;
    padding: 0 16px;
    text-align: center;
}

.el-orange-btn {
    border: 1px solid $el-orange;
    background-color: $el-orange;
    color: $el-white;

    &:hover {
        background-color: $el-orange3;
        border: 1px solid $el-orange3;
    }

    &:focus {
        background-color: $el-orange4;
        border: 1px solid $el-orange4;
    }
}
.el-green-btn {
    border: 1px solid $el-green;
    background-color: $el-green;
    color: $el-white;

    &:hover {
        background-color: $el-green3;
        border: 1px solid $el-green3;
    }

    &:focus {
        background-color: $el-green4;
        border: 1px solid $el-green4;
    }
}
.el-raspberry-btn {
    border: 1px solid $el-raspberry;
    background-color: $el-raspberry;
    color: $el-white;

    &:hover {
        background-color: $el-raspberry3;
        border: 1px solid $el-raspberry3;
    }

    &:focus {
        background-color: $el-raspberry4;
        border: 1px solid $el-raspberry4;
    }
}
.el-blue-btn {
    border: 1px solid $el-blue;
    background-color: $el-blue;
    color: $el-white;

    &:hover {
        background-color: transparent;
        color: $el-blue;
    }

    &:focus {
        background-color: $el-raspberry4;
        border: 1px solid $el-raspberry4;
    }

}
.el-grayish-blue-btn {
    border: 1px solid $el-grayish-blue;
    background-color: $el-grayish-blue;
    color: $el-white;

    &:hover {
        background-color: $el-grayish-blue3;
        border: 1px solid $el-grayish-blue3;
    }

    &:focus {
        background-color: $el-grayish-blue4;
        border: 1px solid $el-grayish-blue4;
    }
}
.el-gray-btn {
    border: 1px solid $el-grayish-blue2;
    background-color: $el-grayish-blue2;
    color: $el-grayish-blue4;

    &:hover {
        background-color: $el-grayish-blue3;
        border: 1px solid $el-grayish-blue3;
        color: white;
    }

    &:focus {
        background-color: $el-grayish-blue4;
        border: 1px solid $el-grayish-blue4;
        color: white;
    }
}
.el-grayish-blue-outline-btn {
    border: 1px solid $el-grayish-blue;
    background-color: $el-white;
    color: $el-grayish-blue;

    &:hover {
        border: 1px solid $el-grayish-blue3;
        background-color: $el-grayish-blue3;
        color: white;
    }

    &:focus {
        border: 1px solid $el-grayish-blue4;
        background-color: $el-grayish-blue4;
        color: white;
    }
}
.el-raspberry-outline-btn {
    border: 1px solid $el-raspberry;
    background-color: $el-white;
    color: $el-raspberry;

    &:hover {
        border: 1px solid $el-raspberry3;
        background-color: $el-raspberry3;
        color: white;
    }

    &:focus {
        border: 1px solid $el-raspberry4;
        background-color: $el-raspberry4;
        color: white;
    }
}
.el-blue-outline-btn {
    border: 1px solid $el-blue;
    background-color: transparent;
    color: $el-blue;

    &:hover {
        background-color: $el-blue;
        color: white;
    }

    &:focus {
        background-color: $el-blue;
        color: $el-grayish-blue;
    }
}
.el-white-outline-btn {
    border: 1px solid $el-white;
    background-color: $el-grayish-blue;
    color: $el-white;

    &:hover {
        background-color: $el-white;
        color: $el-grayish-blue;
    }

    &:focus {
        background-color: $el-grayish-blue2;
        color: $el-grayish-blue;
    }
}


@import "../../../../../../sass/imports";

@include keyframes(pulsing-dropshadow) {
    0% {
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
    }
    50% {
        border: 1px solid $orange;
        @include box-shadow(0, 2px, 6px, 0, $orange);
    }
    100% {
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
    }
}

.percent-entry {
    .mode-toggle {
        @include outline-button($raspberry);
        @include border-radius(0);
        font-size: 10px;
        text-transform: uppercase;
        padding: 8px 10px;
        border-width: 0px;
        text-decoration: underline;
        margin: 5px;
        vertical-align: middle;

        &:focus {
            font-weight: bold;
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }

        @include max-screen(47em) {
            padding: 8px 2px;
        }
    }

    .mode-entry-suffix {
        display: inline-block;
        font-size: 14px;
        color: $text-gray;
        padding: 3px;
        font-weight: bold;
        opacity: 0;
    }

    &[data-mode="kcal"] {
        .mode-entry-suffix {
            opacity: 1;
        }
    }

    &[data-mode="total"] {
        .mode-entry-suffix {
            opacity: 1;
        }
        .mode-toggle {
            opacity: 0;
        }

        input[type="text"] {
            border: none !important;
            opacity: 1;
        }
    }

    .drop-down {
        position: absolute;
        z-index: 10;
        top: 74%;
        width: 100%;
        min-width: 240px;
        left: 50%;
        /*@include transform(translateX(-50%));*/

        opacity: 0;
        max-height: 0;
        overflow: hidden;
        @include transition(opacity .333s ease-in-out);

        h6 {
            color: $text-gray;
            font-weight: bold;
            font-size: 14px;
            width: calc(100% - 25px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            color: $text-gray;
            font-size: 12px;
            margin-top: 10px;
        }

        .close-btn {
            position: absolute;
            top: 0; right: 0;
            border: none;
            background: none;
            font-size: 10px;
            padding: 12px;
            color: $text-gray;
        }
    }

    &[data-dropdown="true"] {
        .drop-down {
            max-height: 25em;
            opacity: 1;
            background-color: white;
            border: 1px solid $inactive-text-gray;
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
            padding: 10px;

            @include animation-name(pulsing-dropshadow);
            @include animation-duration(.5s);
            @include animation-timing-function(linear);
            @include animation-iteration-count(5);
        }
    }
}

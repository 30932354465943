
@import "../../../../sass/imports";

.edit-recipe-groups {
    position: relative;
    padding: 0 40px;
    @include min-screen(47em) {
        padding: 0 80px;
    }

    .variances-popup {
        position: absolute;
        top: 0;
        right: 0;
    }

    .group-title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1.15px;
        color: $text-navy;
        padding: 7px 0 7px 5px;
        margin: 30px 0 7px 0;
        border: none;
        background: none;
        width: 100%;

        &:focus {
            border: 1px solid #EDEFF2;
            @include border-radius(7px);
            @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.3));
            @include placeholder() {
                color: #81909f;
                text-transform: none;
                font-weight: normal;
            }
        }

        @include placeholder() {
            font-size: 16px;
            @include min-screen(47em) {
                font-size: 14px;
            }
            font-weight: bold;
            letter-spacing: 1.15px;
            color: $text-navy;
            text-transform: uppercase;

        }
    }
}

.edit-recipe-group {
    > header {
        width: calc(100% - 10px);
        display: flex;

        input {
            flex-grow: 9;
        }
    }

    > footer {
        margin: 0 0 20px 0;
    }

    .add-food-btn {
        margin: 0;
        font-size: 11px;
        @include border-radius(40px);
        background-color: $raspberry;
        color: white;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(168, 172, 190, 0.2);
        border: none;
        -webkit-appearance: none;
        padding: 12px 25px 12px 25px;
        font-weight: bold;
        text-transform: uppercase;

        i {
            font-size: 11px;
            vertical-align: middle;
        }
    }

    .add-group-btn {
        @include outline-button($mint-green3);
        border: none;
        white-space: nowrap;
        width: calc(50% - 10px);
        margin: 5px;
        padding: 8px;
    }

    .delete-group-btn {
        @include outline-button($raspberry);
        border: none;
        white-space: nowrap;
        margin: 5px;
        padding: 8px;
    }

    .group-menu {
        flex-shrink: 1;
        margin-top: 22px;

        h4 {
            color: $text-gray;
            font-size: 18px;
        }

        .popup-content {
            min-width: 275px;
        }

        .dropdown-btn {
            border: none;
            background: none;
            font-size: 18px;
            color: #203152;
            padding: 14px 0 0 10px;

            i {
                display: inline-block;
                @include transform(rotate(90deg));
            }
        }
    }
}

.ingredient-group-item-list {
    display: table;
    border-collapse: separate;
    border-spacing: 10px 20px;
    width: 100%;

    .ingredient {
        display: table-row;
        position: relative;

        padding: 7px 0;
        margin: 0 0 14px 0;
        border-bottom: 1px solid $light-gray;
        position: relative;
        text-align: right;
        margin: 0;

        &:after {
            position: absolute;
            content: ' ';
            width: 100%;
            height: 1px;
            background-color: $el-grayish-blue1;
            bottom: -12px;
            left: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    .ingredient-quantity, .ingredient-item-name, .ingredient-controls {
        display: table-cell;
    }

    .ingredient-controls {
        white-space: nowrap;
    }

    .nutrition-info-btn, .ingredient-alert-btn, .edit-ingredient-btn {
        vertical-align: top;
        background: none;
        border: none;
        color: $text-gray;
        font-size: 11px;
        margin-left: 10px;

        i {
            padding: 5px;
            width: 22px;
            height: 22px;
            display: inline-block;
            font-size: 11px;
        }

        .icon-analyze {
            border: 1px solid $text-gray;
            @include border-radius(20px);
        }

        .icon-pencil3 {
            font-size: 16px;
        }

        .feather-alert-triangle {
            color: $el-orange3;
            font-size: 16px;
        }
    }

    .ingredient-alert {
        .popup-content {
            text-align: left;
            min-width: 200px;

            p {
                margin: 0 0 2em 0;
                font-size: 14px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &[data-dragging="true"] {
        opacity: 0.5;
        border: 1px dashed green;
    }

    .ingredient-item-name {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: left;

        > p {
            font-size: 16px;
            font-weight: bold;
            color: $text-navy;
            text-align: left;
            cursor: move;
            line-height: 20px;
        }

        .mapped-food {
            color: $el-grayish-blue4;

            em {
                font-weight: 300;
            }

            .select-grocery-item-btn {
                margin: 0;
                font-weight: normal;
            }
        }

        .brand {
            display: inline-block;
            font-weight: 300;
            margin-right: .5em;
            vertical-align: middle;
        }
    }

}

@import "../../../../sass/imports";

.quick-add-ingredients-textarea {
    min-height: 200px;
    line-height: unset !important;
}

.ingredients-loader {

    .loading-text {
        color: $el-raspberry;
        margin-top: 4px;
    }
}


@import "./../../../../sass/imports";

.down-for-maintenance {
    .icon-logo, img {
        display: block;
        width: 50px;
        margin: 10px auto 25px auto;
        font-size: 48px;
        color: $raspberry;
    }

    pre {
        font-size: 14px;
        padding: 5px;
        margin: 20px auto;
        overflow: auto;
        background-color: $lighter-gray;
    }
}

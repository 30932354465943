@import "../../../../sass/imports";

.deactivate-btn {
	color: #2E3B55;
}

.deactivate-working-btn {
}

.reactivate-btn {
	background-color: #2CD296;
	color: #FFFFFF;
}

.inactive-deactivate-btn {
	color: #2E3B55;
}

.patient-deactivator {
}

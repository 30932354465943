@import "../../../../sass/imports";

.page-card {
    margin: 10px;
    overflow: hidden;
    @include border-radius(5px);
    @include box-shadow(0, 10px, 10px, 0, rgba(0, 0, 0, 0.2));


    .card-image {
        background-image: $food-card-gradient;
        width: 100%;
        background-size: cover;
        background-position: center;
        padding-top: 60%;
        position: relative;
    }

    .card-text {
        padding: 10px;
    }

    a, a:visited {
        color: $text-navy;
    }

    h3 {
        font-size: 20px;
        font-weight: bold;
        height: 2.35em;
        overflow: hidden;
        line-height: 1.1;
    }

    .description {
        font-size: 14px;
        height: 12em;

        position: relative;
        overflow: hidden;
        &:after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 1.3em;
            background: linear-gradient(to bottom,  rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.50) 50%, rgba(255, 255, 255, 1) 100%);
        }
    }

    .icon-logo2 {
        position: absolute;
        top: 40%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        font-size: 60px;
        opacity: .2;

        .path1:before {
            color: $text-navy;
        }
    }

    .tags {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: $bluey-gray;
        margin: 10px 0;
        height: 2.3em;
    }
}

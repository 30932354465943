@import "../../../sass/imports";

.orders-container {

    .order-info {
        img {
            margin: 0 auto;
            display: block;
        }

        p {
            text-align: center;
            white-space: nowrap;
        }

        .id {
            line-height: 17px;
            text-align: center;
            margin: 0 0 7px 0;
            font-weight: 400;
        }

        .placed, .status, .delivery {
            line-height: 18px;
        }

        span {
            margin: 0 6px 0 0;
            text-align: right;
        }

        a {
            color: $el-raspberry-gradient3-2;
        }
    }
}

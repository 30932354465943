
@import "../../../../sass/imports";

.send-invite-modal {

}

.send-invite-modal-container {
    .modal-body {
        padding: 40px;
        padding-bottom: 0;
        min-height: 15em;
        text-align: center;
    }

    h2 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: $text-gray;
        margin-top: 30px;
        margin-bottom: 50px;
    }

    p {
        margin-top: 30px;
        color: $text-gray;
        font-size: 14px;
    }

    .icon-email, .icon-spinner {
        color: $raspberry;
        font-size: 36px;
        display: inline-block;
    }
}


@import "../../../sass/imports";

.pro-header {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    background-color: $dark-slate-gray;
    @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.13));
    @include transition(all .25s ease-in-out);

    @media print {
        box-shadow: none;
        background: none;
        display: none;
    }

    .header-container {
        position: relative;
        margin: 0 auto;
    }

    .version-notifier {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 90px;
        background: none;
        z-index: 10;
        padding: 5px;
        text-align: center;
        color: $text-gray;
        font-size: 14px;

        .el-alert {
            margin-top: 30px;
            padding: 20px;
            &[data-has-icon="true"] {
                padding: 20px 20px 20px 68px;
            }
        }

        .notifier-container {
            position: absolute;
            top: 50%;
            left: 0; right: 0; width: 100%;
            @include transform(translateY(-50%));
        }
    }

    .logo-container {
        display: inline-block;
        margin: 12px 0 12px 20px;

        a {
            background-image: url(https://static.chewba.info/images/eatlove-logo-white-2019.svg);
            background-repeat: no-repeat;
            background-size: 100px 22px;
            display: inline-block;
            height: 22px;
            color: white;
            font-size: 12px;
            padding: 7px 0 0 106px;

            @media print {
                background-image: url(https://static.chewba.info/images/eatlove-logo-grey-2019.svg);
            }

            @media (min-width:47em) {
                height: 37px;
                padding: 13px 0 0 178px;
                font-size: 18px;
                background-size: 172px 37px;
            }
        }
    }

    .tagline {
        color: white;
        font-size: 10px;
        font-weight: 500;
        padding: 0 0 0 5px;

        @media print {
            color: $text-navy;
        }

        @include min-screen(47em) {
            font-size: 1em;
        }
    }


    .avatar {
        display: inline-block;
        padding: 7px 0 0 10px;
        vertical-align: top;

        @include min-screen(47em) {
            padding: 18px 0 0 10px;
        }

        img, i, em {
            width: 35px;
            height: 35px;
            @include border-radius(35px);
            display: inline-block;
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
        }

        em {
            font-size: 14px;
            white-space: nowrap;
            font-weight: bold;
            padding: 8px 4px;
            text-align: center;
            color: $raspberry;
            background-color: white;
        }

        i.icon-male3, i.icon-female3 {
            font-size: 20px;
            padding: 7px 7px;
            background-color: white;
            color: $raspberry;
        }
    }

    .navigation-links {
        @media print {
            display: none;
        }

        @include max-screen(47em) {
            display: block;
            background-image: linear-gradient(to bottom, #5b7287, #495e73);

            a {
                color: $light-blue-grey;
                text-align: center;
                width: 20%;
                display: inline-block;
                padding: 7px 0 3px 0;

                &.active {
                    box-shadow: inset -0.5px 0 0 0 rgba(0, 0, 0, 0.1), inset 0.5px 0 0 0 rgba(0, 0, 0, 0.1);
                    -webkit-box-shadow: inset -0.5px 0 0 0 rgba(0, 0, 0, 0.1), inset 0.5px 0 0 0 rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: inset -0.5px 0 0 0 rgba(0, 0, 0, 0.1), inset 0.5px 0 0 0 rgba(0, 0, 0, 0.1);
                    background-image: linear-gradient(to bottom, #889bac, #5e748b);
                }

                > span {
                    font-size: 9px;
                    letter-spacing: .5px;
                }

                > i {
                    font-size: 15px;
                }
            }

            .header-invite-link {
                display: none;
            }
        }

        @include min-screen(47em) {
            display: inline-block;
            text-align: left;
            vertical-align: top;
            overflow: hidden;
            width: calc(100% - 369px);
            padding: 12px 0px;
            text-align: center;
            white-space: nowrap;

            a {
                color: $light-blue-grey;
                font-size: 12px;
                padding: 12px 1em;
                text-decoration: none;
                position: relative;
                text-overflow: ellipsis;
                display: inline-block;
                vertical-align: top;

                &:hover {
                    color: $mint-green3;
                }

                &.active {
                    /*color: #a8acbe;*/
                    color: $mint-green3;
                }

                > i {
                    margin: 1px 0 5px 0;
                    font-size: 14px;
                }
            }

            .icon-patients {
                font-size: 15px;
            }
        }


        i {
            display: block;
            text-align: center;
        }

        .badge {
            display: inline-block;
            position: absolute;
            font-size: 9px;
            top: 7px;
            left: 38px;
            color: #fff;
            background-color: $orange4;
            @include border-radius(10em);
            width: 12px;
            height: 12px;
            display: inline-block;
            font-weight: bold;
            cursor: pointer;
            white-space: nowrap;
            text-align: center;
            line-height: 12px;

            @include max-screen(47em) {
                bottom: 28px;
                left: 28px;
                line-height: 11px;
                top: unset;
                position: relative;
            }
        }

    }

    .utility-links {
        position: absolute;
        top: 0;
        right: 0;
        white-space: nowrap;
        padding: 0 10px 0 0;

        @media print {
            display: none;
        }

        @include min-screen(47em) {
            padding: 0 20px 0 0;
            top: 50%;
            @include transform(translateY(-50%));
        }

        .icon-logo2 {
            font-size: 27px;

            .path1:before,
            .path2:before {
                color: $light-blue-grey;
            }
        }

        .dropdown-btn {
            border: none;
            background: none;
        }
    }

    .go-to-consumer > button {
        border: none;
        background: none;
        padding: 15px 10px;
        color: $light-blue-grey;
        font-size: 22px;
    }

    .drawer-menu-btn {
        background-color: transparent;
        border: none;
        color: $light-blue-grey;
        text-align: right;
        font-size: 27px;
        line-height: 1;
        text-decoration: none;
        position: relative;
        padding: 0px 15px 0 15px;

        @include min-screen(47em) {
            padding: 4px 0 4px 10px;
        }

        i {
            font-size: 0.7em;
            line-height: 50px;
        }
    }

    .signin-btn {
        border: none;
        background: none;
        font-size: 10px;
        font-weight: bold;
        padding: 10px 10px;
        margin: 1em;
        color: white;
        text-transform: uppercase;
    }

    .pro-signup-btn {
        @include outline-button(white, $raspberry);
        @include border-radius(0);
        border-width: 1px;
        border-color: $raspberry;
        font-size: 10px;
        font-weight: bold;
        padding: 10px 20px;
        margin: 1em;
        @include box-shadow(0, 4px, 10px, 0, rgba(0, 0, 0, 0.3));
    }

    .header-freetrial-link {
        padding: 10px 0 10px 7px;
        background-image: linear-gradient(to right, rgba($dark-slate-gray, 0) 0%, rgba($dark-slate-gray, 1) 10%);
        display: inline-block;
        text-align: center;
    }

    .free-trial-remaining {
        font-size: 10px;
        font-style: italic;
        color: $orange3;
        vertical-align: bottom;
        cursor: pointer;
        text-decoration: none;
    }
}


@import "../../../../sass/imports";
@import '../../../pro/components/Widgets/Select.scss';
@import "../../FeedModals.scss";


.edit-timings-modal {

}

.edit-timings-modal-container {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    min-width: 300px;
    width: calc(100vw - 40px);
    max-width: 375px;
    max-height: calc(100vh - 40px);

    > footer {
        text-align: center;
    }
}

.edit-timings-modal-container-new {
    .el-labeled-input {
        margin-bottom: 20px;
    }

    .explanation {
        .dropdown-btn {
            color: $raspberry;
            padding: 0 3px;
            border: none;
            background: none;
        }

        .popup-content {
            min-width: 250px;

            p {
                color: $text-navy;
                font-size: 14px;
                margin: 0.75em 0;
            }
        }
    }
}

.edit-timings-modal-body {
    padding: 0 20px 100px;

    .with-label {
        .prep-label {
            padding: 10px 5px;
        }

        .optional-text {
            position: static;
            margin-left: 2px;
            text-transform: none;
        }
    }

    > .with-label:last-child {
        margin-bottom: 10px;
    }
}

.duration-selector {
    display: flex;

    .select-container {
        display: inline-block;
        width: 40%;
        background-color: rgba(66,85,105,.1);
        border-radius: 40px;
        padding: 12px 20px 12px 10px;
        cursor: pointer;

        .value, .placeholder {
            font-size: 14px;
        }
    }

    .select-hours {
        border-radius: 40px 0 0 40px;
        border-right: 2px solid white;
        flex: 1;
    }

    .select-minutes {
        border-radius: 0 40px 40px 0;
        flex: 1;
    }
}


@import "../../../../sass/imports";

.doc-type-params ul {
    text-align: center;

    li {
        display: inline-block;

        button {
            color: $text-gray;
            border: 1px solid $bluey-gray;
            background: none;
            padding: 5px 10px;
            @include border-radius(3px);

            font-size: 10px;
            margin: 5px;
            text-transform: uppercase;
            white-space: nowrap;

            &[data-active="true"] {
                color: white;
                background-color: $bluey-gray;
                border: 1px solid $bluey-gray;
            }
        }
    }
}

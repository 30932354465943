
@import "../../../sass/imports";

.feed-skip-menu {
    .drawer-menu-modal-container {
        .inner-container {
            background-image: linear-gradient(172deg, #8aa1b6, #5a6d82);
            @include box-shadow(10px, 0, 60px, 0, rgba(0, 0, 0, 0.1));
        }

        .menu-item {
            color: white;

            i {
                color: #5a6d82;
            }
        }
    }

    .skip-meal-btn {
        border: 1px solid white;
        @include border-radius(25px);
        font-size: 10px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 1px;
        text-align: center;
        color: white;
        padding: 13px 35px !important;
        margin: 40px 80px 0 0;
        text-transform: uppercase;
        background-color: rgba(0, 0, 0, 0.1);
    }
}

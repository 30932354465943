
@import "../../../sass/imports";

.report-issue-btn {

}

.report-issue-form {
    padding: 20px;

    h2 {
        font-size: 24px;
        color: $text-navy;
        text-align: center;
    }

    p {
        color: $text-navy;
        font-size: 14px;
        text-align: center;
    }

    textarea {
        width: 100%;
        min-height: 100px;
        font-size: 14px;
        color: $raspberry;
        font-weight: bold;

        &::placeholder {
            font-weight: normal;
            font-style: italic;
            color: $bluey-gray;
        }
    }

    .error-msg {
        font-weight: bold;
        color: $orange4;
    }
}

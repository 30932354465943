
@import "../../../../sass/imports";

.dashboard-needs-update {
    margin: 0 0 60px 0;

    > header {
        margin: 0 0 15px;
        h2 {
            font-size: 24px;
            color: #425569;

            @include min-screen(47em) {
                text-align: left;
            }
        }
    }

    .card-container {
        width: 100%;
        max-width: 350px;
        margin: auto;
        position: relative;
    }

    .grocery-alert-card {
        font-size: 14px;

        .alert-subtext {
            color: #425569;
            font-weight: normal;
        }
        .reviews-info > span {
            color: $raspberry;
        }
        .manage-grocery-btn {
            @include flat-button($raspberry);
            font-size: 10px;
            font-weight: bold;
            width: 100%;
            display: block;
            white-space: nowrap;
            padding: 8px 15px;
            font-size: 9px;
            margin: 10px 0;
        }
    }

    .hide-btn {
        border: none;
        background: none;
        position: absolute;
        top: 14px;
        right: 15px;
        color: black;
        font-size: 13px;
    }
}

@import "../../../../sass/imports";
@import "../../../pro/components/Plans/Editor/PlanToPdfButton.scss";

.collection-to-pdf-form {
    .admonition {
        margin: 1em 5px;
        font-size: 14px;
    }
}

.collection-to-pdf-btn {
    @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.1));
    @include border-radius(5em);
    background-color: white;
    border: solid 1px $raspberry;
    margin-right: 10px;
    color: $raspberry;
    height: 36px;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 3px;
    min-width: 180px;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 10px;
    padding: 10px;
    height: 100%;

    & > i {
        font-size: 18px;
        position: relative;
        bottom: -2px;
    }
}
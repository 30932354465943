
@import "../../../../../sass/imports";

.customizer-pick-date-modal {


}

.customizer-pick-date-container {
    background-color: white;

    h2 {
        font-size: 18px;
        text-align: center;
        margin: 1em;
        width: calc(100% - 80px);
    }

    .custom-calendar {
        margin: 0;
        width: 100%;
    }
}


@import "../../../../../sass/imports";

.customizer-meal-cell {
    width: 100%;
    padding: 10px;
    position: relative;

    .mismatch-modal-btn, .pro-dropdown-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 15;

        &[data-state="true"] {
            z-index: 16;
        }
    }

    .mismatch-modal-btn, .pro-dropdown-container .dropdown-btn {
        border: none;
        background: $warning-yellow;
        color: white;
        padding: 2px 5px;
        display: block;
        margin: 3px;
        font-size: 16px;
        font-weight: bold;
        @include transition(all .2s ease-in-out);
        width: 26px;
        height: 26px;
        overflow: hidden;
        text-align: center;
        @include border-radius(10em);

        i {
            color: white;
        }

        &:hover {
            background: darken($warning-yellow, 10%);
        }

        @include min-screen(47em) {
            font-size: 14px;
        }
    }

    .pro-dropdown-container .dropdown-dropdown {
        @include transform(translate(-50%, -5px));
    }

    &[data-offset="0"] {
        .pro-dropdown-container .dropdown-dropdown {
            @include transform(translate(-13%, -5px));

            .triangle, .triangle2 {
                left: 13%;
            }
        }
    }

    &[data-hover="true"] {
        .customizer-meal-draggable, .add-recipe-btn {
            border: 1px dashed red;
        }
    }

    .add-recipe-btn {
        height: 127px;
        width: 100%;
        color: $bluey-gray;
        @include border-radius(5px);
        border-style: dashed;
        font-size: 40px;
        background-color: white;
    }

    .mismatch-actions {
        padding: 20px 0 0 0;
        text-align: center;
    }
}

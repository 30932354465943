
// Loading Icon

@include keyframes(spinning) {
  0% {
    @include transform(scale(.75) rotate(0deg));
  }
  50% {
    @include transform(scale(1.1) rotate(180deg));
  }
  100% {
    @include transform(scale(.75) rotate(360deg));
  }
}

@include keyframes(spinning-fixed-scale) {
  0% {
    @include transform(scale(1) rotate(0deg));
  }
  50% {
    @include transform(scale(1) rotate(180deg));
  }
  100% {
    @include transform(scale(1) rotate(360deg));
  }
}

.icon-spinner, .icon-spinner2 {
  @include animation-name(spinning);
  @include animation-duration(1s);
  @include animation-timing-function(linear);
  @include animation-iteration-count(120);

  &.fixed-scale {
    @include animation-name(spinning-fixed-scale);
  }
}


body > #app-loading-throbber,
#root > #app-loading-throbber {
    display: none !important;
}

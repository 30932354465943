
@import "../../../../sass/imports";

.add-swap-modal-container {
    > header {
        z-index: 10;
    }

    @include min-screen(47em) {
        bottom: auto;
        max-width: max-content;
    }
}

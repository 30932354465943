@import "../../../../../sass/imports";

.add-swap-search-use-own-recipe {
    width: calc(100vw - 40px);
    max-width: 1070px;
    padding: 10px 10px 120px;
    min-height: 85vh;

    @include max-screen(47em) {
        width: 100%;
        &:before {
            content: ' ';
            position: absolute;
            z-index: 5;
            left: 50%;
            width: 145vw;
            height: 305px;

            top: 0;
            @supports (top: constant(safe-area-inset-top)) {
                top: calc(constant(safe-area-inset-top));
            }

            @supports (top: env(safe-area-inset-top)) {
                top: calc(env(safe-area-inset-top));
            }

            @include transform(translate(-50%,calc(-100% + 193px)));
            @include border-radius(50%);

            @include background-image(linear-gradient(0deg, #5b7287, #324253 50%));
            @include box-shadow(0, 2px, 8px, 0, rgba(0, 0, 0, 0.5));
        }
    }
}

.mode-switcher {
    min-height: 70vh;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    .tile {
        white-space: initial;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    .loading-container {
        max-width: 575px;
        width: calc(100vw - 40px);
        text-align: center;
        padding-top: 150px;

        .icon-spinner2 {
            color: $el-raspberry;
            font-size: 50px;
            display: inline-block;
        }
    }

    &[data-transition="true"] .tile { @include transition(transform .25s ease-in-out); }

    &[data-position="1"] .tile { @include transform(translateX(-100%)); }
    &[data-position="2"] .tile { @include transform(translateX(-200%)); }
}

@import "../../../sass/imports";

.numbered-textarea {
    position: relative;
    box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.168139);
    -webkit-appearance: none;
    border-radius: 10px;
    background-color: $el-white;
    border: 1px solid transparent;
    color: $el-grayish-blue3;

    ol {
        list-style: auto;
        position: relative;
        z-index: 1;
    }

    textarea, ol {
        margin: 0 0 0 30px;
        padding: 8px 16px 8px 0;
    }

    .line-content {
        color: black;
        opacity: 0.3;
        white-space: pre-wrap;
    }

    textarea {
        border: none;
        resize: none;
        outline: none;
        font-family: inherit;
        width: calc(100% - 30px);

        height: 100%;
        background: transparent;
        color: $el-grayish-blue3;
        caret-color: black;
        overflow: hidden;

        position: absolute;
        z-index: 2;
        top: 0px;
        bottom: 0px;
        left: 0px;

        &:selection {
            background: rgba(0, 0, 0, 0.1);
            color: black;
        }
    }
}

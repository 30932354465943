
@import "../../../sass/imports";

.meals-scroll-hero {
    white-space: nowrap;
    background-image: linear-gradient(308deg, #a8acbe, #f0f0f0);
    overflow: hidden;
    position: relative;
    max-width: 972px;
    &::-webkit-scrollbar {
        display: none;
    }

    * {
        white-space: initial;
    }

    .hero-item {
        position: absolute;
        will-change: transform;
        left: 0;

        &:first-child .icon-plus-thick {
            display: none;
        }

        &:first-child .next-card-btn {
            display: none;
        }

        &:first-child .prev-card-btn {
            display: none;
        }

        display: inline-block;
        vertical-align: top;
        width: 250px;
        height: 250px;
        cursor: pointer;

        @include min-screen(47em) {
            width: 350px;
            height: 350px;
        }
    }

    .hero-tag {
        position: absolute;
        z-index: 10;
        top: 30px;
        right: 30px;
        height: 39px;
    }

    .hero-item-container {
        width: 100%;
        height: 100%;
        will-change: transform;
        width: 250px;
        height: 250px;

        position: relative;

        @include min-screen(47em) {
            width: 350px;
            height: 350px;
        }
    }

    .triangle {
        position: absolute;
        bottom: 0;

        @include transition(left 0.333s ease-in-out);
        z-index: 27;

        width: 0;
        height: 0;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-bottom: 24px solid #da67ad;
    }

    .icon-plus-thick {
        position: absolute;
        left: -19px;
        top: 52%;
        @include transform(translate(-50%, -50%));
        color: #e25cad;
        font-size: 22px;
    }

    .next-card-btn,
    .prev-card-btn {
        position: absolute;
        top: 58%;
        @include transform(translate(-50%, -50%));

        width: 40px;
        height: 40px;
        background-color: $el-white;
        border: none;
        cursor: pointer;
        @include border-radius(100px);
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));
        @include transition(background-color 0.25s ease-in-out);

        color: $bluey-gray;
        font-size: 17px;

        &:hover {
            background-color: $el-white;
        }

        &:focus {
            background-color: $el-white;
        }
    }

    .next-card-btn {
        left: unset;
        right: 0px;
    }

    .prev-card-btn {
        left: 40px;
    }

    .image-container {
        position: absolute;
        top: 20px; bottom: 20px;
        left: 0; right: 40px;
        overflow: hidden;
        @include box-shadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.1));
        @include transition(all .333s ease-in-out);

        .icon-logo2 {
            font-size: 6em;
            opacity: .3;
            display: inline-block;
            @include transform(translateY(15%));

            @include min-screen(47em) {
                @include transform(translateY(-25%));

            }
        }
    }

    .brand-product {
        background-image: $product-card-gradient;
    }

    .food {
        background-image: $food-card-gradient;
        .icon-logo2 {
            .path1:before {
                color: $text-navy;
            }
        }
    }

    .recipe-image {
        background-color: $grayscale5;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        width: 100%;
        height: 100%;

        &[data-has-thumb="true"] {
            background-size: cover;
            background-position: center;

            @include filter(blur(10px));
            @include transform(filter .25s ease-in-out);

            &.img-loaded {
                @include filter(none);
            }
        }
    }

    .image-card-text-overlay {
        position: absolute;
        top: 24%;
        left: 50%;
        max-width: 100%;
        padding: 0 5px;
        @include transform(translate(-50%, -50%));

        @include min-screen(47em) {
            top: 40%;
        }

        h4, h5 {
            text-align: center;
            color: white;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }

        h4 {
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 1.2px;

            @include min-screen(47em) {
                font-size: 18.8px;
            }
        }

        h5 {
            font-size: 21.6px;
            font-weight: 900;
            line-height: 1.5;
            letter-spacing: 2.2px;

            @include min-screen(47em) {
                font-size: 28px;
                letter-spacing: 3.1px;
            }
        }
    }

    .meal-info {
        position: absolute;
        bottom: 20px; left: 0px;
        width: calc(100% - 40px);

        font-size: 12px;
        color: white;
        font-weight: normal;
        background-color: rgba(28, 30, 45, 0.6);
        padding: 10px;
        line-height: 1.33;
        z-index: 2;
        @include transition(all .333s ease-in-out);

        .lj {
            display: flex;
        }

        .dish-badge {
            padding: 5px 9px;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.5px;
            border-radius: 3.7px;
            background-color: #5b7287;
            display: inline-block;
            margin: 0 6px 6px 0;
            text-transform: uppercase;
            white-space: nowrap;
        }

        img {
            margin: 0 10px 0 0;
            height: 18px;

            @include min-screen(47em) {
                height: 24px;
            }
        }

        p {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.13;
            letter-spacing: 0.5px;
            height: 2.3em;
            overflow: hidden;

            @include min-screen(47em) {
                font-size: 17px;
                line-height: 1.18;
                letter-spacing: 0.8px;
            }
        }

        em {
            font-weight: 300;
            text-transform: uppercase;
            color: white;
        }

        footer {
            text-align: right;
            min-height: 19px;
            content: ' ';
            margin: 0 0 5px;

            display: flex;
            justify-content: space-between;
        }
    }

    .favorite-btn, .delete-dish-btn, .swap-menu-btn, .edit-meal-btn {
        border: none;
        background: none;
        padding: 2px 8px;
        font-size: 16px;
    }

    .favorite-btn[data-boarded="true"] {
        color: #da67ad;
    }

    .delete-dish-btn {
    }

    .edit-meal-btn {
    }

    .swap-menu-btn {
        em {
            display: none;
        }
    }

    .container > footer {
        text-align: right;
        color: white;
        z-index: 20;
        border: none;
        background: none;

        &[data-boarded="true"] {
            color: lighten($raspberry, 25%);
        }
    }
}


@import "../../../sass/imports";
@import "../Search/Modals/AddSwapRecipe/SearchTypeModal.scss";

.date-selector-popover {
    text-align: center;
    width: 100%;
    max-width: 280px;
    margin: auto;

    .date-select-container {
        position: relative;
    }

    .dropdown-btn {
        text-align: center;

        &:after {
            float: none;
            color: $raspberry;
            font-size: 14px;
        }
    }

    .dropdown-dropdown {

    }

    .dropdown-content {
        overflow: hidden;

        .close-btn {
            display: none;
        }
    }

    .rw-widget {
        border: none;
    }


    .next-date-btn-wrapper, .prev-date-btn-wrapper {
        height: 42px;
        position: absolute;
        top: 49%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 60px;
        z-index: 10;
        right: 10px;
        background: none;
        border: none;
    }

    .next-date-btn, .prev-date-btn {
        border: none;
        background: none;
        @include border-radius(30px);
        width: 22px;
        height: 22px;
        border: 1px solid white;
        color: white;
        font-size: 12px;
        padding: 2px 4px 1px;
    }

    .next-date-btn-wrapper {
        padding: 0 0 0 20px;
        right: 0;
    }

    .prev-date-btn-wrapper {
        padding: 0 10px;
        padding: 0 20px 0 0;
        left: 0;
    }

    i {
        &.icon-chevron-down {
            display: inline;
            color: $el-raspberry;
            font-size: 15px;
            font-weight: 900;
            position: relative;
            left: 15px;
        }   
    }


}

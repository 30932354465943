
@import "../../../../sass/imports";

.global-search-sort {

    .dropdown-btn {
        padding: 20px 6px;
        text-transform: uppercase;
        > span {
            font-size: 9px;
            font-weight: 800;
        }

        &:after {
            content: $icon-sort-by;
            padding-left: 10px;
        }
    }

    &.global-search-hidable-filter {
        display: inline-block;
        vertical-align: top;

        .dropdown-container > button {
            display: inline-block;
        }
    }

}

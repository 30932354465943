@import "../../../../sass/imports";

.results-mini-card {
    font-size: 14px;
    margin: 5px;
    position: relative;
    padding: 0 0 0 40px;
    color: $text-navy;
    cursor: pointer;
    min-width: 150px;
    max-width: 300px;
    display: inline-block;
    text-align: left;
    width: 100%;
    min-height: 35px;
    vertical-align: middle;

    @include min-screen(500px) {
        width: 50%;
        max-width: 300px;
    }

    .thumb {
        width: 35px; height: 35px;
        display: inline-block;

        position: absolute;
        top: 50%;
        left: 0;
        @include transform(translate(0, -50%));
        @include border-radius(5px);
    }

    i.thumb {
        font-size: 24px;
        text-align: center;
        color: white;
        padding: 5px 0;
        background-image: $food-card-gradient;
    }

    .title, .cals {
        line-height: 1;

        em {
            font-weight: 300;
            display: inline-block;
            margin-right: .25em;
        }
    }

    .title {
        display: block;
        font-weight: normal;
    }

    .brand {
        font-weight: 300;
        display: inline-block;
        vertical-align: middle;
        margin-right: .5em;
    }

    .cals {
        color: $bluey-gray;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
    }
}

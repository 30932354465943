
@import "../../../../../../sass/imports";

.create-patient-modal {
}

.create-patient-container {
    &[data-working="true"] {
        > header, .wizard-page {
            opacity: 0;
        }
    }

    .editor-scrollable {
        &[data-active="true"] {
            z-index: 1;
        }

        height: calc(100% - 174px);
        padding-bottom: 40px;
    }

    .modal-footer {
        bottom: 0;
        z-index: 2;
        position: absolute;
        padding: 10px;
        white-space: nowrap;

        .error-msg {
            padding-bottom: 10px;
            font-weight: normal;

            em {
                font-weight: bold;
            }
        }

        > .prev, .skip, .next {
            padding: 8px 26px;
        }
    }

    .wizard-page {
        display: none;

        &[data-active="true"] {
            display: block;
        }
    }

    .accept-license {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 3;
    }
}

.accept-license {
    &.editor-modal-container {
        .editor-scrollable {
            height: calc(100%);
            padding-bottom: 80px;
        }
    }
}

.practice-expired {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));

    text-align: center;


    h1 {
        font-size: 20px;
        color: $text-gray;
        white-space: nowrap;
    }

    @include max-screen(47em) {
        width: 100%;
        h1 {
            white-space: initial;
        }
    }

    p {
        color: $text-gray;
        padding: 80px 0;
    }

    > footer {

        button {
            @include flat-button($raspberry);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @include min-screen(47em) {
                margin-right: 15px;
                padding: 10px 55px;
                width: 300px;
            }

            i {
                color: white;
            }
        }

    }
}


// Global Layout Rules

@mixin inner-container() {
    width: 96%;
    display: block;
    margin: 0 auto;
    max-width: 1000px;
    // @include min-screen(480px) { width: 90%; }
    /*@include min-screen(600px) { width: 80%; }*/
    /*@include min-screen(800px) { width: 70%; }*/
    /*@include min-screen(1200px) { */
    /*    width: 50%; */
    /*    max-width: 1200px;*/
    /*}*/
}




@import "../../../../sass/imports";

.global-search-select {
    position: relative;

    select {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        opacity: 0;
        z-index: 2;
        width: 100%;
    }

    section {
        color: #425569;
        font-size: 12px;
        text-align: center;

        &:after {
            display: inline-block;
            @include icon-font;
            content: $icon-chevron-down;
            padding-left: 2em;
        }
    }
}


.el-toolbar1 {
    display: flex;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    background-color: white;

    .el-toolbar-btn, .el-toolbar-popup .dropdown-btn {
        border: none;
        background: transparent;
        padding: 11px 21px;
        color: $el-grayish-blue;
        box-shadow: inset 0.5px 0px 0px rgba(0, 0, 0, 0.1), inset -0.5px 0px 0px rgba(0, 0, 0, 0.1);
        font-size: 11px;
        @include transition(all 0.25s ease-in-out);

        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;

        @include max-screen(47em) {
            &:has(i) {
                > span {
                    display: none;
                }
            }
        }

        i {
            font-size: 18px;
            display: inline-block;
        }

        &:hover {
            background-color: $el-gray4;
        }

        &:focus {
            background-color: $el-grayish-blue3;
            color: white;
        }
    }

    > * {
        &:first-child {
            &.el-toolbar-btn, &.el-toolbar-popup .dropdown-btn {
                &:focus, &:hover {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }
        }

        &:last-child {
            &.el-toolbar-btn, &.el-toolbar-popup .dropdown-btn {
                &:focus, &:hover {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

    }

    &:has(.el-toolbar-cta-btn) {
        position: relative;
        padding-right: 40px;
    }

    .el-toolbar-cta-btn {
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translate(13%, -50%));
    }

    .el-toolbar-orange-circle-cta-btn {
        border-radius: 40px;
        background-color: $el-orange;
        border: none;
        font-size: 26px;
        padding: 17px;
        color: white;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .el-toolbar-green-pill-btn {
        margin: 5px 13px;

        background-color: $el-green;
        border: none;
        border-radius: 20px;
        color: white;
        text-transform: uppercase;
        font-size: 10px;
        padding: 7px 10px;
        font-weight: bold;
        white-space: nowrap;

        line-height: 1.4;
        letter-spacing: 1px;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(39, 172, 75, 0.2);
        vertical-align: top;
        @include transition(background-color 0.25s ease-in-out);

        &:hover {
            background-color: darken($el-green, 10%);
        }

        i {
            display: inline-block;
            margin: 0 5px;
        }

    }

    .el-toolbar-btn, .el-toolbar-popup {
        flex-grow: 1;

        .dropdown-btn {
            width: 100%;
        }
    }

    .el-toolbar-search {
        flex-grow: 30;

        position: relative;

        input[type="search"] {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            background-color: transparent;
            border: none;
            padding-left: 10px;
            font-size: 16px;
            color: $el-grayish-blue;
        }

        .clear-search-btn {
            opacity: 0;
            position: absolute;
            top: 50%;
            right: 0;
            padding: 5px;
            font-size: 10px;

            border: none;
            background: none;
            @include transform(translateY(-50%));

            @include placeholder {
                color: $el-grayish-blue2;
            }
        }

        &[data-has-terms="true"] {
            .clear-search-btn {
                opacity: 1;
            }
        }
    }

    &.el-toolbar1-dark {
        background-color: $el-grayish-blue;

        .el-toolbar-btn, .el-toolbar-popup .dropdown-btn {
            color: white;

            &:hover {
                background-color: $el-grayish-blue3;
            }

            &:focus {
                background-color: $el-grayish-blue4;
            }
        }
    }
}

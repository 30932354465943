
@import "../../../../sass/imports";

.combobox-container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;

    &[data-disabled="true"] {
        opacity: 0.5;
    }

    &[data-focus="true"] {
        @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
    }

    .chevron {
        position: absolute;
        display: inline-block;
        border: none;
        background: none;
        padding: 10px;
        top: 50%;
        @include transform(translateY(-50%));
        right: 0;
        z-index: 1;
        color: $text-navy;
        font-size: 8px;
    }

    .type-ahead {
        cursor: pointer;
        z-index: 2;
    }

    .search {
        width: calc(100% - 10px) !important;
        margin: 5px;
    }

    .placeholder {
        color: $inactive-text-gray;
    }

    .combobox-dropdown {
        position: absolute;
        top: 100%;
        left: 50%;
        @include transform(translate(-50%, 0px));
        max-height: 0;
        width: 100%;
        overflow: hidden;
        z-index: 25;

        background-color: #ffffff;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        border: solid 0.5px #e0e0e0;

        opacity: 0;
        @include transition(opacity .15s ease-in-out);

        .dropdown-content {
            position: relative;
            padding: 0px;
            z-index: 26;
        }

        .close-btn {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background: none;
            padding: 1.5em;
            font-size: 8px;
            color: #425569;
        }
    }

    &[data-state="true"] {
        .combobox-dropdown {
            opacity: 1;
            max-height: 20em;
            overflow-y: scroll;
            padding-top: 5px;
            @include box-shadow(0, 2px, 6px, 0, rgba(0, 0, 0, 0.35));
        }
    }

    &[data-above="true"] {
        .combobox-dropdown {
            bottom: calc(100% + 5px);
            top: auto;
        }
    }

    .option {
        cursor: pointer;
        padding: 5px 10px;
        color: $text-gray;
        @include transition(all .05s ease-in-out);

        &[data-selected="true"] {
            background-color: $dinner-color !important;
            color: white;
        }

        &[data-disabled="true"] {
            color: $inactive-text-gray;
        }

        &:hover {
            background-color: $breakfast-color;
        }

    }

    .sub-label-short {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
            color:  white;
            font-size: 16px;
            width: auto;
            cursor: pointer;
        }

        &[data-selected="false"] {
            label {
                color: $raspberry;
            }
        }
    }
}

.clear-name-btn {
    border: none;
    background: none;
    margin: 0 12px;
    position: relative;
    z-index: 3;
    font-size: 10px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    i {
        line-height: 40px;
    }
}
